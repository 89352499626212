import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { housekeepingOverviewDate } from '../../housekeeping/housekeeping-overview/state/housekeeping-overview-state'

export function useDateUpdate() {
    const [date, setDate] = useRecoilState(housekeepingOverviewDate)

    const [autoMode, setAutoMode] = useState(true)
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

    const getCurrentMoment = () => moment()

    useEffect(() => {
        const systemNow = getCurrentMoment()
        if (!date.isSame(systemNow, 'day')) {
            setAutoMode(false)
        } else {
            setAutoMode(true)
        }
    }, [date, setAutoMode])

    useEffect(() => {
        if (!autoMode) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
            return
        }

        intervalRef.current = setInterval(() => {
            const systemNow = getCurrentMoment()

            if (!date.isSame(systemNow, 'day')) {
                setDate(systemNow)
            }
        }, 60000)

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [autoMode, date, setDate])

    return [date, setDate]
}
