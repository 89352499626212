import { createFileRoute } from '@tanstack/react-router'
import { RuleAdd } from 'components/organisms/rule-form/rule-add-form'

export const Route = createFileRoute('/snap/_authenticated/settings/rules_/new')({
    validateSearch: (search: Record<string, unknown>): { step: number } => {
        return {
            step: Number(search?.step ?? 1)
        }
    },
    component: RuleAdd
})
