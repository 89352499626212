import { findLeadBooking } from '@shared/booking-service'
import { calculateCleaningStatusSync, getActiveRule } from '@shared/calculator/cleaning-calculator'
import type { AreaCleaningStatus, BookingStruct, IssueStruct, Occupancy, OrgStruct, RuleStruct } from '@shared/firestore-structs'
import type { AreaSummaryCombined, AreaSummaryStruct_v2, AreasProjected_v2 } from '@shared/projections-v2'
import { remapToNumber } from '@shared/projections/projection-ticks'
import { mapToAreaProjection_v2 } from '@shared/projections/v2/mapToAreaProjection'
import { OCCUPANCY_CHECKIN, OCCUPANCY_CHECKOUT, OCCUPANCY_TURNOVER } from '@shared/txt-constants'
import type { Needed } from '@shared/type-utils'
import { type UserOption, constructUsersOptions } from '@shared/user-data'
import moment, { type Moment } from 'moment'
import { sort } from 'ramda'
import { atom, selector, selectorFamily } from 'recoil'
import type { Translation } from '../../../../../i18n/i18n-types'
import { overrideableState } from '../../../../infra/overrideable-state'
import type { CleaningStatusColor, Icon } from '../../../../types/visuals.types'
import {
    cleaningStatusOptions,
    cleaningStatusToHuman,
    occupancyOptions,
    occupancyToHuman,
    pickAreaIcons,
    pickCleaningStatusColor
} from '../../../../utils/housekeeping-utils'
import { areasHousekeepingSelectorWithAccess, currentOrg, currentOrgSelector, rulesAtom, usersSelector } from '../../../auth/state/login'
import { areaSummaryDate } from '../../date/state/date-selection-state'

export interface ScheduleDayInterface {
    date: number
    subheader?: keyof Translation['shared']['occupancy']
    cleaningStatus: AreaCleaningStatus
    occupancy: Occupancy
    mandatory?: boolean
    ruleKey?: string
    activeRule?: Partial<RuleStruct>
}

export interface CleaningScheduleSectionInterface {
    month: string
    schedule: ScheduleDayInterface[]
}

export interface DisplayedBooking {
    guestName: string
    nrOfGuests: string
    notes?: string
    occupancyText: string
    bedSetup: string
}

export const unknownBooking: DisplayedBooking = {
    guestName: '',
    nrOfGuests: '',
    notes: '',
    occupancyText: 'Unknown next arrival',
    bedSetup: 'No bed setup info'
}

export const selectedAreaKey = atom<string | null>({
    key: 'housekeeping-areaSummary-selectedAreaKey',
    default: null
})

export const bookingToDisplay = atom<DisplayedBooking>({
    key: 'housekeeping-areaSummary-bookingToDisplay',
    default: unknownBooking
})
export const {
    frontendSetter: areaSummaryLocalSetter,
    backendSetter: areaSummaryBackend,
    resolverValue: areaSummaryView
} = overrideableState<AreaSummaryStruct_v2, AreaSummaryStruct_v2, Needed<Partial<AreaSummaryStruct_v2>, 'ticks'>>({
    id: 'housekeeping-areaSummary',
    backendSelector: backend => backend,
    dateSelector: areaSummaryDate,
    assembleOutput: (backend, override) => {
        const backendActivities = backend.activities ?? []
        const backendActivitiesKeys = backendActivities.map(a => a.key)
        return {
            ...backend,
            ...override,
            ...(override.activities
                ? { activities: backendActivities.concat(override.activities.filter(a => !backendActivitiesKeys.includes(a.key))) }
                : {})
        }
    },
    overridesMerger: (prev, curr) => ({
        ...prev,
        ...curr,
        ...(curr.activities ? { activities: (prev.activities ?? []).concat(curr.activities) } : {})
    }),
    overrideDiscarder: (backend, override) => {
        const result = remapToNumber(backend.ticks) >= override.ticks ? 'discard' : 'keep'
        console.log(
            `<area summary ${backend.area.key}>Comparing backend ticks ${backend.ticks} with override ticks ${override.ticks}, result: ${result}`
        )
        return result
    }
})

export const areaSummary = selector<AreaSummaryCombined>({
    key: 'housekeeping-areaSummary',
    get: ({ get }) => {
        const areaKey = get(selectedAreaKey)
        if (!areaKey) throw new Error('Area key is not defined')
        const areas = get(areasHousekeepingSelectorWithAccess)
        const area = areas[areaKey]
        const startDate = get(areaSummaryDate)
        const rules = get(rulesAtom)
        const currentOrganization = get(currentOrgSelector)

        if (!area) throw new Error(`Area details for area key ${areaKey} not found in current snapshot of areas`)

        const isInFuture = startDate.isAfter(moment())

        const result: AreaSummaryStruct_v2 =
            get(areaSummaryView)[areaKey] ??
            mapToAreaProjection_v2({
                area: { ...area },
                startOfDayInTZ: moment(startDate).startOf('day').valueOf(),
                org: currentOrganization,
                bookings: [],
                activities: [],
                rules: rules,
                currentTask: undefined,
                dailyComments: [],
                lastCleaningTask: null
            }).result
        return {
            ...result,
            lastHousekeeping:
                isInFuture === false && result.lastHousekeeping?.cleaning
                    ? {
                          cleaning: result.lastHousekeeping.cleaning,
                          assignedTo: result.lastHousekeeping?.assignedTo ?? []
                      }
                    : null, // hide for future dates
            area: {
                ...result.area,
                name: area.name,
                group: area.group,
                description: area.description,
                note: area.note
            }
        }
    }
})

export const areaGlobal = selector<AreasProjected_v2>({
    key: 'housekeeping-areaGlobal',
    get: ({ get }) => {
        const areaKey = get(selectedAreaKey)
        if (!areaKey) throw new Error('Area key is not defined')
        return get(areasHousekeepingSelectorWithAccess)[areaKey]
    }
})

export const areaState = selector({
    key: 'housekeeping-area',
    get: ({ get }) => {
        return get(areaSummary)?.area
    }
})

export const activitiesAtom = selector<AreaSummaryCombined['activities']>({
    key: 'housekeeping-activities',
    get: ({ get }) => {
        return [...(get(areaSummary)?.activities ?? [])].sort((a, b) => b.created - a.created)
    }
})

export const assignedAtom = selector<AreaSummaryCombined['assigned']>({
    key: 'housekeeping-assigned',
    get: ({ get }) => {
        return get(areaSummary)?.assigned ?? []
    }
})

export const bookingsAtom = selector<AreaSummaryCombined['bookings']>({
    key: 'housekeeping-bookings',
    get: ({ get }) => {
        return get(areaSummary)?.bookings ?? []
    }
})

export const dailyCommentsAtom = selector<AreaSummaryCombined['dailyComments']>({
    key: 'housekeeping-dailyComments',
    get: ({ get }) => {
        return get(areaSummary)?.dailyComments ?? []
    }
})

export const extrasAtom = selector<AreaSummaryCombined['extras']>({
    key: 'housekeeping-extras',
    get: ({ get }) => {
        return get(areaSummary)?.extras ?? []
    }
})

export const currentTaskAtom = selector<AreaSummaryCombined['currentTask'] | null>({
    key: 'housekeeping-currentTask',
    get: ({ get }) => {
        return get(areaSummary)?.currentTask ?? null
    }
})

export const activeRuleAtom = selector<AreaSummaryCombined['activeRule']>({
    key: 'housekeeping-area-summary-activeRule',
    get: ({ get }) => {
        return get(areaSummary)?.activeRule ?? null
    }
})

export const areaRuleSelector = selectorFamily<RuleStruct[], string | null>({
    key: 'housekeeping-area-rule-selector',
    get:
        areaKey =>
        ({ get }) => {
            if (!areaKey) {
                console.error('Provided areaKey is null.')
                return []
            }
            const rules = get(rulesAtom)

            return rules.filter(rule => rule.areas.includes(areaKey))
        }
})

export const lastHousekeepingAtom = selector<AreaSummaryCombined['lastHousekeeping']>({
    key: 'housekeeping-lastHousekeeping',
    get: ({ get }) => {
        return get(areaSummary)?.lastHousekeeping ?? null
    }
})

export const areaSummaryPrioritySelector = selector<boolean>({
    key: 'area-summary-prioritySelector',
    get: ({ get }) => {
        const activities = get(activitiesAtom)
        const cleaningPriorityActivity = sort(a => a.created, activities).find(activity => activity.type === 'cleaning-priority')

        return cleaningPriorityActivity ? (cleaningPriorityActivity.change.after as boolean) : false
    }
})

export const areaSummaryHeaderSelector = selector<{
    name: string
    cleaningStatusColor: CleaningStatusColor
    icons: Icon[]
    usersOptions: UserOption[]
    humanCleaningStatus: string
}>({
    key: 'housekeeping-areaSummaryHeaderSelector',
    get: ({ get }) => {
        const area = get(areaState)
        const dailyComments = get(dailyCommentsAtom)
        const users = get(usersSelector)

        const name = area.group + ' > ' + (area.description ? area.description + ' > ' : '') + area.name
        const cleaningStatusColor = pickCleaningStatusColor({
            cleaningStatus: area.cleaningStatus,
            occupancy: area.occupancy
        })
        const humanCleaning = cleaningStatusToHuman(area.cleaningStatus, area.occupancy)
        const icons = pickAreaIcons({
            occupancy: area.occupancy,
            cleaningStatus: area.cleaningStatus,
            guestCheckedIn: area.guestCheckedIn ?? false,
            note: area.note ?? '',
            dailyComments,
            rules: get(areaRuleSelector(area.key))
        })
        const usersOptions = constructUsersOptions(users)

        return { name, cleaningStatusColor, icons, humanCleaningStatus: humanCleaning, usersOptions }
    }
})

export const cleaningStatusColorSelector = selector<CleaningStatusColor>({
    key: 'housekeeping-cleaningStatusColorSelector',
    get: ({ get }) => {
        const area = get(areaState)
        return pickCleaningStatusColor({ cleaningStatus: area.cleaningStatus, occupancy: area.occupancy })
    }
})

export const occupancySelector = selector({
    key: 'housekeeping-occupancySelector',
    get: ({ get }) => {
        const area = get(areaState)
        return {
            currentValue: { value: area.occupancy, label: occupancyToHuman(area.occupancy) },
            options: occupancyOptions
        }
    }
})

export const cleaningStatusSelector = selector({
    key: 'housekeeping-cleaningStatusSelector',
    get: ({ get }) => {
        const area = get(areaState)
        return {
            currentValue: {
                value: area.cleaningStatus,
                label: cleaningStatusToHuman(area.cleaningStatus, null)
            },
            options: cleaningStatusOptions
        }
    }
})

export const assignedToSelector = selector({
    key: 'housekeeping-assignedToSelector',
    get: ({ get }) => {
        const assigned = get(assignedAtom)
        const currentTask = get(currentTaskAtom)

        return {
            names: assigned ?? [],
            initials: currentTask?.assignedTo?.map(user => user.initials) ?? []
        }
    }
})

export const dailyCommentSelector = selector({
    key: 'housekeeping-dailyCommentSelector',
    get: ({ get }) => {
        const [dailyComment] = get(dailyCommentsAtom)
        const date = get(areaSummaryDate)

        if (!dailyComment) return null

        return {
            key: dailyComment.key,
            comment: dailyComment?.comment ?? null,
            currentDate: moment(date).format('ddd, MMM D YYYY')
        }
    }
})

export const cleaningSelector = selector({
    key: 'housekeeping-cleaningSelector',
    get: ({ get }) => get(currentTaskAtom)?.cleaning ?? null
})

export const unitNoteSelector = selector({
    key: 'housekeeping-unitNoteSelector',
    get: ({ get }) => get(areaState)?.note ?? null
})

export const extraServiceSelector = selector({
    key: 'housekeeping-extraServicesSelector',
    get: ({ get }) => {
        const extras = get(extrasAtom)
        const date = get(areaSummaryDate)
        const org = get(currentOrgSelector)
        const activeExtra = extras.find(extra => moment.tz(extra.date, org.timezone).isSame(date, 'day'))

        if (!activeExtra) return null

        return activeExtra.quantity + 'x' + ' ' + activeExtra.name
    }
})

export const cleaningStatusIconsSelector = selector({
    key: 'housekeeping-area-summary-cleaningStatusIconsSelector',
    get: ({ get }) => {
        const area = get(areaState)
        if (!area) throw new Error('Area is not defined')
        return pickAreaIcons({
            occupancy: area.occupancy,
            cleaningStatus: area.cleaningStatus,
            guestCheckedIn: area.guestCheckedIn ?? false,
            note: area.note ?? '',
            dailyComments: get(dailyCommentsAtom),
            rules: get(areaRuleSelector(area.key))
        })
    }
})

const isDateInMonth = (date: number | moment.Moment | Date, month: string) => {
    return moment(date).format('YYYY-MM') === month
}

export const leadBookingSelector = selector({
    key: 'housekeeping-overview-leadBooking-selector',
    get: ({ get }) => {
        const area = get(areaState)
        const bookings = get(bookingsAtom)
        const currentOrganization = get(currentOrgSelector)

        if (!area) return undefined

        const bookingsWithBookingDates = bookings.map(booking => ({
            ...booking,
            bookingDates: getDatesArray(booking.checkinDate, booking.checkoutDate, currentOrganization)
        }))

        const leadBooking = bookingsWithBookingDates.find(booking => booking.isLeadBooking)

        return leadBooking
    }
})

export const isFutureBookingSelector = selector({
    key: 'housekeeping-overview-isFutureBooking-selector',
    get: ({ get }) => {
        const leadBooking = get(leadBookingSelector)
        const selectedDate = get(areaSummaryDate)
        const currentOrganization = get(currentOrg)

        if (!currentOrganization) throw new Error('Organization is not defined')
        const dateTimestamp = moment.tz(selectedDate, currentOrganization.timezone).startOf('day').valueOf()
        if (leadBooking && leadBooking.bookingDates.includes(dateTimestamp.toString())) {
            return false
        }
        return true
    }
})

const sectionMonths = (dates: (Moment | Date | number)[], org: OrgStruct) => {
    const months: { [key: string]: (Moment | Date | number)[] } = {}
    months[moment.tz(dates[0], org.timezone).format('YYYY-MM')] = [dates[0]]

    for (const date of dates) {
        const month = moment.tz(date, org.timezone).format('YYYY-MM')
        if (!months[month]) {
            months[month] = []
        }
        months[month].push(date)
    }
    return months
}

export const cleaningScheduleSectionsSelector = selector({
    key: 'housekeeping-modal-cleaningScheduleSections',
    get: ({ get }) => {
        const area = get(areaState)
        const activities = get(activitiesAtom)
        const currentOrganization = get(currentOrgSelector)
        const leadBooking = get(leadBookingSelector)
        const rules = get(rulesAtom)

        if (!leadBooking || !leadBooking.checkinDate || !leadBooking.checkoutDate || !rules) return []

        const bookingDates = getDatesArray(leadBooking?.checkinDate, leadBooking?.checkoutDate, currentOrganization)

        const months = sectionMonths(
            bookingDates.map(date => Number.parseInt(date)),
            currentOrganization
        )
        const sortedBookingDates = bookingDates.sort((a, b) => Number.parseInt(a) - Number.parseInt(b))

        const calculatedAreas = sortedBookingDates.map(date => {
            const currentBooking = { ...leadBooking, bookingDates } as unknown as BookingStruct

            const newArea = Object.assign({}, area)
            delete newArea?.activeRule
            const result = calculateCleaningStatusSync(
                [newArea],
                Number.parseInt(date),
                currentOrganization,
                [currentBooking],
                [],
                rules,
                true
            )[0]

            const firstOrLast = [OCCUPANCY_TURNOVER, OCCUPANCY_CHECKIN, OCCUPANCY_CHECKOUT].includes(result.occupancy)

            if (!firstOrLast) {
                result.occupancy = getActiveRule(result, [currentBooking], Number.parseInt(date), currentOrganization).occupancy!
            }

            return result
        })

        const schedule = calculatedAreas.map(({ occupancy, cleaningStatus, activeRule }, index) => {
            const subheader: keyof Translation['shared']['occupancy'] | null =
                occupancy === 'checkin' ? 'checkin' : ['checkout', 'turnover'].includes(occupancy) ? 'checkout' : null

            return {
                date: Number.parseInt(bookingDates[index]),
                ...(subheader && { subheader }),
                cleaningStatus,
                occupancy,
                ...(activeRule?.mandatory ? { mandatory: activeRule.mandatory } : { mandatory: false }),
                ...(activeRule?.key ? { ruleKey: activeRule.key } : {}),
                ...(activeRule ? { activeRule } : {})
            }
        })

        const sections = Object.keys(months).map(month => ({
            month,
            schedule: schedule.filter(s => isDateInMonth(s.date, month))
        }))

        return sections
    }
})

function getDatesArray(startTimestamp: number | string, endTimestamp: number | string, org: OrgStruct): string[] {
    const startDate = moment.tz(startTimestamp, org.timezone)
    const endDate = moment.tz(endTimestamp, org.timezone)
    const datesArray: string[] = []

    let currentDate = startDate

    while (currentDate <= endDate) {
        datesArray.push(currentDate.valueOf().toString())
        currentDate = currentDate.add(1, 'days')
    }

    return datesArray
}
