import { useRecoilState, useRecoilValue } from 'recoil'
import {
    groupFilterAtom,
    searchValueAtom,
    selectedUnitsAtom,
    statusFilterAtom,
    syncedFilterAtom,
    unitsAtom,
    unitsFiltersSelector,
    unitsGroupsSelector
} from '../state/units-state'

export function useUnitsSearch() {
    return useRecoilState(searchValueAtom)
}

export function useGroupFilter() {
    return useRecoilState(groupFilterAtom)
}

export function useStatusFilter() {
    return useRecoilState(statusFilterAtom)
}

export function useSyncedFilter() {
    return useRecoilState(syncedFilterAtom)
}

export function useUnitsFilters() {
    return useRecoilValue(unitsFiltersSelector)
}

export function useSelectedUnits() {
    return useRecoilState(selectedUnitsAtom)
}

export function useUnits() {
    return useRecoilState(unitsAtom)
}

export function useUnitsGroups() {
    return useRecoilValue(unitsGroupsSelector)
}

export function useEditedUnit(areaKey?: string | null) {
    const units = useRecoilValue(unitsAtom)

    if (!areaKey) return null

    return units.find(unit => unit.key === areaKey)
}
