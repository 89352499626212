import { isFeatureOn } from '@shared/feature-toggles'
import type { Firebase } from '@shared/firebase'
import type { BookingStruct, RuleStruct } from '@shared/firestore-structs'
import { CLEANING_STATUS_CLEAN, CLEANING_STATUS_NO_SERVICE } from '@shared/txt-constants'
import moment from 'moment-timezone'
import { useRecoilValue } from 'recoil'
import { useCurrentOrg, useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUserAreaSummaryAction } from '../../use-user-action'
import { type ScheduleDayInterface, cleaningScheduleSectionsSelector } from '../state/area-summary-state'
import { useLeadBooking } from './use-area-summary'

export function useCleaningSchedule(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const currentOrganization = useCurrentOrg()
    const leadBooking = useLeadBooking()

    const action = useUserAreaSummaryAction('update-cleaning-schedule', firebase)
    const overrideAction = useUserAreaSummaryAction('override-cleaning-schedule', firebase)

    const updateCleaningSchedule = (currentBooking: BookingStruct, day: ScheduleDayInterface, ruleKey?: string) => {
        const isNewOptionalCalculationFeatureOn = isFeatureOn(currentOrganization, 'new-optional-calculations')
        let dates: string[] = []
        let updatedDates: string[] = []
        let updateType: 'optInDates' | 'optOutDates' = 'optInDates'
        if (!currentBooking) return

        const updates: {
            dates: string[]
            field: 'optInDates' | 'optOutDates'
            isMainAction: boolean
            booking: BookingStruct
        }[] = []
        if (isNewOptionalCalculationFeatureOn) {
            updateType = [CLEANING_STATUS_NO_SERVICE, CLEANING_STATUS_CLEAN].includes(day.cleaningStatus) ? 'optInDates' : 'optOutDates'

            const optInDates = leadBooking?.optInDates ? [...new Set(leadBooking.optInDates)] : []
            const optOutDates = leadBooking?.optOutDates ? [...new Set(leadBooking.optOutDates)] : []

            if (updateType === 'optInDates') {
                optInDates.push(day.date.toString())
                const filteredOptOutDates = optOutDates.filter(existingDate => existingDate !== day.date.toString())
                updates.push({ dates: optInDates, field: 'optInDates', isMainAction: true, booking: currentBooking })
                updates.push({
                    dates: filteredOptOutDates,
                    field: 'optOutDates',
                    isMainAction: false,
                    booking: currentBooking
                })
            } else {
                optOutDates.push(day.date.toString())
                const filteredOptInDates = optInDates.filter(existingDate => existingDate !== day.date.toString())
                updates.push({
                    dates: filteredOptInDates,
                    field: 'optInDates',
                    isMainAction: false,
                    booking: currentBooking
                })
                updates.push({ dates: optOutDates, field: 'optOutDates', isMainAction: true, booking: currentBooking })
            }
        } else {
            updateType = currentOrganization.allowOptIn ? 'optInDates' : 'optOutDates'
            dates = currentOrganization.allowOptIn ? (leadBooking?.optInDates ?? []) : (leadBooking?.optOutDates ?? [])
            const index = dates.indexOf(day.date.toString())
            updatedDates = index > -1 ? dates.filter(existingDate => existingDate !== day.date.toString()) : [...dates, day.date.toString()]
            updates.push({ dates: updatedDates, field: updateType, isMainAction: true, booking: currentBooking })
        }

        action(updates, currentUser)
    }

    const updateCleaningScheduleMulti = (
        updates: { dates: string[]; field: 'optInDates' | 'optOutDates'; isMainAction: boolean; booking: BookingStruct }[]
    ) => {
        action(updates, currentUser)
    }

    const overrideCleaningSchedule = (
        currentBooking: BookingStruct,
        days: ScheduleDayInterface[],
        rules: RuleStruct[],
        ruleKey?: string
    ) => {
        const ruleOverride = currentBooking.ruleOverride ?? []

        const rule = rules.find(rule => rule.key === ruleKey)

        for (const day of days) {
            if (ruleKey) {
                // If date exists replace ruleKey else add to ruleOverride
                const index = ruleOverride.findIndex(override => override[moment(day.date).valueOf().toString()])
                if (index > -1) {
                    ruleOverride[index] = { [moment(day.date).valueOf().toString()]: { ruleKey } }
                } else {
                    ruleOverride.push({ [moment(day.date).valueOf().toString()]: { ruleKey } })
                }
            } else {
                // remove date from ruleOverride
                const index = ruleOverride.findIndex(override => override[moment(day.date).valueOf().toString()])
                ruleOverride.splice(index, 1)
            }
        }
        overrideAction(currentBooking, ruleOverride, rule)
    }

    return { updateCleaningSchedule, updateCleaningScheduleMulti, overrideCleaningSchedule }
}

export function useCleaningScheduleSections() {
    return useRecoilValue(cleaningScheduleSectionsSelector)
}
