import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Translation } from '@shared-snap/i18n/i18n-types'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { rulesAtom } from '@shared-snap/snap/components/auth/state/login'
import {
    useAreaSummaryDate,
    useAreaSummaryRulesOptions,
    useLeadBooking
} from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-area-summary'
import {
    useCleaningSchedule,
    useCleaningScheduleSections
} from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-cleaning-schedule'
import type {
    CleaningScheduleSectionInterface,
    ScheduleDayInterface
} from '@shared-snap/snap/components/housekeeping/area-summary/state/area-summary-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Colors } from '@shared-snap/snap/types/visuals.types'
import { groupDaysByWeeks } from '@shared-snap/snap/utils/housekeeping-utils'
import { getBookingByKey, getBookingsByGroupId } from '@shared/booking-data'
import { pickGuestCleaningActionIcon } from '@shared/cleaning-helpers'
import { isFeatureOn } from '@shared/feature-toggles'
import type { BookingStruct, OrgStruct } from '@shared/firestore-structs'
import {
    CLEANING_STATUS_CLEAN,
    CLEANING_STATUS_NO_SERVICE,
    OCCUPANCY_CHECKIN,
    OCCUPANCY_CHECKOUT,
    OCCUPANCY_TURNOVER,
    OCCUPANCY_VACANT
} from '@shared/txt-constants'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button'
import { Modal } from 'components/atoms/modal/modal'
import moment, { type Moment } from 'moment'
import momentTz from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { IconButton } from '../../../atoms/icon-button'
import { CustomChar } from '../area-box/custom-char'
import { RulePickerModal } from './rule-picker-modal'

const planningInfo: Array<{ name: keyof Translation['area']['planningInfo']; colorClass: Colors }> = [
    { name: 'ready', colorClass: 'sweeply-green' },
    { name: 'clean', colorClass: 'sweeply-rose' },
    { name: 'skip', colorClass: 'sweeply-teal' },
    { name: 'checkout', colorClass: 'sweeply-purple' }
]

function CleaningScheduleSection() {
    const {
        LL: { area }
    } = useI18nContext()
    const currentOrganization = useCurrentOrg()
    const leadBooking = useLeadBooking()
    const { date: selectedDate } = useAreaSummaryDate()
    const { updateCleaningSchedule, updateCleaningScheduleMulti, overrideCleaningSchedule } = useCleaningSchedule(
        asFirebase(firebaseWrapped)
    )
    const cleaningScheduleSections = useCleaningScheduleSections()
    const [showMassActionConfirmation, setShowMassActionConfirmation] = useState(false)
    const [showMassEdit, setShowMassEdit] = useState(false)
    const [groupBookings, setGroupBookings] = useState<BookingStruct[]>([])
    const [currentBooking, setCurrentBooking] = useState<BookingStruct>()
    const [rulePickerModalOpen, setRulePickerModalOpen] = useState(false)
    const [rulePickerModalProps, setRulePickerModalProps] = useState<ScheduleDayInterface | null>()
    const [multiEditModalProps, setMultiEditModalProps] = useState<(ScheduleDayInterface & { ruleKey: string }) | null>()
    const rules = useRecoilValue(rulesAtom)

    const openModal = (day: ScheduleDayInterface) => {
        setRulePickerModalProps(day)
        setRulePickerModalOpen(true)
    }

    const closeModal = () => {
        setRulePickerModalOpen(false)
    }

    const isCleaningScheduleVisible =
        (currentOrganization.allowOptIn || currentOrganization.allowOptOut) && cleaningScheduleSections.length > 0

    useEffect(() => {
        const fetchCurrentBooking = async () => {
            if (!leadBooking) return

            try {
                const currBooking = await getBookingByKey(asFirebase(firebaseWrapped), leadBooking.key)
                setCurrentBooking(currBooking)
            } catch (error) {
                console.error('Failed to fetch booking:', error)
            }
        }
        fetchCurrentBooking()
    }, [leadBooking])

    const onDateClick = (day: ScheduleDayInterface) => {
        if (!currentBooking) return
        try {
            updateCleaningSchedule(currentBooking, day)
        } catch (error) {
            console.error(error)
        }
    }

    const onHandleDateRuleChange = (day: ScheduleDayInterface, ruleKey: string) => {
        if (!currentBooking) return
        const allDates = cleaningScheduleSections.flatMap(x => x.schedule)
        const futureDates = allDates.filter(x => x.date >= day.date)
        if (futureDates.length >= 6) {
            setShowMassEdit(true)
            setMultiEditModalProps({ ...day, ruleKey })
        } else {
            try {
                overrideCleaningSchedule(currentBooking, [day], rules, ruleKey)
            } catch (error) {
                console.error(error)
            }
        }
    }

    const selectAll = async (bookings: BookingStruct[]) => {
        if (!leadBooking) return

        const stayOverDays = Math.max(0, leadBooking.bookingDates.length - 2)

        const selectedDates = currentOrganization.allowOptIn ? (leadBooking.optInDates ?? []) : (leadBooking.optOutDates ?? [])
        const shouldToggleSelection = (selectedDates?.length ?? 0) < stayOverDays
        const filteredBookingDates = leadBooking.bookingDates.filter(
            date =>
                Number.parseInt(date) >= selectedDate.valueOf() &&
                Number.parseInt(date) !== leadBooking.checkoutDate &&
                Number.parseInt(date) !== leadBooking.checkinDate
        )
        try {
            const updatedDates = shouldToggleSelection
                ? leadBooking.optInDates?.length === filteredBookingDates.length
                    ? []
                    : filteredBookingDates
                : []
            await Promise.all(
                bookings.map(b =>
                    updateCleaningScheduleMulti([
                        {
                            dates: updatedDates,
                            field: currentOrganization.allowOptIn ? 'optInDates' : 'optOutDates',
                            isMainAction: true,
                            booking: b
                        }
                    ])
                )
            )
        } catch (error) {
            console.error(error)
        }
    }
    const onConfirmSelectAll = () => {
        selectAll(groupBookings)
        setShowMassActionConfirmation(false)
    }

    const onSelectAllClick = async () => {
        if (!currentBooking) return

        try {
            if (currentBooking._external?.groupID) {
                const fetchedGroupBookings = await getBookingsByGroupId(
                    asFirebase(firebaseWrapped),
                    currentBooking.organizationKey,
                    currentBooking._external?.groupID
                )

                if (fetchedGroupBookings.length > 1) {
                    setGroupBookings(fetchedGroupBookings)
                    setShowMassActionConfirmation(true)
                } else {
                    onConfirmSelectAll()
                }
            } else {
                selectAll([currentBooking])
            }
        } catch (error) {
            console.error('Error fetching bookings: ', error)
        }
    }

    const onCancelSelectAll = () => {
        if (!currentBooking) return
        selectAll([currentBooking])
        setShowMassActionConfirmation(false)
    }

    const onCancelMassEdit = () => {
        const selectedDate = rulePickerModalProps
        if (!currentBooking) return
        if (!selectedDate) return
        try {
            overrideCleaningSchedule(currentBooking, [selectedDate], rules, multiEditModalProps?.ruleKey)
        } catch (error) {
            console.error(error)
        }
        setMultiEditModalProps(null)
        setShowMassEdit(false)
    }

    const onConfirmMassEdit = () => {
        const selectedDate = rulePickerModalProps?.date
        if (!selectedDate) return
        if (!currentBooking) return
        const allDates = cleaningScheduleSections.flatMap(x => x.schedule)
        const futureDates = allDates.filter(x => x.date >= selectedDate.valueOf())
        const sameWeekday = futureDates.filter(x => moment(x.date).weekday() === moment(selectedDate).weekday())
        overrideCleaningSchedule(currentBooking, sameWeekday, rules, multiEditModalProps?.ruleKey)
        setMultiEditModalProps(null)
        setShowMassEdit(false)
    }

    if (!isCleaningScheduleVisible) return null

    return (
        <div>
            <RulePickerModal
                isOpen={rulePickerModalOpen}
                onClose={closeModal}
                onConfirm={onHandleDateRuleChange}
                day={rulePickerModalProps}
            />
            <ConfirmMassActionModal
                isOpen={showMassActionConfirmation}
                onClose={onCancelSelectAll}
                onConfirm={onConfirmSelectAll}
                headerText={area.groupActionModalHeader()}
                bodyText={area.groupActionModalBody()}
                noButtonText={area.groupActionModalNo()}
            />
            <ConfirmMassActionModal
                isOpen={showMassEdit}
                onClose={onCancelMassEdit}
                onConfirm={onConfirmMassEdit}
                headerText={area.massEditActionModalHeader()}
                bodyText={area.massEditActionModalBody({ days: moment(multiEditModalProps?.date).format('dddd') })}
                noButtonText={area.massEditActionModalNoButton()}
            />
            <div className="mb-[12px]">
                <UI.Icon className="mr-[12px]" size="sm" icon="specta-schedule" />
                <UI.Text size="2xl">{area.housekeepingPlan()}</UI.Text>
            </div>

            <UI.Text color="snap-silver">{area.planSubtitle()}</UI.Text>

            <div className="flex justify-between my-[20px]">
                {planningInfo.map(({ name, colorClass }) => (
                    <div key={name} className="flex gap-x-[6px] items-center">
                        <div className={`rounded-full bg-${colorClass} w-3 h-3`} />
                        <UI.Text>{area.planningInfo[name]()}</UI.Text>
                    </div>
                ))}
            </div>

            <div className="flex flex-col gap-y-[16px]">
                {cleaningScheduleSections.map(section => (
                    <MonthSection
                        selectedDate={selectedDate}
                        key={section.month}
                        onDateClick={onDateClick}
                        section={section}
                        onSelectAllClick={onSelectAllClick}
                        currentOrganization={currentOrganization}
                        openRulePickerModal={openModal}
                    />
                ))}
            </div>
        </div>
    )
}

function ConfirmMassActionModal({
    isOpen,
    onConfirm,
    onClose,
    headerText,
    bodyText,
    noButtonText
}: {
    isOpen: boolean
    onConfirm: () => void
    onClose: () => void
    headerText?: string
    bodyText?: string
    noButtonText?: string
}) {
    const {
        LL: { area, shared }
    } = useI18nContext()
    return (
        <Modal isOpen={isOpen} onClose={onClose} className="h-72 w-1/2 p-6">
            <div className="flex flex-col gap-5 mt-12 items-center">
                <UI.Text size="xl" weight="bold">
                    {headerText}
                </UI.Text>
                <UI.Text size="md">{bodyText} </UI.Text>
            </div>
            <div className="flex justify-between align-center mt-12">
                <Button onClick={onClose} className="w-46 h-auto text-left h-16 !px-2">
                    {noButtonText || shared.no()}
                </Button>
                <Button onClick={onConfirm}>{shared.yes()}</Button>
            </div>
        </Modal>
    )
}

function MonthSection({
    section,
    onDateClick,
    onSelectAllClick,
    selectedDate,
    currentOrganization,
    openRulePickerModal
}: {
    section: CleaningScheduleSectionInterface
    selectedDate: Moment
    onDateClick: (day: ScheduleDayInterface) => void
    onSelectAllClick: () => void
    currentOrganization: OrgStruct
    openRulePickerModal: (day: ScheduleDayInterface) => void
}) {
    const {
        LL: { shared }
    } = useI18nContext()

    const { month, schedule } = section
    const selectAllDisabled =
        momentTz(selectedDate)
            .tz(currentOrganization.timezone)
            .startOf('day')
            .isBefore(moment.tz(currentOrganization.timezone).startOf('day')) ||
        schedule.every(({ occupancy }) => occupancy === 'checkin' || occupancy === 'checkout')
    const weeks = useMemo(() => groupDaysByWeeks(schedule), [schedule])

    const pickPlanningColor = (day: ScheduleDayInterface) => {
        if (day.occupancy === 'checkin') return planningInfo[0].colorClass
        if (day.occupancy === 'checkout') return planningInfo[3].colorClass
        if (day.cleaningStatus === 'dirty') return planningInfo[1].colorClass
        return planningInfo[2].colorClass
    }

    const pickPlanningIcon = (day: ScheduleDayInterface) => {
        const { occupancy, cleaningStatus } = day
        const firstOrLast = [OCCUPANCY_TURNOVER, OCCUPANCY_CHECKIN, OCCUPANCY_CHECKOUT].includes(occupancy)
        const cleaningActionIcon = pickGuestCleaningActionIcon(occupancy)
        if (!cleaningActionIcon || firstOrLast) return null
        if (cleaningStatus === CLEANING_STATUS_NO_SERVICE) {
            return pickGuestCleaningActionIcon(OCCUPANCY_VACANT)
        }

        return cleaningActionIcon
    }

    return (
        <div>
            <div className="flex justify-between items-center mb-[10px]">
                <UI.Text size="2xl">{moment.tz(month, currentOrganization.timezone).format('MMMM YYYY')}</UI.Text>
                <div
                    onClick={() => !selectAllDisabled && onSelectAllClick()}
                    className={`underline decoration-snap-red underline-offset-4 select-none ${
                        !selectAllDisabled ? 'cursor-pointer' : 'opacity-50'
                    }`}>
                    <UI.Text color="snap-red">{shared.selectAll()}</UI.Text>
                </div>
            </div>
            <div className="flex flex-col gap-8 items-center">
                {weeks.map((week, i) => (
                    <div key={i} className="flex gap-4 items-end w-full justify-between">
                        {week.map((day, i) => {
                            if (day === null) return <div key={Math.random() + i} className="w-[90px] h-[90px]"></div>

                            const disabled =
                                day.date < moment.tz(currentOrganization.timezone).startOf('day').valueOf() ||
                                ['turnover', 'checkin', 'checkout'].includes(day.occupancy) ||
                                day.mandatory

                            return (
                                <div key={day.date}>
                                    {day.subheader && (
                                        <div className="w-fit m-auto mb-[6px]">
                                            <UI.Text>{shared.occupancy[day.subheader]()}</UI.Text>
                                        </div>
                                    )}
                                    {isFeatureOn(currentOrganization, 'editable-optin') && !disabled && day.cleaningStatus === 'dirty' && (
                                        <div className="w-fit m-auto mb-[6px]">
                                            <IconButton
                                                icon="specta-pencil2"
                                                size="xs"
                                                color="snap-dark-gray"
                                                onClick={() => {
                                                    openRulePickerModal(day)
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div
                                        onClick={() => !disabled && onDateClick(day)}
                                        className={`flex flex-col w-[90px] h-[90px] rounded-full justify-center items-center bg-${pickPlanningColor(
                                            day
                                        )} ${disabled ? 'opacity-50' : 'cursor-pointer hover:opacity-50'}`}>
                                        <UI.Text color="white">{moment.tz(day.date, currentOrganization.timezone).format('ddd')}</UI.Text>
                                        <UI.Text color="white">{moment.tz(day.date, currentOrganization.timezone).format('DD')}</UI.Text>
                                        {(() => {
                                            const icon = pickPlanningIcon(day)
                                            const customChar = day.activeRule?.customChar
                                            if (customChar) {
                                                return <CustomChar customChar={customChar} />
                                            } else if (icon && icon.source && icon.width && icon.height) {
                                                return <img src={icon.source} width={icon.width} height={icon.height} className="mt-1" />
                                            }
                                            return null
                                        })()}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CleaningScheduleSection
