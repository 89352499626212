import type { Row } from '@tanstack/react-table'
import { useEffect, useState } from 'react'

export function useTableCheckboxes<T extends { key?: string }>(state: T[]) {
    const [selected, setSelected] = useState<T[]>(state)

    useEffect(() => {
        setSelected(state)
    }, [state])

    const areAllRowsSelected = (rows: Row<T>[]) => {
        const currentPageRows = rows.map(row => row.original)
        return currentPageRows.every(row => row.key && selected.some(item => item.key === row.key))
    }

    const toggleSelectAll = (rows: Row<T>[], isSelected: boolean) => {
        const currentPageRows = rows.map(row => row.original)

        setSelected(prev => {
            if (isSelected) {
                const newSelections = currentPageRows.filter(row => !prev.some(item => item.key === row.key))
                return [...prev, ...newSelections]
            } else {
                return prev.filter(item => !currentPageRows.some(row => row.key === item.key))
            }
        })
    }

    const isRowSelected = (row: T) => selected.some(item => item.key === row.key)

    const toggleRowSelection = (row: T, isSelected: boolean) => {
        setSelected(prev => (isSelected ? [...prev, row] : prev.filter(item => item.key !== row.key)))
    }

    return { selected, setSelected, areAllRowsSelected, toggleSelectAll, isRowSelected, toggleRowSelection }
}
