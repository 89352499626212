import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Icon } from '@shared-snap/snap/types/icon-font.enum'
import type { Colors } from '@shared-snap/snap/types/visuals.types'
import { cleaningStatusesAndColors, statusesAndIcons } from '@shared-snap/snap/utils/housekeeping-utils'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
interface QuickGuideModalProps {
    visible: boolean
    onClose: () => void
}
const StatusBullet = ({ icon, text }: { icon: Icon; text: string }) => {
    return (
        <div className="flex items-center gap-2">
            <div className="flex items-center justify-center bg-snap-mid-silver rounded-full h-6 w-6">
                <UI.Icon icon={icon} size="xs" />
            </div>
            <UI.Text size="md" weight="normal">
                {text}
            </UI.Text>
        </div>
    )
}

const CleaningBullet = ({ color, text }: { color: Colors; text: string }) => {
    return (
        <div className="flex items-center gap-3">
            <div className={`bg-${color} rounded-full h-4 w-4`} />
            <UI.Text size="md" weight="normal">
                {text}
            </UI.Text>
        </div>
    )
}

const QuickGuideModal = ({ visible, onClose }: QuickGuideModalProps) => {
    const firstColumnStatuses = cleaningStatusesAndColors.slice(0, Math.ceil(cleaningStatusesAndColors.length / 2))
    const secondColumnStatuses = cleaningStatusesAndColors.slice(Math.ceil(cleaningStatusesAndColors.length / 2))
    const {
        LL: { quickGuide }
    } = useI18nContext()

    return (
        <Modal isOpen={visible} onClose={onClose} className="max-h-[95%] max-w-[900px] overflow-y-scroll">
            <ModalHeader onClose={onClose} className="bg-white !px-12">
                <div className="flex flex-col items-start mb-5">
                    <UI.Text size="sm" color="snap-mid-gray" weight="normal">
                        {quickGuide.subtitle()}
                    </UI.Text>
                    <UI.Text weight="bold" size="2xl" color="snap-black">
                        {quickGuide.title()}
                    </UI.Text>
                </div>
            </ModalHeader>
            <div className="px-12 pb-8">
                <div className="flex items-start mb-9 gap-10 justify-center">
                    <img src={require('../../../../assets/quick-guide-phone.png')} className="h-[380px] w-[190px]" />
                    <div className="flex flex-col gap-2">
                        {statusesAndIcons.map((status, index) => (
                            <StatusBullet
                                key={status.label + index}
                                icon={status.icon}
                                text={quickGuide.statusesAndIcons[status.label]()}
                            />
                        ))}
                    </div>
                </div>
                <div className="flex gap-10 justify-center">
                    <div className="flex flex-col gap-2">
                        {firstColumnStatuses.map((status, index) => (
                            <CleaningBullet
                                key={status.text + index}
                                color={status.color}
                                text={quickGuide.statusesAndIcons[status.text]()}
                            />
                        ))}
                    </div>
                    <div className="flex flex-col gap-2">
                        {secondColumnStatuses.map((status, index) => (
                            <CleaningBullet
                                key={status.text + index}
                                color={status.color}
                                text={quickGuide.statusesAndIcons[status.text]()}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default QuickGuideModal
