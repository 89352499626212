import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg, useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { currentOrgSelector } from '@shared-snap/snap/components/auth/state/login'
import { getOrganization } from '@shared/org-data'
import { Link, Outlet, createFileRoute } from '@tanstack/react-router'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button'
import { CategorySection } from 'components/atoms/category-section'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { useCallback, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { prefix } from '../../__root'

function Header() {
    const org = useCurrentOrg()
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    const categoryCount = org.categories ? Object.keys(org.categories).length : 0

    return (
        <div className="flex justify-between items-center mb-10">
            <div className="flex items-center gap-x-[8px]">
                <h1 className="text-3xl font-bold">Categories</h1>
                <QuantityLabel quantity={categoryCount} />
            </div>

            <Link to={`${prefix}/settings/categories/new`}>
                <Button>{settingsWeb.categories.newCategory()}</Button>
            </Link>
        </div>
    )
}

export const Route = createFileRoute(`${prefix}/_authenticated/settings/categories`)({
    component: () => {
        const [currentOrganization, setCurrentOrganization] = useRecoilState(currentOrgSelector)

        const currentUser = useCurrentUser()

        const fetchOrg = useCallback(async () => {
            const org = await getOrganization(asFirebase(firebase), currentUser.organizationKey)
            setCurrentOrganization(org)
        }, [currentUser])

        useEffect(() => {
            fetchOrg()
        }, [fetchOrg])

        useEffect(() => {
            console.log('currentOrganization', currentOrganization.categories)
        }, [currentOrganization])

        return (
            <div className="flex flex-col grow shrink-0 p-2">
                <Outlet />
                <Header />

                {currentOrganization?.categories &&
                    currentOrganization.categories.map(category => (
                        <div className="mb-[48px]" key={category.id}>
                            <CategorySection
                                category={category}
                                onDidUpdate={() => {
                                    fetchOrg()
                                }}
                            />
                        </div>
                    ))}
            </div>
        )
    }
})
