import type { TextProps } from '@shared-snap/snap/registry/ui-elements-registry'

export function Text({ size, color, weight, height, style, align, children }: TextProps) {
    const sizeClassNames = (() => {
        switch (size) {
            case '2xl':
                return 'text-2xl'
            case 'xl':
                return 'text-xl'
            case 'lg':
                return 'text-lg'
            case 'md':
                return 'text-base'
            case 'sm':
                return 'text-sm'
            case 'xs':
                return 'text-xs'
            default:
                return 'text-sm'
        }
    })()
    const styleClassNames = (() => {
        switch (style) {
            case 'normal':
                return 'not-italic'
            case 'bold':
                return 'text-md'
            case 'italic':
                return 'italic'
            case 'underline':
                return 'underline'
            default:
                return ''
        }
    })()

    const alignClassNames = (() => {
        switch (align) {
            case 'left':
                return 'text-left'
            case 'center':
                return 'text-center'
            case 'right':
                return 'text-right'
            default:
                return 'text-left'
        }
    })()

    const weightClassNames = (() => {
        switch (weight) {
            case 'normal':
                return 'font-normal'
            case 'medium':
                return 'font-medium'
            case 'semibold':
                return 'font-semibold'
            case 'bold':
                return 'font-bold'
            default:
                return 'font-medium'
        }
    })()

    const lineHeightClassNames = (() => {
        switch (height) {
            case 'normal':
                return 'leading-normal'
            case 'tight':
                return 'leading-tight'
            case 'loose':
                return 'leading-loose'
            case 'none':
                return 'leading-none'
            default:
                return 'leading-normal'
        }
    })()

    return (
        <span
            className={`${[
                sizeClassNames,
                styleClassNames,
                alignClassNames,
                weightClassNames,
                lineHeightClassNames,
                color ? `text-${color}` : ''
            ]
                .join(' ')
                .trim()}`}>
            {children}
        </span>
    )
}
