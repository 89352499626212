import { type Size, UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Colors, Icon } from '@shared-snap/snap/types/visuals.types'
import { useState } from 'react'
import { match } from 'ts-pattern'

interface Props {
    icon: Icon
    onClick?: () => void
    iconColor?: Colors
    hoverIconColor?: Colors
    transparent?: boolean
    backgroundColor?: Colors
    disabled?: boolean
    size?: Size
    className?: string
}

export function RoundButton({
    icon,
    onClick,
    transparent,
    iconColor = 'snap-black',
    hoverIconColor,
    backgroundColor = 'sweeply-light-gray',
    disabled = false,
    size = 'sm',
    className
}: Props) {
    const [hoveredButton, setHoveredButton] = useState(false)

    const handleMouseEvent = () => setHoveredButton(prev => !prev)

    const background = transparent ? 'snap-transparent-black' : backgroundColor
    const sizeClass = match(size)
        .with('xs', () => 'w-5 h-5')
        .with('sm', () => 'w-8 h-8')
        .with('md', () => 'w-12 h-12')
        .with('lg', () => 'w-14 h-14')
        .otherwise(() => 'w-8 h-8')
    return (
        <div
            onMouseEnter={handleMouseEvent}
            onMouseLeave={handleMouseEvent}
            onClick={onClick}
            role="button"
            aria-disabled={disabled}
            className={`${sizeClass} cursor-pointer rounded-full flex items-center justify-center ${
                disabled ? 'opacity-50 pointer-events-none' : ''
            } bg-${background} ${className}`}>
            <UI.Icon className="ease-linear duration-200" icon={icon} size={size} color={hoveredButton ? hoverIconColor : iconColor} />
        </div>
    )
}
