import moment from 'moment'
import type { Firebase } from './firebase'
import type { RuleStruct } from './firestore-structs'

export async function saveRule(firebase: Firebase, rule: Partial<RuleStruct>) {
    const db = firebase.firestore()
    const ruleRef = db.collection('rules').doc()
    const ruleKey = rule.key ?? ruleRef.id
    const ruleData = { ...rule }

    if (ruleData.inspection) {
        ruleData.priority = 0
    }

    ruleData.start = moment(ruleData.start).startOf('day').valueOf()
    ruleData.end = moment(ruleData.end).startOf('day').valueOf()

    if (ruleData.repeatType && ['checkin', 'checkout', 'daily'].includes(ruleData.repeatType)) {
        ruleData.repeatOffsetStart = 0
        ruleData.repeatOffsetEnd = 0
    }

    const result = await db
        .collection('rules')
        .doc(ruleKey)
        .set({ ...ruleData, key: ruleKey, visible: true }, { merge: true })

    return result
}

export async function getRule(firebase: Firebase, ruleKey: string) {
    const ruleRef = await firebase.firestore().collection('rules').doc(ruleKey).get()

    return ruleRef.data() as RuleStruct
}
