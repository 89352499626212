import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useAreaSummaryRulesOptions } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-area-summary'
import { type ScheduleDayInterface, activeRuleAtom } from '@shared-snap/snap/components/housekeeping/area-summary/state/area-summary-state'
import { useSelection } from '@shared-snap/snap/hooks/use-selection'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { capitalize, search } from '@shared-snap/snap/utils'
import { UserStruct } from '@shared/firestore-structs'
import { UserOption } from '@shared/user-data'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { SearchInput } from 'components/atoms/search-input'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

export interface RuleOption {
    value: string
    label: string
}

interface Props {
    isOpen: boolean
    onClose: () => void
    day: ScheduleDayInterface | null | undefined
    // onAssign: (userKeys: string[]) => void
    onConfirm: (day: ScheduleDayInterface, ruleKey: string) => void
    initialSelected?: string
    closeByPressOutside?: boolean
}

interface RuleOptionProps {
    rule: RuleOption
    onClick: (ruleKey: string) => void
    selected: boolean
}

export function RulePickerModal({ onConfirm, isOpen, onClose, day, closeByPressOutside = true }: Props) {
    const {
        LL: { shared, navigation }
    } = useI18nContext()
    const { options } = useAreaSummaryRulesOptions()
    const [searchVal, setSearchVal] = useState('')
    const [rules, setRules] = useState(options ?? [])
    const [selectedOption, setSelectedOption] = useState(day?.ruleKey)
    const usersQuantity = useMemo(() => options.length, [options])
    const selectedRule = useMemo(() => options.find(x => x.value === selectedOption), [options, selectedOption])

    const handleSubmit = () => {
        if (!day || !selectedOption) return
        onClose()
        onConfirm(day, selectedOption)
    }

    useEffect(() => {
        setSelectedOption(day?.ruleKey)
    }, [day?.ruleKey])

    const handleSearch = (value: string) => {
        setSearchVal(value)
        const filteredRules = search(value, options, rule => rule.label)
        setRules(filteredRules)
    }

    useEffect(() => {
        setSearchVal('')
        setRules(options)
    }, [])

    const handleSelect = (ruleKey: string) => {
        setSelectedOption(ruleKey)
    }

    const closeModal = () => {
        setSelectedOption('')
        onClose()
    }

    return (
        <Modal
            className="z-20 w-1/2 overflow-none h-1/2 flex flex-col"
            isOpen={isOpen}
            closeByPressOutside={closeByPressOutside}
            onClose={closeModal}>
            <ModalHeader onClose={closeModal} closeColor="snap-black" className="bg-white pb-3 z-50 rounded-t-lg">
                <div className="flex items-center gap-3 mb-3">
                    <UI.Text weight="bold" size="md" color="snap-black">
                        {navigation.rules()}
                    </UI.Text>
                    <QuantityLabel quantity={usersQuantity} size="md" />
                </div>
                <SearchInput value={searchVal} onChange={handleSearch} placeholder={shared.selectRules()} />
            </ModalHeader>
            <div className="px-6 pb-3 overflow-y-auto grow">
                <div className="flex flex-col gap-4">
                    <UI.List
                        items={rules}
                        render={rule => (
                            <RuleOptionItem key={rule.value} selected={selectedOption === rule.value} rule={rule} onClick={handleSelect} />
                        )}
                    />
                </div>
            </div>
            {selectedOption && selectedOption?.length > 0 && (
                <div className="px-6 bg-white w-full min-h-[80px] flex justify-between items-center border-t border-sweeply-light-gray rounded-b-lg">
                    <div className="flex gap-[8px]">
                        {selectedRule && (
                            <UI.InitialsCircle initials={selectedRule.label.slice(0, 3).toUpperCase()} name={selectedRule.label} />
                        )}
                        <UI.Icon
                            className="cursor-pointer h-fit"
                            onClick={() => {
                                setSelectedOption('')
                            }}
                            icon="specta-close"
                            size="xs"
                        />
                    </div>
                    <UI.Button text={shared.confirm()} onClick={handleSubmit} />
                </div>
            )}
        </Modal>
    )
}

function RuleOptionItem({ rule, onClick, selected }: RuleOptionProps) {
    return (
        <UI.Pressable onPress={() => onClick(rule.value)}>
            <div className={`flex items-center gap-3 cursor-pointer ${selected ? 'opacity-50' : ''}`}>
                <UI.InitialsCircle initials={rule.label.slice(0, 3).toUpperCase()} name={rule.label} />
                <span>{rule.label}</span>
            </div>
        </UI.Pressable>
    )
}
