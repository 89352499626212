import { CLEANING_STATUS_DIRTY, CLEANING_STATUS_DO_NOT_DISTURB, CLEANING_STATUS_WAITING_FOR_CHECKOUT } from '@shared/txt-constants'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useFeatureToggle } from '../../../../hooks/use-feature-toggles'
import { areasBoxesSelector, teamWorkloadListSelector } from '../state/housekeeping-overview-state'
import {
    cleaningTaskNameAtom,
    massAssigningBarOpenAtom,
    massAssigningFilterModeAtom,
    selectedAreasAtom,
    usersOptionsSelector
} from '../state/mass-assigning-state'

export function useMassAssigningBarOpen() {
    return useRecoilValue(massAssigningBarOpenAtom)
}

export function useSetMassAssigningBarOpen() {
    return useSetRecoilState(massAssigningBarOpenAtom)
}

export function useSelectedAreas() {
    return useRecoilState(selectedAreasAtom)
}

export function useUsersOptions() {
    return useRecoilValue(usersOptionsSelector)
}

export function useMassAssignFilterMode() {
    return useRecoilState(massAssigningFilterModeAtom)
}

export function useSelectAllAreas() {
    const areasBoxes = useRecoilValue(areasBoxesSelector)
    const setSelectedAreas = useSetRecoilState(selectedAreasAtom)

    function selectAllAreas() {
        setSelectedAreas(new Set(areasBoxes.map(({ key }) => key)))
    }

    function clearAllAreas() {
        setSelectedAreas(new Set())
    }

    return { selectAllAreas, clearAllAreas }
}

export function useSelectByGroup() {
    const areasBoxes = useRecoilValue(areasBoxesSelector)
    const setSelectedAreas = useSetRecoilState(selectedAreasAtom)
    const { isFeatureOn } = useFeatureToggle()

    function setAllAreasFromGroup(groupName: string) {
        const areasToSelect = areasBoxes
            .filter(
                ({ group, cleaningStatus }) =>
                    group === groupName &&
                    (!isFeatureOn('smart-group-selection') ||
                        cleaningStatus === CLEANING_STATUS_DIRTY ||
                        cleaningStatus === CLEANING_STATUS_WAITING_FOR_CHECKOUT ||
                        cleaningStatus === CLEANING_STATUS_DO_NOT_DISTURB)
            )
            .map(({ key }) => key)
        setSelectedAreas(prev => {
            if (areasToSelect.every(key => prev.has(key))) {
                return new Set([...prev].filter(key => !areasToSelect.includes(key)))
            }
            return new Set([...prev, ...areasToSelect])
        })
    }
    return setAllAreasFromGroup
}

export function useCleaningTaskName() {
    return useRecoilState(cleaningTaskNameAtom)
}

export function useTeamWorkload(showGroups: boolean) {
    return useRecoilValue(teamWorkloadListSelector(showGroups))
}
