import { useAddImagesToIssue } from '@shared-snap/snap/components/issues/hooks/use-add-images-to-issue'
import { useDeleteImageFromIssue } from '@shared-snap/snap/components/issues/hooks/use-delete-image-from-issue'
import { useIssueImageText } from '@shared-snap/snap/components/issues/hooks/use-issue-image-text'
import { ImagesList } from '@shared-snap/snap/components/issues/ui/issue'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { IssueStruct } from '@shared/firestore-structs'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import { type Image, ImagesDropBox } from 'components/atoms/images-dropbox'
import { type ChangeEvent, type KeyboardEvent, type MouseEvent, useState } from 'react'

export function Images({
    issueKey,
    items,
    handleOpenImageModal
}: {
    issueKey: string
    items: IssueStruct['items']
    handleOpenImageModal: (url: string) => void
}) {
    const addImagesToIssue = useAddImagesToIssue(asFirebase(firebaseWrapped))
    const updateImageText = useIssueImageText(asFirebase(firebaseWrapped), issueKey)
    const deleteImage = useDeleteImageFromIssue(asFirebase(firebaseWrapped))
    const [loading, setLoading] = useState(false)

    async function setImages(images: Image[]) {
        await addImagesToIssue(issueKey, images, items)
        setLoading(false)
    }

    const onSubmit = (itemKey: string) => (text: string) => {
        updateImageText(itemKey, { content: text })
    }

    const onDelete = (e: MouseEvent<HTMLDivElement>, itemKey: string) => {
        e.stopPropagation()
        deleteImage(issueKey, itemKey)
    }

    return (
        <div className="flex flex-wrap gap-2 mt-4">
            <ImagesDropBox setLoading={setLoading} setImages={setImages} previewShown={false} size="lg" />
            <ImagesList
                imageItems={items}
                Render={({ src, thumbUrl, text, first, itemKey }) => {
                    return (
                        <>
                            {first && loading && (
                                <div className="relative flex items-center justify-center w-64 h-64">
                                    <UI.Loader />
                                </div>
                            )}
                            <div
                                onClick={() => handleOpenImageModal(src)}
                                className="group relative flex items-center w-64 h-64 cursor-pointer">
                                {text && <ImageTextInput onSubmit={onSubmit(itemKey)} text={text} />}
                                <img className="object-cover w-full h-full rounded-lg" src={thumbUrl} alt="issue image" />
                                <div
                                    onClick={e => onDelete(e, itemKey)}
                                    className="hidden group-hover:flex hover:opacity-70 justify-center items-center absolute top-1 right-2 bg-white rounded-full w-[25px] h-[25px]">
                                    <UI.Icon size="xs" icon="specta-trash" />
                                </div>
                            </div>
                        </>
                    )
                }}
            />
        </div>
    )
}

const ImageTextInput = ({ text, onSubmit }: { text: string; onSubmit: (text: string) => void }) => {
    const [inputValue, setInputValue] = useState(text)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleBlurOrEnter = () => {
        if (text !== inputValue) {
            onSubmit(inputValue)
        }
    }

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleBlurOrEnter()
            e.currentTarget.blur()
        }
    }

    return (
        <div style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} className="absolute flex items-center justify-center w-full h-6">
            <input
                className="bg-transparent text-xs text-white w-full text-center outline-none"
                type="text"
                value={inputValue}
                onChange={handleChange}
                onBlur={handleBlurOrEnter}
                onKeyDown={handleKeyDown}
                onClick={e => e.stopPropagation()}
            />
        </div>
    )
}
