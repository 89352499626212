import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCreateIssue } from '@shared-snap/snap/components/issues/hooks/use-create-issue'
import { useAreasOptions, useHashtagsOptions, useUsersOptions } from '@shared-snap/snap/components/issues/hooks/use-issues'
import type { IssueForm } from '@shared-snap/snap/components/issues/types'
import { useFeatureToggle } from '@shared-snap/snap/hooks/use-feature-toggles'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { CategorySelection } from '@shared/issue-data'
import { useNavigate } from '@tanstack/react-router'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { FieldErrorMessage } from 'components/atoms/field-error-message'
import { FieldLabel } from 'components/atoms/field-label'
import { FieldSelect } from 'components/atoms/field-select'
import { FieldImagesDropbox } from 'components/atoms/images-dropbox'
import { InitialsCircle } from 'components/atoms/initials-circle/initials-circle'
import Input from 'components/atoms/input/input'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import Tooltip from 'components/atoms/tooltip'
import { DateField } from 'components/molecules/date-field'
import { PriorityCheckbox } from 'components/molecules/priority-checkbox'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { prefix } from '../../routes/__root'
import { CategorySelectionBlock } from './categories-modal/categories-modal'

export const CategorySelect = () => {
    const { setValue } = useFormContext()

    const [categorySelection, setCategorySelection] = useState<CategorySelection>({})

    useEffect(() => {
        setValue('categories', categorySelection, { shouldValidate: false })
    }, [categorySelection])

    return (
        <CategorySelectionBlock
            leftRight
            categoryTextProps={{
                size: 'sm',
                color: 'snap-dark-gray',
                weight: 'normal'
            }}
            categorySelection={categorySelection ?? {}}
            setCategorySelection={setCategorySelection}
        />
    )
}

export function NewIssueModal() {
    const {
        LL: {
            shared,
            tooltips,
            issues: { newIssue }
        }
    } = useI18nContext()
    const [loading, setLoading] = useState(false)
    const areasOptions = useAreasOptions()
    const usersOptions = useUsersOptions()
    const hashtagsOptions = useHashtagsOptions()
    const createIssue = useCreateIssue(asFirebase(firebaseWrapped))
    const navigate = useNavigate()
    const defaultValues: Partial<IssueForm> = {
        images: [],
        title: '',
        dueDate: moment().startOf('day').format('YYYY-MM-DD'),
        hashtags: [],
        assignees: [],
        priority: false,
        categories: {}
    }

    const { isFeatureOn } = useFeatureToggle()

    const form = useForm<IssueForm>({
        defaultValues
    })
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = form

    async function onSubmit(data: IssueForm) {
        setLoading(true)
        await createIssue(data)
        setLoading(false)
        navigate({ to: `${prefix}/issues` })
    }

    const onClose = () => navigate({ to: `${prefix}/issues` })

    return (
        <>
            <Modal className="w-3/5 h-3/4 flex flex-col overflow-auto max-h-[702px]" onClose={onClose}>
                <ModalHeader className="bg-white pb-4" onClose={onClose}>
                    <UI.Text size="xl" color="snap-black">
                        {newIssue.header()}
                    </UI.Text>
                </ModalHeader>
                <div className="px-6 pb-6">
                    <FormProvider {...form}>
                        <form className="flex flex-col h-full justify-between" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col gap-8">
                                <FieldLabel label={newIssue.imageLabel()}>
                                    <FieldImagesDropbox disabled={loading} name="images" />
                                </FieldLabel>

                                <FieldLabel label={newIssue.titleLabel()} required>
                                    <Input
                                        disabled={loading}
                                        name="title"
                                        placeholder={newIssue.titlePlaceholder()}
                                        required
                                        register={register}
                                    />
                                    {errors.title && <FieldErrorMessage message={shared.defaultError()} />}
                                </FieldLabel>
                                <FieldLabel horizontal label={`${shared.dueDate()}:`} fieldName="dueDate">
                                    <div className="flex items-center gap-4">
                                        <UI.Icon icon="specta-duedate" color="snap-light-blue" />
                                        <DateField defaultValue={defaultValues.dueDate!} />
                                    </div>
                                </FieldLabel>

                                <FieldLabel horizontal label={`${shared.unit()}:`} required>
                                    <FieldSelect
                                        defaultValue={defaultValues.area}
                                        name={'area'}
                                        options={areasOptions}
                                        disabled={loading}
                                        Option={({ data, innerProps }) => (
                                            <div {...innerProps} className="flex flex-col w-full gap-1 p-2 border-b cursor-pointer">
                                                <UI.Text size="md" weight="bold">
                                                    {data.label}
                                                </UI.Text>
                                                <UI.Text color="snap-silver" size="sm" weight="normal">
                                                    {data.group}
                                                </UI.Text>
                                            </div>
                                        )}
                                        placeholder={shared.unitPlaceholder()}
                                        rules={{ required: shared.errorFieldIsRequired({ field: shared.unit() }) }}
                                    />
                                </FieldLabel>

                                {isFeatureOn('hashtag-killer') ? (
                                    <CategorySelect />
                                ) : (
                                    <FieldLabel horizontal label={`${shared.hashtags()}:`}>
                                        <FieldSelect
                                            defaultValue={defaultValues.hashtags}
                                            name="hashtags"
                                            placeholder={tooltips.selectHashtags()}
                                            isMulti
                                            options={hashtagsOptions}
                                            Option={({ data, innerProps }) => (
                                                <div {...innerProps} className="w-full p-2 border-b border-snap-light-gray cursor-pointer">
                                                    <UI.Text size="md" weight="normal" color="snap-light-blue">
                                                        {data.label}
                                                    </UI.Text>
                                                </div>
                                            )}
                                        />
                                    </FieldLabel>
                                )}

                                <FieldLabel horizontal label={`${shared.users()}:`}>
                                    <FieldSelect
                                        defaultValue={defaultValues.assignees}
                                        name={'assignees'}
                                        placeholder={shared.selectUsers()}
                                        isMulti
                                        options={usersOptions}
                                        Option={({ data, innerProps }) => (
                                            <div
                                                {...innerProps}
                                                className="flex items-center w-full gap-2 p-2 border-b border-snap-light-gray cursor-pointer">
                                                <InitialsCircle initials={data.initials} size="sm" />
                                                <UI.Text size="sm" weight="normal">
                                                    {data.label}
                                                </UI.Text>
                                            </div>
                                        )}
                                    />
                                </FieldLabel>
                                <div className="flex items-center justify-between">
                                    <PriorityCheckbox />
                                    <div className="flex gap-6 justify-end">
                                        {loading ? (
                                            <UI.Loader />
                                        ) : (
                                            <>
                                                <Button className="bg-snap-silver" type="button" onClick={onClose}>
                                                    {shared.cancel()}
                                                </Button>
                                                <Button type="submit">{shared.save()}</Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </Modal>
            <Tooltip id="category-selection-tooltip" />
        </>
    )
}
