export const getUserEmail = async (phoneNumber: string, projectId: string) => {
    const baseURL = `https://us-central1-${projectId}.cloudfunctions.net/`

    const response = await fetch(`${baseURL}getUserEmail`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ data: { phoneNumber } })
    })

    if (!response.ok) {
        console.error('Error fetching user email:', response.status, await response.text())
        return null
    }

    const { data } = await response.json()
    return data?.email || null
}

export const sendEmailLink = async (phoneNumber: string, projectId: string) => {
    const baseURL = `https://us-central1-${projectId}.cloudfunctions.net/`

    const response = await fetch(`${baseURL}getUserEmail/sendInvite`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data: {
                phoneNumber,
                devMode: typeof process.env['LOCAL_DEV'] === 'string'
            }
        })
    })

    if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Unknown error occurred')
    }

    return response
}
