import moment from 'moment-timezone'
import type { AreaStruct, BookingStruct, DailyCommentStruct, TaskStruct } from '../../firestore-structs'
import type { AreaSummaryStruct_v2, HousekeepingOverviewProjectionStruct_v2 } from '../../projections-v2'
import { remapToNumber } from '../projection-ticks'

export function areaOverview_v2({
    leadBooking,
    area,
    currentTask,
    isPriority,
    areaSummary,
    bookings,
    timezone,
    dailyComments
}: {
    bookings: BookingStruct[]
    timezone: string
    leadBooking: BookingStruct | null
    area: Pick<AreaStruct, 'key' | 'name' | 'activeRule' | 'guestCheckedIn' | 'guestCheckedOut'>
    currentTask?: TaskStruct
    isPriority: boolean
    areaSummary: AreaSummaryStruct_v2
    dailyComments: DailyCommentStruct[]
}) {
    const housekeepingOverview: HousekeepingOverviewProjectionStruct_v2 = {
        [area.key]: {
            extras: leadBooking?.extras ?? null,
            stays: bookings.map(b => ({
                checkin: moment.tz(b.checkinDate, timezone).format('YYYYMMDD'),
                nights: moment.tz(b.checkoutDate, timezone).startOf('days').diff(moment.tz(b.checkinDate, timezone).startOf('day'), 'days')
            })),
            activeRule: {
                customChar: area.activeRule?.customChar ?? null,
                name: area.activeRule?.name ?? null,
                description: area.activeRule?.description ?? null,
                checklistTasks: area.activeRule?.checklistTasks ?? null,
                mandatory: area.activeRule?.mandatory ?? false
            },
            currentTask: currentTask
                ? {
                      key: currentTask.key,
                      status: currentTask.status
                  }
                : null,
            bookingNotes: leadBooking?.notes ?? null,
            guestCheckedIn: area.guestCheckedIn!,
            priority: isPriority,
            guestCheckedOut: area.guestCheckedOut!,
            cleaningStatus: areaSummary.area.cleaningStatus,
            occupancy: areaSummary.area.occupancy,
            assignedTo: currentTask ? (currentTask.assignedTo ?? []) : [],
            dailyComments: (dailyComments || []).map(d => {
                return { comment: d.comment, date: d.date, key: d.key }
            }),
            leadBooking: leadBooking
                ? {
                      nrOfGuests: leadBooking.adults + leadBooking.children,
                      checkinDate: moment(leadBooking.checkinDate).valueOf(),
                      checkoutDate: moment(leadBooking.checkoutDate).valueOf(),
                      status: leadBooking.status,
                      guestName: leadBooking.guestName,
                      notes: leadBooking.notes ?? null,
                      bedSetup: leadBooking.bedSetup ?? null,
                      areaKey: leadBooking.areaKey,
                      key: leadBooking.key,
                      optInDates: leadBooking.optInDates ?? null,
                      optOutDates: leadBooking.optOutDates ?? null,
                      guestCheckedIn: leadBooking.guestCheckedIn,
                      guestCheckedOut: leadBooking.guestCheckedOut
                  }
                : null,
            ticks: remapToNumber(areaSummary.ticks)
        }
    }
    return housekeepingOverview
}
