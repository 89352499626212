import type { Firebase } from '@shared/firebase'
import { updateImageItemText } from '@shared/issue-data'
import { useRecoilState } from 'recoil'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { issueSelector } from '../state/issues-state'

export const useIssueImageText = (firebase: Firebase, issueKey: string) => {
    const currentUser = useCurrentUser()
    const [issue, setIssue] = useRecoilState(issueSelector(issueKey))

    return async (itemKey: string, obj: { content: string }) => {
        if (!issue) {
            return
        }
        setIssue({
            ...issue,
            items: issue.items?.map(item => {
                return item.key === itemKey ? { ...item, text: { ...obj, position: item.text?.position || 0 } } : item
            })
        })

        await updateImageItemText(firebase, issueKey, itemKey, obj, currentUser)
    }
}
