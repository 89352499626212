import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { ZIndex } from '@shared/css-constants'
import { type PlacesType, Tooltip as ReactTooltip } from 'react-tooltip'

interface TooltipProps {
    id: string
    text?: string | string[]
    place?: PlacesType
}

const Tooltip = ({ text, id, place = 'top' }: TooltipProps) => {
    return (
        <ReactTooltip
            id={id}
            render={({ content }) => {
                const theText: string | string[] = text ?? content ?? ''

                return Array.isArray(theText) ? (
                    <ul className="text-center">
                        {theText.map((text, index) => (
                            <li key={`${id}-${index}`}>
                                <UI.Text size="xs" weight="bold" color="snap-white">
                                    {text}
                                </UI.Text>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <UI.Text size="xs" weight="bold" color="snap-white">
                        {theText}
                    </UI.Text>
                )
            }}
            positionStrategy="fixed"
            place={place}
            noArrow
            style={{
                borderRadius: '8px',
                zIndex: ZIndex.tooltip
            }}
        />
    )
}

export default Tooltip
