import { createElement } from 'react'
import './infra/rum'
import './infra/checksumai'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'
import { App } from './app/app'
import './assets/specta-icons/sweeply-icons.css'
import './index.css'

const elementById = document.getElementById('root')
if (elementById) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    createRoot(elementById).render(createElement(RecoilRoot, {}, createElement(App)))
} else {
    console.error('No root element found')
}
