// import { StrictMode } from 'react'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import './firebase'
import './boot'
import { StrictMode } from 'react'
import { routeTree } from 'routeTree.gen'

const router = createRouter({
    routeTree,
    context: {
        isAuthenticated: false
    }
})

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router
    }
}

export const App = () => {
    return (
        <StrictMode>
            <RouterProvider router={router} />
        </StrictMode>
    )
}
