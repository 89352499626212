import { Size } from '../../registry/ui-elements-registry'
import type { Colors } from '../../types/visuals.types'
import { RoundedButton } from './rounded-button'

interface Props {
    priority: boolean
    backgroundColor?: Colors
    iconColor?: Colors
    onClick?: () => void
    size?: 'xs' | 'sm' | 'md' | 'lg'
    disabled?: boolean
}

export function PrioritySign({ priority, onClick, iconColor, backgroundColor, size, disabled = false }: Props) {
    if (!priority && !onClick) return null
    const iconColorCondition = priority ? (iconColor ?? 'sweeply-red') : 'sweeply-black'
    const emptyFunction = () => null

    return (
        <RoundedButton
            backgroundColor={backgroundColor}
            icon="specta-priority-low"
            iconColor={iconColorCondition}
            size={size}
            onClick={onClick ?? emptyFunction}
            disabled={disabled}
        />
    )
}
