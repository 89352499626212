import type { RuleStruct } from '@shared/firestore-structs'
import { atom, selector } from 'recoil'

export const rulesAtom = atom<RuleStruct[]>({
    key: 'rules-atom',
    default: []
})

export const selectedRulesAtom = atom<RuleStruct[]>({
    key: 'selected-rules-atom',
    default: []
})

export const currentRuleWizardStep = atom<number>({
    key: 'rule-wizard-steps',
    default: 1
})

export const groupFilterAtom = atom<string[]>({
    key: 'rule-groupFilterAtom',
    default: []
})

export const syncedFilterAtom = atom<boolean | null>({
    key: 'rule-syncedFilterAtom',
    default: null
})

export const searchValueAtom = atom<string>({
    key: 'rule-searchValueAtom',
    default: ''
})

export const unitsFiltersSelector = selector<{ id: string; value: string[] | string | boolean | null }[]>({
    key: 'rule-filtersSelector',
    get: ({ get }) => {
        const groupsFilter = get(groupFilterAtom)
        const syncedFilter = get(syncedFilterAtom)

        return [
            { id: 'group', value: groupsFilter },
            { id: 'synced', value: syncedFilter }
        ]
    }
})
