import type { Firebase } from '@shared/firebase'
import { useRecoilValue } from 'recoil'
import { useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUserAreaSummaryAction } from '../../use-user-action'
import { currentTaskAtom } from '../state/area-summary-state'

export function useChecklist(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const action = useUserAreaSummaryAction('update-checklist', firebase)
    const currentTask = useRecoilValue(currentTaskAtom)

    const updateChecklist = (name: string, isChecked: boolean) => {
        action(name, isChecked, currentUser)
    }

    const checklist = (currentTask?.checklist ?? []).filter(item => item.name.trim().length > 0)
    return {
        checklist: checklist,
        updateChecklist
    }
}
