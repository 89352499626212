import { UI } from '@shared-snap/snap/registry/ui-elements-registry'

export function CustomChar(props: { customChar: string }) {
    return (
        <div className="h-6 w-6 border flex flex-col justify-center items-center rounded-full border-white opacity-70">
            <UI.Text size="sm" color="white">
                {props.customChar}
            </UI.Text>
        </div>
    )
}
