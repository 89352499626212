import type { AreaStruct } from '@shared/firestore-structs'
import type { AreaSummaryStruct_v2, HousekeepingOverviewProjectionStruct_v2, ProjectionMetadata } from '@shared/projections-v2'
import type { Needed } from '@shared/type-utils'
import { DefaultValue, selector } from 'recoil'
import { areasFrontendSetter } from '../../auth/state/login'
import { areaSummaryLocalSetter, selectedAreaKey } from '../area-summary/state/area-summary-state'
import { areaSummaryDate } from '../date/state/date-selection-state'
import { housekeepingOverviewLocalOverride } from '../housekeeping-overview/state/housekeeping-overview-state'

export const areaSummaryLocalOverwrite = selector<{
    areaSummary?: Needed<Partial<AreaSummaryStruct_v2>, 'ticks'>
    overview?: Needed<Partial<HousekeepingOverviewProjectionStruct_v2[0]>, 'ticks'>
    area?: Needed<Partial<Pick<AreaStruct, 'note'> & ProjectionMetadata>, 'ticks'>
}>({
    key: 'housekeeping-areaLocalOverwrite',
    get: () => {
        throw new Error('Do not use this selector for reading. Writing only.')
    },
    set: ({ set, get }, newValue) => {
        const areaKey = get(selectedAreaKey)
        const date = get(areaSummaryDate)
        if (!areaKey) throw new Error('Area key is not defined')

        if (newValue instanceof DefaultValue) {
            throw new Error('Cannot reset local overwrite')
        } else {
            if (newValue.areaSummary) {
                set(areaSummaryLocalSetter(date), { [areaKey]: newValue.areaSummary })
            }
            if (newValue.overview) {
                set(housekeepingOverviewLocalOverride(date), { [areaKey]: newValue.overview })
            }
            if (newValue.area) {
                set(areasFrontendSetter, { [areaKey]: newValue.area })
            }
        }
    }
})
