import type { AreaStruct } from '@shared/firestore-structs'
import { atom, selector } from 'recoil'

export const unitsAtom = atom<Partial<AreaStruct>[]>({
    key: 'units-atom',
    default: []
})

export const unitsGroupsSelector = selector<string[]>({
    key: 'units-groupsSelector',
    get: ({ get }) => {
        const units = get(unitsAtom)
        return Array.from(new Set(units.map(unit => unit.group ?? '')))
    }
})

export const searchValueAtom = atom<string>({
    key: 'units-searchValueAtom',
    default: ''
})

export const groupFilterAtom = atom<string[]>({
    key: 'units-groupFilterAtom',
    default: []
})

export const statusFilterAtom = atom<string>({
    key: 'units-statusFilterAtom',
    default: 'all'
})

export const syncedFilterAtom = atom<boolean | null>({
    key: 'units-syncedFilterAtom',
    default: null
})

export const unitsFiltersSelector = selector<{ id: string; value: string[] | string | boolean | null }[]>({
    key: 'units-filtersSelector',
    get: ({ get }) => {
        const groupsFilter = get(groupFilterAtom)
        const statusFilter = get(statusFilterAtom)
        const syncedFilter = get(syncedFilterAtom)

        return [
            { id: 'group', value: groupsFilter },
            { id: 'cleaningStatus', value: statusFilter },
            { id: 'synced', value: syncedFilter }
        ]
    }
})

export const selectedUnitsAtom = atom<Partial<AreaStruct>[]>({
    key: 'units-selectedUnitsAtom',
    default: []
})
