import type { Firebase } from '@shared/firebase'
import { assignIssue, unassignIssue } from '@shared/issue-data'
import { assignTask, unassignTask } from '@shared/task-data'
import { constructAssignedTo } from '@shared/task-helpers'
import moment from 'moment-timezone'
import { useRecoilValue } from 'recoil'
import { createActivity } from '../../../user-actions/area-summary-user-actions'
import { useCurrentOrg, useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useUsers } from '../../auth/hooks/use-users'
import { startDateAtom } from '../state/my-tasks'
import { taskAreaToIssueArea } from '../utils'
import { useAreasOptions, useTaskData } from './use-tasks'

export function useAssignTask(firebase: Firebase, taskKey: string) {
    const allUsers = useUsers()
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()
    const task = useTaskData(taskKey)
    const taskDate = useRecoilValue(startDateAtom)
    const areas = useAreasOptions()
    const area = areas.find(area => area.value === task.areaKey)
    const areaKey = task.area?.key ?? area?.value

    // if (!areaKey) throw new Error('Task does not have area associated with it.')

    return async (userKeys: string[]) => {
        const users = allUsers.filter(user => userKeys.includes(user.key))
        try {
            const taskObject = { assignedTo: users.length > 0 ? users.map(user => constructAssignedTo(user)) : [] }

            if (task.type === 'issue') {
                if (taskObject.assignedTo.length > 0) {
                    await assignIssue(firebase, {
                        issueKey: task.key,
                        assignedTo: taskObject.assignedTo,
                        dueDate: task.startDate,
                        currentUser
                    })
                } else {
                    await unassignIssue(firebase, {
                        issueKey: task.key,
                        currentUser
                    })
                }
            }
            if (areaKey) {
                const activity = createActivity(
                    firebase.firestore(),
                    task.assignedTo ?? [],
                    taskObject.assignedTo,
                    currentUser,
                    areaKey,
                    currentUser.organizationKey,
                    moment.tz(taskDate, currentOrg.timezone)
                )
                task.type === 'cleaning' && taskObject.assignedTo.length === 0
                    ? await unassignTask(firebase, currentUser, task.key, null, activity)
                    : await assignTask(firebase, currentUser, task.key, taskObject, null, activity)
            }
        } catch (error: unknown) {
            //TODO: Add proper error handling
            console.error(error)
            throw new Error('Error assigning task')
        }
    }
}
