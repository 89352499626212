import type { ApaleoQueryParams, IntegrationParams, TaskboardContext } from '@shared/traces-types'
import { atom } from 'recoil'

type Params = { taskboardContext: TaskboardContext | null; search: string }

export const integrationParamsAtom = atom<IntegrationParams<TaskboardContext.RESERVATIONS | TaskboardContext.PROPERTY> | null>({
    key: 'traces-integrationParamsAtom',
    default: null
})

export const tracesParamsAtom = atom<ApaleoQueryParams | undefined>({
    key: 'traces-params',
    default: undefined
})
