import type { Firebase } from '@shared/firebase'
import { createTask, setTaskUpdate } from '@shared/task-data'
import moment from 'moment'
import { useRecoilValue } from 'recoil'
import { findSelectedItemsByKeys } from '../../../utils'
import { useCurrentOrg, useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useUsers } from '../../auth/hooks/use-users'
import { areasSelector } from '../../auth/state/login'
import type { GeneralTaskForm } from '../types'

export function useSaveGeneralTask(firebase: Firebase) {
    const areas = useRecoilValue(areasSelector)
    const users = useUsers()
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()

    async function saveGeneralTask(formData: GeneralTaskForm, taskKey?: string) {
        const { area, assignees, priority, name, dueDate, propName, propKey, pmsLinkUrl, areaKey, reservationId } = formData
        const [selectedArea] = findSelectedItemsByKeys(areas, [area ? area.value : (areaKey ?? '')])
        const selectedUsers =
            assignees && assignees.length > 0
                ? findSelectedItemsByKeys(
                      users,
                      assignees.map(u => u.value)
                  )
                : []
        const startDate = moment.tz(dueDate, currentOrg.timezone).startOf('day')

        try {
            taskKey
                ? await setTaskUpdate(firebase, currentUser, taskKey, {
                      name,
                      startDate,
                      assignedTo: selectedUsers.length > 0 ? selectedUsers : null,
                      status: selectedUsers.length > 0 ? 'assigned' : 'open',
                      priority,
                      area: selectedArea
                  })
                : await createTask(firebase, selectedArea, currentUser, 'general', {
                      name,
                      startDate,
                      assignedTo: selectedUsers.length > 0 ? selectedUsers : null,
                      priority,
                      propName,
                      propKey,
                      pmsLinkUrl,
                      reservationId,
                      areaKey: area?.value || areaKey
                  })
        } catch (error) {
            console.error(error)
        }
    }

    return saveGeneralTask
}
