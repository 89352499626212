import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import {
    useHousekeepingOverviewDate,
    useHousekeepingOverviewSetDate
} from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-housekeeping-overview'
import moment from 'moment-timezone'
import { DateNavigator } from './date-picker/date-picker'

export function DateNav() {
    const date = useHousekeepingOverviewDate()
    const setDate = useHousekeepingOverviewSetDate()
    const org = useCurrentOrg()

    return <DateNavigator value={date.format('YYYY-MM-DD')} onChange={value => setDate(moment.tz(value, org.timezone))} />
}
