import type { AreaSummaryStruct_v2, HousekeepingOverviewProjectionStruct_v2 } from '@shared/projections-v2'
import type { Needed } from '@shared/type-utils'
import { DefaultValue, selector } from 'recoil'
import { areaSummaryLocalSetter } from '../area-summary/state/area-summary-state'
import { housekeepingOverviewDate, housekeepingOverviewLocalOverride } from '../housekeeping-overview/state/housekeeping-overview-state'

export const housekeepingLocalOverride = selector<{
    areas?: { [key: string]: Needed<Partial<AreaSummaryStruct_v2>, 'ticks'> }
    overview?: { [key: string]: Needed<Partial<HousekeepingOverviewProjectionStruct_v2[0]>, 'ticks'> }
}>({
    key: 'housekeeping-overview-local-override',
    get: () => {
        throw new Error('Do not use this selector for reading. Writing only.')
    },
    set: ({ set, get }, newValue) => {
        const date = get(housekeepingOverviewDate)

        if (newValue instanceof DefaultValue) {
            throw new Error('Cannot reset local overwrite')
        } else {
            if (newValue.areas) {
                set(areaSummaryLocalSetter(date), newValue.areas)
            }
            if (newValue.overview) {
                set(housekeepingOverviewLocalOverride(date), newValue.overview)
            }
        }
    }
})
