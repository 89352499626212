import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Icon } from '@shared-snap/snap/types/icon-font.enum'
import Input from 'components/atoms/input/input'
import TextAreaInput from 'components/atoms/text-area-input'
import { useState } from 'react'

export function NoteSection({
    label,
    icon,
    name,
    handleSave,
    value,
    type = 'input'
}: {
    label: string
    name: string
    icon: Icon
    handleSave: (val: string) => void
    value: string
    type?: 'input' | 'textarea'
}) {
    const {
        LL: { area }
    } = useI18nContext()
    const [inputValue, setInputValue] = useState<string>(value)

    const handleSubmit = (changed: string) => {
        if (changed !== inputValue) {
            handleSave(changed)
            setInputValue(changed)
        }
    }
    return (
        <div className="w-full">
            <div className="flex items-center gap-2 mb-2 ">
                <UI.Icon size="sm" icon={icon} color="snap-mid-gray" />
                <UI.Text weight="bold">{label}</UI.Text>
            </div>
            <div className="flex border-b border-snap-light-gray">
                {type === 'input' ? (
                    <Input
                        initialValue={value}
                        name={name}
                        placeholder={area.addNotePlaceholder()}
                        className="w-full border-none"
                        onSave={handleSubmit}
                    />
                ) : (
                    <TextAreaInput
                        initialValue={value}
                        name={name}
                        placeholder={area.addNotePlaceholder()}
                        className="w-full border-none"
                        onSave={handleSubmit}
                    />
                )}
            </div>
        </div>
    )
}
