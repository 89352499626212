import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useNotes } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-comments-and-notes'
import {
    useCleaningStatusFilterAtom,
    useCleaningStatusFilterValues,
    useOccupancyFilterAtom,
    usePriorityFilter
} from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-housekeeping-overview'
import { notesTabsTypes } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/state/comments-and-notes-state'
import { occupancyFilterValueAtom } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/state/housekeeping-overview-state'
import {
    ListCleaningStatusOptions,
    type Option,
    ResetCleaningStatusButton
} from '@shared-snap/snap/components/housekeeping/housekeeping-overview/ui/cleaning-status-filter'
import {
    ListOccupancyOptions,
    type OccupancyOption,
    ResetOccupancyButton
} from '@shared-snap/snap/components/housekeeping/housekeeping-overview/ui/occupancy-filter'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { cleaningStatuses, occupancyList, pickCleaningStatusColor, pickOccupancyIcons } from '@shared-snap/snap/utils/housekeeping-utils'
import { Link } from '@tanstack/react-router'
import ContextMenu from 'components/atoms/context-menu/context-menu'
import { RoundButton } from 'components/atoms/round-button'
import Tooltip from 'components/atoms/tooltip'
import { useRecoilValue } from 'recoil'
import { prefix } from '../../../../routes/__root'

export function Filters() {
    const {
        LL: { tooltips }
    } = useI18nContext()
    const dailyCommentsCount = useNotes(notesTabsTypes.dailyComment).length
    return (
        <>
            <div className="flex gap-[32px]">
                <PriorityFilter />
                <Link
                    to={`${prefix}/housekeeping/comments-and-notes`}
                    className="h-8 flex relative justify-center items-center after:absolute after:content-[''] after:bg-snap-black after:w-4 after:h-4 after:rounded-full after:right-2 after:z-[-1]"
                    data-tooltip-id="daily-comments-tooltip">
                    <UI.Icon icon="specta-daily-comment" size="lg" color="sweeply-light-gray" />
                    <div className="absolute rounded-full bg-sweeply-yellow text-white text-xs p-1 h-4 w-4 flex items-center justify-center justify-self-end -right-1 -top-1">
                        {dailyCommentsCount}
                    </div>
                </Link>
                <div data-tooltip-id="housekeeping-filter-tooltip">
                    <CleaningStatusFilter />
                </div>
                <div data-tooltip-id="occupancy-filter-tooltip">
                    <OccupancyFilter />
                </div>
            </div>
            <Tooltip id="daily-comments-tooltip" text={tooltips.dailyComments()} />
            <Tooltip id="housekeeping-filter-tooltip" text={tooltips.housekeepingFilter()} />
            <Tooltip id="occupancy-filter-tooltip" text={tooltips.occupancyFilter()} place="top-end" />
        </>
    )
}

function PriorityFilter() {
    const {
        LL: { shared, tooltips }
    } = useI18nContext()
    const [active, setActive] = usePriorityFilter()
    return (
        <>
            <div data-tooltip-id="priority-filter-tooltip">
                <RoundButton icon="specta-priority-low" iconColor={active ? 'snap-red' : 'snap-black'} onClick={() => setActive(!active)} />
            </div>
            <Tooltip id="priority-filter-tooltip" text={active ? shared.seeAll() : tooltips.priorityOnlyHigh()} />
        </>
    )
}

const OccupancyRender = (option: OccupancyOption) => {
    const { LL } = useI18nContext()
    return (
        <div className="flex flex-row justify-start items-center cursor-pointer py-[14px] px-[10px] border-b border-sweeply-light-gray gap-2">
            <UI.Icon size="xs" className={'grow-0'} icon={pickOccupancyIcons(option.value)}></UI.Icon>
            <label className={'grow text-left cursor-pointer'}>{LL.shared.occupancy[option.label]()}</label>
            <div
                className={`w-6 h-6 grow-0 flex items-center pl-1.5 rounded-full  ${
                    option.selected ? 'bg-sweeply-teal' : 'border border-sweeply-light-gray'
                }`}>
                {option.selected && <UI.Icon icon="specta-checkmark" size="xs" color="white" />}
            </div>
        </div>
    )
}

function OccupancyFilter() {
    const { dropdownIsOpen, openDropdown, closeDropdown } = useOccupancyFilterAtom()
    const occupancyFilterValues = useRecoilValue(occupancyFilterValueAtom)

    return (
        <div className="flex flex-col">
            <RoundButton
                backgroundColor={
                    occupancyFilterValues.length !== occupancyList.length || dropdownIsOpen ? 'snap-light-yellow' : 'sweeply-light-gray'
                }
                className={dropdownIsOpen ? 'border border-snap-yellow' : ''}
                icon="specta-booking-note"
                onClick={openDropdown}
            />
            <div className="relative flex justify-end">
                {dropdownIsOpen && (
                    <ContextMenu className="px-[10px] py-[20px]" onBlur={closeDropdown}>
                        <ListOccupancyOptions occupancyOptionRenderer={OccupancyRender} />
                        <ResetOccupancyButton />
                    </ContextMenu>
                )}
            </div>
        </div>
    )
}

const CleaningStatusOption = (option: Option) => {
    const color = pickCleaningStatusColor({ cleaningStatus: option.value, occupancy: 'vacant' })
    const { LL } = useI18nContext()
    return (
        <div className="flex justify-between items-center cursor-pointer py-[14px] px-[10px] border-b border-sweeply-light-gray">
            <div className="flex flex-row items items-center mr-[40px]">
                <div className={`w-[12px] h-[12px] mr-[10px] rounded-full bg-${color}`} />
                <label>{LL.shared.cleaningStatuses[option.label]()}</label>
            </div>
            <div
                className={`w-6 h-6 flex pl-1.5 items-center rounded-full  ${
                    option.selected ? 'bg-sweeply-teal' : 'border border-sweeply-light-gray'
                }`}>
                {option.selected && <UI.Icon icon="specta-checkmark" size="xs" color="white" />}
            </div>
        </div>
    )
}

function CleaningStatusFilter() {
    const { dropdownIsOpen, openDropdown, closeDropdown } = useCleaningStatusFilterAtom()
    const cleaningStatusFilterValues = useCleaningStatusFilterValues()

    return (
        <div className="flex flex-col">
            <RoundButton
                backgroundColor={
                    cleaningStatusFilterValues.length !== cleaningStatuses.length || dropdownIsOpen
                        ? 'snap-light-yellow'
                        : 'sweeply-light-gray'
                }
                className={dropdownIsOpen ? 'border border-snap-yellow' : ''}
                icon="specta-housekeeping-filter"
                onClick={openDropdown}
            />
            <div className="relative flex justify-end">
                {dropdownIsOpen && (
                    <ContextMenu className="px-[10px] py-[20px] w-max" onBlur={closeDropdown}>
                        <ListCleaningStatusOptions cleaningStatusOptionRenderer={CleaningStatusOption} />
                        <ResetCleaningStatusButton />
                    </ContextMenu>
                )}
            </div>
        </div>
    )
}
