import { match } from 'ts-pattern'
import { UI } from '../../registry/ui-elements-registry'
import type { Colors, Icon } from '../../types/visuals.types'

interface RoundedButtonProps {
    icon: Icon
    iconColor?: Colors
    backgroundColor?: Colors
    size?: 'xs' | 'sm' | 'md' | 'lg'
    onClick: () => void
    disabled?: boolean
}

export function RoundedButton({ icon, onClick, iconColor, size = 'md', backgroundColor = 'white', disabled = false }: RoundedButtonProps) {
    const sizeClass = match(size)
        .with('xs', () => 'w-4 h-4')
        .with('sm', () => 'w-5 h-5')
        .with('md', () => 'w-7 h-7')
        .with('lg', () => 'w-12 h-12')
        .otherwise(() => 'w-8 h-8')
    const iconSize = match(size)
        .with('xs', () => 'text-xs')
        .with('sm', () => 'text-sm')
        .with('md', () => 'text-md')
        .with('lg', () => 'text-lg')
        .otherwise(() => 'text-md')

    return (
        <UI.Pressable onPress={onClick} disabled={disabled}>
            <UI.View
                className={`flex items-center justify-center ${sizeClass} bg-${backgroundColor} ${
                    disabled ? 'opacity-50 pointer-events-none' : ''
                } rounded-full`}>
                <UI.Icon
                    className={`text-${iconColor ? iconColor : 'sweeply-black'} ${iconSize}`}
                    size={size}
                    color={iconColor}
                    icon={icon}
                />
            </UI.View>
        </UI.Pressable>
    )
}
