import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useHousekeepingOverviewDataStatus } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-housekeeping-overview'
import {
    useAllTasksCount,
    useAreasOptions,
    useAssignedTasks,
    useDoneTasks,
    useDueTasks,
    usePriorityFilter,
    useTaskboardContext,
    useTasks,
    useTasksDate,
    useTasksSearch
} from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import { combineFirestoreFetchStatus } from '@shared-snap/snap/infra/firestore-atom'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { TaskStruct } from '@shared/firestore-structs'
import { TaskboardContext } from '@shared/traces-types'
import { Link, Outlet } from '@tanstack/react-router'
import { Button } from 'components/atoms/button/button'
import { DateNav } from 'components/atoms/dateNav'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { RoundButton } from 'components/atoms/round-button'
import { SearchInput } from 'components/atoms/search-input'
import Tooltip from 'components/atoms/tooltip'
import { TaskRow } from 'components/molecules/taskboard/task-row'
import { TypeFilterButtons } from 'components/molecules/taskboard/type-filter-buttons'
import moment from 'moment'
import { type PropsWithChildren, useMemo, useReducer, useState } from 'react'
import NoTask from '../../../assets/message-no-task.svg'
import { prefix } from '../../../routes/__root'

interface ColumnProps {
    type: 'due' | 'assigned' | 'done' | 'overdue'
    items: TaskStruct[]
}

export function Taskboard() {
    const taskDataStatus = useTasks()
    const housekeepingDataStatus = useHousekeepingOverviewDataStatus()
    const taskData = combineFirestoreFetchStatus([housekeepingDataStatus, taskDataStatus])
    const { taskboardContext } = useTaskboardContext()
    const { status } = taskData

    return (
        <div>
            <Outlet />
            <div className="flex flex-col">
                <Header context={taskboardContext} />
                {status === 'error' && <UI.ErrorMessage>{`Error loading data ${taskData.errors}`}</UI.ErrorMessage>}
                {status === 'loading' && <UI.Loader />}
                {status === 'loaded' && <Content />}
            </div>
        </div>
    )
}

function Content() {
    const {
        LL: { tasks }
    } = useI18nContext()
    const taskCount = useAllTasksCount()
    return (
        <div>
            <div className="flex flex-row gap-5 py-5">
                <DueColumn />
                <AssignedColumn />
                <DoneColumn />
            </div>
            {!taskCount && (
                <div className="flex flex-col justify-center items-center">
                    <img src={NoTask} alt="No tasks found" className="mb-5" />
                    <UI.Text size="xl" weight="bold" color="snap-mid-gray">
                        {tasks.notFoundLabel()}
                    </UI.Text>
                    <div className="flex items-start gap-1 mb-2 mt-4">
                        <UI.Text size="md" color="snap-mid-gray">
                            {tasks.notFoundSublabelWeb()}
                        </UI.Text>
                        <Link to={`${prefix}/taskboard/new-task`}>
                            <button className="text-snap-red border-b-2 border-snap-yellow text-base">
                                {tasks.notFoundSublabelNewTask()}
                            </button>
                        </Link>
                    </div>
                    <UI.Text size="md" color="snap-mid-gray">
                        {tasks.notFoundSublabelWebContinue()}
                    </UI.Text>
                </div>
            )}
        </div>
    )
}

function Header({ context }: { context: TaskboardContext }) {
    const {
        LL: { tasks }
    } = useI18nContext()
    const allTaskCount = useAllTasksCount()
    const isReservations = context === TaskboardContext.RESERVATIONS

    return (
        <div className="flex items-center justify-between mb-[21px]">
            <div className="flex flex-col gap-8 flex-1">
                <div className="flex gap-[16px] items-center">
                    <h1 className="text-[32px] mb-[5px] font-bold">{tasks.header()}</h1>
                    <QuantityLabel quantity={allTaskCount} />
                </div>
                {!isReservations && <SearchFilter />}
            </div>

            {!isReservations && (
                <div className="flex flex-col gap-8 flex-2">
                    <TypeFilterButtons />
                    <div className="flex items-center justify-center">
                        <DateNav />
                    </div>
                </div>
            )}
            <div className="flex flex-col gap-8 flex-1">
                <Link to={`${prefix}/taskboard/new-task`} className="w-fit ml-auto">
                    <Button>{tasks.newTaskHeader()}</Button>
                </Link>
                {!isReservations && (
                    <div className="flex items-center justify-end">
                        <PriorityFilter />
                    </div>
                )}
            </div>
        </div>
    )
}

function SearchFilter() {
    const {
        LL: { tasks }
    } = useI18nContext()
    const [search, setSearch] = useTasksSearch()
    return <SearchInput placeholder={tasks.searchPlaceholder()} value={search} onChange={value => setSearch(value)} />
}

function PriorityFilter() {
    const {
        LL: { tooltips, shared }
    } = useI18nContext()
    const [active, setActive] = usePriorityFilter()
    return (
        <>
            <div data-tooltip-id="priority-tooltip">
                <RoundButton icon="specta-priority-low" iconColor={active ? 'snap-red' : 'snap-black'} onClick={() => setActive(!active)} />
            </div>
            <Tooltip id="priority-tooltip" text={active ? shared.seeAll() : tooltips.priorityOnlyHigh()} place="top-end" />
        </>
    )
}

function DueColumn() {
    const { dueTasks, dueLateTasks } = useDueTasks()

    return (
        <ColumnsWrapper>
            <Column type="due" items={dueTasks} />
            <Column type="overdue" items={dueLateTasks} />
        </ColumnsWrapper>
    )
}

function AssignedColumn() {
    const { assignedTasks, assignedLateTasks } = useAssignedTasks()

    return (
        <ColumnsWrapper>
            <Column type="assigned" items={assignedTasks} />
            <Column type="overdue" items={assignedLateTasks} />
        </ColumnsWrapper>
    )
}

function DoneColumn() {
    const doneTasks = useDoneTasks()

    return (
        <ColumnsWrapper>
            <Column type="done" items={doneTasks} />
        </ColumnsWrapper>
    )
}

function ColumnsWrapper({ children }: PropsWithChildren) {
    return <div className="flex flex-col gap-3 basis-1/3">{children}</div>
}

function Column({ type, items }: ColumnProps) {
    const {
        LL: {
            tasks: { columns }
        }
    } = useI18nContext()
    const [isOpened, setIsOpened] = useState(true)

    const bottomColor = (() => {
        switch (type) {
            case 'due':
                return 'snap-black'
            case 'assigned':
                return 'snap-yellow'
            case 'done':
                return 'snap-green'
            default:
                return 'transparent'
        }
    })()

    return (
        <div>
            <div
                className={`flex items-center gap-4 cursor-pointer mb-4 ${
                    type === 'due' || type === 'assigned' || type === 'done' ? `pb-2 border-b-2 border-${bottomColor}` : ''
                }`}
                onClick={() => setIsOpened(!isOpened)}>
                <div className="flex items-center gap-1">
                    <UI.Text size="md">{columns[type]()}</UI.Text>
                    <QuantityLabel quantity={items.length} size="md" />
                </div>
                <div className={`transition-transform duration-300 transform ${isOpened ? 'rotate-90' : ''}`}>
                    <UI.Icon icon="specta-arrow-right" size="xs" />
                </div>
                {type === 'overdue' && <UI.Icon icon="specta-issues" size="xs" />}
            </div>
            {isOpened && (
                <div className="flex flex-col gap-2">
                    <UI.List items={items} render={(item, index) => <TaskRow key={item.key + index} item={item} />} />
                </div>
            )}
        </div>
    )
}
