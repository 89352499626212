import type { Occupancy } from '@shared/firestore-structs'
import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { useI18nContext } from '../../../../../i18n/i18n-react'
import type { Translation } from '../../../../../i18n/i18n-types'
import { UI } from '../../../../registry/ui-elements-registry'
import { occupancyList, occupancyOptions } from '../../../../utils/housekeeping-utils'
import { RoundedButton } from '../../../molecules/rounded-button'
import { occupancyFilterValueAtom } from '../state/housekeeping-overview-state'
import { occupancyFilterAtom } from '../state/housekeeping-overview-state'

export interface OccupancyOption {
    value: Occupancy
    label: keyof Translation['shared']['occupancy']
    selected: boolean
}
export function OccupancyButton() {
    const [dropdownOpen, setDropdownOpen] = useRecoilState(occupancyFilterAtom)
    const onClick = () => setDropdownOpen(!dropdownOpen)
    return <RoundedButton icon={'specta-booking-note'} backgroundColor="sweeply-mid-gray" onClick={onClick} />
}

export function ResetOccupancyButton() {
    const {
        LL: { filters }
    } = useI18nContext()
    const setOccupancyFilterValue = useSetRecoilState(occupancyFilterValueAtom)
    const onReset = () => {
        setOccupancyFilterValue(occupancyList)
    }
    return <UI.Button onClick={() => onReset()} text={filters.reset()} />
}

export function ListOccupancyOptions(props: { occupancyOptionRenderer: (option: OccupancyOption) => JSX.Element }) {
    const [occupancyFilter, setOccupancyFilterValue] = useRecoilState(occupancyFilterValueAtom)
    const [options, setOptions] = useState(createOccupancyOptions(occupancyFilter))

    useEffect(() => {
        setOptions(createOccupancyOptions(occupancyFilter))
    }, [occupancyFilter])

    const onOptionSelect = (option: OccupancyOption) => {
        const newOptions = options.map(o => ({
            ...o,
            selected: o.value === option.value ? !o.selected : o.selected
        }))
        setOptions(newOptions)
        const newSelected = newOptions.filter(o => o.selected).map(o => o.value)
        setOccupancyFilterValue(newSelected)
    }

    return (
        <UI.List
            items={options}
            render={(option, i) => {
                return (
                    <UI.Pressable key={i} onPress={() => onOptionSelect(option)}>
                        {props.occupancyOptionRenderer(option)}
                    </UI.Pressable>
                )
            }}
        />
    )
}

function createOccupancyOptions(occupancyFilter: Occupancy[]): OccupancyOption[] {
    const allSelected = occupancyFilter.length === occupancyOptions.length

    return [
        ...occupancyOptions.map(option => ({
            ...option,
            selected: occupancyFilter.includes(option.value) || allSelected
        }))
    ]
}
