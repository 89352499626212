import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { usePermission } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import {
    useAreaGroupsFilter,
    useAreaGroupsNames,
    useAreasQuantity,
    useHousekeepingOverviewData,
    useHousekeepingOverviewDataStatus
} from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-housekeeping-overview'
import {
    useMassAssigningBarOpen,
    useSelectByGroup,
    useSetMassAssigningBarOpen
} from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-mass-assigning-bar'
import { useSearchFilter } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/ui/search-filter'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { Outlet } from '@tanstack/react-router'
import NoAreas from 'assets/no-areas-found.svg'
import { DateNav } from 'components/atoms/dateNav'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { SearchInput } from 'components/atoms/search-input'
import Tooltip from 'components/atoms/tooltip'
import { AreaBox } from 'components/molecules/housekeeping/area-box/area-box'
import { Filters } from 'components/molecules/housekeeping/housekeeping-filters/housekeeping-filters'
import QuickGuideModal from 'components/molecules/housekeeping/quick-guide/quick-guide-modal'
import { MassAssigningBar } from 'components/organisms/mass-assigning-bar/mass-assigning-bar'
import { Workload } from 'components/organisms/mass-assigning-bar/workload'
import { useMemo, useState } from 'react'

export const HousekeepingLayout: React.FC = () => {
    return (
        <>
            <Outlet />
            <div className="flex flex-col grow shrink-0 basis-auto">
                <Header />
                <GroupsTabs />
                <Content />
            </div>
        </>
    )
}

function GroupsTabs() {
    const [areaGroupsFilterValue, setAreaGroupsFilterValue] = useAreaGroupsFilter()
    const groupsNames = useAreaGroupsNames()

    const groupsTabsOptions = useMemo(() => {
        const allTab = {
            groupName: 'All',
            quantity: groupsNames.reduce((acc, g) => acc + g.size, 0)
        }

        return [allTab, ...groupsNames.map(group => ({ groupName: group.name, quantity: group.size }))]
    }, [groupsNames])

    return (
        <div className="flex gap-y-3 mb-[30px] w-fit max-w-[100%] flex-wrap">
            <UI.List
                items={groupsTabsOptions}
                render={({ groupName, quantity }) => {
                    const selected = groupName === areaGroupsFilterValue

                    return (
                        <div
                            key={groupName}
                            onClick={() => setAreaGroupsFilterValue(groupName)}
                            className="relative flex items-center px-2 gap-x-2 pb-[8px] cursor-pointer border-b border-sweeply-light-gray">
                            <div className="w-max">
                                <UI.Text size="sm" color={selected ? 'sweeply-red' : 'snap-silver'}>
                                    {groupName}
                                </UI.Text>
                            </div>
                            <QuantityLabel backgroundColor={selected ? 'snap-teal' : 'snap-silver'} size="md" quantity={quantity} />
                            {selected && <div className="absolute left-0 right-0 bottom-[-2px] h-[3px] bg-snap-yellow rounded" />}
                        </div>
                    )
                }}
            />
        </div>
    )
}

function Content() {
    const {
        LL: { housekeeping }
    } = useI18nContext()
    const groups = useHousekeepingOverviewData()
    const data = useHousekeepingOverviewDataStatus()
    const isMassAssigningBarOpen = useMassAssigningBarOpen()
    const selectByGroup = useSelectByGroup()

    const { status } = data

    if (status === 'loading') {
        return <UI.Loader />
    }

    if (status === 'error') {
        return <UI.ErrorMessage>{`Error loading data ${data.errors}`}</UI.ErrorMessage>
    }

    return (
        <div className="flex flex-col grow shrink-0 basis-auto">
            {!groups.length ? (
                <div className="h-full flex flex-col justify-center items-center mt-10">
                    <img src={NoAreas} alt="No issues found" className="mb-5" />
                    <UI.Text size="xl" weight="bold" color="snap-mid-gray">
                        {housekeeping.notFoundLabel()}
                    </UI.Text>
                    <div className="mt-4 text-center max-w-[200px]">
                        <UI.Text size="md" color="snap-mid-gray">
                            {housekeeping.notFoundSublabel()}
                        </UI.Text>
                    </div>
                </div>
            ) : (
                <div className="h-full pb-20">
                    <UI.List
                        items={groups}
                        render={(data, i) => (
                            <div key={data.group + i} className="mb-5">
                                <button onClick={() => selectByGroup(data.group)} disabled={!isMassAssigningBarOpen} className="w-full">
                                    <div className="flex items-center gap-x-5 mb-[17px]">
                                        <div className="flex items-center gap-[6px]">
                                            <h3 className="text-[16px] font-bold text-snap-mid-gray">{data.group}</h3>
                                            <QuantityLabel quantity={data.areaBoxSummaries.length} size="md" />
                                        </div>
                                        <div className="flex items-center gap-x-2">
                                            <Workload areas={data.areaBoxSummaries} />
                                        </div>
                                    </div>
                                </button>
                                <div className="flex flex-wrap gap-[8px]">
                                    <UI.List
                                        items={data.areaBoxSummaries}
                                        render={(areaBoxSummary, index) => (
                                            <AreaBox
                                                key={areaBoxSummary.areaKey + index}
                                                areaKey={areaBoxSummary.areaKey}
                                                areaBoxSummary={areaBoxSummary}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                    />
                </div>
            )}
            <MassAssigningBar />
        </div>
    )
}

function Header() {
    const {
        LL: { housekeeping, quickGuide }
    } = useI18nContext()
    const [quickGuideOpen, setQuickGuideOpen] = useState(false)
    const areasQuantity = useAreasQuantity()
    const groups = useHousekeepingOverviewData()
    const areas = groups.flatMap(group => group.areaBoxSummaries)
    return (
        <>
            {quickGuideOpen && <QuickGuideModal visible={quickGuideOpen} onClose={() => setQuickGuideOpen(false)} />}
            <div className="flex items-center justify-between mb-[21px]">
                <div className="flex gap-[16px] items-center">
                    <h1 className="text-[32px] mb-[5px] font-bold">{housekeeping.header()}</h1>
                    <QuantityLabel quantity={areasQuantity} />
                    <Workload areas={areas} />
                    <button onClick={() => setQuickGuideOpen(true)} data-tooltip-id="quick-guide-tooltip">
                        <UI.Icon icon="specta-quick_guide" size="sm" color="snap-silver" />
                    </button>
                </div>
                <MassAssigningButton />
            </div>
            <div className="flex items-center justify-between mb-[60px]">
                <div>
                    <SearchFilter />
                </div>
                <DateNav />
                <Filters />
            </div>
            <Tooltip id="quick-guide-tooltip" text={quickGuide.title()} />
        </>
    )
}

function MassAssigningButton() {
    const {
        LL: {
            housekeeping: { massAssignBar }
        }
    } = useI18nContext()
    const setMassAssigningBarOpen = useSetMassAssigningBarOpen()
    const havePermission = usePermission()

    if (!havePermission('assign-housekeeping-assign')) return null

    return <UI.Button text={massAssignBar.assignStaffBtn()} onClick={() => setMassAssigningBarOpen(prev => !prev)} />
}

function SearchFilter() {
    const {
        LL: { housekeeping }
    } = useI18nContext()
    const { searchTerm, setSearchTerm } = useSearchFilter()

    return (
        <SearchInput
            placeholder={housekeeping.searchPlaceholder()}
            value={searchTerm === 'all' ? '' : searchTerm.searchTerm}
            onChange={value => setSearchTerm(value)}
        />
    )
}
