import type { DocumentReference, FirebaseFirestore, Query } from '@shared/firebase'
import { useEffect, useState } from 'react'
import { type RecoilState, atom, useSetRecoilState } from 'recoil'
import { Hooks } from '../registry/hooks-registry'
import type { FirestoreError } from './firebase-hooks'

export function firestoreAtom<T>(name: string) {
    return atom<{ loading: boolean; error: Error | undefined; data: T | undefined }>({
        key: name,
        default: { loading: true, error: undefined, data: undefined }
    })
}

export type FirebaseFetchStatus = { status: 'loading' } | { status: 'error'; errors: Error[] } | { status: 'loaded' }

export function useLoadFirestoreQueryInAtom<T>(
    atom: RecoilState<{
        loading: boolean
        error: Error | undefined
        data: T[] | undefined
    }>,
    query: (db: FirebaseFirestore) => Query<T> | null
): FirebaseFetchStatus {
    const setAtomState = useSetRecoilState(atom)
    const [data, loading, error] = Hooks.useCollectionData(db => query(db))
    useEffect(() => {
        setAtomState({ loading: loading, error: error, data: data ?? [] })
    }, [data, loading, error])
    return loading ? { status: 'loading' } : error ? { status: 'error', errors: [error] } : { status: 'loaded' }
}

export function useSplitFirestoreQuery<T>(
    onData: (data: T[]) => void,
    onLoading: () => void,
    query: (db: FirebaseFirestore) => Query<T> | null,
    name?: string
): FirebaseFetchStatus {
    const useColl = Hooks.useCollectionData
    const [data, loading, error] = useColl(db => query(db))
    return useDocumentSplitter({
        loading: loading,
        data: data,
        onLoading: onLoading,
        onData: onData,
        error: error,
        name: name ?? 'unknown'
    })
}

export function useSplitFirestoreQueryOnce<T>(
    onData: (data: T[]) => void,
    onLoading: () => void,
    query: (db: FirebaseFirestore) => Query<T>
): FirebaseFetchStatus {
    const useColl = Hooks.useCollectionDataOnce
    const [data, loading, error] = useColl(db => query(db))
    return useDocumentSplitter({ loading: loading, data: data, onLoading: onLoading, onData: onData, error: error })
}

export function useSplitFirestoreDocument<T>({
    onData,
    onLoading,
    query,
    onError,
    name
}: {
    onData: (data: T) => void
    onLoading?: () => void
    onError?: () => T
    query: (db: FirebaseFirestore) => DocumentReference<T>
    name?: string
}): FirebaseFetchStatus {
    const useColl = Hooks.useDocumentData
    const [data, loading, error] = useColl(db => query(db))
    return useDocumentSplitter({
        loading: loading,
        data: data,
        onMissingData: onError,
        onLoading:
            onLoading ??
            (() => {
                return
            }),
        onData: onData,
        error: error,
        name: name
    })
}

function useDocumentSplitter<T>({
    loading,
    data,
    onLoading,
    onData,
    onMissingData,
    error,
    name
}: {
    loading: boolean
    data: T | undefined
    onLoading: () => void
    onData: (data: T) => void
    onMissingData?: () => T
    error: FirestoreError | undefined
    name?: string
}): FirebaseFetchStatus {
    useEffect(() => {
        const docName = name ?? 'unknown document'
        if (error && !onMissingData) {
            console.error(`error loading ${docName}`, error)
        } else if (error && onMissingData) {
            const fallbackData = onMissingData()
            console.error(`error loading ${docName} but fallback data ${fallbackData} ...`, error)
            onData(fallbackData)
        } else if (loading) {
            console.debug(`loading document ${docName}...`)
            onLoading()
        } else if (data) {
            console.debug(`loaded document ${docName}...`)
            onData(data)
        } else {
            const fallbackData = onMissingData?.()
            if (fallbackData) {
                console.debug(`No data found for ${docName}, so falling on fallback ${typeof fallbackData}...`)
                onData(fallbackData)
            } else {
                console.error(`No data found for ${docName} and fallback data is not provided.`)
            }
        }
    }, [loading, data, error])
    return loading
        ? { status: 'loading' }
        : error && !data
          ? { status: 'error', errors: [error ?? new Error('Missing data')] }
          : { status: 'loaded' }
}

export function useSplitFirestoreDocumentOnce<T>(
    onData: (data: T) => void,
    onLoading: () => void,
    query: (db: FirebaseFirestore) => DocumentReference<T>
): FirebaseFetchStatus {
    const [data, loading, error] = Hooks.useDocumentDataOnce(db => query(db))
    return useDocumentSplitter({ loading: loading, data: data, onLoading: onLoading, onData: onData, error: error })
}

export function combineFirestoreFetchStatus(statuses: FirebaseFetchStatus[]): FirebaseFetchStatus {
    const result = statuses.reduce((acc, s) => {
        if (acc.status === 'error' || s.status === 'error') {
            return {
                status: 'error',
                errors: (acc.status === 'error' ? acc.errors : []).concat(s.status === 'error' ? s.errors : [])
            }
        } else if (s.status === 'loading' || acc.status === 'loading') {
            return { status: 'loading' }
        } else {
            return { status: 'loaded' }
        }
    })
    console.debug(`Input statuses: ${statuses.map(s => s.status).join(', ')}. Combined status: ${result.status}`)
    return result
}

export function useFetchStatus(...statuses: FirebaseFetchStatus[]) {
    const [fetchStatus, setFetchStatus] = useState<FirebaseFetchStatus>({ status: 'loading' })
    const status = combineFirestoreFetchStatus(statuses)
    useEffect(() => {
        setFetchStatus(status)
    }, [status.status, (status.status === 'error' ? status.errors : []).length])
    return fetchStatus
}
