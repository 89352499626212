import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useMassAssignIssues } from '@shared-snap/snap/components/issues/hooks/use-assign-issue'
import { useMassCompleteIssues } from '@shared-snap/snap/components/issues/hooks/use-complete-issue'
import { useMassDeleteIssues } from '@shared-snap/snap/components/issues/hooks/use-delete-issue'
import { useMassUpdateHashtagsToIssues } from '@shared-snap/snap/components/issues/hooks/use-hashtags-issue'
import { useHashtagsOptions, useSelectedIssues, useUsersOptions } from '@shared-snap/snap/components/issues/hooks/use-issues'
import { useMassUncompleteIssues } from '@shared-snap/snap/components/issues/hooks/use-uncomplete-issue'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { TaskStatus } from '@shared/firestore-structs'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import { Modal } from 'components/atoms/modal/modal'
import { RoundButton } from 'components/atoms/round-button'
import Tooltip from 'components/atoms/tooltip'
import { SelectHashtagsInput } from 'components/molecules/issues/hashtags-select'
import { useState } from 'react'
import { UsersModal } from '../users-modal/users-modal'

export function ActionBar() {
    const {
        LL: {
            issues: { actionBar },
            shared,
            tooltips
        }
    } = useI18nContext()
    const usersOptions = useUsersOptions()
    const { clearAll, selectedIssues, hasSelectedIssues } = useSelectedIssues()

    const deleteIssues = useMassDeleteIssues(asFirebase(firebaseWrapped))
    const completeIssues = useMassCompleteIssues(asFirebase(firebaseWrapped))
    const uncompleteIssues = useMassUncompleteIssues(asFirebase(firebaseWrapped))
    const assignIssues = useMassAssignIssues(asFirebase(firebaseWrapped))

    const [isHashtagModalOpen, setHashtagModalOpen] = useState(false)
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
    const [isUnassignModalOpen, setUnassignModalOpen] = useState(false)
    const [isUsersModalOpen, setUsersModalOpen] = useState(false)

    if (!hasSelectedIssues) return null

    const onDelete = () => {
        deleteIssues(selectedIssues)
        clearAll()
        setDeleteModalOpen(false)
    }

    const onSetStatus = (status: TaskStatus) => {
        if (status === 'completed') {
            completeIssues(selectedIssues)
        } else if (status === 'open') {
            uncompleteIssues(selectedIssues)
        }
        clearAll()
    }

    const onUnassign = () => {
        assignIssues(selectedIssues, [])
        clearAll()
        setUnassignModalOpen(false)
    }

    const onAssign = (userKeys: string[]) => {
        assignIssues(selectedIssues, userKeys)
        clearAll()
        setUsersModalOpen(false)
    }

    return (
        <div
            style={{
                filter: 'drop-shadow(0px -16px 16px rgba(0, 0, 0, 0.09)) drop-shadow(0px -63px 25px rgba(0, 0, 0, 0.01))'
            }}
            className="sticky inset-0 py-[24px] px-[65px] bg-snap-mid-silver z-20">
            <div className="flex flex-row items-center justify-between">
                <div onClick={clearAll} className="cursor-pointer p-2">
                    <UI.Icon size="xs" icon="specta-close" color="snap-silver" />
                </div>
                <UI.Text size="xs" color="snap-silver">
                    {actionBar.selected({ num: selectedIssues.length })}
                </UI.Text>

                <div className="flex flex-row justify-between w-[380px]">
                    <div data-tooltip-id="delete-issues">
                        <RoundButton onClick={() => setDeleteModalOpen(true)} icon="specta-trash" />
                    </div>
                    <div data-tooltip-id="complete-issues">
                        <RoundButton onClick={() => onSetStatus('completed')} icon="specta-checkmark" />
                    </div>
                    <div data-tooltip-id="open-issues">
                        <RoundButton onClick={() => onSetStatus('open')} icon="specta-recycling" />
                    </div>
                    <div data-tooltip-id="set-issues-hashtags">
                        <RoundButton onClick={() => setHashtagModalOpen(true)} icon="specta-hashtags" />
                    </div>
                </div>

                <div className="flex gap-x-10">
                    <UI.Button className="bg-snap-silver" onClick={() => setUnassignModalOpen(true)} text={shared.unassignBtn()} />
                    <UI.Button onClick={() => setUsersModalOpen(true)} text={shared.assignBtn()} />
                </div>
            </div>

            <HashtagModal issuesCount={selectedIssues.length} isOpen={isHashtagModalOpen} onClose={() => setHashtagModalOpen(false)} />
            <ConfirmModal
                isOpen={isDeleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                onConfirm={onDelete}
                headerText={actionBar.deletingHeader()}
                type="delete"
                count={selectedIssues.length}
            />
            <ConfirmModal
                isOpen={isUnassignModalOpen}
                onClose={() => setUnassignModalOpen(false)}
                onConfirm={onUnassign}
                headerText={actionBar.unassigningHeader()}
                type="unassign"
                count={selectedIssues.length}
            />
            {isUsersModalOpen && (
                <UsersModal
                    usersOptions={usersOptions}
                    isOpen={isUsersModalOpen}
                    onClose={() => setUsersModalOpen(false)}
                    onAssign={onAssign}
                />
            )}

            <Tooltip id="delete-issues" text={tooltips.deleteIssue()} />
            <Tooltip id="complete-issues" text={tooltips.completeIssue()} />
            <Tooltip id="open-issues" text={tooltips.openIssue()} />
            <Tooltip id="set-issues-hashtags" text={tooltips.selectHashtags()} />
        </div>
    )
}

function HashtagModal({ isOpen, onClose, issuesCount }: { isOpen: boolean; onClose: () => void; issuesCount: number }) {
    const {
        LL: {
            issues: { actionBar }
        }
    } = useI18nContext()
    const hashtagsOptions = useHashtagsOptions()
    const { clearAll, selectedIssues } = useSelectedIssues()
    const [selectedHashtags, setSelectedHashtags] = useState<
        {
            value: string
            label: string
        }[]
    >([])

    const updateIssueHashtags = useMassUpdateHashtagsToIssues(asFirebase(firebaseWrapped))

    if (!isOpen) return null

    const onChange = (hashtag: string) => {
        setSelectedHashtags(prev =>
            prev.some(h => h.value === hashtag) ? prev.filter(h => h.value !== hashtag) : [...prev, { value: hashtag, label: hashtag }]
        )
    }

    const updateHashtags = (type: 'add' | 'remove') => {
        updateIssueHashtags(
            type,
            selectedIssues,
            selectedHashtags.map(h => h.value)
        )
        clearAll()
        onClose()
    }

    return (
        <Modal className="w-[550px] p-8" isOpen={isOpen} onClose={onClose}>
            <div className="flex flex-row justify-between items-center mb-8">
                <UI.Text size="lg">{actionBar.selectHashtagsHeader()}</UI.Text>
                <UI.Icon className="cursor-pointer" size="xs" color="snap-silver" onClick={onClose} icon="specta-close" />
            </div>
            <div className="flex flex-col gap-y-8">
                <SelectHashtagsInput value={selectedHashtags} hashtagsOptions={hashtagsOptions} onChange={onChange} />

                <UI.Text color="snap-silver">{actionBar.affected({ count: issuesCount })}</UI.Text>

                <div className="flex flex-row justify-between">
                    <UI.Button
                        disabled={!selectedHashtags.length}
                        className="border border-snap-red bg-transparent"
                        onClick={() => updateHashtags('remove')}
                        text={<span className="text-snap-red">{actionBar.removeBtn()}</span>}
                    />
                    <UI.Button disabled={!selectedHashtags.length} onClick={() => updateHashtags('add')} text={actionBar.addBtn()} />
                </div>
            </div>
        </Modal>
    )
}

function ConfirmModal({
    isOpen,
    onClose,
    onConfirm,
    headerText,
    type,
    count
}: {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    headerText: string
    type: string
    count: number
}) {
    const {
        LL: {
            issues: { actionBar },
            shared
        }
    } = useI18nContext()
    if (!isOpen) return null

    return (
        <Modal className="w-[550px] p-8" isOpen={isOpen} onClose={onClose}>
            <div className="flex flex-row justify-between items-center">
                <UI.Text size="lg">{headerText}</UI.Text>
            </div>
            <div className="py-8">
                <UI.Text color="snap-silver">
                    {type === 'delete' ? actionBar.confirmQuestionDelete({ count }) : actionBar.confirmQuestionUnassign({ count })}
                </UI.Text>
            </div>
            <div className="flex flex-row justify-between">
                <UI.Button
                    className="border border-snap-red bg-transparent"
                    onClick={onClose}
                    text={<span className="text-snap-red">{shared.cancel()}</span>}
                />
                <UI.Button onClick={onConfirm} text={shared.confirm()} />
            </div>
        </Modal>
    )
}
