import type { HousekeepingOverviewProjectionStruct_v2 } from '@shared/projections-v2'
import moment from 'moment'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { getHousekeepingOverviewQuery } from '../../../../api/queries'
import { useDropdown } from '../../../../hooks/use-dropdown'
import { useFetchStatus, useSplitFirestoreDocument } from '../../../../infra/firestore-atom'
import { useCurrentOrg, useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { areaNotesSelector, bookingNotesSelector, dailyCommentsSelector, extrasSelector } from '../state/comments-and-notes-state'
import {
    areaBoxGroupsBackend,
    areaBoxGroupsNamesFilteredSelector,
    areaBoxGroupsNamesSelector,
    areaBoxGroupsSelector,
    areaBoxGroupsState,
    areaGroupsFilterAtom,
    areaGroupsSelector,
    areasQuantitySelector,
    cleaningStatusFilterAtom,
    cleaningStatusFilterValuesAtom,
    housekeepingOverviewDate,
    occupancyFilterAtom,
    occupancyFilterValueAtom,
    priorityFilterValueAtom
} from '../state/housekeeping-overview-state'

export function useHousekeepingOverviewDataStatus() {
    const user = useCurrentUser()
    const startDate = useRecoilValue(housekeepingOverviewDate)
    const setAreaBoxGroups = useSetRecoilState(areaBoxGroupsBackend)

    return useFetchStatus(
        useSplitFirestoreDocument<HousekeepingOverviewProjectionStruct_v2>({
            onData: data => {
                setAreaBoxGroups(data)
            },
            onError: () => {
                return {}
            },
            query: db => getHousekeepingOverviewQuery(db, user.organizationKey, startDate.format('YYYY-MM-DD')),
            name: `housekeeping-overview-${user.organizationKey}-${startDate.format('YYYY-MM-DD')}`
        })
    )
}

export function useHousekeepingOverviewData() {
    return useRecoilValue(areaBoxGroupsSelector)
}

export function useHousekeepingGroupsData() {
    return useRecoilValue(areaBoxGroupsState)
}

export function useHousekeepingOverviewDate() {
    return useRecoilValue(housekeepingOverviewDate)
}

export function useHousekeepingOverviewSetDate() {
    return useSetRecoilState(housekeepingOverviewDate)
}

export function useOccupancyFilterAtom() {
    return useDropdown(occupancyFilterAtom)
}

export function useCleaningStatusFilterAtom() {
    return useDropdown(cleaningStatusFilterAtom)
}

export function useCleaningStatusFilterValues() {
    return useRecoilValue(cleaningStatusFilterValuesAtom)
}

export function useSetCleaningStatusFilterValues() {
    return useSetRecoilState(cleaningStatusFilterValuesAtom)
}

export function useDailyComments() {
    return useRecoilValue(dailyCommentsSelector)
}

export function useAreaNotes() {
    return useRecoilValue(areaNotesSelector)
}

export function useBookingNotes() {
    return useRecoilValue(bookingNotesSelector)
}

export function useExtras() {
    return useRecoilValue(extrasSelector)
}

export function useAreasQuantity() {
    return useRecoilValue(areasQuantitySelector)
}

export function usePriorityFilter() {
    return useRecoilState(priorityFilterValueAtom)
}

export function useOccupancyFilter() {
    return useRecoilState(occupancyFilterValueAtom)
}

export function useAreaGroups() {
    return useRecoilValue(areaGroupsSelector)
}

export function useAreaGroupsFilter() {
    return useRecoilState(areaGroupsFilterAtom)
}

export function useAreaGroupsNames() {
    return useRecoilValue(areaBoxGroupsNamesSelector)
}

export function useFilteredAreaGroupsNames() {
    return useRecoilValue(areaBoxGroupsNamesFilteredSelector)
}
