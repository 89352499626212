import type { Firebase } from '@shared/firebase'
import type { IssueStruct } from '@shared/firestore-structs'
import { uncompleteIssue } from '@shared/issue-data'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useSetIssueState } from './use-issues'

export function useUncompleteIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const setIssueState = useSetIssueState()

    return async function uncompleteIssueRaw(issueKey: IssueStruct['key'], assignedContacts: IssueStruct['assignedTo']) {
        setIssueState({ key: issueKey, status: 'open' })

        try {
            await uncompleteIssue(firebase, { issueKey, currentUser, assignedContacts })
        } catch (error) {
            console.error(error)
            throw new Error('Failed to uncomplete issue')
        }
    }
}

export function useMassUncompleteIssues(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const setIssueState = useSetIssueState()

    return async (issues: IssueStruct[]) => {
        issues.forEach(issue => {
            setIssueState({ key: issue.key, status: 'open' })
        })

        const db = firebase.firestore()
        const batch = db.batch()

        try {
            await Promise.all([
                ...issues.map(issue =>
                    uncompleteIssue(
                        firebase,
                        {
                            issueKey: issue.key,
                            currentUser,
                            assignedContacts: issue.assignedTo
                        },
                        batch
                    )
                )
            ])

            await batch.commit()
        } catch (error) {
            console.error(error)
            throw new Error('Failed to mass uncomplete issues')
        }
    }
}
