import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

function ChecklistInput({
    value,
    onSave,
    onRemove,
    isNew
}: {
    value?: string
    onSave: (value: string) => void
    onRemove?: () => void
    isNew?: boolean
}) {
    const [inputValue, setInputValue] = useState<string>(value || '')
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value
        setInputValue(newValue)

        if (event.key === 'Enter') {
            onSave(newValue)
            isNew ? setInputValue('') : event.currentTarget.blur()
        }
    }

    const handleRemove = () => {
        isNew && setInputValue('')

        if (onRemove) {
            onRemove()
        }
    }

    return (
        <div className="flex items-center gap-x-2">
            <input
                autoFocus={isNew}
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onKeyDown={handlePressEnter}
                className="py-2 w-full border-b border-snap-light-gray focus:outline-none focus:ring-0 placeholder:text-sm"
                placeholder={settingsWeb.ruleForm.checklistPlaceholder()}
            />
            <UI.Icon className="cursor-pointer" onClick={handleRemove} icon="specta-close" color="snap-silver" />
        </div>
    )
}

export function ChecklistStep() {
    const { watch, setValue } = useFormContext()
    const lastInputRef = useRef<HTMLInputElement | null>(null)

    const checklist: string[] = watch('checklistTasks')

    useEffect(() => {
        if (lastInputRef.current) {
            lastInputRef.current.focus()
        }
    }, [checklist])

    const handleAddTask = (newValue: string) => {
        if (newValue.trim()) {
            setValue('checklistTasks', [...checklist, newValue.trim()])
        }
    }
    const handleRemove = (index: number) => {
        const updatedChecklist = [...checklist]
        updatedChecklist.splice(index, 1)
        setValue('checklistTasks', updatedChecklist)
    }

    return (
        <div className="flex flex-col grow w-3/4 mx-auto gap-y-4">
            {checklist.map((task, index) => (
                <ChecklistInput
                    key={task + index}
                    value={task}
                    onSave={newValue => {
                        const updatedChecklist = [...checklist]
                        updatedChecklist[index] = newValue.trim()
                        setValue('checklistTasks', updatedChecklist)
                    }}
                    onRemove={() => handleRemove(index)}
                />
            ))}

            <ChecklistInput isNew onSave={handleAddTask} />
        </div>
    )
}
