import type { Firebase } from '@shared/firebase'
import { useRecoilValue } from 'recoil'
import { UI } from '../../../../registry/ui-elements-registry'
import type { Colors } from '../../../../types/visuals.types'
import { RoundedButton } from '../../../molecules/rounded-button'
import { useAreaSummaryPriorityUpdate } from '../hooks/use-area-summary-priority-update'
import { areaSummaryHeaderSelector, areaSummaryPrioritySelector } from '../state/area-summary-state'

export function PrioritySign({ firebase, clickable }: { firebase: Firebase; clickable?: boolean }) {
    const priority = useRecoilValue(areaSummaryPrioritySelector)
    const updatePriority = useAreaSummaryPriorityUpdate(firebase)
    if (!priority && !clickable) return null

    return <RoundedButton icon="specta-priority-low" iconColor={priority ? 'sweeply-red' : 'sweeply-black'} onClick={updatePriority} />
}

interface Props {
    onClick: () => void
}

export function AssignButton({ onClick }: Props) {
    return <RoundedButton icon={'specta-assign-user'} onClick={onClick} />
}

export function AreaName({ color }: { color?: Colors }) {
    const { name } = useRecoilValue(areaSummaryHeaderSelector)
    return (
        <UI.Text size={'lg'} style={'bold'} color={color || 'sweeply-light-gray'}>
            {name}
        </UI.Text>
    )
}

export function AreaIcons() {
    const { icons } = useRecoilValue(areaSummaryHeaderSelector)
    return (
        <>
            {icons.map((icon, i) => (
                <UI.Icon key={icon + i} icon={icon} className={`text-white`} size={'sm'} />
            ))}
        </>
    )
}
