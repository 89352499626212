import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'

import { currentOrgSelector } from '@shared-snap/snap/components/auth/state/login'
import { addCategory, updateCategoryName } from '@shared/category-data'
import type { CategoryStruct } from '@shared/firestore-structs'
import { useNavigate } from '@tanstack/react-router'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { FieldErrorMessage } from 'components/atoms/field-error-message'
import { FieldLabel } from 'components/atoms/field-label'
import Input from 'components/atoms/input/input'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useRecoilState } from 'recoil'
import { prefix } from 'routes/__root'

interface CategoryForm {
    name: string
}

export function CategoryModal({ categoryToEdit }: { categoryToEdit?: CategoryStruct }) {
    const currentUser = useCurrentUser()
    const [currentOrganization, setCurrentOrganization] = useRecoilState(currentOrgSelector)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const navigate = useNavigate()
    const {
        LL: { settingsWeb, settings, shared }
    } = useI18nContext()

    const defaultValues: Readonly<CategoryForm> = {
        name: ''
    }
    const form = useForm<CategoryForm>({
        defaultValues
    })
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = form

    useEffect(() => {
        if (categoryToEdit && !form.getValues('name')) {
            setValue('name', categoryToEdit.name)
        }
    }, [categoryToEdit, setValue])

    const onClose = () => navigate({ to: `${prefix}/settings/categories` })

    const onSubmit = useCallback(
        async (data: CategoryForm) => {
            setIsSubmitting(true)

            try {
                const organization = !categoryToEdit
                    ? await addCategory(asFirebase(firebase), currentUser, data.name)
                    : await updateCategoryName(asFirebase(firebase), currentUser, categoryToEdit.id, data.name)
                setCurrentOrganization(organization)
                onClose()
            } catch (error) {
                // TODO: Add user friendly error handling
                console.error('Failed to save unit:', error)
                setErrorMessage((error as Error).message)
            } finally {
                setIsSubmitting(false)
            }
        },
        [asFirebase, currentUser, currentOrganization, categoryToEdit, setCurrentOrganization, onClose]
    )

    return (
        <Modal className="flex flex-col w-[90%] max-w-[554px] overflow-y-scroll" onClose={onClose}>
            <ModalHeader className="mb-4" onClose={onClose}>
                <UI.Text size="2xl" color="snap-black">
                    {categoryToEdit ? settingsWeb.categories.editCategory() : settingsWeb.categories.newCategory()}
                </UI.Text>
            </ModalHeader>
            <div className="grow px-6 pb-6">
                <FormProvider {...form}>
                    <form className="flex flex-col justify-between" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-8 mb-8">
                            <FieldLabel label={`${settingsWeb.units.nameColumn()}:`} required>
                                <Input
                                    initialValue={categoryToEdit?.name}
                                    name="name"
                                    placeholder={settings.nameFieldPlaceholder()}
                                    required
                                    register={register}
                                />
                                {errors.name && <FieldErrorMessage message={shared.errorFieldIsRequired({ field: 'This field' })} />}
                            </FieldLabel>
                        </div>
                        {errorMessage && <FieldErrorMessage message={errorMessage} />}

                        <div className="w-full flex justify-end gap-x-4">
                            {isSubmitting ? (
                                <div>
                                    <UI.Loader />
                                </div>
                            ) : (
                                <>
                                    <Button className="bg-snap-silver" type="button" onClick={onClose}>
                                        {shared.cancel()}
                                    </Button>
                                    <Button type="submit">{shared.save()}</Button>
                                </>
                            )}
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>
    )
}
