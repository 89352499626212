import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
    type NotesTab,
    areaNotesSelector,
    bookingNotesSelector,
    dailyCommentsSelector,
    extrasSelector,
    selectedTabTypeAtom,
    tabsSelector
} from '../state/comments-and-notes-state'

export function useTabs(isTaskView = false) {
    return useRecoilValue(tabsSelector(isTaskView))
}

export function useSetSelectedTab() {
    return useSetRecoilState(selectedTabTypeAtom)
}

export function useSelectedTab() {
    return useRecoilValue(selectedTabTypeAtom)
}

export function useNotes(type: NotesTab['type']) {
    switch (type) {
        case 'daily-comment':
            return useRecoilValue(dailyCommentsSelector)
        case 'area-note':
            return useRecoilValue(areaNotesSelector)
        case 'booking-note':
            return useRecoilValue(bookingNotesSelector)
        case 'extra':
            return useRecoilValue(extrasSelector)
        default:
            return []
    }
}
