import { useRecoilState } from 'recoil'
import { searchValueAtom } from '../state/housekeeping-overview-state'

export function useSearchFilter() {
    const [searchFilterValue, setSearchFilterValue] = useRecoilState(searchValueAtom)
    return {
        searchTerm: searchFilterValue,
        setSearchTerm: (text: string) => setSearchFilterValue(text.trim().length > 0 ? { searchTerm: text } : 'all')
    }
}
