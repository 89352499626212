import type { Firebase } from '@shared/firebase'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useCurrentOrg } from '../../auth/hooks/use-auth-state'
import { useTaskboardContext } from '../../my-tasks/hooks/use-tasks'
import { loadableIntegrationParams } from '../integration-utils'
import { integrationParamsAtom, tracesParamsAtom } from '../state/atoms'

export function useTracesIntegrationParams(firebase: Firebase) {
    const org = useCurrentOrg()
    const params = useTracesQueryParams()
    const { taskboardContext } = useTaskboardContext()

    const setIntegrationParams = useSetRecoilState(integrationParamsAtom)

    async function loadParams() {
        console.debug(`Loading integration params`)
        try {
            const contents = await loadableIntegrationParams(taskboardContext, params!, org, firebase)

            setIntegrationParams(contents)
        } catch (error) {
            console.error(error)
        }
    }

    return loadParams
}

export function useSetTracesQueryParams() {
    return useSetRecoilState(tracesParamsAtom)
}
export function useTracesQueryParams() {
    return useRecoilValue(tracesParamsAtom)
}
