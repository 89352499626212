import type {
    ActivityStruct,
    AreaCleaningStatus,
    AreaStruct,
    AssignedUserInfo,
    BookingStruct,
    Occupancy,
    OrgStruct,
    RuleStruct,
    UserStruct
} from '@shared/firestore-structs'

import { createDailyComment, logActivity, touchArea, updateArea } from '@shared/area-data'
import { updateAreaPriority } from '@shared/area-task-actions'
import { updateDailyComment } from '@shared/daily-comments-data'
import { getMiniUserObject } from '@shared/dataObjects'
import type { FirebaseFirestore } from '@shared/firebase'
import type { AreaBoxSummary_v2, AreaSummaryCombined, AreaSummaryStruct_v2, OmitMetadata } from '@shared/projections-v2'
import {
    pauseCleaningInternal,
    persistHousekeepingAction,
    startCleaningInternal,
    stopCleaningInternal
} from '@shared/projections/housekeeping-actions'
import { assignTask, createTask, generateTaskId, setTaskUpdate } from '@shared/task-data'
import { TASK_CLEANING_DEFAULT_NAME } from '@shared/txt-constants'
import type { Optional } from '@shared/type-utils'
import moment, { type Moment } from 'moment-timezone'

type AreaSummaryUserAction = {
    isAllowed: () => boolean
    applyAction: (
        input: {
            areaSummary: Readonly<AreaSummaryCombined>
            area: Readonly<AreaStruct>
        },
        date: Moment,
        firebase: FirebaseFirestore,
        ...args: any[]
    ) => {
        io: Promise<void>
        areaSummary: Partial<Readonly<OmitMetadata<AreaSummaryStruct_v2>>>
        area?: Partial<Pick<AreaStruct, 'note'>>
        overview?: Partial<OmitMetadata<AreaBoxSummary_v2>>
        affected: { [areaKey: string]: Moment[] }
    }
}

export function findDifferences(previouslyAssignedUsers: AssignedUserInfo[], assignedNames: string[]) {
    const previouslyAssignedUsersNames = previouslyAssignedUsers.map(user => user.name)

    return assignedNames.filter(name => !previouslyAssignedUsersNames.includes(name)).join(', ')
}

function generateActivityKey(firebase: FirebaseFirestore, areaKey: string) {
    return firebase.collection('areas').doc(areaKey).collection('activities').doc().id
}

function generateDailyCommentKey(firebase: FirebaseFirestore, areaKey: string) {
    return firebase.collection('areas').doc(areaKey).collection('dailyComments').doc().id
}

export function createActivity(
    firebase: FirebaseFirestore,
    previouslyAssignedUsers: AssignedUserInfo[],
    assignedTo: AssignedUserInfo[],
    currentUser: UserStruct,
    areaKey: string,
    orgKey: string,
    date: Moment
): ActivityStruct {
    const assignedNames = assignedTo.map(user => user.name)
    const startDate = date.startOf('day').valueOf()
    const activityLog = {
        type: 'assignment',
        date: startDate,
        change: {
            before: previouslyAssignedUsers.length > assignedNames.length ? 'unassigned' : 'assigned',
            after: findDifferences(previouslyAssignedUsers, assignedNames)
        },
        organizationKey: orgKey,
        areaKey: areaKey
    } as const
    const created = moment().valueOf()
    return {
        ...activityLog,
        key: generateActivityKey(firebase, areaKey),
        created: created,
        user: { initials: currentUser.initials, name: currentUser.name, key: currentUser.key }
    }
}

function assignToUser<T extends Optional<Pick<AreaSummaryCombined, 'currentTask' | 'area' | 'activities' | 'activeRule'>, 'activities'>>(
    firebase: FirebaseFirestore,
    currentArea: T,
    assignedTo: AssignedUserInfo[],
    currentUser: UserStruct,
    date: moment.Moment,
    ruleOverride?: RuleStruct
) {
    const currentRule = ruleOverride ?? currentArea.activeRule

    const startDate = moment(date).startOf('day').valueOf()
    const activity = createActivity(
        firebase,
        currentArea.currentTask?.assignedTo ?? [],
        assignedTo,
        currentUser,
        currentArea.area.key,
        currentArea.area.organizationKey,
        moment(date)
    )
    const assignedTo1 = assignedTo.map(user => ({ key: user.key, name: user.name, initials: user.initials }))
    const taskName = currentRule?.name ?? TASK_CLEANING_DEFAULT_NAME

    const task = currentArea.currentTask
        ? {
              ...currentArea.currentTask,
              assignedTo: assignedTo1
          }
        : {
              key: generateTaskId(firebase),
              assignedTo: assignedTo1,
              name: taskName,
              startDate,
              status: assignedTo1.length === 0 ? ('open' as const) : ('assigned' as const) // Set task status based on assigned users
          }

    const result = {
        ...(ruleOverride
            ? {
                  activeRule: {
                      customChar: ruleOverride.customChar,
                      checklistTasks: ruleOverride.checklistTasks ?? [],
                      name: ruleOverride.name,
                      description: ruleOverride.description,
                      mandatory: ruleOverride.mandatory
                  }
              }
            : {}),
        area: {
            ...currentArea.area,
            activeRule: ruleOverride
        },
        assigned: assignedTo1,
        activities: [activity],
        currentTask: task
    }

    // Define an asynchronous function to either update an existing task or create a new one
    const io = async () => {
        if (currentArea.currentTask) {
            // If there is an existing task, update its assigned users
            await assignTask(
                firebase,
                currentUser,
                result.currentTask.key,
                {
                    assignedTo: assignedTo1
                },
                null,
                activity
            )
        } else {
            // If there's no existing task, create a new one
            const startDate = date.startOf('day')
            await createTask<'cleaning'>(
                firebase,
                currentArea.area,
                currentUser,
                'cleaning',
                {
                    assignedTo: assignedTo1,
                    name: taskName,
                    startDate
                },
                null,
                activity,
                currentRule?.checklistTasks?.map(t => ({ checked: false, name: t })) ?? null,
                task.key,
                ruleOverride ? { [startDate.valueOf()]: { ruleKey: ruleOverride?.key } } : undefined
            )
        }
    }

    // Return the formatted assigned users, updated result object, and invoke the async function
    return { assignedTo1, result, io: io() }
}

export const areaSummaryUserActions = {
    'assign-to-user': {
        isAllowed: () => true,
        applyAction: (
            input,
            date,
            firebase: FirebaseFirestore,
            assignedTo: AssignedUserInfo[],
            currentUser: UserStruct,
            ruleOverride?: RuleStruct
        ) => {
            const currentArea = input.areaSummary
            const { assignedTo1, result, io } = assignToUser(firebase, currentArea, assignedTo, currentUser, date, ruleOverride)

            return {
                io,
                area: undefined,
                areaSummary: result,
                overview: { assignedTo: assignedTo1 },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'set-unit-note': {
        isAllowed: () => true,
        applyAction: (input, date, firebase, newValue: string, currentUser: UserStruct) => {
            const activityKey = generateActivityKey(firebase, input.areaSummary.area.key)
            const created = moment().valueOf()
            const newActivity = {
                type: 'area-note' as const,
                change: input.areaSummary.area ? { before: input.area.note ?? '', after: newValue } : { after: newValue },
                created: created,
                date: moment(date).startOf('day').valueOf(),
                key: activityKey,
                user: getMiniUserObject(currentUser)
            }
            const result = {
                activities: [newActivity]
            }
            const io = async () => {
                await logActivity(
                    firebase,
                    currentUser,
                    input.areaSummary.area.key,
                    'area-note',
                    moment(date).startOf('day').valueOf(),
                    newActivity.change,
                    undefined,
                    activityKey,
                    created
                )
                await updateArea(firebase, input.areaSummary.area.key, { note: newValue }, currentUser)
            }
            return {
                io: io(),
                areaSummary: result,
                overview: undefined,
                area: { note: newValue },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'set-cleaning-status': {
        isAllowed: () => true,
        applyAction: (input, date, firebase, newValue: AreaCleaningStatus, currentUser: UserStruct) => {
            const activityKey = generateActivityKey(firebase, input.areaSummary.area.key)
            const created = moment().valueOf()
            const newActivity = {
                type: 'cleaning-status' as const,
                change: {
                    field: 'cleaningStatus',
                    before: input.areaSummary.area.cleaningStatus,
                    after: newValue
                },
                created: created,
                date: moment(date).startOf('day').valueOf(),
                key: activityKey,
                user: getMiniUserObject(currentUser)
            }
            const result = {
                area: {
                    ...input.areaSummary.area,
                    cleaningStatus: newValue,
                    lastChangedByUser: currentUser.key
                },

                activities: [newActivity]
            }
            const io = async () => {
                await logActivity(
                    firebase,
                    currentUser,
                    input.areaSummary.area.key,
                    'cleaning-status',
                    moment(date).startOf('day').valueOf(),
                    newActivity.change,
                    undefined,
                    activityKey,
                    created
                )
                if (moment().isSame(date, 'day')) {
                    await updateArea(firebase, input.areaSummary.area.key, { cleaningStatus: newValue }, currentUser)
                }
            }
            return {
                io: io(),
                area: undefined,
                areaSummary: result,
                overview: { cleaningStatus: newValue },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'set-occupancy': {
        isAllowed: () => true,
        applyAction: (input, date, firebase, newValue: Occupancy, currentUser: UserStruct) => {
            const activityKey = generateActivityKey(firebase, input.areaSummary.area.key)
            const created = moment().valueOf()
            const newActivity = {
                type: 'occupancy' as const,
                change: {
                    field: 'occupancy',
                    before: input.areaSummary.area.occupancy,
                    after: newValue
                },
                created: created,
                date: moment(date).startOf('day').valueOf(),
                key: activityKey,
                user: getMiniUserObject(currentUser)
            }
            const result = {
                area: {
                    ...input.areaSummary.area,
                    occupancy: newValue,
                    lastChangedByUser: currentUser.key
                },

                activities: [newActivity]
            }
            const io = async () => {
                await logActivity(
                    firebase,
                    currentUser,
                    input.areaSummary.area.key,
                    'occupancy',
                    moment(date).startOf('day').valueOf(),
                    newActivity.change,
                    undefined,
                    activityKey,
                    created
                )
                if (moment().isSame(date, 'day')) {
                    await updateArea(firebase, input.areaSummary.area.key, { occupancy: newValue }, currentUser)
                }
            }
            return {
                io: io(),
                area: undefined,
                areaSummary: result,
                overview: { occupancy: newValue },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'start-cleaning': {
        isAllowed: () => true,
        applyAction: (input, date, firebase, currentUser: UserStruct, ruleOverride?: RuleStruct) => {
            const { assignedTo, newCleaning, beforeCleaningStatus, newStateTask } = startCleaningInternal(
                firebase,
                input.areaSummary.currentTask,
                currentUser,
                input.areaSummary.area,
                moment(date).startOf('day'),
                ruleOverride
            )
            const activityKey = generateActivityKey(firebase, input.areaSummary.area.key)
            const created = moment().valueOf()
            const result = {
                area: {
                    ...input.areaSummary.area,
                    cleaningStatus: 'preparing' as const,
                    lastCleaningTaskKey: newStateTask.key,
                    cleaningPriority: !!input.areaSummary.area.cleaningPriority,
                    lastStatusChange: {
                        user: getMiniUserObject(currentUser),
                        updated: created
                    }
                },

                currentTask: { ...newStateTask, assignedTo: assignedTo },
                assigned: assignedTo,
                // dailyComments: input.areaSummary.dailyComments ?? [],
                // extras: input.areaSummary.extras ?? [],
                lastHousekeeping: { assignedTo: assignedTo, cleaning: newCleaning },
                activities: [
                    {
                        type: 'cleaning-action-start',
                        date: moment(date).startOf('day').valueOf(),
                        created: created,
                        change: {
                            before: beforeCleaningStatus,
                            after: 'preparing',
                            field: 'cleaningStatus'
                        },
                        key: activityKey,
                        user: {
                            key: currentUser.key,
                            initials: currentUser.initials,
                            name: currentUser.name
                        }
                    } as const
                ]
            }
            const io = async () => {
                await persistHousekeepingAction(
                    firebase,
                    input.areaSummary.currentTask?.key ?? null,
                    currentUser,
                    result.currentTask.cleaning!,
                    'preparing',
                    'cleaning-action-start',
                    beforeCleaningStatus,
                    result.area,
                    result.currentTask?.name,
                    moment(date).startOf('day'),
                    assignedTo,
                    newStateTask.key,
                    activityKey,
                    created,
                    ruleOverride
                )
            }

            return {
                io: io(),
                area: undefined,
                areaSummary: result,
                overview: { assignedTo: assignedTo, cleaningStatus: 'preparing' },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'pause-cleaning': {
        isAllowed: () => true,
        applyAction: (input, date, firebase, currentUser: UserStruct) => {
            const { beforeCleaningStatus, newCleaning, cleaningStatus, newTaskState } = pauseCleaningInternal(
                input.areaSummary.area,
                input.areaSummary.currentTask
            )
            const activityKey = generateActivityKey(firebase, input.areaSummary.area.key)
            const created = moment().valueOf()
            const result = {
                area: {
                    ...input.areaSummary.area,
                    cleaningStatus: cleaningStatus,
                    lastCleaningTaskKey: newTaskState.key,
                    // cleaningPriority: !!input.areaSummary.area.cleaningPriority,
                    lastStatusChange: {
                        user: getMiniUserObject(currentUser),
                        updated: created
                    }
                },

                lastHousekeeping: {
                    assignedTo: input.areaSummary.lastHousekeeping?.assignedTo || [],
                    cleaning: newCleaning
                },
                currentTask: { ...newTaskState, assignedTo: newTaskState.assignedTo ?? undefined },
                activities: [
                    {
                        type: 'cleaning-action-pause',
                        date: moment(date).startOf('day').valueOf(),
                        created: created,
                        change: {
                            before: beforeCleaningStatus,
                            after: cleaningStatus,
                            field: 'cleaningStatus'
                        },
                        key: activityKey,
                        user: {
                            key: currentUser.key,
                            initials: currentUser.initials,
                            name: currentUser.name
                        }
                    } as const
                ]
            }
            const io = async () => {
                await persistHousekeepingAction(
                    firebase,
                    input.areaSummary.currentTask?.key ?? null,
                    currentUser,
                    newCleaning,
                    cleaningStatus,
                    'cleaning-action-pause',
                    beforeCleaningStatus,
                    input.areaSummary.area,
                    input.areaSummary.currentTask?.name,
                    moment(date).startOf('day'),
                    input.areaSummary.currentTask?.assignedTo || [],
                    undefined,
                    activityKey,
                    created
                )
            }

            return {
                io: io(),
                area: undefined,
                areaSummary: result,
                overview: { cleaningStatus: cleaningStatus },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'stop-cleaning': {
        isAllowed: () => true,
        applyAction: (input, date, firestore, currentUser: UserStruct, currentOrg: OrgStruct, ruleOverride?: RuleStruct) => {
            const { beforeCleaningStatus, newCleaning, cleaningStatus, cleaningAction, newStateTask } = stopCleaningInternal(
                firestore,
                input.areaSummary.currentTask,
                currentUser,
                input.areaSummary.area,
                moment(date).startOf('day'),
                currentOrg,
                ruleOverride
            )

            const activityKey = generateActivityKey(firestore, input.areaSummary.area.key)

            const created = moment().valueOf()
            const result = {
                area: {
                    ...input.areaSummary.area,
                    cleaningStatus: cleaningStatus,
                    lastCleaningTaskKey: newStateTask.key,
                    // cleaningPriority: !!input.areaSummary.area.cleaningPriority,
                    lastStatusChange: {
                        user: getMiniUserObject(currentUser),
                        updated: created
                    }
                },

                lastHousekeeping: {
                    assignedTo: newStateTask.assignedTo || [],
                    cleaning: newCleaning
                },
                currentTask: {
                    ...newStateTask,
                    name: newStateTask.name!,
                    assignedTo: newStateTask.assignedTo || undefined,
                    key: newStateTask.key!,
                    startDate: newStateTask.startDate!,
                    status: newStateTask.status!
                },
                activities: [
                    {
                        type: cleaningAction,
                        date: moment(date).startOf('day').valueOf(),
                        created: created,
                        change: {
                            before: beforeCleaningStatus,
                            after: cleaningStatus,
                            field: 'cleaningStatus'
                        },
                        key: activityKey,
                        user: {
                            key: currentUser.key,
                            initials: currentUser.initials,
                            name: currentUser.name
                        }
                    } as const
                ]
            }
            const io = async () => {
                await persistHousekeepingAction(
                    firestore,
                    input.areaSummary.currentTask?.key ?? null,
                    currentUser,
                    newCleaning,
                    cleaningStatus,
                    cleaningAction,
                    beforeCleaningStatus,
                    input.areaSummary.area,
                    input.areaSummary.currentTask?.name,
                    moment(date).startOf('day'),
                    newStateTask.assignedTo || undefined,
                    newStateTask.key,
                    activityKey,
                    created
                )
            }
            return {
                io: io(),
                area: undefined,
                areaSummary: result,
                overview: { cleaningStatus: cleaningStatus },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },

    'set-priority': {
        isAllowed: () => true,
        applyAction: (input, date, firestore, priority: boolean, currentUser: UserStruct) => {
            const activityKey = generateActivityKey(firestore, input.areaSummary.area.key)
            const created = moment().valueOf()

            const io = async () => {
                await updateAreaPriority(
                    firestore,
                    currentUser,
                    input.areaSummary.area.key,
                    moment(date).startOf('day').valueOf(),
                    priority,
                    input.areaSummary.currentTask?.key,
                    activityKey,
                    created
                )
            }
            const result = {
                priority: priority,
                activities: [
                    {
                        created: created,
                        date: moment(date).startOf('day').valueOf(),
                        type: 'cleaning-priority',
                        key: activityKey,
                        change: {
                            after: priority,
                            before: !priority,
                            field: 'cleaning-priority'
                        },
                        user: {
                            key: currentUser.key,
                            initials: currentUser.initials,
                            name: currentUser.name
                        }
                    } as const
                ]
            }
            return {
                io: io(),
                area: undefined,
                areaSummary: result,
                overview: {
                    priority: priority
                },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'set-daily-comment': {
        isAllowed: () => true,
        applyAction: (input, date, firestore, comment: string, currentUser: UserStruct) => {
            const change = input.areaSummary.dailyComments[0]?.comment
                ? {
                      before: input.areaSummary.dailyComments[0]?.comment,
                      after: comment
                  }
                : { after: comment }

            const activityKey = generateActivityKey(firestore, input.areaSummary.area.key)
            const created = moment().valueOf()
            const dailyCommentKey =
                input.areaSummary.dailyComments[0]?.key ?? generateDailyCommentKey(firestore, input.areaSummary.area.key)
            const result = {
                dailyComments: [{ comment, date: moment(date).startOf('date').valueOf(), key: dailyCommentKey }],
                activities: [
                    {
                        created: created,
                        date: moment(date).startOf('date').valueOf(),
                        type: 'daily-comment',
                        key: activityKey,
                        change,
                        user: {
                            key: currentUser.key,
                            initials: currentUser.initials,
                            name: currentUser.name
                        }
                    } as const
                ]
            }
            const io = async () => {
                input.areaSummary.dailyComments[0]?.key
                    ? await updateDailyComment(firestore, input.areaSummary.area.key, input.areaSummary.dailyComments[0]?.key, comment)
                    : await createDailyComment(
                          firestore,
                          input.areaSummary.area.key,
                          moment(date).startOf('date').valueOf(),
                          comment,
                          currentUser,
                          dailyCommentKey
                      )

                await logActivity(
                    firestore,
                    currentUser,
                    input.areaSummary.area.key,
                    'daily-comment',
                    moment(date).startOf('date').valueOf(),
                    change,
                    undefined,
                    activityKey,
                    created
                )
                await touchArea(firestore, input.areaSummary.area.key, currentUser)
            }
            return {
                io: io(),
                area: undefined,
                areaSummary: result,
                overview: { dailyComments: result.dailyComments },
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'update-checklist': {
        isAllowed: () => true,
        applyAction: (input, date, firestore, name: string, isChecked: boolean, currentUser: UserStruct) => {
            const updatedChecklist = (input.areaSummary.currentTask?.checklist ?? []).map(item =>
                item.name === name
                    ? {
                          ...item,
                          checked: isChecked
                      }
                    : item
            )
            const result = {
                currentTask: {
                    ...input.areaSummary.currentTask!,
                    checklist: updatedChecklist
                }
            }
            const io = async () => {
                await setTaskUpdate(firestore, currentUser, input.areaSummary.currentTask!.key, {}, null, undefined, updatedChecklist)
            }
            return {
                io: io(),
                area: undefined,
                areaSummary: result,
                affected: { [input.areaSummary.area.key]: [date] },
                overview: undefined
            }
        }
    },
    'update-cleaning-schedule': {
        isAllowed: () => true,
        applyAction: (
            input,
            date,
            firestore,
            updates: {
                dates: string[]
                field: 'optInDates' | 'optOutDates'
                isMainAction: boolean
                booking: BookingStruct
            }[],
            currentUser: UserStruct
        ) => {
            const mainAction = updates.find(update => update.isMainAction)
            if (!mainAction) {
                throw new Error('No main action found.')
            }
            const updateField = mainAction.field
            const currentDates = mainAction.booking[updateField] ?? []
            const isAdding = mainAction.dates.length > currentDates.length && mainAction.field === 'optInDates'
            const activityAction = isAdding ? updates.find(x => x.field === 'optInDates') || mainAction : mainAction
            const activityKey = generateActivityKey(firestore, input.areaSummary.area.key)
            const created = moment().valueOf()
            const update = updates.reduce((acc: { optInDates?: string[]; optOutDates?: string[] }, { dates, field }) => {
                acc[field] = dates
                return acc
            }, {})

            // TODO: Should this not just be the booking from the input?
            const beforeBooking = input.areaSummary.bookings.find(booking => booking.isLeadBooking) ?? mainAction.booking

            const result = {
                activities: [
                    {
                        created: created,
                        date: moment(date).startOf('day').valueOf(),
                        type: 'cleaning-schedule',
                        key: activityKey,
                        change: {
                            field: activityAction.field,
                            before: beforeBooking[activityAction.field] ?? [],
                            after: activityAction.dates
                        },
                        user: {
                            key: currentUser.key,
                            initials: currentUser.initials,
                            name: currentUser.name
                        }
                    } as const
                ],
                bookings: input.areaSummary.bookings.map(booking => {
                    if (booking.isLeadBooking) {
                        return {
                            ...booking,
                            ...update
                        }
                    }

                    return booking
                })
            }
            const io = async () => {
                await firestore.collection('bookings').doc(mainAction.booking.key).update(update)

                await logActivity(
                    firestore,
                    currentUser,
                    input.areaSummary.area.key,
                    'cleaning-schedule',
                    moment(date).startOf('day').valueOf(),
                    {
                        field: activityAction.field,
                        before: beforeBooking[activityAction.field] ?? [],
                        after: activityAction.dates
                    },
                    undefined,
                    activityKey,
                    created
                )
            }
            return {
                io: io(),
                area: undefined,
                areaSummary: { ...result },
                overview: undefined,
                affected: { [input.areaSummary.area.key]: [date] }
            }
        }
    },
    'override-cleaning-schedule': {
        isAllowed: () => true,
        applyAction: (
            input,
            date,
            firestore,
            booking: BookingStruct,
            update: { [key: string]: { ruleKey?: string } }[],
            appliedRule?: RuleStruct
        ) => {
            const result = {
                ...(appliedRule
                    ? {
                          activeRule: {
                              name: appliedRule?.name,
                              description: appliedRule?.description,
                              checklistTasks: appliedRule?.checklistTasks ?? [],
                              mandatory: appliedRule?.mandatory ?? false,
                              ...(appliedRule?.customChar ? { customChar: appliedRule.customChar } : {})
                          }
                      }
                    : {}),
                bookings: input.areaSummary.bookings.map(booking => {
                    if (booking.isLeadBooking) {
                        return {
                            ...booking,
                            ruleOverride: update
                        }
                    }

                    return booking
                })
            }

            const io = async () => {
                await firestore.collection('bookings').doc(booking.key).update({ ruleOverride: update })
            }

            return {
                io: io(),
                area: undefined,
                areaSummary: { ...result },
                affected: { [input.areaSummary.area.key]: [date] },
                overview: undefined
            }
        }
    }

    // eslint-disable-next-line prettier/prettier
} satisfies { [name: string]: AreaSummaryUserAction }
