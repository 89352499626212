import moment from 'moment-timezone'
import type { AreaSummaryProjectionBooking, BookingStruct } from './firestore-structs'
import * as c from './txt-constants'

export function occupancyToHuman(occupancy: string, firstIsCapital = true) {
    if (occupancy === c.OCCUPANCY_CHECKIN) {
        if (firstIsCapital) {
            return 'Check-in'
        } else {
            return 'check-in'
        }
    }
    if (occupancy === c.OCCUPANCY_CHECKOUT) {
        if (firstIsCapital) {
            return 'Check-out'
        } else {
            return 'check-out'
        }
    }
    if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
        if (firstIsCapital) {
            return 'Stayover'
        } else {
            return 'stayover'
        }
    }
    if (occupancy === c.OCCUPANCY_TURNOVER) {
        if (firstIsCapital) {
            return 'Check-out & check-in'
        } else {
            return 'check-out & check-in'
        }
    }

    if (occupancy === c.OCCUPANCY_VACANT) {
        if (firstIsCapital) {
            return 'Vacant'
        } else {
            return 'vacant'
        }
    }
    if (occupancy === c.OCCUPANCY_ALL) {
        if (firstIsCapital) {
            return 'All'
        } else {
            return 'all'
        }
    }

    return 'unknown'
}

function ucFirst(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function isSameDate(date1: moment.MomentInput, date2: moment.MomentInput) {
    const d1 = moment(date1).startOf('day').valueOf()

    const d2 = moment(date2).startOf('day').valueOf()

    return d1 === d2
}

export function daysBetween(startDate: moment.MomentInput, endDate: moment.MomentInput) {
    const start = moment(startDate)
    const end = moment(endDate)

    return end.diff(start, 'days')
}

export function getGuestPostFix(nrOfGuests: number) {
    return nrOfGuests > 1 || nrOfGuests == 0 ? 'guests' : 'guest'
}

export function getOccupancyText(
    booking: BookingStruct | AreaSummaryProjectionBooking,
    guestPostfix: string,
    date = moment(),
    isTaskRow = false
) {
    date.startOf('day')

    const nrOfDaysStayed = daysBetween(booking.checkinDate, date)
    const lenghtOfStay = daysBetween(booking.checkinDate, booking.checkoutDate)
    const daysTillArrival = daysBetween(date, booking.checkinDate)

    if (isSameDate(booking.checkinDate, date.valueOf())) {
        if (isTaskRow) {
            return 'Arriving today'
        } else {
            return ucFirst(guestPostfix) + ' arriving today'
        }
    }
    if (moment(booking.checkoutDate).isSame(moment(date), 'day')) {
        return 'Checkout today'
    }
    if (daysTillArrival > 0) {
        return 'Arrival in ' + daysTillArrival + ' days'
    }
    return 'Day ' + (nrOfDaysStayed + 1) + ' of ' + lenghtOfStay
}
