import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/snap/_authenticated/reports/optional')({
    component: RouteComponent
})

// export const addressBase =  'http://localhost:3001'
export const addressBase = ''
function RouteComponent() {
    return <iframe width="100%" height="100%" src={`${addressBase}/reports/optional`} style={{ border: 'none' }} />
}
