import { userHasAccessToAreaGroup } from '@shared/area-groups-helpers'
import type { CommentivityStruct, IssueStruct } from '@shared/firestore-structs'
import { getHashTags } from '@shared/helpers'
import { getCommentivitiesQuery, getFilteredIssuesQuery, getIssueQuery } from '@shared/issue-data'
import type { UserOption } from '@shared/user-data'
import { descend, prop, sortWith } from 'ramda'
import { useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useSplitFirestoreQuery } from '../../../infra/firestore-atom'
import { constructOptions, constructUsersOptions } from '../../../utils'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useUsers } from '../../auth/hooks/use-users'
import { areasSelector } from '../../auth/state/login'
import {
    categoryFilterAtom,
    currentStatusFilterAtom,
    hashtagsFilterAtom,
    hashtagsSelector,
    hashtagsWithCountSelector,
    issueImageAtom,
    issueSelector,
    issuesAtom,
    issuesSelector,
    priorityFilterAtom,
    searchValueAtom,
    selectedIssuesAtom,
    statusFilterOpenAtom
} from '../state/issues-state'
import { convertHashtagsToOptions } from '../utils'

export function useIssues() {
    const currentUser = useCurrentUser()
    const setIssues = useSetRecoilState(issuesAtom)
    const currentStatusFilter = useRecoilValue(currentStatusFilterAtom)

    return useSplitFirestoreQuery<IssueStruct>(
        issues => {
            const filteredIssues = issues
                .filter(issue => userHasAccessToAreaGroup(currentUser.areaGroups, issue.area.group))
                .map(i => ({ ...i, name: i.name ?? '' }))
            setIssues(sortWith([descend(prop('priority')), descend(prop('updated'))], filteredIssues))
        },
        () => null,
        db => getFilteredIssuesQuery(db, currentUser.organizationKey, { statuses: currentStatusFilter })
    )
}

export function useFetchCommentivities(key: string) {
    const [commentivities, setCommentivities] = useState<CommentivityStruct[]>([])
    useSplitFirestoreQuery<CommentivityStruct>(
        commentivities => setCommentivities(commentivities),
        () => setCommentivities([]),
        db => getCommentivitiesQuery(db, key)
    )
    return commentivities
}

export function useIssuesList() {
    return useRecoilValue(issuesSelector)
}

export function useAreas() {
    return useRecoilValue(areasSelector)
}
export function useAreasOptions() {
    return constructOptions(useRecoilValue(areasSelector))
}

export function useUsersOptions(): UserOption[] {
    return constructUsersOptions(useUsers())
}

export function useHashtagsOptions() {
    return convertHashtagsToOptions(useRecoilValue(hashtagsSelector))
}

export function useIssuesSearch() {
    return useRecoilState(searchValueAtom)
}

export function usePriorityFilter() {
    return useRecoilState(priorityFilterAtom)
}

export function useStatusFilterOpenAtom() {
    return statusFilterOpenAtom
}

export function useCurrentStatusFilter() {
    return useRecoilState(currentStatusFilterAtom)
}

export function useHashtagsWithCount() {
    return useRecoilValue(hashtagsWithCountSelector)
}

export function useSelectedIssues() {
    const [selectedIssues, setSelectedIssues] = useRecoilState(selectedIssuesAtom)
    const issues = useRecoilValue(issuesSelector)

    const selectIssue = (key: string) => {
        if (selectedIssues.has(key)) {
            setSelectedIssues(prev => {
                const newSet = new Set(prev)
                newSet.delete(key)
                return newSet
            })
        } else {
            setSelectedIssues(prev => {
                const newSet = new Set(prev)
                newSet.add(key)
                return newSet
            })
        }
    }

    const clearAll = () =>
        setSelectedIssues(prev => {
            const newSet = new Set(prev)
            newSet.clear()
            return newSet
        })

    return {
        hasSelectedIssues: !!selectedIssues.size,
        selectedIssues: issues.filter(issue => selectedIssues.has(issue.key)),
        selectedIssuesKeys: selectedIssues,
        selectIssue,
        clearAll
    }
}

export function useIssue(key: string) {
    const issues = useRecoilValue(issuesSelector)

    return issues.find(issue => issue.key === key)
}

export function useIssueData(key: string) {
    return useRecoilValue(issueSelector(key))
}

export function useSetIssueState() {
    const [issues, setIssues] = useRecoilState(issuesAtom)

    return function setIssue(issue: Partial<IssueStruct> & Pick<IssueStruct, 'key'>) {
        setIssues(issues.map(i => (i.key === issue.key ? { ...i, ...issue } : i)))
    }
}

export function useIssueUsersOptions(key: string) {
    const issue = useIssueData(key)
    const options = useUsersOptions()
    return [issue?.assignedTo ? issue.assignedTo.map(user => user.key) : [], options] as const
}

export function useIssueHashtagsOptions(key: string) {
    const issue = useIssueData(key)
    const options = useHashtagsOptions()
    return [issue?.name ? options.filter(opt => getHashTags(issue.name).includes(opt.label)).map(opt => opt.label) : [], options] as const
}

export function useImage() {
    return useRecoilState(issueImageAtom)
}

export function useHashtagsFilter() {
    return useRecoilState(hashtagsFilterAtom)
}

export function useCategoryFilter() {
    return useRecoilState(categoryFilterAtom)
}
