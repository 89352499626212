import type { FirebaseFirestore } from '@shared/firebase'
import { AreaBoxGroups, AreaStruct, AreaSummaryStruct, HousekeepingOverviewProjectionStruct } from '@shared/firestore-structs'
import {
    type AreaSummaryStruct_v2,
    type AreasProjected_v2,
    type HousekeepingOverviewProjectionStruct_v2,
    ProjectionMetadata
} from '@shared/projections-v2'

export function getAreas(db: FirebaseFirestore, organizationKey: string) {
    return db.collection<{ areas: AreasProjected_v2[] }>('housekeeping-overview-v2').doc(organizationKey)
}
export function getHousekeepingOverviewQuery(db: FirebaseFirestore, organizationKey: string, date: string) {
    return db
        .collection('housekeeping-overview-v2')
        .doc(organizationKey)
        .collection<HousekeepingOverviewProjectionStruct_v2>('dates')
        .doc(date)
}

export function getAreaSummaryQuery(db: FirebaseFirestore, organizationKey: string, date: string, areaKey: string) {
    return db
        .collection('housekeeping-overview-v2')
        .doc(organizationKey)
        .collection('dates')
        .doc(date)
        .collection<AreaSummaryStruct_v2>('area-summary')
        .doc(areaKey)
}
