import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { type TextProps, UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { search } from '@shared-snap/snap/utils'
import type { CategoryStruct } from '@shared/firestore-structs'
import type { CategorySelection } from '@shared/issue-data'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { SearchInput } from 'components/atoms/search-input'
import { type Dispatch, type HTMLProps, type SetStateAction, useCallback, useEffect, useState } from 'react'

interface CategoryModalProps {
    categoryOptions: CategoryStruct[]
    isOpen: boolean
    actionTitle?: string
    onClose: () => void
    onDone: (categorySelection: CategorySelection) => void
    headerTitleOverride?: string
    initialSelected?: CategorySelection
    closeByPressOutside?: boolean
}

export function CategorySelectionBlock({
    categoryTextProps,
    categorySelection,
    leftRight = false,
    showClearButtons = false,
    setCategorySelection
}: {
    categorySelection: CategorySelection
    categoryTextProps?: TextProps
    leftRight?: boolean
    showClearButtons?: boolean
    setCategorySelection?: Dispatch<SetStateAction<CategorySelection>>
}) {
    const org = useCurrentOrg()

    const onItemClick = useCallback(
        (category: CategoryStruct, subcategory: string) => {
            setCategorySelection?.(prev => {
                if ((prev[category.id] ?? []).includes(subcategory)) {
                    return {
                        ...prev,
                        [category.id]: prev[category.id].filter(item => item !== subcategory)
                    }
                } else {
                    return {
                        ...prev,
                        [category.id]: [...(prev[category.id] ?? []), subcategory]
                    }
                }
            })
        },
        [setCategorySelection]
    )

    const cursorClass = setCategorySelection ? 'cursor-pointer' : ''

    const rowOrColumn = leftRight ? 'flex-row' : 'flex-col'

    const Category = useCallback(
        (category: CategoryStruct) => {
            return (
                <div key={`category-selection-block-${category.id}`} className={`flex gap-3 ${rowOrColumn} items-start`}>
                    <div className="flex flex-row items-center gap-x-2">
                        <CategoryOptionItem textProps={categoryTextProps} key={category.id} category={category} left={leftRight} />
                        {showClearButtons && (categorySelection[category.id] ?? []).length > 0 && (
                            <UI.Pressable onPress={() => setCategorySelection?.(prev => ({ ...prev, [category.id]: [] }))}>
                                <UI.Icon icon="specta-close" size="xxs" />
                            </UI.Pressable>
                        )}
                    </div>
                    {leftRight && <div className="flex-1" />}
                    <div className={`${leftRight ? 'flex justify-end ml-2' : 'flex-1 flex-start'} flex-wrap gap-2 pl-6 mb-2`}>
                        {category.categoryItems.map(subcategory => {
                            const pillIsSelected =
                                category.id in categorySelection && (categorySelection[category.id] ?? []).includes(subcategory)
                            return (
                                <UI.Pressable
                                    key={`category-selection-block-${category.id}-${subcategory}`}
                                    disabled={!onItemClick}
                                    type="button"
                                    onPress={() => {
                                        if (onItemClick) {
                                            onItemClick(category, subcategory)
                                        }
                                    }}>
                                    <div className={`flex items-center mr-1 mb-2 ${cursorClass}`}>
                                        <Pill text={subcategory} selected={pillIsSelected} />
                                    </div>
                                </UI.Pressable>
                            )
                        })}
                    </div>
                </div>
            )
        },
        [categorySelection, categoryTextProps, leftRight, rowOrColumn, cursorClass, showClearButtons, onItemClick, setCategorySelection]
    )

    const hasElements = org.categories?.some(c => c.categoryItems.length > 0)
    if (!hasElements) {
        return null
    }
    return (
        <div className="flex flex-col gap-4">
            <UI.List items={org.categories?.filter(c => c.categoryItems.length > 0) ?? []} render={Category} />
        </div>
    )
}

// This component needs a higher level React Tooltip component to work properly
export function CategorySelectionAsGroupTags({
    selection,
    onClick,
    disableTooltip = false
}: {
    selection: CategorySelection
    onClick?: () => void
    disableTooltip?: boolean
}) {
    const org = useCurrentOrg()

    const categories: CategoryStruct[] = Object.keys(selection)
        .map(categoryId => {
            return org.categories?.find(cat => cat.id === categoryId)
        })
        .filter(cat => cat)
        .map(cat => cat as CategoryStruct)
        .filter(cat => selection[cat.id].length > 0)
        .sort((a, b) => {
            // sort by category name
            return a.name.localeCompare(b.name)
        })
        .map(category => {
            return {
                ...category,
                categoryItems: selection[category.id].map(subcategory => subcategory)
            }
        })

    const cursorClass = onClick ? 'cursor-pointer' : ''

    return (
        <div className="flex flex-row gap-1 flex-wrap">
            {categories.map(category => {
                const tooltipText = category.categoryItems.join('<br/>')

                const props: HTMLProps<HTMLDivElement> = disableTooltip
                    ? {}
                    : {
                          'data-tooltip-id': 'category-selection-tooltip',
                          'data-tooltip-html': tooltipText,
                          'data-tooltip-class-name': 'flex flex-row gap-1 flex-wrap max-w-[200px]',
                          'data-tooltip-place': 'top'
                      }
                return (
                    <div {...props} key={category.id} className={`mb-1 ${cursorClass}`} onClick={onClick}>
                        <Pill key={`selection-block-${category.id}`} text={category.name} selected={true}>
                            <QuantityLabel quantity={category.categoryItems.length} size="sm" />
                        </Pill>
                    </div>
                )
            })}
        </div>
    )
}

export function CategoriesModal({
    categoryOptions,
    isOpen,
    onClose,
    onDone,
    actionTitle,
    headerTitleOverride,
    initialSelected = {},
    closeByPressOutside = true
}: CategoryModalProps) {
    const {
        LL: { shared, issues, settingsWeb }
    } = useI18nContext()
    const [searchVal, setSearchVal] = useState('')

    const [categorySelection, setCategorySelection] = useState<CategorySelection>(initialSelected)

    const [categories, setCategories] = useState(categoryOptions)

    const reset = useCallback(() => {
        setCategorySelection(initialSelected)
    }, [initialSelected])

    useEffect(() => {
        console.log('USE EFFECT')
        setCategorySelection(initialSelected)
    }, [initialSelected])

    const handleSearch = useCallback(
        (value: string) => {
            setSearchVal(value)
            const filteredCategories: CategoryStruct[] = categories.map(category => ({
                ...category,
                categoryItems: search(value, category.categoryItems, item => item)
            }))
            setCategories(filteredCategories.filter(cat => cat.categoryItems.length > 0))
        },
        [categories]
    )

    const handleClose = useCallback(() => {
        reset()
        onClose()
    }, [reset, onClose])

    const handleClearSelection = useCallback(() => {
        setCategorySelection({})
    }, [])

    const totalSubcategories = categoryOptions.reduce((acc, prev) => acc + prev.categoryItems.length, 0)
    const selectedSubcategoryCount = Object.values(categorySelection).reduce((acc, prev) => acc + prev.length, 0)

    const hasSelection = selectedSubcategoryCount > 0

    return (
        <Modal
            className="z-20 w-1/2 overflow-none h-4/6 flex flex-col z-1000"
            isOpen={isOpen}
            closeByPressOutside={closeByPressOutside}
            onClose={onClose}>
            <ModalHeader onClose={handleClose} closeColor="snap-black" className="bg-white pb-3 z-50 rounded-t-lg">
                <div className="flex items-center gap-3 mb-3">
                    <UI.Text weight="bold" size="md" color="snap-black">
                        {headerTitleOverride ?? issues.newIssue.selectCategories()}
                    </UI.Text>
                    <QuantityLabel quantity={totalSubcategories - selectedSubcategoryCount} size="md" />
                </div>
                <SearchInput value={searchVal} onChange={handleSearch} placeholder={settingsWeb.categories.searchPlaceholder()} />
            </ModalHeader>
            <div className="px-6 pb-3 overflow-y-auto grow">
                <CategorySelectionBlock categorySelection={categorySelection} setCategorySelection={setCategorySelection} />
            </div>

            <div className="px-6 py-6 bg-white w-full min-h-[80px] flex justify-between items-center border-t border-sweeply-light-gray rounded-b-lg">
                <div className="flex gap-[8px] items-center">
                    <CategorySelectionAsGroupTags selection={categorySelection} />
                </div>
                {hasSelection && (
                    <UI.Icon
                        className="float-right cursor-pointer h-fit flex-row ml-2 mr-2"
                        onClick={handleClearSelection}
                        icon="specta-close"
                        size="xs"
                    />
                )}
                <div className="flex-1" />
                <div className="flex gap-2 align-center justify-center">
                    <UI.Button text={actionTitle ?? shared.save()} onClick={() => onDone(categorySelection)} />
                </div>
            </div>
        </Modal>
    )
}

export function Pill({
    text,
    selected,
    onClick,
    children
}: { text: string; selected: boolean; onClick?: () => void; children?: React.ReactNode }) {
    return (
        <div
            className={`flex items-center gap-2 px-3 py-1 border-[1px] ${
                selected ? 'border-snap-light-blue' : 'border-snap-light-gray'
            } rounded-full`}
            onClick={onClick}>
            <UI.Text size="sm" color={selected ? 'snap-light-blue' : 'snap-mid-gray'}>
                {text}
            </UI.Text>
            {children && children}
        </div>
    )
}

function CategoryOptionItem({
    category,
    onClick,
    textProps,
    left = false
}: {
    category: CategoryStruct
    textProps?: TextProps
    left?: boolean
    onClick?: (category: CategoryStruct) => void
}) {
    return (
        <UI.Pressable
            disabled={!onClick}
            onPress={() => {
                if (onClick) {
                    onClick(category)
                }
            }}>
            <UI.Text {...textProps}>{left ? `${category.name}:` : category.name}</UI.Text>
        </UI.Pressable>
    )
}
