import { isFeatureOn } from '@shared/feature-toggles'
import type { ActivityType, AreaCleaningStatus, AreaSummaryProjectionStruct, OrgStruct } from '@shared/firestore-structs'
import * as c from '@shared/txt-constants'
import moment from 'moment'
import type { PropsWithChildren } from 'react'
import { useRecoilValue } from 'recoil'
import { useI18nContext } from '../../../../../i18n/i18n-react'
import { UI } from '../../../../registry/ui-elements-registry'
import { cleaningStatusToHuman, occupancyToHuman } from '../../../../utils/housekeeping-utils'
import { currentOrgSelector } from '../../../auth/state/login'
import { activitiesAtom } from '../state/area-summary-state'

const Text = ({ children }: PropsWithChildren) => (
    <UI.Text color="snap-mid-gray" weight="normal">
        {children}
    </UI.Text>
)

const Small = ({ children }: PropsWithChildren) => (
    <UI.Text color="sweeply-mid-gray" weight="normal" size="xs">
        {children}
    </UI.Text>
)

interface ActivityRowProps {
    activity: AreaSummaryProjectionStruct['activities'][0]
    currentOrganization: OrgStruct
}
interface ActivityProps {
    activity: AreaSummaryProjectionStruct['activities'][0]
    currentOrganization: OrgStruct
}

export function Activities() {
    const activities = useRecoilValue(activitiesAtom)
    const currentOrganization = useRecoilValue(currentOrgSelector)
    return (
        <UI.List
            items={activities}
            render={activity => {
                return (
                    <UI.View key={activity.key} className="flex flex-col">
                        <ActivityRow activity={activity} currentOrganization={currentOrganization} />
                    </UI.View>
                )
            }}
        />
    )
}

export function ActivityRow({ activity, currentOrganization }: ActivityRowProps) {
    if (!activity) return null
    const createdDay = moment.tz(activity.created, currentOrganization.timezone).format(c.DATE_FORMAT_WITH_WEEK_DAY)
    const createdTime = moment.tz(activity.created, currentOrganization.timezone).format('HH:mm')
    const primaryActivities = new Set<ActivityType>(['occupancy', 'cleaning-status', 'cleaning-priority', 'daily-comment', 'area-note'])
    const cleaningActionActivities = new Set<ActivityType>([
        'cleaning-action-start',
        'cleaning-action-stop',
        'cleaning-action-pause',
        'cleaning-action-stop-inspection'
    ])
    const cleaningScheduleActivities = new Set<ActivityType>(['cleaning-schedule'])
    const withUsersActivities = new Set<ActivityType>(['assignment', 'delete-due-date'])
    const guestStatusActivities = new Set<ActivityType>(['guest-checked-in', 'guest-checked-out'])

    return (
        <>
            <Small>
                {createdTime} | {createdDay}
            </Small>
            {(primaryActivities.has(activity.type) && <ActivityPrimary activity={activity} currentOrganization={currentOrganization} />) ||
                (cleaningActionActivities.has(activity.type) && (
                    <CleaningActionActivity activity={activity} currentOrganization={currentOrganization} />
                )) ||
                (cleaningScheduleActivities.has(activity.type) && (
                    <CleaningScheduleActivity activity={activity} currentOrganization={currentOrganization} />
                )) ||
                (withUsersActivities.has(activity.type) && (
                    <ActivityWithUsers activity={activity} currentOrganization={currentOrganization} />
                )) ||
                (guestStatusActivities.has(activity.type) && (
                    <GuestStatusActivity activity={activity} currentOrganization={currentOrganization} />
                )) ||
                null}
        </>
    )
}

// The logic in this method is replicated from the preexisting codebase
function CleaningScheduleActivity({ activity, currentOrganization }: ActivityProps) {
    const {
        LL: {
            activities: { cleaningSchedule }
        }
    } = useI18nContext()
    const before = (activity.change.before as string[] | undefined) ?? []
    const after = (activity.change.after as string[] | undefined) ?? []
    const field: 'optInDates' | 'optOutDates' | undefined = activity.change.field as 'optOutDates' | 'optInDates' | undefined
    let actionText = before.length > after.length ? cleaningSchedule.removed() : cleaningSchedule.added()
    let firstArr = actionText === cleaningSchedule.added() ? after : before
    let secondArr = actionText === cleaningSchedule.added() ? before : after
    if (currentOrganization && currentOrganization.allowOptOut) {
        actionText = before.length > after.length ? cleaningSchedule.added() : cleaningSchedule.removed()
        firstArr = actionText === cleaningSchedule.added() ? before : after
        secondArr = actionText === cleaningSchedule.added() ? after : before
    }

    if (isFeatureOn(currentOrganization, 'new-optional-calculations')) {
        if (field) {
            actionText = field === 'optInDates' ? cleaningSchedule.added() : cleaningSchedule.removed()
            firstArr = after
            secondArr = before
        }
    }
    const difference = firstArr.filter(x => !secondArr.includes(x))
    const a =
        difference.length > 14
            ? cleaningSchedule.difference({ difference: difference.length })
            : difference
                  .map((date, i) => {
                      if (i === difference.length - 1) {
                          return `${moment.tz(parseInt(date), currentOrganization.timezone).format('MMM D')}`
                      } else {
                          return `${moment.tz(parseInt(date), currentOrganization.timezone).format('MMM D')}`
                      }
                  })
                  .join(', ')
    const username = activity.user?.name ?? ''

    const activityStr =
        actionText === cleaningSchedule.added()
            ? cleaningSchedule.activityAddition({ username, difference: a })
            : cleaningSchedule.activityRemoval({ username, difference: a })
    return <Text>{activityStr}</Text>
}
function ActivityPrimary({ activity }: ActivityProps) {
    const {
        LL: {
            activities: { primaryActivities },
            shared
        }
    } = useI18nContext()
    const changeText = primaryActivities.types[activity.type as keyof typeof primaryActivities.types]()
    const getActionText = (change: unknown) => {
        switch (activity.type) {
            case 'cleaning-status': {
                const translationKey = cleaningStatusToHuman(change as AreaCleaningStatus, null)
                return shared.cleaningStatuses[translationKey]().toLowerCase()
            }
            case 'occupancy': {
                const translationKey = occupancyToHuman(change as string)
                return shared.occupancy[translationKey]().toLowerCase()
            }
            case 'cleaning-priority':
                return (change as boolean) ? primaryActivities.priorityHigh() : primaryActivities.priorityLow()
            default:
                return change as string | undefined
        }
    }
    const before = getActionText(activity.change.before)
    const after = getActionText(activity.change.after)
    const getActivityStr = () => {
        const username = activity.user?.name ?? ''
        if (before && after) {
            return primaryActivities.activityWithBeforeAfter({ username, changeText, before, after })
        } else if (after) {
            return primaryActivities.activityWithAfter({ username, changeText, after })
        }
        return primaryActivities.activityRemoval({ username, changeText })
    }
    return <Text>{getActivityStr()}</Text>
}

function ActivityWithUsers({ activity }: ActivityProps) {
    const {
        LL: { activities }
    } = useI18nContext()
    const action =
        activity.change.before === 'assigned' ? activities.withUserActivity.assignAction() : activities.withUserActivity.unassignAction()
    const activityStr = activities.withUserActivity.activityString({
        username: activity.user?.name ?? '',
        action,
        after: activity.change.after
    })

    return <Text>{activityStr}</Text>
}

function GuestStatusActivity({ activity }: ActivityProps) {
    const {
        LL: {
            activities: { guestActivities }
        }
    } = useI18nContext()
    const text = guestActivities[activity.type as keyof typeof guestActivities]()
    return <Text>{text}</Text>
}

function CleaningActionActivity({ activity }: ActivityProps) {
    const {
        LL: { activities, shared }
    } = useI18nContext()

    const actionText = activities.cleaningAction[activity.type as keyof typeof activities.cleaningAction]()
    const beforeTranslationKey = cleaningStatusToHuman(activity.change.before as AreaCleaningStatus, null)
    const afterTranslationKey = cleaningStatusToHuman(activity.change.after as AreaCleaningStatus, null)

    const before = shared.cleaningStatuses[beforeTranslationKey]().toLowerCase()
    const after = shared.cleaningStatuses[afterTranslationKey]().toLowerCase()
    const username = activity.user?.name ?? ''
    const activityStr = before
        ? activities.cleaningActionWithBefore({ username, action: actionText, before, after })
        : activities.cleaningActionWithoutBefore({ username, action: actionText, after })
    return <Text>{activityStr}</Text>
}
