import { RoundButton } from 'components/atoms/round-button'

export function ActionsCell({ onEdit, onDelete }: { onEdit: () => void; onDelete: () => void }) {
    return (
        <div className="flex gap-2">
            <div onClick={e => e.stopPropagation()}>
                <RoundButton onClick={onEdit} hoverIconColor="snap-teal" iconColor="snap-mid-gray" icon="specta-pencil" />
            </div>
            <div onClick={e => e.stopPropagation()}>
                <RoundButton onClick={onDelete} hoverIconColor="snap-red" iconColor="snap-mid-gray" icon="specta-trash" />
            </div>
        </div>
    )
}
