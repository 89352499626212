import type { Firebase } from '@shared/firebase'
import { addCommentToIssue } from '@shared/issue-data'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'

export function useAddCommentToIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (issueKey: string, text: string) => {
        try {
            await addCommentToIssue(firebase, {
                currentUser,
                issueKey,
                text
            })
        } catch (error: unknown) {
            //TODO: add proper error handling
            console.error(error)
            throw new Error('Error adding hashtags to issue', { cause: error })
        }
    }
}
