import type { CSSObject } from '@emotion/serialize'
import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { SnapColors } from '@shared/colors'
import ReactSelect, {
    type ActionMeta,
    type GroupBase,
    type MultiValue,
    type OptionProps,
    type SingleValue,
    type StylesConfig
} from 'react-select'
import { LocalizedString } from 'typesafe-i18n'

export type Option<T> = {
    value: T
    label: string
}
export interface CustomOptionProps<T> {
    option: Option<T>
    innerProps?: React.HTMLAttributes<HTMLDivElement>
}

type CustomStyles<T> = Partial<{ [K in keyof StylesConfig<T>]: CSSObject }>
interface SelectProps<T = string> {
    options: Option<T>[]
    value?: Option<T> | Option<T>[]
    onChange: (value: T) => void
    disabled?: boolean
    isMulti?: boolean
    placeholder?: string
    CustomOption?: (props: OptionProps<Option<T>, boolean>) => JSX.Element
    customStyles?: CustomStyles<Option<T>>
}

export function Select<T extends string | number | readonly string[] | undefined = string>({
    options,
    onChange,
    value,
    disabled,
    isMulti,
    CustomOption,
    placeholder,
    customStyles = {}
}: SelectProps<T>) {
    const { LL } = useI18nContext()
    const handleSelect = (newValue: SingleValue<Option<T>> | MultiValue<Option<T>>, actionMeta: ActionMeta<Option<T>>) => {
        if (isMulti) {
            if (Array.isArray(newValue) && Array.isArray(value)) {
                if (actionMeta.action === 'select-option') {
                    const addedOption = newValue.find(newOption => !value.some(oldOption => oldOption.value === newOption.value))
                    if (addedOption) {
                        onChange(addedOption.value)
                    }
                } else if (actionMeta.action === 'remove-value') {
                    const removedOption = value.find(oldOption => !newValue.some(newOption => newOption.value === oldOption.value))
                    if (removedOption) {
                        onChange(removedOption.value)
                    }
                }
            }
        } else {
            onChange((newValue as Option<T>).value)
        }
    }

    const selectStyles: StylesConfig<Option<T>> = {
        control: styles => ({
            ...styles,
            boxShadow: 'none',
            borderColor: `${SnapColors.lightGray} !important`
        }),
        dropdownIndicator: styles => ({
            ...styles,
            color: `${SnapColors.silver} !important`
        }),
        multiValue: styles => ({
            ...styles,
            fontWeight: 400,
            background: 'none',
            borderRadius: 10,
            border: `1px solid ${SnapColors.lightBlue}`,
            padding: '1px 5px'
        }),
        menu: styles => ({
            ...styles,
            boxShadow:
                '0px 101px 28px 0px rgba(0, 0, 0, 0.00), 0px 64px 26px 0px rgba(0, 0, 0, 0.01), 0px 36px 22px 0px rgba(0, 0, 0, 0.05), 0px 16px 16px 0px rgba(0, 0, 0, 0.09), 0px 4px 9px 0px rgba(0, 0, 0, 0.10)',
            borderRadius: 8
        }),
        clearIndicator: styles => ({
            ...styles,
            color: `${SnapColors.silver} !important`
        }),
        multiValueLabel: styles => ({
            ...styles,
            color: `${SnapColors.lightBlue} !important`
        }),
        multiValueRemove: styles => ({
            ...styles,
            color: `${SnapColors.lightBlue} !important`
        })
    }

    const mergedStyles = mergeStyles(selectStyles, customStyles)

    const components = {
        IndicatorSeparator: () => null,
        ...(CustomOption && { Option: CustomOption })
    }
    return (
        <ReactSelect<Option<T>, boolean, GroupBase<Option<T>>>
            value={value}
            onChange={handleSelect}
            isDisabled={disabled}
            options={options}
            isSearchable={false}
            isMulti={isMulti}
            components={components}
            styles={mergedStyles}
            placeholder={placeholder}
            maxMenuHeight={160}
        />
    )
}

function mergeStyles<T>(defaultStyles: StylesConfig<T>, customStyles?: CustomStyles<T>): StylesConfig<T> {
    const styleKeys = Object.keys(defaultStyles) as Array<keyof StylesConfig<T>>
    return styleKeys.reduce(
        (acc, key) => {
            acc[key] = (base: any, props: any) => ({
                ...defaultStyles[key]?.(base, props),
                ...customStyles?.[key]
            })
            return acc
        },
        {} as StylesConfig<T>
    )
}
