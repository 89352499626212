import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { addTag, deleteCategory, removeCategoryItem } from '@shared/category-data'
import type { CategoryStruct } from '@shared/firestore-structs'
import { Link } from '@tanstack/react-router'
import firebase, { asFirebase } from 'app/firebase'
import { ConfirmModal } from 'components/layouts/settings/confirm-modal'
import { useCallback, useState } from 'react'
import { prefix } from 'routes/__root'
import { type CategorySectionOption, LabelSelect } from './label-select'
import { QuantityLabel } from './quantity-label'
import Tooltip from './tooltip'

type CateogrySectionProps = {
    category: CategoryStruct
    onDidUpdate: () => void
}

export const CategorySection = ({ category, onDidUpdate }: CateogrySectionProps) => {
    const currentUser = useCurrentUser()
    const [isAttemptingToDeleteCategory, setIsAttemptingToDeleteCategory] = useState(false)
    const [isRemovingCategory, setIsRemovingCategory] = useState(false)

    const {
        LL: { settingsWeb, shared }
    } = useI18nContext()

    const onCreateItem = useCallback(
        async (item: { label: string; value: string }) => {
            await addTag(asFirebase(firebase), currentUser, category.id, item.value).finally(() => onDidUpdate())
        },
        [onDidUpdate]
    )

    const onRemoveLabel = useCallback(
        async (option: CategorySectionOption) => {
            await removeCategoryItem(asFirebase(firebase), currentUser, category.id, option.value).finally(() => onDidUpdate())
        },
        [onDidUpdate]
    )

    const removeCategory = useCallback(async () => {
        setIsRemovingCategory(true)
        await deleteCategory(asFirebase(firebase), currentUser, category.id).finally(() => {
            setIsRemovingCategory(false)
            return onDidUpdate()
        })
    }, [onDidUpdate])

    const onTrashClick = useCallback(async () => {
        setIsAttemptingToDeleteCategory(true)
    }, [removeCategory])

    return (
        <div className="flex flex-col">
            <div className="mb-[32px] flex flex-row gap-[16px] items-center">
                <UI.Text size="2xl" color="snap-black">
                    {category.name}
                </UI.Text>
                <QuantityLabel quantity={category.categoryItems.length} />
                <Link to={`${prefix}/settings/categories/$key`} params={{ key: category.id }}>
                    <div
                        data-tooltip-id="edit-category"
                        className="flex items-center aspect-square justify-center w-[26px] h-[26px] rounded-full p-[6px] bg-snap-light-gray cursor-pointer">
                        <UI.Icon icon="specta-pencil2" size="xs" color="snap-dark-gray" />
                    </div>
                </Link>
                {isRemovingCategory ? (
                    <div className="h-[26px] w-[26px]">
                        <UI.Loader />
                    </div>
                ) : (
                    <div className="flex items-center justify-center p-[6px] cursor-pointer" onClick={onTrashClick}>
                        <UI.Icon className="aspect-square" icon="specta-trash" size="xs" color="snap-dark-gray" />
                    </div>
                )}
            </div>
            <LabelSelect
                values={category.categoryItems.map(item => ({
                    label: item,
                    value: item
                }))}
                onCreateLabel={onCreateItem}
                onRemoveLabel={onRemoveLabel}
            />

            <Tooltip id="edit-category" text={settingsWeb.categories.editTooltip()} />

            <ConfirmModal
                isOpen={isAttemptingToDeleteCategory}
                onClose={() => setIsAttemptingToDeleteCategory(false)}
                onConfirm={removeCategory}
                headerText={settingsWeb.categories.deleteHeader()}
                bodytext={settingsWeb.categories.massDeleteConfirmModal({ count: '1' })}
                isLoading={isRemovingCategory}
            />
        </div>
    )
}
