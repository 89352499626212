import type { AreaStruct } from '@shared/firestore-structs'
import type { AssigneesWithCountAndAreas, TeamWorkloadGroup } from '../../../../types/housekeeping.types'
import type { HousekeepingOverviewCombined } from '../../../../user-actions/housekeeping-overview-actions'
import { groupSorting } from '../../../../utils/housekeeping-utils'

export function getTeamWorkloadList(
    cleaningTasks: HousekeepingOverviewCombined[0][],
    showGroups: boolean
): TeamWorkloadGroup[] | AssigneesWithCountAndAreas[] {
    const groups = getCleaningTasksGroups(cleaningTasks)

    return showGroups
        ? groups.map(group => ({
              group,
              users: getAssigneesWithCountAndAreas(cleaningTasks, group)
          }))
        : getAssigneesWithCountAndAreas(cleaningTasks)
}

export function getCleaningTasksGroups(cleaningTasks: HousekeepingOverviewCombined[0][]): string[] {
    return Array.from(
        new Set(
            cleaningTasks
                .filter(area => Boolean(area?.group && area.assignedTo.length))
                .sort(groupSorting)
                .map(area => area.group)
        )
    )
}

export function getAssigneesWithCountAndAreas(areas: HousekeepingOverviewCombined[0][], group?: string): AssigneesWithCountAndAreas[] {
    const areasWithCleaningTasksFilteredByGroup = group ? areas.filter(task => task.group === group) : areas

    const result = areasWithCleaningTasksFilteredByGroup.reduce((acc: Map<string, AssigneesWithCountAndAreas>, area) => {
        if (!area.assignedTo || area.assignedTo.length === 0) {
            return acc
        }

        const userKeys = area.assignedTo
            .map(user => user.key)
            .sort()
            .join(',')
        const existingItem = acc.get(userKeys)

        if (existingItem) {
            if (isAreaStruct(area)) {
                existingItem.count += 1
                existingItem.areas = [...existingItem.areas, area]
            }
        } else {
            if (isAreaStruct(area)) {
                acc.set(userKeys, {
                    users: area.assignedTo,
                    count: 1,
                    areas: [area]
                })
            }
        }

        return acc
    }, new Map<string, AssigneesWithCountAndAreas>())

    return Array.from(result.values())
}
function isAreaStruct(area: any): area is Pick<AreaStruct, 'key' | 'cleaningStatus' | 'occupancy'> {
    return 'key' in area && 'cleaningStatus' in area && 'occupancy' in area
}
