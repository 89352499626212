import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useEditedUnit, useUnits, useUnitsGroups } from '@shared-snap/snap/components/settings/hooks/use-units'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { createArea, updateArea } from '@shared/area-data'
import { useNavigate } from '@tanstack/react-router'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { Checkbox } from 'components/atoms/checkbox'
import { FieldErrorMessage } from 'components/atoms/field-error-message'
import { FieldLabel } from 'components/atoms/field-label'
import { FieldSelect } from 'components/atoms/field-select'
import Input from 'components/atoms/input/input'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { prefix } from 'routes/__root'

interface UnitForm {
    name: string
    description: string
    address: string
    group: { value: string; label: string }
    displayCleaningStatus: boolean
}

export function UnitModal({ unitKey }: { unitKey: string }) {
    const editedArea = useEditedUnit(unitKey)
    const groups = useUnitsGroups()
    const currentUser = useCurrentUser()
    const [areas, setAreas] = useUnits()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const navigate = useNavigate()
    const {
        LL: { settingsWeb, settings, shared }
    } = useI18nContext()

    const defaultValues: Readonly<UnitForm> = {
        name: '',
        description: '',
        address: '',
        group: { value: '', label: '' },
        displayCleaningStatus: true
    }
    const form = useForm<UnitForm>({
        defaultValues
    })
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        reset
    } = form

    useEffect(() => {
        if (editedArea) {
            setValue('name', editedArea.name ?? '')
            setValue('description', editedArea.description ?? '')
            setValue('address', editedArea.address ?? '')
            setValue('group', editedArea.group ? { value: editedArea.group, label: editedArea.group } : { value: '', label: '' })
            setValue('displayCleaningStatus', editedArea.displayCleaningStatus ?? true)
        } else {
            reset(defaultValues)
        }
    }, [editedArea, reset])

    const onClose = () => navigate({ to: `${prefix}/settings/units` })

    const onSubmit = async (data: UnitForm) => {
        setIsSubmitting(true)
        const formattedValues = { ...data, group: data.group.value }

        try {
            if (unitKey) {
                await updateArea(asFirebase(firebase), unitKey, formattedValues, currentUser)
                setAreas(areas.map(area => (area.key === unitKey ? { ...area, ...formattedValues } : area)))
            } else {
                const areaKey = await createArea(asFirebase(firebase), formattedValues, currentUser)
                setAreas([...areas, { ...formattedValues, key: areaKey, cleaningStatus: 'clean', occupancy: 'vacant' }])
            }
            onClose()
        } catch (error) {
            // TODO: Add user friendly error handling
            console.error('Failed to save unit:', error)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Modal className="flex flex-col w-3/4 h-3/4 overflow-y-scroll" onClose={onClose}>
            <ModalHeader className="mb-4" onClose={onClose}>
                <UI.Text size="2xl" color="snap-black">
                    {unitKey ? settingsWeb.units.editUnit() : settingsWeb.units.newUnit()}
                </UI.Text>
            </ModalHeader>
            <div className="grow px-6 pb-6">
                <FormProvider {...form}>
                    <form className="flex flex-col h-full justify-between" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-8 mb-8">
                            <FieldLabel label={`${settingsWeb.units.nameColumn()}:`} required>
                                <Input name="name" placeholder={settings.nameFieldPlaceholder()} required register={register} />
                                {errors.name && <FieldErrorMessage message={shared.errorFieldIsRequired({ field: 'This field' })} />}
                            </FieldLabel>
                            <FieldLabel label={`${settings.units.descriptionLabel()}:`}>
                                <Input
                                    name="description"
                                    placeholder={settingsWeb.units.unitDescriptionPlaceholder()}
                                    register={register}
                                />
                            </FieldLabel>
                            <FieldLabel label={`${settings.units.addressLabel()}:`} required>
                                <Input name="address" placeholder={settings.units.addressPlaceholder()} register={register} />
                            </FieldLabel>
                            <FieldLabel horizontal label={`${settings.units.groupLabel()}:`}>
                                <FieldSelect
                                    defaultValue=""
                                    name="group"
                                    options={groups.map(group => ({ value: group, label: group }))}
                                    Option={({ data, innerProps }) => (
                                        <div {...innerProps} className="flex flex-col w-full gap-1 p-2 border-b cursor-pointer">
                                            <UI.Text size="md" weight="bold">
                                                {data.label}
                                            </UI.Text>
                                        </div>
                                    )}
                                    placeholder={settingsWeb.units.groupPlaceholder()}
                                    isCreatable
                                    rules={{ required: shared.errorFieldIsRequired({ field: 'This field' }) }}
                                />
                            </FieldLabel>
                            <div className="flex items-center gap-x-2">
                                <Checkbox name="displayCleaningStatus" />
                                <label htmlFor="displayCleaningStatus">{settings.units.display()}</label>
                            </div>
                        </div>

                        <div className="w-full flex justify-end gap-x-4">
                            {isSubmitting ? (
                                <div>
                                    <UI.Loader />
                                </div>
                            ) : (
                                <>
                                    <Button className="bg-snap-silver" type="button" onClick={onClose}>
                                        {shared.cancel()}
                                    </Button>
                                    <Button type="submit">{shared.save()}</Button>
                                </>
                            )}
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>
    )
}
