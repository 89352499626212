import Markdown from 'react-markdown'
import 'github-markdown-css/github-markdown-light.css'

const MarkdownRenderer = ({ content }: { content: string }) => {
    return (
        <div style={{ color: '#8E8E93' }} className="markdown-body">
            <Markdown>{content}</Markdown>
        </div>
    )
}

export default MarkdownRenderer
