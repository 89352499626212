import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { lastHousekeepingAtom } from '@shared-snap/snap/components/housekeeping/area-summary/state/area-summary-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { getTimerDisplay } from '@shared/timer-helpers'
import { useRecoilValue } from 'recoil'

function Timer() {
    const {
        LL: { area }
    } = useI18nContext()
    const lastHousekeeping = useRecoilValue(lastHousekeepingAtom)
    const duration = lastHousekeeping?.cleaning
        ? getTimerDisplay(lastHousekeeping.cleaning)
        : {
              hours: '00',
              min: '00'
          }
    return (
        <div className="flex flex-col gap-1 w-full">
            <div className="border border-snap-light-gray border-dashed py-[6px] px-[8px] flex justify-center items-center rounded-md h-10">
                <UI.Text size="md" color="snap-silver">
                    {duration.hours}:{duration.min}
                </UI.Text>
            </div>
            <UI.Text size="xs" color="snap-silver" align="center">
                {area.lastDuration()}
            </UI.Text>
        </div>
    )
}

export default Timer
