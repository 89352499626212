import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Locales } from '@shared-snap/i18n/i18n-types'
import { loadLocaleAsync } from '@shared-snap/i18n/i18n-util.async'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { languages } from '@shared-snap/snap/utils'
import ContextMenu from 'components/atoms/context-menu/context-menu'
import { useState } from 'react'

export function LanguageSwitcher() {
    const { locale, setLocale } = useI18nContext()
    const [isSwitcherOpen, setIsSwitcherOpen] = useState(false)
    const languagesOptions = languages.map(({ code, flag }) => ({ label: `${flag} ${code.toUpperCase()}`, value: code }))
    const currentLocale = languages.find(l => l.code === locale)

    const onLocaleSelected = async (locale: Locales) => {
        try {
            await loadLocaleAsync(locale)
            setLocale(locale)
            setIsSwitcherOpen(false)
        } catch (error) {
            console.error('Error changing locale', error)
        }
    }

    return (
        <div className="relative flex flex-col justify-center items-center">
            {isSwitcherOpen && (
                <ContextMenu
                    direction="up"
                    className="max-h-[140px] w-[80px] left-0 "
                    overflow="auto"
                    onBlur={() => setIsSwitcherOpen(false)}>
                    <UI.List
                        items={languagesOptions}
                        render={option => {
                            return (
                                <UI.Pressable
                                    className={`hover:bg-snap-teal-100 py-2 ${option.value === locale ? 'bg-snap-teal-100' : ''}`}
                                    key={option.value}
                                    onPress={() => onLocaleSelected(option.value)}>
                                    <UI.Text>{option.label}</UI.Text>
                                </UI.Pressable>
                            )
                        }}
                    />
                </ContextMenu>
            )}

            <div className="flex items-center gap-x-2 cursor-pointer" onClick={() => setIsSwitcherOpen(true)}>
                <UI.Text>
                    {currentLocale?.flag} {currentLocale?.code.toUpperCase()}
                </UI.Text>
                <UI.Icon className={isSwitcherOpen ? 'rotate-[270deg]' : 'rotate-90'} size="xs" icon="specta-arrow-right" />
            </div>
        </div>
    )
}
