import type { Firebase } from '@shared/firebase'
import type { AreaCleaningStatus } from '@shared/firestore-structs'
import { useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUserAreaSummaryAction } from '../../use-user-action'

export function useChangeCleaningStatus(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const action = useUserAreaSummaryAction('set-cleaning-status', firebase)

    function changeCleaningStatus(cleaningStatus: AreaCleaningStatus) {
        action(cleaningStatus, currentUser)
    }

    return changeCleaningStatus
}
