import type { Firebase } from '@shared/firebase'
import type { TaskStruct } from '@shared/firestore-structs'
import { unassignIssue } from '@shared/issue-data'
import { deleteTask } from '@shared/task-data'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'

export function useDeleteTask(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (task: TaskStruct) => {
        try {
            await deleteTask(firebase, task.key, currentUser)
            if (task.type === 'issue') {
                await unassignIssue(firebase, {
                    issueKey: task.key,
                    currentUser
                })
            }
        } catch (error: unknown) {
            //TODO: proper error handling
            console.error(error)
            throw new Error('Error deleting task')
        }
    }
}
