import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Colors } from '@shared-snap/snap/types/visuals.types'
import { useScrollToggle } from 'app/hooks/use-scroll-toggle'
import React, { type ReactNode, type MouseEvent, useEffect } from 'react'
import { createPortal } from 'react-dom'

interface Props {
    children: ReactNode
    className?: string
    isOpen?: boolean
    closeByPressOutside?: boolean
    onClose: () => void
}

const useEscape = (onEscape: () => void) => {
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') onEscape()
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [])
}
export function Modal({ isOpen = true, onClose, className, children, closeByPressOutside = true }: Props) {
    const setScroll = useScrollToggle()

    useEffect(() => {
        if (isOpen) {
            setScroll(false)
        } else {
            setScroll(true)
        }

        return () => setScroll(true)
    }, [isOpen, setScroll])

    useEscape(onClose)
    if (!isOpen) {
        return null
    }

    const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
        if (!closeByPressOutside) return

        if (e.target === e.currentTarget) {
            onClose()
        }
    }
    return createPortal(
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-30 z-50" onClick={handleOverlayClick}>
            <div className={`relative rounded-lg bg-white ${className}`}>{children}</div>
        </div>,
        document.body
    )
}

export function ModalHeader({
    onClose,
    className,
    children,
    closeColor
}: {
    children: ReactNode
    className?: string
    onClose: () => void
    closeColor?: Colors
}) {
    return (
        <div className={`relative w-full text-white flex sticky pt-6 px-6 inset-x-0 top-0 items-start z-50 ${className}`}>
            <div className="w-full">{children}</div>
            <button className="absolute right-5 top-6 w-fit h-fit" onClick={onClose}>
                <UI.Icon icon="specta-close" color={closeColor || 'snap-mid-gray'} size="xs" />
            </button>
        </div>
    )
}
