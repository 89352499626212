import type { CommentivityStruct, TaskStruct } from '@shared/firestore-structs'
import { TaskboardContext } from '@shared/traces-types'
import moment from 'moment'
import { DefaultValue, atom, selector, selectorFamily } from 'recoil'
import { match } from 'ts-pattern'
import { search } from '../../../utils'
import { currentOrgSelector, currentUser as currentUserState } from '../../auth/state/login'
import { housekeepingOverviewDate } from '../../housekeeping/housekeeping-overview/state/housekeeping-overview-state'

export interface IntegrationParams {
    areaKey?: string
    propKey: string
    reservationId?: string
    pmsLinkUrl?: string
    propName?: string
}

export enum TaskView {
    myTasks = 'myTasksHeader',
    allTasks = 'allTasksHeader'
}
export const currentTasksTypesAtom = atom<TaskStruct['type'][]>({
    key: 'taskboard-currentTasksTypesAtom',
    default: ['cleaning', 'general', 'issue']
})

export const currentChosenTaskViewAtom = atom<TaskView>({
    key: 'taskboard-currentChosenTaskViewAtom',
    default: TaskView.myTasks
})

export const taskboardContextAtom = atom<TaskboardContext>({
    key: 'taskboard-contextAtom',
    default: TaskboardContext.EMBEDDED
})

export const startDateAtom = selector<number>({
    key: 'my-tasks-selectedDate',
    get: ({ get }) => {
        return moment(get(housekeepingOverviewDate)).startOf('day').valueOf()
    },
    set: ({ set, get }, newValue) => {
        const org = get(currentOrgSelector)
        if (newValue instanceof DefaultValue) {
            set(housekeepingOverviewDate, moment.tz(org.timezone).startOf('day'))
        } else {
            set(housekeepingOverviewDate, moment.tz(newValue, org.timezone).startOf('day'))
        }
    }
})

export const priorityFilterAtom = atom<boolean>({
    key: 'my-tasks-priorityFilter',
    default: false
})

export const searchInputValueAtom = atom<string>({
    key: 'my-tasks-searchInputValue',
    default: ''
})

export const housekeepingTasksAtom = atom<TaskStruct[]>({ key: 'housekeepingTasks', default: [] })
export const issueTasksAtom = atom<TaskStruct[]>({ key: 'issueTasks', default: [] })
export const generalActiveTasksAtom = atom<TaskStruct[]>({ key: 'generalActiveTasks', default: [] })
export const generalCompletedTasksAtom = atom<TaskStruct[]>({ key: 'generalCompletedTasks', default: [] })

export const allTasksSelector = selector({
    key: 'my-tasks-allTasks',
    get: ({ get }) => {
        const priorityFilter = get(priorityFilterAtom)
        const searchInputValue = get(searchInputValueAtom)
        const atoms = [housekeepingTasksAtom, issueTasksAtom, generalActiveTasksAtom, generalCompletedTasksAtom]
        const allTasks = atoms.flatMap(atom => get(atom) ?? [])

        if (priorityFilter) {
            return allTasks.filter(task => task.priority)
        }

        if (searchInputValue.length > 0) {
            return search(searchInputValue, allTasks, searchExtract)
        }

        return allTasks
    }
})

export const assignedTasksSelector = selector({
    key: 'my-tasks-assignedTasks',
    get: ({ get }) => {
        const allTasks = get(allTasksSelector)
        const startDate = get(startDateAtom)
        const taskboardContext = get(taskboardContextAtom)
        return getTasksByStatus(allTasks, 'assigned', startDate, taskboardContext)
    }
})

export const assignedLateTasksSelector = selector({
    key: 'my-tasks-assignedLateTasks',
    get: ({ get }) => {
        const allTasks = get(allTasksSelector)
        const startDate = get(startDateAtom)
        const taskboardContext = get(taskboardContextAtom)
        return getTasksByStatus(allTasks, 'assignedLate', startDate, taskboardContext)
    }
})

export const dueTasksSelector = selector({
    key: 'my-tasks-dueTasks',
    get: ({ get }) => {
        const allTasks = get(allTasksSelector)
        const startDate = get(startDateAtom)
        const taskboardContext = get(taskboardContextAtom)
        return getTasksByStatus(allTasks, 'due', startDate, taskboardContext)
    }
})

export const dueLateTasksSelector = selector({
    key: 'my-tasks-dueLateTasks',
    get: ({ get }) => {
        const allTasks = get(allTasksSelector)
        const startDate = get(startDateAtom)
        const taskboardContext = get(taskboardContextAtom)
        return getTasksByStatus(allTasks, 'dueLate', startDate, taskboardContext)
    }
})

export const doneTasksSelector = selector({
    key: 'my-tasks-doneTasks',
    get: ({ get }) => {
        const allTasks = get(allTasksSelector)
        const startDate = get(startDateAtom)
        const taskboardContext = get(taskboardContextAtom)
        return getTasksByStatus(allTasks, 'done', startDate, taskboardContext)
    }
})

export const myTasksSelector = selector({
    key: 'my-tasks-myTasks',
    get: ({ get }) => {
        const dueTasks = get(dueTasksSelector)
        const assignedTasks = get(assignedTasksSelector)
        const assignedLateTasks = get(assignedLateTasksSelector)
        const currentUser = get(currentUserState)

        const due = filterTasksByAssignedUser(assignedTasks, currentUser.key)
        const overdue = filterTasksByAssignedUser(assignedLateTasks, currentUser.key)
        const createdByMe = filterTasksCreatedByUser([...dueTasks, ...assignedTasks], currentUser.key)

        const taskCount = due.length + overdue.length
        const priorityTasksCount = [...due, ...overdue].filter(task => task.priority).length

        return { due: due, overdue: overdue, createdByMe, taskCount, priorityTasksCount }
    }
})

export const allTasksBySectionSelector = selector({
    key: 'my-tasks-allTasksBySection',
    get: ({ get }) => {
        const assignedDue = get(assignedTasksSelector)
        const openDue = get(dueTasksSelector)
        const openOverdue = get(dueLateTasksSelector)
        const assignedOverdue = get(assignedLateTasksSelector)
        const currentUser = get(currentUserState)

        const due = [...openDue, ...assignedDue]
        const overdue = [...openOverdue, ...assignedOverdue]
        const createdByMe = filterTasksCreatedByUser([...due, ...overdue], currentUser.key)

        const taskCount = due.length + overdue.length
        const priorityTasksCount = [...due, ...overdue].filter(task => task.priority).length
        return { due, overdue, createdByMe, taskCount, priorityTasksCount }
    }
})

export const currentTaskAtom = atom<TaskStruct | null>({
    key: 'my-tasks-currentTask',
    default: null
})

export const taskSelector = selectorFamily({
    key: 'my-tasks-taskSelector',
    get:
        (taskKey: string) =>
        ({ get }) => {
            const taskFromList = get(allTasksSelector).find(task => task.key === taskKey)
            const currentTask = get(currentTaskAtom)
            const task = taskFromList || currentTask

            if (!task) throw new Error(`Task with key ${taskKey} not found`)

            return task
        }
})

export const currentCommentivitiesAtom = atom<CommentivityStruct[]>({
    key: 'my-tasks-currentCommentivities',
    default: []
})

export function getTasksByStatus(
    tasks: TaskStruct[],
    status: 'assigned' | 'assignedLate' | 'due' | 'dueLate' | 'done',
    startDate: number,
    taskboardContext: TaskboardContext
): TaskStruct[] {
    return tasks
        .filter(task =>
            match(status)
                .with('assigned', () => getTodayTaskWithStatus(task, 'assigned', startDate, taskboardContext))
                .with('assignedLate', () => getOverdueTasksWithStatus(task, 'assigned', startDate, taskboardContext))
                .with('due', () => getTodayTaskWithStatus(task, 'open', startDate, taskboardContext))
                .with('dueLate', () => getOverdueTasksWithStatus(task, 'open', startDate, taskboardContext))
                .with('done', () => getCompletedCondition(task, startDate, taskboardContext))
                .exhaustive()
        )
        .sort((a, b) => (a.startDate ?? 0) - (b.startDate ?? 0))
}

function filterTasksByAssignedUser(tasks: TaskStruct[], userKey: string) {
    return tasks.filter(task => task.assignedTo?.map(u => u.key).includes(userKey))
}

function filterTasksCreatedByUser(tasks: TaskStruct[], userKey: string) {
    return tasks.filter(task => task.creator.key === userKey)
}

function getTodayTaskWithStatus(task: TaskStruct, status: TaskStruct['status'], startDate: number, taskboardContext: TaskboardContext) {
    return (
        task.status === status &&
        task.startDate !== null &&
        (task.startDate === startDate || (taskboardContext === TaskboardContext.RESERVATIONS && task.startDate >= startDate))
    )
}

function getOverdueTasksWithStatus(task: TaskStruct, status: TaskStruct['status'], startDate: number, taskboardContext: TaskboardContext) {
    return task.status === status && task.startDate !== null && task.startDate < startDate
}

function getCompletedCondition(task: TaskStruct, startDate: number, taskboardContext: TaskboardContext) {
    return (
        task.status === 'completed' &&
        ((task.type !== 'general' && task.completedDate === startDate) ||
            (task.type === 'general' && moment(task.updated).startOf('day').isSame(task.updated, 'day')) ||
            taskboardContext === TaskboardContext.RESERVATIONS)
    )
}

function searchExtract(task: TaskStruct) {
    const name = task.name ? task.name : ''
    const areaName = 'area' in task && task.area?.name ? task.area.name : ''
    const assignees = task.assignedTo ? task.assignedTo.map(assignee => assignee.name).join(' ') : ''
    const group = task.area ? task.area.group : ''
    const reservationId = 'reservationId' in task ? `Reservation ${task.reservationId}` : ''
    const propName = 'propName' in task ? task.propName : ''

    return name + areaName + assignees + group + reservationId + propName
}
