import { useTracesIntegrationParams } from '@shared-snap/snap/components/traces/hooks/use-traces-integration-params'
import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import firebase, { asFirebase } from '../../../app/firebase'
import { Taskboard } from '../../../components/layouts/taskboard/taskboard-layout'
import { prefix } from '../../__root'

export const Route = createFileRoute(`${prefix}/_authenticated/integrations/apaleo/taskboard`)({
    component: () => {
        const [done, setDone] = useState(false)
        const loadParsm = useTracesIntegrationParams(asFirebase(firebase))
        useEffect(() => {
            loadParsm().finally(() => setDone(true))
        }, [])
        return done ? <Taskboard /> : null
    }
})
