import { useTaskData } from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import { createFileRoute } from '@tanstack/react-router'
import { AreaSummaryModal } from 'components/organisms/area-summary-modal'
import { IssueModal } from 'components/organisms/issue-modal'
import { P, match } from 'ts-pattern'
import { GeneralTaskModal } from '../../../components/organisms/general-task-modal'
import { prefix } from '../../__root'

export const Route = createFileRoute(`${prefix}/_authenticated/taskboard/$key`)({
    component: Task
})

function Task() {
    const { key } = Route.useParams()
    const task = useTaskData(key)
    if (!task) throw new Error('Task not found')

    return match(task)
        .with({ type: 'issue' }, () => <IssueModal issueKey={task.key} />)
        .with({ type: 'general' }, c => <GeneralTaskModal task={c} />)
        .with({ type: 'cleaning' }, () => task.area?.key && <AreaSummaryModal areaKey={task.area.key} />)
        .otherwise(() => <div>Task type is wrong</div>)
}
