import { registrySetter } from './registry-utils'

interface ConstantsTypes {
    platform: 'web' | 'mobile'
}

export const Constants: ConstantsTypes = {
    platform: 'web'
}

export const setPlatformConstants = registrySetter(Constants)
