import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Colors } from '@shared-snap/snap/types/visuals.types'
import moment from 'moment'
import type React from 'react'
import { useCallback } from 'react'
import ReactDatePicker from 'react-datepicker'
import { IconButton } from '../icon-button'
import Tooltip from '../tooltip'
import 'react-datepicker/dist/react-datepicker.css'
import './custom-datepicker.css'

type DatePickerPropsWithTrigger = {
    value: string
    onChange: (date: string) => void
    dateFormatOverride?: string | string[]
    disabled?: boolean
    withTrigger?: {
        Trigger: React.ReactNode
        id: string
    }
}

type DatePickerProps = DatePickerPropsWithTrigger

export function DatePicker(props: DatePickerProps) {
    const { locale } = useI18nContext()
    const { value, onChange, disabled = false, withTrigger, dateFormatOverride } = props

    const handleValueChange = useCallback(
        (newValue?: Date) => {
            if (!newValue) {
                return
            }
            onChange(moment(newValue).format('YYYY-MM-DD'))
        },
        [onChange]
    )

    const inputClassName = `bg-snap-light-yellow border border-snap-yellow rounded-[10px] text-[16px] text-snap-black font-bold text-center`

    return withTrigger ? (
        <div className="flex">
            <ReactDatePicker
                selected={new Date(moment(value).format())}
                customInput={
                    <div
                        onClick={() => {
                            const input = document.getElementById(withTrigger.id)
                            input?.focus()
                        }}>
                        {withTrigger.Trigger}
                    </div>
                }
                id={withTrigger.id}
                onChange={date => handleValueChange(date ?? undefined)}
                onSelect={date => handleValueChange(date ?? undefined)}
                popperProps={{
                    strategy: 'fixed'
                }}
                locale={locale}
                dateFormat={dateFormatOverride ?? 'MMM do, yyyy'}
            />
        </div>
    ) : (
        <ReactDatePicker
            selected={new Date(moment(value).format())}
            className={`${inputClassName} w-[130px]`}
            onChange={date => handleValueChange(date ?? undefined)}
            onSelect={date => handleValueChange(date ?? undefined)}
            usePointerEvent
            popperProps={{
                strategy: 'fixed'
            }}
            locale={locale}
            dateFormat={dateFormatOverride ?? 'MMM do, yyyy'}
        />
    )
}

export function DateNavigator({
    value,
    onChange,
    todayBtnColor = 'snap-red'
}: {
    value: string
    onChange: (date: string) => void
    todayBtnColor?: Colors
}) {
    const {
        LL: { calendar }
    } = useI18nContext()
    const today = moment()
    const selected = moment(value)

    const displayResetToToday = !selected.isSame(today, 'day')
    return (
        <div className="flex gap-4 items-center">
            <div className="flex gap-x-1">
                <UI.Icon
                    onClick={() => onChange(moment(selected).subtract(1, 'day').format('YYYY-MM-DD'))}
                    className="rotate-180 cursor-pointer p-1"
                    icon="specta-arrow-right"
                    size="xs"
                />
                <DatePicker
                    value={value}
                    onChange={d => {
                        onChange(moment(d).format('YYYY-MM-DD'))
                    }}
                    dateFormatOverride={'dd/MM/YYYY'}
                />
                <UI.Icon
                    onClick={() => onChange(moment(selected).add(1, 'day').format('YYYY-MM-DD'))}
                    className="cursor-pointer p-1"
                    icon="specta-arrow-right"
                    size="xs"
                />
            </div>

            <div
                className={`${displayResetToToday ? 'opacity-100' : 'opacity-0'} transition-all duration-300 ease-linear`}
                data-tooltip-id="today-tooltip">
                <IconButton
                    icon="specta-arrow-left-filled"
                    disabled={!displayResetToToday}
                    color={todayBtnColor}
                    onClick={() => onChange(moment().format('YYYY-MM-DD'))}
                />
            </div>
            <Tooltip id="today-tooltip" text={calendar.today()} />
        </div>
    )
}
