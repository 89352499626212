import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import * as colors from '@shared/colors'
import { useCallback, useRef, useState } from 'react'
import {
    type ActionMeta,
    type GroupBase,
    type MultiValueRemoveProps,
    type OnChangeValue,
    type SelectInstance,
    type StylesConfig,
    components
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { match } from 'ts-pattern'

export interface CategorySectionOption {
    label: string
    value: string
}

const RemoveIcon = ({ innerProps }: MultiValueRemoveProps<CategorySectionOption, true, GroupBase<CategorySectionOption>>) => {
    return (
        <div {...innerProps} className="flex items-center justify-center pl-[2px] cursor-pointer">
            <UI.Icon icon="specta-close" size="xxs" color="snap-light-blue" />
        </div>
    )
}

type LabelSelectProps = {
    values: CategorySectionOption[]
    onCreateLabel: (option: CategorySectionOption) => Promise<void>
    onRemoveLabel: (option: CategorySectionOption) => Promise<void>
}

const LabelSelect = ({ values, onCreateLabel, onRemoveLabel }: LabelSelectProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const selectRef = useRef<SelectInstance<CategorySectionOption, true, GroupBase<CategorySectionOption>>>(null)

    const {
        LL: { settingsWeb }
    } = useI18nContext()

    const customStyles: StylesConfig<CategorySectionOption, true, GroupBase<CategorySectionOption>> = {
        control: provided => ({
            ...provided,
            padding: 0,
            border: 'none',
            boxShadow: 'none',
            '&:hover': { borderColor: '#aaa' },
            backgroundColor: 'white',
            display: 'flex',
            flexWrap: 'nowrap',
            overflow: 'hidden'
        }),
        clearIndicator: provided => ({
            ...provided
        }),
        placeholder: provided => ({
            ...provided,
            paddingLeft: '0.5em'
        }),
        input: provided => ({
            ...provided,
            border: 'none',
            margin: 0,
            marginLeft: '0.5em',
            padding: 0
        }),
        valueContainer: provided => ({
            ...provided,
            padding: '0px !important'
        }),
        multiValue: provided => ({
            ...provided,
            backgroundColor: 'transparent',
            border: `1px solid ${colors.SnapColors.lightBlue}`,
            borderRadius: '10px',
            marginRight: '6px',

            display: 'flex',
            padding: '2px 10px 6px 10px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px'
        }),
        multiValueRemove: () => ({
            ':active': {
                backgroundColor: 'purple'
            }
        }),
        indicatorsContainer: () => ({
            display: 'none' // Hide dropdown indicators
        }),
        menu: () => ({
            display: 'none' // Hide dropdown menu
        })
    }

    const onChangeFinished = useCallback(() => {
        setIsLoading(false)
        setTimeout(() => {
            selectRef.current?.inputRef?.focus()
        }, 0)
    }, [selectRef])

    const onChange: (newValue: OnChangeValue<CategorySectionOption, boolean>, actionMeta: ActionMeta<CategorySectionOption>) => void =
        useCallback(
            async (newValue, action) => {
                console.log('onChange', newValue, action)

                match(action.action)
                    .with('create-option', () => {
                        if (!action.option) {
                            return
                        }
                        setIsLoading(true)
                        onCreateLabel({
                            label: action.option.label.trim(),
                            value: action.option.value.trim()
                        }).finally(() => {
                            onChangeFinished()
                        })
                    })
                    .with('remove-value', () => {
                        if (!action.removedValue) {
                            return
                        }
                        setIsLoading(true)
                        onRemoveLabel(action.removedValue).finally(() => {
                            onChangeFinished()
                        })
                    })
                    .with('clear', () => {
                        Promise.all(action.removedValues?.map(onRemoveLabel) ?? []).finally(() => {
                            onChangeFinished()
                        })
                    })
                    .with('pop-value', () => {
                        if (!action.removedValue) {
                            return
                        }
                        setIsLoading(true)
                        onRemoveLabel(action.removedValue).finally(() => {
                            onChangeFinished()
                        })
                    })
                    .with('select-option', () => {
                        console.log('SELECT', action)
                    })
                    .with('deselect-option', () => {
                        console.log('DESELECT', action)
                    })
                    .exhaustive()
            },
            [onChangeFinished, onCreateLabel, onRemoveLabel]
        )

    return (
        <CreatableSelect<CategorySectionOption, true, GroupBase<CategorySectionOption>>
            ref={selectRef}
            autoFocus
            placeholder={settingsWeb.categories.addTagPlaceholder()}
            components={{
                MultiValueRemove: props => <RemoveIcon {...props} />,
                MultiValueLabel: props => (
                    <div {...props.innerProps} className="align-center justify-center p-0">
                        <UI.Text size="sm" weight="bold" color="snap-light-blue" height="normal">
                            {props.children}
                        </UI.Text>
                    </div>
                ),
                Input: props => (
                    <>
                        {isLoading ? (
                            <div className="h-[20px] aspect-square inline-block" style={{ fill: colors.SnapColors.lightBlue }}>
                                <UI.Loader />
                            </div>
                        ) : (
                            <components.Input {...props} />
                        )}
                    </>
                ),
                Placeholder: props => <>{!isLoading && <components.Placeholder {...props} />}</>
            }}
            styles={customStyles}
            isMulti
            onChange={onChange}
            isValidNewOption={option => option.trim().length > 0 && !values.map(val => val.value).includes(option.trim())}
            value={values}
            noOptionsMessage={() => null}
        />
    )
}

export { LabelSelect }
