import type { Firebase } from '@shared/firebase'
import { IssueStruct } from '@shared/firestore-structs'
import { changeIssueName } from '@shared/issue-data'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useIssueData } from './use-issues'

export function useRenameIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (issueKey: string, newName: string) => {
        try {
            await changeIssueName(firebase, {
                currentUser,
                issueKey,
                name: newName
            })
        } catch (error: unknown) {
            //TODO: add proper error handling
            console.error(error)
            throw new Error('Error adding hashtags to issue', { cause: error })
        }
    }
}
