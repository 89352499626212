import { useEffect } from 'react'

const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

function isScrollbarVisible() {
    return window.innerWidth > document.documentElement.clientWidth
}

export const useScrollToggle = () => {
    const scrollbarEnabled = isScrollbarVisible()

    const toggleScroll = (hasScroll: boolean) => {
        if (hasScroll) {
            document.body.classList.remove('overflow-hidden')
            document.body.classList.add('overflow-y-scroll')

            if (scrollbarEnabled) {
                document.body.style.paddingRight = '0px'
            }
        } else {
            document.body.classList.add('overflow-hidden')
            document.body.classList.remove('overflow-y-scroll')

            if (scrollbarEnabled) {
                document.body.style.paddingRight = `${scrollbarWidth}px`
            }
        }
    }

    useEffect(() => {
        return () => toggleScroll(false)
    }, [])

    return toggleScroll
}
