import type { Firebase, FirebaseFirestore } from '@shared/firebase'
import type { UserInteractionsStruct } from '@shared/firestore-structs'
import { remapToNumber } from '@shared/projections/projection-ticks'
import moment from 'moment'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { areaSummaryUserActions } from '../../user-actions/area-summary-user-actions'
import { housekeepingOverviewUserActions } from '../../user-actions/housekeeping-overview-actions'
import { useCurrentOrg } from '../auth/hooks/use-auth-state'
import { areasHousekeepingSelectorWithAccess } from '../auth/state/login'
import { areaGlobal, areaSummary } from './area-summary/state/area-summary-state'
import { areaSummaryDate } from './date/state/date-selection-state'
import { housekeepingOverview, housekeepingOverviewDate } from './housekeeping-overview/state/housekeeping-overview-state'
import { cleaningTaskNameAtom } from './housekeeping-overview/state/mass-assigning-state'
import { areaSummaryLocalOverwrite } from './local-override/area-summary-local-overwrite'
import { housekeepingLocalOverride } from './local-override/housekeeping-local-override'

type SkipFirstThree<T extends any[]> = T extends [any, any, any, ...infer U] ? U : never
type SkipFirstFour<T extends any[]> = T extends [any, any, any, any, ...infer U] ? U : never

const updateUserInteraction = async (
    firestore: FirebaseFirestore,
    date: string,
    orgKey: string,
    areas: { areaKey: string; ticks: number }[]
) => {
    const userInteractionRef = firestore
        .collection<{ ticks: number }>('user-interactions')
        .doc(orgKey)
        .collection('dates')
        .doc(date)
        .collection<{ ticks: number }>('areas')

    try {
        const ticksRemote = await firestore.runTransaction(async transaction => {
            return Promise.all(
                areas.map(async area => {
                    const interactions = await transaction.get(userInteractionRef.doc(area.areaKey))
                    const areaTicks = remapToNumber(area.ticks)
                    if (!interactions.exists) {
                        transaction.set(userInteractionRef.doc(area.areaKey), { ticks: areaTicks }, { merge: true })
                        return { ticks: areaTicks, areaKey: area.areaKey }
                    } else {
                        const currentUserInter = interactions.data() as UserInteractionsStruct
                        const dbTicks = typeof currentUserInter.ticks === 'number' ? remapToNumber(currentUserInter.ticks) : 0
                        console.log(`DB ticks for ${area.areaKey} is ${dbTicks}`)
                        const newTick = Math.max(dbTicks, areaTicks) + 1
                        console.log(`Setting remote tick for ${area.areaKey} to ${newTick}`)
                        transaction.set(userInteractionRef.doc(area.areaKey), { ticks: newTick }, { merge: true })
                        return { ticks: newTick, areaKey: area.areaKey }
                    }
                })
            )
        })
        console.log('Transaction successfully committed!')
        return ticksRemote
    } catch (e) {
        console.error('Transaction failed: ', e)
        return areas
    }
}

export function useUserAreaSummaryAction<Action extends keyof typeof areaSummaryUserActions>(action: Action, firebase: Firebase) {
    const update = useSetRecoilState(areaSummaryLocalOverwrite)
    const currentOrg = useCurrentOrg()
    const areaSummaryCurrent = useRecoilValue(areaSummary)
    const areaCurrent = useRecoilValue(areaGlobal)
    const date = useRecoilValue(areaSummaryDate)

    return function (...args: SkipFirstThree<Parameters<(typeof areaSummaryUserActions)[Action]['applyAction']>>) {
        const change = areaSummaryUserActions[action].applyAction(
            { areaSummary: areaSummaryCurrent, area: areaCurrent },
            moment(date),
            firebase.firestore(),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...args,
            currentOrg
        )
        const updateAreaSummaryTicks = remapToNumber(areaSummaryCurrent.ticks) + 1
        const updateAreaGlobalTicks = remapToNumber(areaCurrent.ticks) + 1
        update({
            ...(change.overview ? { overview: { ...change.overview, ticks: updateAreaSummaryTicks } } : {}),
            ...(change.area ? { area: { ...change.area, ticks: updateAreaGlobalTicks } } : {}),
            areaSummary: { ...change.areaSummary, ticks: updateAreaSummaryTicks }
        })
        change.io.then(() => {
            console.log(`Change to Firestore performed.`)
            return Promise.all(
                [
                    updateUserInteraction(firebase.firestore(), moment(date).format('YYYY-MM-DD'), currentOrg.key, [
                        {
                            areaKey: areaSummaryCurrent.area.key,
                            ticks: updateAreaSummaryTicks
                        }
                    ])
                ]
                    .concat(
                        change.area
                            ? [
                                  updateUserInteraction(firebase.firestore(), 'global', currentOrg.key, [
                                      { areaKey: areaSummaryCurrent.area.key, ticks: updateAreaGlobalTicks }
                                  ])
                              ]
                            : []
                    )
                    .flat()
            )
                .then(ticksServer => {
                    const newUpdateTicks = ticksServer[0][0].ticks
                    console.log(
                        `Ticks server for date ${moment(date).format(
                            'YYYY-MM-DD'
                        )}: ${newUpdateTicks}} and local: ${updateAreaSummaryTicks}`
                    )

                    if (newUpdateTicks !== updateAreaSummaryTicks) {
                        console.log(`Updating summary and overview ticks for ${date} from ${updateAreaSummaryTicks} to ${newUpdateTicks}`)
                        update({
                            ...(change.overview ? { overview: { ticks: newUpdateTicks } } : {}),
                            areaSummary: { ticks: newUpdateTicks }
                        })
                    }
                    if (change.area && ticksServer[1][0].ticks !== updateAreaGlobalTicks) {
                        console.log(`Updating area ticks for ${date} from ${updateAreaGlobalTicks} to ${ticksServer[1][0].ticks}`)
                        update({
                            area: { ticks: ticksServer[1][0].ticks }
                        })
                    }

                    change.area &&
                        console.log(`Ticks server for area global: ${ticksServer[1][0].ticks} and local: ${updateAreaGlobalTicks}`)
                })
                .catch(e => console.error(e))
        })
    }
}

export function useUserHousekeepingOverviewAction<Action extends keyof typeof housekeepingOverviewUserActions>(
    action: Action,
    firebase: Firebase
) {
    const update = useSetRecoilState(housekeepingLocalOverride)
    const housekeepingOverviewCurrent = useRecoilValue(housekeepingOverview)
    const date = useRecoilValue(housekeepingOverviewDate)
    const areas = useRecoilValue(areasHousekeepingSelectorWithAccess)
    const currentOrg = useCurrentOrg()
    const taskName = useRecoilValue(cleaningTaskNameAtom)

    return async function (...args: SkipFirstFour<Parameters<(typeof housekeepingOverviewUserActions)[Action]['applyAction']>>) {
        const change = housekeepingOverviewUserActions[action].applyAction(
            { overview: housekeepingOverviewCurrent, areas: areas, taskName },
            currentOrg,
            moment(date),
            firebase,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...args
        )

        function getNewTicksForAreas<T>(entries: { [key: string]: T }) {
            return Object.fromEntries(
                Object.entries(entries).map(([areaKey, area]) => [
                    areaKey,
                    { ...area, ticks: housekeepingOverviewCurrent[areaKey].ticks + 1 }
                ])
            )
        }

        const newTicksForAreas = getNewTicksForAreas(change.areas)
        const newTicksForOverviews = getNewTicksForAreas(change.overview)

        update({ overview: newTicksForOverviews, areas: { ...newTicksForAreas } })
        change.io.then(() => {
            console.log(`Successfully performed change to Firestore.`)
            return updateUserInteraction(
                firebase.firestore(),
                moment(date).format('YYYY-MM-DD'),
                currentOrg.key,
                Object.entries(newTicksForAreas).map(([areaKey, area]) => ({
                    areaKey,
                    ticks: area.ticks
                }))
            )
                .then(ticksServer => {
                    ticksServer
                        .map(area => [area.areaKey, area.ticks] as const)
                        .forEach(([areaKey, ticks]) => {
                            if (ticks !== housekeepingOverviewCurrent[areaKey].ticks + 1) {
                                console.log(
                                    `Updating ticks for area ${areaKey} from ${housekeepingOverviewCurrent[areaKey].ticks} to ${ticks}`
                                )
                                update({
                                    areas: { [areaKey]: { ticks } },
                                    overview: { [areaKey]: { ticks } }
                                })
                            }
                        })
                })
                .catch(e => console.error(e))
        })
    }
}
