import type { Firebase } from '@shared/firebase'
import type { RuleStruct, UserStruct } from '@shared/firestore-structs'
import { useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUsers } from '../../../auth/hooks/use-users'
import { useUserAreaSummaryAction } from '../../use-user-action'

export function useAreaSummaryAssign(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const users = useUsers()
    const action = useUserAreaSummaryAction('assign-to-user', firebase)

    function assign(userKeys: string[] = [], ruleOverride?: RuleStruct) {
        const assignedTo = userKeys
            .map(key => users.find(user => user.key === key))
            .filter((user): user is UserStruct => user !== undefined)

        action(assignedTo, currentUser, ruleOverride)
    }

    return assign
}
