import { atom, selector, selectorFamily } from 'recoil'
import type { Translation } from '../../../../../i18n/i18n-types'
import type { Icon } from '../../../../types/visuals.types'
import { TaskView, currentChosenTaskViewAtom, myTasksSelector } from '../../../my-tasks/state/my-tasks'
import { areasBoxesSelector } from './housekeeping-overview-state'

export interface BaseNote {
    areaName: string
    group: string
    note: string
}

export interface Note extends BaseNote {
    quantity?: number
}

export interface NotesTab {
    icon: Icon
    title: keyof Translation['commentAndNotes']
    count: number
    type: 'daily-comment' | 'area-note' | 'booking-note' | 'extra'
    isActiveTab: boolean
}

export const notesTabsTypes: Record<string, NotesTab['type']> = {
    dailyComment: 'daily-comment',
    areaNote: 'area-note',
    bookingNote: 'booking-note',
    extra: 'extra'
}

export const dailyCommentsSelector = selector<Note[]>({
    key: 'housekeeping-overview-dailyComments-selector',
    get: ({ get }) => {
        return get(areasBoxesSelector).reduce<Note[]>((acc, areaBox) => {
            if (areaBox.dailyComments && areaBox.dailyComments.length > 0) {
                const [activeDailyComment] = areaBox.dailyComments

                acc.push({
                    note: activeDailyComment.comment,
                    areaName: areaBox.name,
                    group: areaBox.group
                })
            }

            return acc
        }, [])
    }
})

export const areaNotesSelector = selector<Note[]>({
    key: 'housekeeping-overview-areaNotes-selector',
    get: ({ get }) => {
        return get(areasBoxesSelector).reduce<Note[]>((acc, areaBox) => {
            if (areaBox.note) {
                acc.push({
                    areaName: areaBox.name,
                    group: areaBox.group,
                    note: areaBox.note
                })
            }

            return acc
        }, [])
    }
})

export const bookingNotesSelector = selector<Note[]>({
    key: 'housekeeping-overview-bookingNotes-selector',
    get: ({ get }) => {
        return get(areasBoxesSelector).reduce<Note[]>((acc, areaBox) => {
            if (areaBox.leadBooking && areaBox.leadBooking.notes) {
                acc.push({
                    areaName: areaBox.name,
                    group: areaBox.group,
                    note: areaBox.leadBooking.notes
                })
            }

            return acc
        }, [])
    }
})

export const extrasSelector = selector<Note[]>({
    key: 'housekeeping-overview-extras-selector',
    get: ({ get }) => {
        return get(areasBoxesSelector).reduce<Note[]>((acc, areaBox) => {
            if (areaBox.extras && areaBox.extras.length > 0) {
                const [activeExtra] = areaBox.extras

                acc.push({
                    areaName: areaBox.name,
                    group: areaBox.group,
                    note: activeExtra.name,
                    quantity: activeExtra.quantity
                })
            }

            return acc
        }, [])
    }
})

export const selectedTabTypeAtom = atom<NotesTab['type']>({
    key: 'housekeeping-overview-selected-tab-type',
    default: notesTabsTypes.dailyComment
})

export const tabsSelector = selectorFamily<NotesTab[], boolean>({
    key: 'housekeeping-overview-tabs-selector',
    get:
        isTaskView =>
        ({ get }) => {
            const dailyComments = get(dailyCommentsSelector)
            const areaNotes = get(areaNotesSelector)
            const bookingNotes = get(bookingNotesSelector)
            const extras = get(extrasSelector)
            const selectedTabType = get(selectedTabTypeAtom)
            const chosenTaskView = get(currentChosenTaskViewAtom)
            const shouldBeFiltered = isTaskView && chosenTaskView === TaskView.myTasks
            const { due, overdue } = shouldBeFiltered ? get(myTasksSelector) : { due: [], overdue: [] }

            const filterNotes = (notes: Note[]) => {
                return shouldBeFiltered ? notes.filter(note => [...due, ...overdue].some(task => task.area?.name === note.areaName)) : notes
            }

            return [
                {
                    icon: 'specta-daily-comment',
                    title: 'dailyComments',
                    count: filterNotes(dailyComments).length,
                    type: notesTabsTypes.dailyComment,
                    isActiveTab: selectedTabType === 'daily-comment'
                },
                {
                    icon: 'specta-note',
                    title: 'unitNotes',
                    count: filterNotes(areaNotes).length,
                    type: notesTabsTypes.areaNote,
                    isActiveTab: selectedTabType === 'area-note'
                },
                {
                    icon: 'specta-booking-note',
                    title: 'bookingNotes',
                    count: filterNotes(bookingNotes).length,
                    type: notesTabsTypes.bookingNote,
                    isActiveTab: selectedTabType === 'booking-note'
                },
                {
                    icon: 'specta-extras',
                    title: 'extra',
                    count: filterNotes(extras).length,
                    type: notesTabsTypes.extra,
                    isActiveTab: selectedTabType === 'extra'
                }
            ]
        }
})
