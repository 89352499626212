import type { Firebase } from '@shared/firebase'
import type { IssueStruct } from '@shared/firestore-structs'
import { deleteIssue } from '@shared/issue-data'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useSetIssueState } from './use-issues'

export function useDeleteIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const setIssueState = useSetIssueState()

    return async (issueKey: IssueStruct['key']) => {
        setIssueState({ key: issueKey, status: 'deleted' })

        try {
            await deleteIssue(firebase, { issueKey, currentUser })
        } catch (error) {
            //TODO: proper error handling
            console.error(error)
            throw new Error('Error deleting issue')
        }
    }
}

export function useMassDeleteIssues(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const setIssueState = useSetIssueState()

    return async (issues: IssueStruct[]) => {
        issues.forEach(issue => {
            setIssueState({ key: issue.key, status: 'deleted' })
        })

        const db = firebase.firestore()
        const batch = db.batch()

        try {
            await Promise.all([
                ...issues.map(issue =>
                    deleteIssue(
                        firebase,
                        {
                            issueKey: issue.key,
                            currentUser
                        },
                        batch
                    )
                )
            ])

            await batch.commit()
        } catch (error) {
            console.error(error)
            throw new Error('Failed to mass delete issues')
        }
    }
}
