import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import type { HousekeepingOverviewCombined } from '../../../../user-actions/housekeeping-overview-actions'
import { iconsPositionResolver, pickAreaIcons, pickCleaningStatusColor } from '../../../../utils/housekeeping-utils'
import { areaRuleSelector } from '../../area-summary/state/area-summary-state'
import { useSearchFilter } from '../ui/search-filter'

export function useAreaBox({ areaKey, areaBoxSummary }: { areaKey: string; areaBoxSummary: HousekeepingOverviewCombined[0] }) {
    const { setSearchTerm } = useSearchFilter()
    const rules = useRecoilValue(areaRuleSelector(areaKey))
    const {
        name,
        guestCheckedIn,
        occupancy,
        cleaningStatus,
        dailyComments,
        priority,
        note,
        stays,
        assignedTo: assigned,
        activeRule
    } = areaBoxSummary
    const icons = useMemo(
        () =>
            pickAreaIcons({
                occupancy,
                cleaningStatus,
                guestCheckedIn,
                note,
                dailyComments,
                rules
            }),
        [occupancy, cleaningStatus, guestCheckedIn, note, dailyComments, rules]
    )
    const color = useMemo(() => pickCleaningStatusColor({ cleaningStatus, occupancy }), [cleaningStatus, occupancy])
    const assignedTo = useMemo(() => assigned, [assigned]) ?? []
    const { top, bottom } = useMemo(() => iconsPositionResolver(icons), [icons])
    const customChar = activeRule?.customChar

    const hasTopIcons = useMemo(() => top.length > 0, [top.length])
    const hasBottomIcons = useMemo(() => bottom.length > 0, [bottom.length])
    return {
        name,
        bookingsProblems: stays ? stays.length > 2 : false,
        assignedTo,
        color,
        customChar,
        priority,
        cleaningStatus,
        topIcons: top,
        bottomIcons: bottom,
        hasTopIcons,
        hasBottomIcons,
        setSearchTerm
    }
}
