import type { ProjectionMetadata } from '@shared/projections-v2'
import type moment from 'moment'
import type { Moment } from 'moment'
import { DefaultValue, type RecoilState, type RecoilValueReadOnly, atom, atomFamily, selector, selectorFamily } from 'recoil'

// This overrideableState function is used to create a Recoil state that support local overrides. The overrides can be set for a specific date and will be used instead of the backend value when reading the state.
export function overrideableState<
    T extends ProjectionMetadata,
    Out = T,
    Updates extends Partial<T> & ProjectionMetadata = Partial<T> & ProjectionMetadata
>({
    id,
    dateSelector,
    backendSelector,
    assembleOutput,
    overridesMerger,
    overrideDiscarder
}: {
    id: string
    dateSelector: RecoilState<moment.Moment>
    backendSelector: (backend: RecoilState<{ [key: string]: T }>) => RecoilValueReadOnly<{ [key: string]: Out }>
    assembleOutput: (backend: Out, override: Updates) => Out // Layers the accumulated overrides on top of the backend value and returns the result
    overridesMerger: (prev: Updates, incoming: Updates) => Updates // Merges two overrides together. Used when setting a new override
    overrideDiscarder: (backend: T, override: Updates, id: string) => 'discard' | 'keep'
}) {
    const backendAtom = atom<{ [key: string]: T }>({
        key: `${id}-backendAtom`,
        default: {}
    })

    const backendGetter = backendSelector(backendAtom)

    const overrideAtom = atomFamily<{ [key: string]: Updates[] }, string>({
        key: `${id}-overrideAtom`,
        default: {}
    })

    const resolverValue = selector<{ [key: string]: Out }>({
        key: `${id}-resolver`,
        get: ({ get }) => {
            const date = get(dateSelector)
            const backend = get(backendGetter)
            const key = date.format('YYYY-MM-DD')
            const overrides = get(overrideAtom(key))

            if (!overrides || Object.keys(overrides).length === 0) {
                return backend
            }

            return Object.fromEntries(
                Object.entries(backend).map(([key, value]) => {
                    const override = overrides[key]
                    if (!override) {
                        return [key, value]
                    } else {
                        console.debug(`<${id}>: Using local override for ${key} - ${JSON.stringify(override)}`)
                        return [key, assembleOutput(value, override.reduce(overridesMerger))]
                    }
                })
            )
        }
    })

    const backendSetter = selector<{ [key: string]: T }>({
        key: `${id}-backend`,
        set: ({ set, get }, newValue) => {
            const date = get(dateSelector)
            const key = date.format('YYYY-MM-DD')
            const overrides = overrideAtom(key)
            const override = get(overrides)
            if (!(newValue instanceof DefaultValue)) {
                const newOverrides = Object.entries(override).reduce(
                    (acc, [key, value]) => {
                        const filteredValues = newValue[key]
                            ? value.filter(v => {
                                  const keep = overrideDiscarder(newValue[key], v, key) === 'keep'
                                  if (keep) {
                                      console.debug(`<${id}>: Keeping override for ${key} - ${JSON.stringify(v)}`)
                                  } else {
                                      console.debug(`<${id}>: Discarding override for ${key}`)
                                  }
                                  return keep
                              })
                            : value
                        if (filteredValues.length === 0) {
                            delete acc[key]
                            return acc
                        } else {
                            acc[key] = filteredValues
                            return acc
                        }
                    },
                    {} as { [key: string]: Updates[] }
                )
                set(overrides, newOverrides)
                set(backendAtom, newValue)
            }
        },
        get: () => {
            throw new Error('Do not use this selector for reading. Writing only.')
        }
    })
    const frontendSetter = selectorFamily<{ [key: string]: Updates }, Moment>({
        key: `${id}-frontend`,
        get: date => () => {
            throw new Error('Do not use this selector for reading. Writing only.')
        },
        set:
            date =>
            ({ set, get }, newValue) => {
                if (newValue instanceof DefaultValue) {
                    throw new Error('Cannot reset local override')
                } else {
                    const key = date.format('YYYY-MM-DD')
                    const overrideSelector = overrideAtom(key)
                    const currentOverrides = get(overrideSelector)

                    if (newValue) {
                        const mergedOverride = Object.entries(newValue).reduce(
                            (acc, [key, value]) => {
                                const updatingLastChangeWithTicks = Object.keys(value).length === 1 && Object.keys(value)[0] === 'ticks'
                                let result = currentOverrides[key]
                                if (updatingLastChangeWithTicks && result) {
                                    const last = result.at(-1)
                                    result = [...result.slice(0, -1), { ...last!, ticks: value.ticks }]
                                } else {
                                    result = result ? result.concat(value) : [value]
                                }
                                acc[key] = result
                                return acc
                            },
                            {} as { [key: string]: Updates[] }
                        )
                        const combined = { ...currentOverrides, ...mergedOverride }
                        console.debug(`${id}: Setting local override for ${key} - ${JSON.stringify(newValue)}.`)
                        set(overrideSelector, combined)
                    }
                }
            }
    })
    return { resolverValue, backendSetter, frontendSetter }
}
