import type { IssueStruct, UserStruct } from '@shared/firestore-structs'
import { cleanHashTags, getHashTags } from '@shared/helpers'
import notDownloaded from '@shared/img/not-downloaded.svg'
import moment from 'moment'
import { useMemo } from 'react'
import { useI18nContext } from '../../../../i18n/i18n-react'
import { UI } from '../../../registry/ui-elements-registry'
import type { Colors } from '../../../types/visuals.types'
import { useCurrentOrg } from '../../auth/hooks/use-auth-state'
import { RoundedButton } from '../../molecules/rounded-button'
import { getIssueNumberColor } from '../utils'

export interface IssueImageRenderer {
    priority: IssueStruct['priority']
    src: 'specta-text-issue' | string
}

export interface DueDateRenderer {
    date: string
    icon: 'specta-duedate'
    overdue: boolean
}

export interface AssignedToBlockRenderer {
    assignees: { name: string; initials: string; key: string }[]
    onUnassign?: () => void
    disabled?: boolean
}

export interface ImageItemRenderer {
    src: string
    thumbUrl: string
    text: string
    first: boolean
    itemKey: string
    onDelete: () => void
    onTextChange: (text: string) => void
}

interface NameRenderer {
    name: string
    hashtags: string[]
}

interface AssignedToRenderer {
    name: string
    initials: string
    index: number
}

export function IssueImage({
    lastThumbUrl,
    isImagesLoading,
    lastItemKey,
    priority,
    Render
}: {
    lastThumbUrl: IssueStruct['lastThumbUrl']
    isImagesLoading: IssueStruct['isImagesLoading']
    lastItemKey: IssueStruct['lastItemKey']
    priority: IssueStruct['priority']
    Render: (props: IssueImageRenderer) => JSX.Element
}) {
    if (isImagesLoading) {
        return <UI.Loader />
    }

    if (lastItemKey === 'text-issue') {
        return <Render priority={priority} src={'specta-text-issue'} />
    }

    if (lastThumbUrl && lastThumbUrl.length >= 5 && (lastThumbUrl.startsWith('http') || lastThumbUrl.startsWith('file'))) {
        return <Render priority={priority} src={lastThumbUrl} />
    }

    return <Render priority={priority} src={notDownloaded} />
}

export function AreaInfo({ issueArea, Render }: { issueArea: IssueStruct['area']; Render: (props: { text: string }) => JSX.Element }) {
    return <Render text={`${issueArea.group} > ${issueArea.name}`} />
}

export function IssueNumber({
    issueNumber,
    status,
    Render
}: {
    issueNumber: IssueStruct['issueNumber']
    status: IssueStruct['status']
    Render: (props: { text: string; color: Colors }) => JSX.Element
}) {
    const color = useMemo(() => getIssueNumberColor(status), [status])
    if (!issueNumber) return null

    return <Render text={issueNumber} color={color} />
}

export function Name({ name, Render }: { name: IssueStruct['name']; Render: (props: NameRenderer) => JSX.Element }) {
    const issueName = useMemo(() => cleanHashTags(name), [name])
    const hashTags = useMemo(() => getHashTags(name), [name])

    return <Render name={issueName} hashtags={hashTags} />
}

export function DueDate({
    dueDate,
    Render,
    renderNoDueDate = true
}: {
    dueDate: IssueStruct['startDate']
    Render: (props: DueDateRenderer) => JSX.Element
    renderNoDueDate?: boolean
}) {
    const overdue = useMemo(() => moment(dueDate).diff(moment().startOf('day'), 'days') < 0, [dueDate])
    const date = useMemo(() => dueDate && moment(dueDate).format('MMM D'), [dueDate])
    const { LL } = useI18nContext()

    return date ? (
        <Render date={date} overdue={overdue} icon="specta-duedate" />
    ) : (
        <>
            {renderNoDueDate && (
                <UI.Text size="sm" color="snap-red">
                    {LL.shared.itemsMetadata.noDueDate()}
                </UI.Text>
            )}
        </>
    )
}

export function ChangedInfo({ updated, Render }: { updated: IssueStruct['updated']; Render: (props: { text: string }) => JSX.Element }) {
    const currentOrg = useCurrentOrg()
    const lastUpdatedTime = useMemo(() => moment.tz(updated, currentOrg.timezone).format('HH:mm'), [updated])
    const lastUpdated = useMemo(() => moment.tz(updated, currentOrg.timezone).format('ddd, MMM D, YYYY'), [updated])
    const { LL } = useI18nContext()

    return <Render text={LL.shared.itemsMetadata.changedInfo({ lastUpdatedTime, lastUpdated })} />
}

export function AssignedTo({
    assignedContacts,
    Render
}: {
    assignedContacts: IssueStruct['assignedTo']
    Render: (props: AssignedToRenderer) => JSX.Element
}) {
    if (!assignedContacts) return null

    return (
        <UI.List
            items={assignedContacts}
            render={(user, index) => {
                return <Render key={index} name={user.name} initials={user.initials} index={index} />
            }}
        />
    )
}

export function CreatedInfo({ created, Render }: { created: IssueStruct['created']; Render: (props: { text: string }) => JSX.Element }) {
    const createdDate = useMemo(() => moment(created).format('ddd, MMM D, YYYY'), [created])
    const { LL } = useI18nContext()

    return <Render text={LL.shared.itemsMetadata.createdInfo({ createdDate })} />
}

export function Assignees({
    assignedContacts,
    onUnassign,
    Render
}: {
    assignedContacts: Pick<UserStruct, 'key' | 'name' | 'initials'>[] | null | undefined
    onUnassign?: () => void
    Render: (props: AssignedToBlockRenderer) => JSX.Element
}) {
    if (!assignedContacts || assignedContacts.length === 0) return null

    return <Render assignees={assignedContacts} onUnassign={onUnassign} />
}

export function ImagesList({
    imageItems,
    Render
}: {
    imageItems: IssueStruct['items']
    Render: (props: ImageItemRenderer) => JSX.Element
}) {
    return (
        <UI.List
            items={imageItems ?? []}
            render={(image, index) => {
                return (
                    <Render
                        key={image.key}
                        itemKey={image.key}
                        first={index === 0}
                        src={image.url}
                        thumbUrl={image.thumbUrl}
                        text={image.text?.content ?? ''}
                        onDelete={() => null}
                        onTextChange={() => null}
                    />
                )
            }}
        />
    )
}

export function AssignButton({ onClick, disabled }: { onClick?: () => void; disabled?: boolean }) {
    return <RoundedButton disabled={disabled} icon="specta-assign-user" onClick={onClick ?? (() => null)} />
}
