import type { Moment } from 'moment-timezone'
import moment from 'moment/moment'
import { clone } from 'ramda'
import { logActivity, updateAreaCleaningStatus } from '../area-data'
import { getCleaningObject, getCleaningTaskObject, getMiniUserObject } from '../dataObjects'
import { isFeatureOn } from '../feature-toggles'
import type { FirebaseFirestore } from '../firebase'
import type {
    ActivityType,
    AreaCleaningStatus,
    AssignedUserInfo,
    CleaningStruct,
    Occupancy,
    OrgStruct,
    RuleStruct,
    TaskStruct,
    UserStruct
} from '../firestore-structs'
import type { AreaSummaryCombined, AreaSummaryStruct_v2 } from '../projections-v2'
import { createTask, generateTaskId, setTaskUpdate } from '../task-data'
import * as c from '../txt-constants'
import { CLEANING_STATUS_INSPECTION, OCCUPANCY_CHECKIN, OCCUPANCY_CHECKOUT, OCCUPANCY_TURNOVER, OCCUPANCY_VACANT } from '../txt-constants'
import type { Needed } from '../type-utils'

function getAssignedTo(currentlyAssignedUsers: AssignedUserInfo[], currentUser: UserStruct) {
    return currentlyAssignedUsers.filter(u => u.key === currentUser.key).length === 0
        ? [getMiniUserObject(currentUser)]
        : currentlyAssignedUsers
}

export function startCleaningInternal(
    firebase: FirebaseFirestore,
    currentTask: AreaSummaryStruct_v2['currentTask'],
    currentUser: UserStruct,
    area: AreaSummaryCombined['area'],
    startDate: Moment,
    ruleOverride?: RuleStruct
) {
    const timestamp = moment().valueOf()
    const newCleaning = clone(getCleaningObject(currentTask?.cleaning))
    const currentlyAssignedUsers = currentTask?.assignedTo ?? []
    const assignedTo = getAssignedTo(currentlyAssignedUsers, currentUser)
    const beforeCleaningStatus = clone(area.cleaningStatus)
    const newStateTask: Needed<Partial<TaskStruct>, 'name' | 'key' | 'startDate' | 'status'> = currentTask
        ? {
              ...currentTask,
              cleaning: newCleaning,
              assignedTo: assignedTo,
              startDate: startDate.valueOf()
          }
        : {
              ...getCleaningTaskObject(currentUser, area, startDate, assignedTo, area.activeRule, newCleaning, ruleOverride),
              key: generateTaskId(firebase)
          }

    newCleaning.play = newCleaning.play || []
    newCleaning.play.push(timestamp)
    newCleaning.lastCleanedBy = assignedTo
    return { newCleaning, assignedTo, beforeCleaningStatus, newStateTask }
}

export function stopCleaningInternal(
    firebase: FirebaseFirestore,
    currentTask: AreaSummaryStruct_v2['currentTask'],
    currentUser: UserStruct,
    area: AreaSummaryCombined['area'],
    startDate: Moment,
    currentOrganization: OrgStruct,
    ruleOverride?: RuleStruct
) {
    const beforeCleaningStatus = clone(area.cleaningStatus)
    const timestamp = moment().valueOf()
    const newCleaning = clone(getCleaningObject(currentTask?.cleaning))
    const assignedTo = getMiniUserObject(currentUser)
    const { cleaningStatus, cleaningAction } = handleInspection(area.cleaningStatus, area.occupancy, currentOrganization, area.inspection)
    const newStateTask: Needed<Partial<TaskStruct>, 'name' | 'key' | 'startDate' | 'status'> = currentTask
        ? { ...currentTask, status: 'completed', cleaning: newCleaning, cleaningStatus }
        : {
              ...getCleaningTaskObject(currentUser, area, startDate, [assignedTo], area.activeRule, newCleaning, ruleOverride),
              cleaningStatus,
              status: 'completed',
              key: generateTaskId(firebase)
          }

    newCleaning.end = timestamp
    newCleaning.stop = newCleaning.stop || []
    newCleaning.stop.push(timestamp)

    return { beforeCleaningStatus, newCleaning, cleaningStatus, cleaningAction, newStateTask }
}

export function pauseCleaningInternal(area: AreaSummaryStruct_v2['area'], currentTask: AreaSummaryStruct_v2['currentTask']) {
    const beforeCleaningStatus = clone(area.cleaningStatus)
    const timestamp = moment().valueOf()
    const newCleaning = clone(getCleaningObject(currentTask?.cleaning))
    const cleaningStatus = 'preparing-pause' as const
    const newTaskState: Needed<Partial<TaskStruct>, 'name' | 'key' | 'startDate' | 'status'> = {
        ...currentTask!,
        cleaning: newCleaning,
        cleaningStatus
    }
    newCleaning.pause = newCleaning.pause || []
    newCleaning.pause.push(timestamp)
    return { beforeCleaningStatus, newCleaning, cleaningStatus, newTaskState }
}

function handleInspection(
    cleaningStatus: AreaCleaningStatus,
    occupancy: Occupancy,
    currentOrganization: OrgStruct,
    inspection?: boolean
): { cleaningStatus: AreaCleaningStatus; cleaningAction: 'cleaning-action-stop' | 'cleaning-action-stop-inspection' } {
    if (
        inspection &&
        cleaningStatus !== CLEANING_STATUS_INSPECTION &&
        ([OCCUPANCY_CHECKOUT, OCCUPANCY_TURNOVER, OCCUPANCY_VACANT, OCCUPANCY_CHECKIN].includes(occupancy) ||
            isFeatureOn(currentOrganization, 'allow-stayover-inspection'))
    ) {
        return { cleaningStatus: 'inspection', cleaningAction: 'cleaning-action-stop' }
    }

    if (cleaningStatus === 'inspection') {
        return { cleaningStatus: 'clean', cleaningAction: 'cleaning-action-stop-inspection' }
    }

    return { cleaningStatus: 'clean', cleaningAction: 'cleaning-action-stop' }
}

export async function persistHousekeepingAction(
    firebase: FirebaseFirestore,
    currentTaskKey: string | null,
    currentUser: UserStruct,
    newCleaning: CleaningStruct,
    afterCleaningStatus: AreaCleaningStatus,
    cleaningAction: ActivityType,
    beforeCleaningStatus: AreaCleaningStatus,
    area: AreaSummaryCombined['area'],
    taskName: string | undefined,
    startDate: Moment,
    assignedTo?: Pick<UserStruct, 'key' | 'name' | 'initials'>[],
    taskKey?: string,
    activityKey?: string,
    createdTimestamp?: number,
    ruleOverride?: RuleStruct
) {
    const batch = firebase.batch()
    let task: Pick<TaskStruct, 'key'>

    const currentRule = ruleOverride ?? area.activeRule
    const defaultTaskName = currentRule?.name ?? c.TASK_CLEANING_DEFAULT_NAME

    if (currentTaskKey) {
        task = await setTaskUpdate(
            firebase,
            currentUser,
            currentTaskKey,
            {
                cleaning: newCleaning,
                cleaningStatus: afterCleaningStatus,
                assignedTo,
                name: taskName === c.TASK_CLEANING_DEFAULT_NAME ? defaultTaskName : taskName,
                ...(cleaningAction === 'cleaning-action-stop' || cleaningAction === 'cleaning-action-stop-inspection'
                    ? { status: 'completed' }
                    : {})
            },
            batch
        )
        console.log('task updated', {
            task,
            cleaningAction,
            afterCleaningStatus,
            beforeCleaningStatus,
            assignedTo,
            currentTaskKey
        })
    } else {
        const currentRule = ruleOverride ?? area.activeRule
        const defaultTaskName = currentRule?.name ?? c.TASK_CLEANING_DEFAULT_NAME
        task = await createTask<'cleaning'>(
            firebase,
            area,
            currentUser,
            'cleaning',
            {
                name: taskName ?? defaultTaskName,
                startDate,
                cleaning: newCleaning,
                cleaningStatus: afterCleaningStatus,
                ...(cleaningAction === 'cleaning-action-stop' || cleaningAction === 'cleaning-action-stop-inspection'
                    ? { status: 'completed' }
                    : {}),

                assignedTo: assignedTo ?? [getMiniUserObject(currentUser)]
            },
            batch,
            undefined,
            currentRule?.checklistTasks?.map(t => ({ checked: false, name: t })) ?? null,
            taskKey,
            ruleOverride ? { [startDate.valueOf()]: { ruleKey: ruleOverride?.key } } : undefined
        )
        console.log('task created', {
            task,
            cleaningAction,
            afterCleaningStatus,
            beforeCleaningStatus,
            assignedTo,
            currentTaskKey
        })
    }

    await updateAreaCleaningStatus(
        firebase,
        {
            areaKey: area.key,
            cleaningStatus: afterCleaningStatus,
            currentUser,
            taskKey: task.key,
            cleaningPriority: area.cleaningPriority
        },
        batch
    )
    await logActivity(
        firebase,
        currentUser,
        area.key,
        cleaningAction,
        startDate.valueOf(),
        {
            field: 'cleaningStatus',
            before: beforeCleaningStatus,
            after: afterCleaningStatus
        },
        batch,
        activityKey,
        createdTimestamp
    )

    await batch.commit()
}
