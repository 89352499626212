import { deleteArea } from '@shared/area-data'
import type { Firebase } from '@shared/firebase'
import type { AreaStruct } from '@shared/firestore-structs'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useSelectedUnits, useUnits } from './use-units'

export function useDeleteUnit(firebase: Firebase) {
    const currentUser = useCurrentUser()
    async function deleteUnit(areaKey: string) {
        try {
            await deleteArea(firebase, areaKey, currentUser)
        } catch (error) {
            console.error(error)
        }
    }
    return deleteUnit
}

export function useMassDeleteUnits(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const [units, setUnits] = useUnits()
    const [, setSelectedUnits] = useSelectedUnits()

    async function deleteUnits(selectedUnits: Partial<AreaStruct>[]) {
        const batch = firebase.firestore().batch()

        try {
            await Promise.all(
                selectedUnits.map(({ key }) => {
                    if (!key) return
                    return deleteArea(firebase, key, currentUser, batch)
                })
            )
        } catch (error) {
            console.error(error)
        } finally {
            await batch.commit()
            setUnits(units.filter(unit => !selectedUnits.some(selectedUnit => selectedUnit.key === unit.key)))
            setSelectedUnits([])
        }
    }

    return deleteUnits
}
