import TypesafeI18n, { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useAuthListener } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useSetTaskboardContext, useTaskboardContext } from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import { useSetTracesQueryParams } from '@shared-snap/snap/components/traces/hooks/use-traces-integration-params'
import { getBrand } from '@shared/brand'
import { type ApaleoQueryParams, TaskboardContext } from '@shared/traces-types'
import { Outlet, createRootRouteWithContext, useRouter } from '@tanstack/react-router'
import { useEffect } from 'react'
import { IntercomProvider } from 'react-use-intercom'
import firebase, { asFirebase } from '../app/firebase'

const appId = getBrand().intercomAppId || ''

export interface RouterContext {
    isAuthenticated: boolean
}

export const prefix = '/snap' as const

export const Route = createRootRouteWithContext<RouterContext>()({
    component: () => {
        const setQueryParams = useSetTracesQueryParams()
        const defineAndSetTaskboardContext = useSetTaskboardContext()
        const router = useRouter()
        const urlParams = new URLSearchParams(router.state.location.searchStr)

        const searchParams: ApaleoQueryParams = {
            propertyId: urlParams.get('propertyId') ?? null,
            reservationId: urlParams.get('reservationId') ?? null,
            accountCode: urlParams.get('accountCode') ?? null,
            subjectId: urlParams.get('subjectId') ?? null
        }

        useEffect(() => {
            if (Object.values(searchParams).some(v => !!v)) {
                const context = defineAndSetTaskboardContext(router.state.location.pathname, searchParams)
                if (context !== TaskboardContext.EMBEDDED) {
                    setQueryParams(searchParams)
                }
            }
        }, [router.state.location.pathname, router.state.location.searchStr])
        return <Root />
    }
})

function Root() {
    useAuthListener(firebase.auth().onAuthStateChanged.bind(firebase.auth()), asFirebase(firebase))
    const { locale } = useI18nContext()
    const isEmbeddedInLegacyWeb = process.env.VITE_EMBEDDED

    return (
        <IntercomProvider appId={appId} autoBoot={!isEmbeddedInLegacyWeb}>
            <TypesafeI18n locale={locale}>
                <Outlet />
            </TypesafeI18n>
        </IntercomProvider>
    )
}
