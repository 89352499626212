import type { IconParams } from '@shared-snap/snap/registry/ui-elements-registry'
import { match } from 'ts-pattern'

export function Icon({ icon, size, color, opacity, className, onClick }: IconParams) {
    // 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    const iconSize = match(size ?? 'md')
        .with('2xl', () => 50)
        .with('xl', () => 44)
        .with('lg', () => 32)
        .with('md', () => 20)
        .with('sm', () => 18)
        .with('xs', () => 12)
        .with('xxs', () => 8)
        .exhaustive()

    return (
        <i
            onClick={onClick}
            style={{
                fontSize: iconSize // if [] syntax is not supported
            }}
            className={`${className ?? ''} ${icon} ${color ? `text-${color}` : ''} text-[${iconSize}px] ${
                opacity ? `opacity-${opacity}` : ''
            } `}
        />
    )
}
