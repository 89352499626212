import { UI } from '@shared-snap/snap/registry/ui-elements-registry'

interface Props {
    value: string
    placeholder?: string
    onChange: (value: string) => void
}

export function SearchInput({ value, placeholder, onChange }: Props) {
    return (
        <div className="w-[330px] border-b border-snap-light-silver">
            <div className="w-full flex gap-[8px] items-center h-full mb-[4px]">
                <UI.Icon icon="specta-search" size="sm" color="snap-silver" />
                <input
                    className="w-full placeholder-snap-silver text-snap-black focus:outline-none"
                    value={value}
                    placeholder={placeholder}
                    type="text"
                    onChange={e => onChange(e.target.value)}
                />

                {value.trim().length > 0 && (
                    <UI.Icon
                        className="cursor-pointer"
                        icon={'specta-close'}
                        size={'sm'}
                        color="snap-light-gray"
                        onClick={() => onChange('')}
                    />
                )}
            </div>
        </div>
    )
}
