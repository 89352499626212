import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Note, NotesTab } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/state/comments-and-notes-state'
import { CommentsAndNotesList, Navigation } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/ui/comments-and-notes'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Icon } from '@shared-snap/snap/types/icon-font.enum'
import { useNavigate } from '@tanstack/react-router'
import { DateNav } from 'components/atoms/dateNav'
import MarkdownRenderer from 'components/atoms/markdown-renderer'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { prefix } from '../../../routes/__root'

export function CommentsAndNotes() {
    const navigate = useNavigate()

    const onClose = () => navigate({ to: `${prefix}/housekeeping` })
    return (
        <Modal onClose={onClose} className="relative overflow-auto w-3/4 h-3/4">
            <ModalHeader onClose={onClose} className="bg-white">
                <div className="flex items-center justify-between mr-12">
                    <div className="inset-0 flex gap-8 bg-white justify-between">
                        <Navigation Tab={Tab} />
                    </div>
                    <DateNav />
                </div>
            </ModalHeader>
            <div className="flex flex-col gap-2 mt-6 px-6">
                <CommentsAndNotesList TitleWithIcon={Title} NoteItem={NoteItem} />
            </div>
        </Modal>
    )
}

function Tab({ icon, title, count, isActiveTab }: NotesTab) {
    const { LL } = useI18nContext()
    const tabColor = isActiveTab ? 'sweeply-red' : 'snap-mid-gray'
    return (
        <div className={`cursor-pointer flex gap-2 items-center ${isActiveTab ? `border-b-2 border-snap-yellow` : ''}`}>
            <UI.Icon color={tabColor} icon={icon} size="md" />
            <UI.Text color={tabColor} weight="bold">
                {LL.commentAndNotes[title]()}
            </UI.Text>
            <QuantityLabel quantity={count} size="md" />
        </div>
    )
}

function NoteItem({ areaName, group, note, quantity }: Note) {
    return (
        <div className="py-2 flex flex-col gap-1 justify-between">
            <QuantityLabel quantity={group} size="sm" />
            <h2 className="text-lg font-bold">{areaName}</h2>
            <span>{quantity && `${quantity}x `}</span>
            <MarkdownRenderer content={note} />
        </div>
    )
}

function Title({ title, icon }: { title: string; icon: Icon }) {
    return (
        <div className="flex gap-2 items-center mb-4">
            <UI.Icon icon={icon} size="md" />
            <h1 className="text-xl font-bold">{title}</h1>
        </div>
    )
}
