import type { Firebase } from '@shared/firebase'
import type { OrgStruct } from '@shared/firestore-structs'
import { useRecoilState } from 'recoil'
import { currentOrgSelector } from '../../auth/state/login'

export function useOrganization(firebase: Firebase) {
    const manageOrganization = firebase.functions().httpsCallable('manageOrganization')
    const [currentOrganization, setCurrentOrganization] = useRecoilState(currentOrgSelector)

    const updateOrganization = async ({ name }: Pick<OrgStruct, 'name'>) => {
        try {
            await manageOrganization({ ...currentOrganization, name })
            await setCurrentOrganization({ ...currentOrganization, name })
        } catch (error) {
            console.error(error)
        }
    }

    return { updateOrganization }
}
