import type { JSX } from 'react'
import type { Colors, Icon } from '../types/visuals.types'
import type { HousekeepingOverviewCombined } from '../user-actions/housekeeping-overview-actions'
import { registrySetter } from './registry-utils'

export interface PrimitivesProps {
    children?: any
    className?: string
    onClick?: () => void
}

export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export interface TextProps extends Omit<PrimitivesProps, 'className'> {
    size?: Size
    style?: 'bold' | 'normal' | 'italic' | 'underline' | 'line-through'
    color?: Colors
    align?: 'left' | 'center' | 'right'
    type?: 'warning' | 'error' | 'info' | 'success'
    weight?: 'normal' | 'medium' | 'semibold' | 'bold'
    height?: 'normal' | 'tight' | 'loose' | 'none'
    truncate?: boolean
}

export interface InitialsCircleProps {
    initials: string
    backgroundColor?: Colors
    name?: string
    size?: Size
}

export interface AreaBoxProps {
    areaKey: string
    areaBoxSummary: HousekeepingOverviewCombined[0]
}

//TODO: define better types
export type Option<T> = {
    value: T
    label: string
}

export interface SelectProps<T = string> {
    options: Option<T>[]
    value: Option<T>
    onChange: (value: T) => void
    CustomOption: (props: { option: Option<T> }) => JSX.Element
    className?: string
}

export interface PressableProps extends Omit<PrimitivesProps, 'onClick'> {
    onPress: () => void
    disabled?: boolean
    type?: 'submit' | 'reset' | 'button' | undefined
}

const undefinedComponent = () => <>***Undefined***</>
export type IconParams = Omit<PrimitivesProps, 'children'> & {
    icon: Icon
    size?: TextProps['size']
    color?: Colors
    backgroundColor?: Colors
    border?: boolean
    opacity?: number
}
export type InputParams = {
    type?: 'string' | 'number' | 'email' | 'password' | 'phone'
    placeholder?: string
    autoCapitalize?: boolean
    value?: string
    onChange: (newValue: string) => void
}
export type ButtonParams = Omit<PrimitivesProps, 'className'> & {
    size?: Size
    disabled?: boolean
    className?: string
    text: string | JSX.Element
    onClick: () => void
}
export type LinkParams = {
    enabled?: boolean
    text: string
    type?: 'primary' | 'secondary' | 'login'
    onClick: () => void
}
type SkeletonProps = {
    className?: string
}

export const UI: {
    AreasGroupLayout: (props: PrimitivesProps & { group: string }) => JSX.Element // TODO: remove layouts from registry
    AreaBox: (props: Omit<PrimitivesProps, 'children'> & AreaBoxProps) => JSX.Element
    Button: (props: ButtonParams) => JSX.Element
    Link: (props: LinkParams) => JSX.Element
    ErrorMessage: (props: PrimitivesProps) => JSX.Element
    Icon: (props: IconParams) => JSX.Element
    InitialsCircle: (props: Omit<PrimitivesProps, 'children'> & InitialsCircleProps) => JSX.Element
    List: <T>(props: { items: T[]; render: (item: T, index: number, items: T[]) => JSX.Element }) => JSX.Element | null
    Loader: (props: Omit<PrimitivesProps, 'children'> & { size?: Size }) => JSX.Element
    Pressable: (props: PressableProps) => JSX.Element
    SectionedList: <T>(props: {
        items: { data: T[]; section: string }[]
        renderSection: (section: string) => JSX.Element
        render: (item: T, index: number, items: T[]) => JSX.Element
    }) => JSX.Element | null
    Select?: (props: SelectProps) => JSX.Element
    Text: (props: TextProps) => JSX.Element
    View: (props: PrimitivesProps) => JSX.Element
    Input: (props: InputParams) => JSX.Element
    Skeleton: (props: SkeletonProps) => JSX.Element
} = {
    Text: undefinedComponent,
    View: undefinedComponent,
    Icon: undefinedComponent,
    List: undefinedComponent,
    SectionedList: undefinedComponent,
    InitialsCircle: undefinedComponent,
    Loader: undefinedComponent,
    ErrorMessage: undefinedComponent,
    AreasGroupLayout: undefinedComponent,
    AreaBox: undefinedComponent,
    Select: undefinedComponent,
    Pressable: undefinedComponent,
    Button: undefinedComponent,
    Input: undefinedComponent,
    Link: undefinedComponent,
    Skeleton: undefinedComponent
}
export const setPlatformComponentSet = registrySetter(UI)
