interface Props {
    group: string
    children: React.ReactNode
}

function AreasGroupLayout({ group, children }: Props) {
    return (
        <div className="mb-5">
            <h3 className="mb-2">{group}</h3>

            <div className="flex flex-wrap gap-3">{children}</div>
        </div>
    )
}

export default AreasGroupLayout
