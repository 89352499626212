import type { Firebase } from '@shared/firebase'
import type { OrgStruct } from '@shared/firestore-structs'
import { type ApaleoQueryParams, type IntegrationParams, TaskboardContext } from '@shared/traces-types'

type IntegrationsParamsRoute = 'getReservation' | 'getProperty'

type IntegrationsParamsPayload<T extends IntegrationsParamsRoute> = T extends 'getReservation'
    ? { reservationId: string }
    : T extends 'getProperty'
      ? { propertyId: string }
      : never

function handleSessionAutoTermination(locationSearch: ApaleoQueryParams, firebase: Firebase) {
    const { subjectId } = locationSearch
    const localSubjectId = window.localStorage.getItem('apaleo_subjectId')

    // TODO: Fix this
    // @ts-ignore
    const logout = () => firebase.auth().signOut()

    if (!subjectId) {
        window.localStorage.removeItem('apaleo_subjectId')
    }

    if (subjectId && localSubjectId !== subjectId) {
        window.localStorage.setItem('apaleo_subjectId', subjectId)
        localSubjectId && logout()
    }

    return subjectId
}

async function getIntegrationParams(
    taskboardContext: TaskboardContext.PROPERTY | TaskboardContext.RESERVATIONS,
    search: ApaleoQueryParams,
    firebase: Firebase
): Promise<IntegrationParams<TaskboardContext.PROPERTY | TaskboardContext.RESERVATIONS>> {
    const { reservationId, propertyId } = search

    try {
        const getReservation = await firebase.functions().httpsCallable('integration')

        const route = taskboardContext === TaskboardContext.RESERVATIONS ? 'getReservation' : 'getProperty'
        const payload: IntegrationsParamsPayload<typeof route> =
            route === 'getReservation' && reservationId ? { reservationId } : { propertyId: propertyId! }

        const { data } = await getReservation({ route, payload })

        return data
    } catch (error) {
        console.error(error)
        throw error
    }
}

async function matchCurrentOrganizationWithApaleo(search: ApaleoQueryParams, currentOrganization: OrgStruct, firebase: Firebase) {
    try {
        const getApaleoOrganization = await firebase.functions().httpsCallable('getApaleoOrg')
        const changeOrganization = await firebase.functions().httpsCallable('userChangeOrganization')

        const { accountCode, propertyId } = search

        const { data: matchedOrganizationKey } = await getApaleoOrganization({
            accountCode: accountCode,
            propertyId: propertyId ?? undefined
        })

        if (matchedOrganizationKey === currentOrganization.key) return

        await changeOrganization({ organizationKey: matchedOrganizationKey })
        document.location.reload()
    } catch (error) {
        console.error(error)
        throw error
    }
}

export async function loadableIntegrationParams(
    taskboardContext: TaskboardContext,
    search: ApaleoQueryParams,
    org: OrgStruct,
    firebase: Firebase
) {
    try {
        if (taskboardContext !== TaskboardContext.EMBEDDED) {
            handleSessionAutoTermination(search, firebase)
            await matchCurrentOrganizationWithApaleo(search, org, firebase)
        }

        if (taskboardContext === TaskboardContext.RESERVATIONS || taskboardContext === TaskboardContext.PROPERTY) {
            return await getIntegrationParams(taskboardContext, search, firebase)
        }
    } catch (error) {
        console.error(error)
        throw error
    }

    return null
}
