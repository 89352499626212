import type { Moment } from 'moment/moment'
import { atom, selector } from 'recoil'
import { housekeepingOverviewDate } from '../../housekeeping-overview/state/housekeeping-overview-state'

const areaSummaryDateAtom = atom<Moment | null>({
    key: 'housekeeping-areaSummaryStartDate',
    default: null
})
export const areaSummaryDate = selector<Moment>({
    key: 'housekeeping-dateSelector',
    get: ({ get }) => {
        const startDate = get(housekeepingOverviewDate)
        const areaSummaryStartDate = get(areaSummaryDateAtom)
        return areaSummaryStartDate !== null ? areaSummaryStartDate : startDate
    },
    set: ({ set }, newValue) => {
        set(areaSummaryDateAtom, newValue)
    }
})
