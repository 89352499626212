import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { Modal } from 'components/atoms/modal/modal'

export function ConfirmModal({
    isOpen,
    onClose,
    onConfirm,
    headerText,
    bodytext,
    isLoading
}: {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
    headerText: string
    bodytext: string
    isLoading?: boolean
}) {
    const {
        LL: { shared }
    } = useI18nContext()

    if (!isOpen) return null

    return (
        <Modal className="w-[550px] p-8" onClose={onClose}>
            <div className="flex flex-row justify-between items-center">
                <UI.Text size="lg">{headerText}</UI.Text>
            </div>
            <div className="py-8">
                <UI.Text color="snap-silver">{bodytext}</UI.Text>
            </div>
            <div className="flex flex-row justify-between">
                {isLoading ? (
                    <div className="flex w-full">
                        <UI.Loader />
                    </div>
                ) : (
                    <>
                        <UI.Button
                            className="border border-snap-red bg-transparent"
                            onClick={onClose}
                            text={<span className="text-snap-red">{shared.cancel()}</span>}
                        />
                        <UI.Button onClick={onConfirm} text={shared.confirm()} />
                    </>
                )}
            </div>
        </Modal>
    )
}
