import type { useCollectionData, useCollectionDataOnce, useDocumentData, useDocumentDataOnce } from '../infra/firebase-hooks'
import { registrySetter } from './registry-utils'

const uninitializedMethod = () => {
    throw new Error('FirebaseHooksRegistry not initialized')
}
export const Hooks: {
    useCollectionDataOnce: typeof useCollectionDataOnce
    useCollectionData: typeof useCollectionData
    useDocumentData: typeof useDocumentData
    useDocumentDataOnce: typeof useDocumentDataOnce
} = {
    useCollectionDataOnce: uninitializedMethod,
    useCollectionData: uninitializedMethod,
    useDocumentData: uninitializedMethod,
    useDocumentDataOnce: uninitializedMethod
}
export const setPlatformHooks = registrySetter(Hooks)
