import { type UserOption, constructUsersOptions } from '@shared/user-data'
import { atom, selector } from 'recoil'
import { usersSelector } from '../../../auth/state/login'
export enum AssigningFilterMode {
    ALL = 'all',
    UN_ASSIGNED = 'un-assigned',
    ASSIGNED = 'assigned'
}

export const massAssigningBarOpenAtom = atom({
    key: 'mass-assigning-massAssigningBarOpen',
    default: false
})

export const selectedAreasAtom = atom<Set<string>>({
    key: 'mass-assigning-selectedAreasAtom',
    default: new Set()
})

export const usersOptionsSelector = selector<UserOption[]>({
    key: 'mass-assigning-usersSelector',
    get: ({ get }) => {
        const users = get(usersSelector)
        return constructUsersOptions(users)
    }
})

export const massAssigningFilterModeAtom = atom<AssigningFilterMode>({
    key: 'mass-assigning-massAssigningFilterMode',
    default: AssigningFilterMode.ALL
})

export const cleaningTaskNameAtom = atom<string>({
    key: 'mass-assigning-cleaningTaskName',
    default: ''
})
