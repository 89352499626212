import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { usePhoneCode } from '@shared-snap/snap/components/auth/hooks/use-sign-in'
import { getUserEmail, sendEmailLink } from '@shared-snap/snap/components/auth/magic-link/magic-link'
import { useTaskboardContext } from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { getBrand } from '@shared/brand'
import { TaskboardContext } from '@shared/traces-types'
import { useNavigate } from '@tanstack/react-router'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { FormLinks } from 'components/molecules/login/form-links'
import type React from 'react'
import { type ChangeEvent, type KeyboardEvent, type MouseEvent, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { prefix } from '../../../../routes/__root'

interface Code {
    code: string
}

function FormOTPInput({ value, onChange }: { value: string; onChange: (value: string) => void }) {
    const inputsRef = useRef<HTMLInputElement[]>([])

    useEffect(() => {
        inputsRef.current[0]?.focus()
    }, [])

    const handleChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value.replace(/[^0-9]/g, '')
        if (!newValue) return

        const updatedOTP = value.split('')
        updatedOTP[index] = newValue
        onChange(updatedOTP.join(''))

        if (newValue && index < 5) {
            inputsRef.current[index + 1]?.focus()
        }
    }

    const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && index > 0) {
            if (!value[index]) {
                inputsRef.current[index - 1]?.focus()
                onChange(value.slice(0, index - 1))
            } else {
                onChange(value.slice(0, index))
            }
        }

        if (event.key === 'ArrowLeft') {
            inputsRef.current[index - 1]?.focus()
        }

        if (event.key === 'ArrowRight') {
            inputsRef.current[index + 1]?.focus()
        }
    }

    return (
        <div className="flex justify-between gap-x-2">
            {[...Array(6)].map((_, index) => (
                <input
                    key={index}
                    ref={el => el && (inputsRef.current[index] = el)}
                    type="text"
                    maxLength={1}
                    value={value[index] || ''}
                    onChange={e => handleChange(index, e)}
                    onKeyDown={e => handleKeyDown(index, e)}
                    className="w-10 h-10 text-center text-xl py-2 border-b border-snap-mid-gray focus:outline-none focus:ring-0"
                />
            ))}
        </div>
    )
}

const CodeConfirmationForm: React.FC = () => {
    const navigate = useNavigate()
    const {
        handleSubmit,
        reset,
        control,
        formState: { isValid }
    } = useForm<Code>({
        defaultValues: {
            code: ''
        }
    })
    const confirm = usePhoneCode(asFirebase(firebase))
    const {
        LL: { shared, login }
    } = useI18nContext()
    const [isLoading, setIsLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [isMagicLinkSent, setIsMagicLinkSent] = useState(false)
    const { taskboardContext } = useTaskboardContext()

    useEffect(() => {
        reset()
    }, [])

    const onSubmit = async ({ code }: Code) => {
        setIsLoading(true)

        try {
            await confirm(code)
            if (taskboardContext === TaskboardContext.EMBEDDED) {
                await navigate({ to: `${prefix}/` })
            } else {
                await navigate({
                    to: `${prefix}/integrations/apaleo/taskboard`
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    const sendMagicLink = async (e: MouseEvent) => {
        e.preventDefault()
        setIsLoading(true)

        try {
            const brandId = getBrand().firebase?.projectId

            if (!brandId) {
                console.error('Brand ID not found')
                throw new Error('Brand ID not found')
            }

            const phoneNumber = localStorage.getItem('phoneNumber')

            if (!phoneNumber) {
                console.error('Phone number not found')
                throw new Error('Phone number not found')
            }

            const email = await getUserEmail(phoneNumber, brandId)

            if (!email) {
                console.error('Email not found')
                throw new Error('Email not found')
            }

            setEmail(email)

            await sendEmailLink(phoneNumber, brandId)
            setIsMagicLinkSent(true)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return <UI.Loader />
    }

    if (isMagicLinkSent) {
        return (
            <div className="flex flex-col gap-y-10">
                <UI.Text size="2xl">{login.sentMagicLink()}</UI.Text>
                <UI.Text>{login.sentMagicLinkDescr()}</UI.Text>
                <UI.Text size="lg" color="snap-red">
                    {email}
                </UI.Text>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col grow justify-between">
            <div className="flex items-center grow shrink-0">
                <div className="flex flex-col gap-y-10 w-full">
                    <Controller
                        control={control}
                        name="code"
                        render={({ field: { onChange, value } }) => <FormOTPInput value={value} onChange={onChange} />}
                    />

                    <Button className="mx-auto" disabled={!isValid} type="submit">
                        {shared.confirm()}
                    </Button>
                </div>
            </div>

            <FormLinks type="email" sendMagicLink={sendMagicLink} />
        </form>
    )
}

export default CodeConfirmationForm
