import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { SnapColors } from '@shared/colors'
import { Select } from 'components/atoms/select/select'

export function FilterSelect({
    value,
    options,
    onChange,
    isMulti = false,
    filterLabel
}: {
    value: { value: string; label: string }[]
    options: { value: string; label: string }[]
    onChange: (value: string) => void
    isMulti?: boolean
    filterLabel?: string
}) {
    return (
        <div className="flex flex-col gap-y-2 flex-1">
            <Select
                value={value}
                options={options}
                onChange={onChange}
                isMulti={isMulti}
                customStyles={{
                    control: {
                        border: 'none',
                        borderBottom: '1px solid' + SnapColors.midSilver,
                        paddingBottom: 4,
                        height: 40
                    },
                    multiValueRemove: { ':hover': { background: 'none' } },
                    clearIndicator: { display: 'none' }
                }}
            />
            {filterLabel && (
                <UI.Text size="xs" color="snap-mid-gray">
                    {filterLabel}
                </UI.Text>
            )}
        </div>
    )
}
