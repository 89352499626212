import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentTasksTypes } from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import type { TaskStruct } from '@shared/firestore-structs'

const filterButtons: TaskStruct['type'][] = ['general', 'issue', 'cleaning']

export function TypeFilterButtons() {
    const {
        LL: {
            tasks: { filters }
        }
    } = useI18nContext()
    const [currentTasksTypes, setCurrentTasksTypes] = useCurrentTasksTypes()

    return (
        <div className="flex gap-3">
            {filterButtons.map((type, index) => {
                const selected = currentTasksTypes.includes(type)

                function onFilterButtonClick() {
                    const newTasksTypes = selected ? currentTasksTypes.filter(t => t !== type) : [...currentTasksTypes, type]
                    setCurrentTasksTypes(newTasksTypes)
                }

                return (
                    <button
                        onClick={onFilterButtonClick}
                        className={`${
                            selected ? 'bg-snap-teal text-white' : 'bg-snap-light-gray text-snap-mid-gray'
                        } font-bold text-xs rounded-3xl py-2 px-4`}
                        key={type + index}>
                        {filters[type]()}
                    </button>
                )
            })}
        </div>
    )
}
