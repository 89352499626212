import type { Firebase } from '@shared/firebase'
import { useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUserAreaSummaryAction } from '../../use-user-action'

export function useAddUnitNote(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const action = useUserAreaSummaryAction('set-unit-note', firebase)

    return (note: string) => {
        action(note, currentUser)
    }
}
