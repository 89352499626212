import type * as dataObjects from './dataObjects'

import type { DocumentData, Firebase, FirebaseFirestore } from './firebase'
import type { CategoryStruct, UserStruct } from './firestore-structs'

class CategoryError extends Error {
    constructor(message: string) {
        super(message)
        this.name = '' // Remove the 'Error' prefix from the name
    }
}

export const updateCategoryName = async (
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    key: CategoryStruct['id'],
    newName: string
): Promise<dataObjects.OrgStruct> => {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return await db.runTransaction(async transaction => {
        const organizationRef = db.collection('organizations').doc(currentUser.organizationKey)

        // Fetch the organization data
        const organizationSnap = await transaction.get<DocumentData>(organizationRef)
        if (!organizationSnap.exists) {
            throw new CategoryError('Organization does not exist')
        }

        if (!newName.trim()) {
            throw new CategoryError('Category name cannot be empty')
        }

        const organization = organizationSnap.data() as dataObjects.OrgStruct
        const categories = organization?.categories ?? []

        const duplicateName = categories.some(c => c.id !== key && c.name === newName.trim())

        if (duplicateName) {
            throw new CategoryError('Category name already exists')
        }

        // Update the category name
        const category = categories.find(category => category.id === key)
        if (category) {
            category.name = newName.trim()
        }

        // Update Firestore document
        transaction.update(organizationRef, { categories })
        return {
            ...organization,
            categories
        }
    })
}

export const addCategory = async (
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    name: string
): Promise<dataObjects.OrgStruct> => {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return await db.runTransaction(async transaction => {
        const organizationRef = db.collection('organizations').doc(currentUser.organizationKey)

        // Fetch the organization data
        const organizationSnap = await transaction.get<DocumentData>(organizationRef)
        if (!organizationSnap.exists) {
            throw new CategoryError('Organization does not exist')
        }

        const organization = organizationSnap.data() as dataObjects.OrgStruct
        const categories = organization?.categories ?? []

        // Use firebase to generate a new category ID without creating a new firestore document
        const parentKey = db.collection('organizations').doc().id

        // Only add if category doesn't already exist

        categories.push({ id: parentKey, name, categoryItems: [] })

        // Update Firestore document
        transaction.update(organizationRef, { categories })
        return {
            ...organization,
            categories
        }
    })
}

export const addTag = async (
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    parentKey: CategoryStruct['id'],
    tag: string
): Promise<dataObjects.OrgStruct> => {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return await db.runTransaction(async transaction => {
        const organizationRef = db.collection('organizations').doc(currentUser.organizationKey)

        // Fetch the organization data
        const organizationSnap = await transaction.get<DocumentData>(organizationRef)
        if (!organizationSnap.exists) {
            throw new CategoryError('Organization does not exist')
        }

        const organization = organizationSnap.data() as dataObjects.OrgStruct
        const categories = organization?.categories ?? []

        // add tag if it doesn't already exist in this parent
        const parentCategory = categories.find(category => category.id === parentKey)
        if (parentCategory && !parentCategory.categoryItems.find(item => item === tag)) {
            parentCategory.categoryItems.push(tag)
        }

        // Update Firestore document
        transaction.update(organizationRef, { categories })

        return {
            ...organization,
            categories
        }
    })
}

export const deleteCategory = async (firebase: Firebase | FirebaseFirestore, currentUser: UserStruct, parentKey: string) => {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return await db.runTransaction(async transaction => {
        const organizationRef = db.collection('organizations').doc(currentUser.organizationKey)

        // Fetch the organization data
        const organizationSnap = await transaction.get<DocumentData>(organizationRef)
        if (!organizationSnap.exists) {
            throw new CategoryError('Organization does not exist')
        }

        const organization = organizationSnap.data() as dataObjects.OrgStruct

        // remove category if it exists
        const categories = organization?.categories ?? []
        const categoryIndex = categories.findIndex(category => category.id === parentKey)
        if (categoryIndex !== -1) {
            categories.splice(categoryIndex, 1)
        }

        // Update Firestore document
        transaction.update(organizationRef, { categories })
        return {
            ...organization,
            categories
        }
    })
}

export const removeCategoryItem = async (
    firebase: Firebase | FirebaseFirestore,
    currentUser: UserStruct,
    parentKey: CategoryStruct['id'],
    tag: string
): Promise<dataObjects.OrgStruct> => {
    const db = 'firestore' in firebase ? firebase.firestore() : firebase
    return await db.runTransaction(async transaction => {
        const organizationRef = db.collection('organizations').doc(currentUser.organizationKey)

        // Fetch the organization data
        const organizationSnap = await transaction.get<DocumentData>(organizationRef)
        if (!organizationSnap.exists) {
            throw new CategoryError('Organization does not exist')
        }

        const organization = organizationSnap.data() as dataObjects.OrgStruct
        const categories = organization?.categories ?? []

        // only remove category item if both it and its parent exists
        const parentCategory = categories.find(category => category.id === parentKey)
        const categoryItemIndex = parentCategory?.categoryItems.findIndex(item => item === tag) ?? -1
        if (parentCategory && categoryItemIndex !== -1) {
            parentCategory.categoryItems.splice(categoryItemIndex, 1)
        }

        // Update Firestore document
        transaction.update(organizationRef, { categories })
        return {
            ...organization,
            categories
        }
    })
}
