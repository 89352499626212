import { createFileRoute } from '@tanstack/react-router'
import { HousekeepingLayout } from '../../../components/layouts/housekeeping-overview/housekeeping-layout/housekeeping-layout'
import { prefix } from '../../__root'

export const Route = createFileRoute(`${prefix}/_authenticated/housekeeping`)({
    component: Housekeeping
})

function Housekeeping() {
    return <HousekeepingLayout />
}
