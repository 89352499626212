import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { Checkbox } from 'components/atoms/checkbox'
import { FieldLabel } from 'components/atoms/field-label'
import { useFormContext } from 'react-hook-form'

export function PriorityCheckbox() {
    const {
        LL: { shared }
    } = useI18nContext()
    const { watch } = useFormContext()
    const isPriority = watch('priority')

    return (
        <FieldLabel
            label={shared.priorityCheckbox()}
            className="relative !flex-row-reverse !justify-end items-center !w-fit"
            fieldName="priority">
            <Checkbox name="priority" className="appearance-none w-6 h-6 bg-snap-light-gray rounded-full cursor-pointer" />
            <div className="absolute w-6 h-6 flex justify-center items-center cursor-pointer pointer-events-none">
                <UI.Icon icon="specta-priority-low" size="xs" color={isPriority ? 'snap-red' : 'snap-black'} />
            </div>
        </FieldLabel>
    )
}
