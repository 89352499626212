const db: { areaKey: string; id: string }[] = []
// Search in the database for the provided term.
export const searchDb = (term: string) => {
    const terms = term.toLocaleLowerCase().trim().split(' ')
    return db
        .filter(item => {
            const fields = item.id.split('^%$}]]#!@')
            return terms.every(term => fields.some(field => field.includes(term)))
        })
        .map(item => item.areaKey)
}
// Replaces the data in the search index with the provided items.
export const setData = (
    items: {
        areaKey: string
        guestNames: string
        areaName: string
        groupName: string
        assignedTo: string[]
    }[]
) => {
    db.length = 0
    db.push(
        ...items.map((item, i) => ({
            areaKey: item.areaKey,
            id: joinWithSeparator([item.areaName, item.areaKey, item.groupName, item.guestNames, joinWithSeparator(item.assignedTo)])
        }))
    )
}

function joinWithSeparator(arr: string[]): string {
    return arr.map(l => l.toLocaleLowerCase()).join('^%$}]]#!@')
}
