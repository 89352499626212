import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useOrganization } from '@shared-snap/snap/components/settings/hooks/use-organization'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button'
import { FieldErrorMessage } from 'components/atoms/field-error-message'
import { FieldLabel } from 'components/atoms/field-label'
import Input from 'components/atoms/input/input'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

interface GeneralForm {
    name: string
}

export function General() {
    const [isLoading, setIsLoading] = useState(false)
    const currentOrganization = useCurrentOrg()
    const { updateOrganization } = useOrganization(asFirebase(firebase))
    const {
        LL: { shared }
    } = useI18nContext()

    const defaultValues = {
        name: currentOrganization.name
    }

    const form = useForm<GeneralForm>({
        defaultValues
    })

    const {
        handleSubmit,
        register,
        formState: { errors }
    } = form

    const onSubmit = async (data: GeneralForm) => {
        setIsLoading(true)

        try {
            await updateOrganization(data)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <FormProvider {...form}>
            <form className="flex flex-col gap-y-10" onSubmit={handleSubmit(onSubmit)}>
                <FieldLabel label="Name" required>
                    <Input required name="name" placeholder="Name" register={register} />
                    {errors.name && <FieldErrorMessage message={shared.errorFieldIsRequired({ field: 'Name' })} />}
                </FieldLabel>

                <div className="flex justify-end">
                    <Button disabled={isLoading} type="submit">
                        {isLoading ? <UI.Loader /> : shared.save()}
                    </Button>
                </div>
            </form>
        </FormProvider>
    )
}
