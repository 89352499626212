import type { Firebase } from '@shared/firebase'
import type { IssueStruct } from '@shared/firestore-structs'
import { addImageItemsToIssue } from '@shared/issue-data'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'

export function useAddImagesToIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (issueKey: string, images: File[], items: IssueStruct['items']) => {
        try {
            await addImageItemsToIssue(firebase, {
                currentUser,
                issueKey,
                images,
                currentItems: items
            })
        } catch (error: unknown) {
            //TODO: add proper error handling
            console.error(error)
            throw new Error('Error adding images to issue', { cause: error })
        }
    }
}
