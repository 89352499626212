import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useMassAssign } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-mass-assign'
import {
    useCleaningTaskName,
    useMassAssignFilterMode,
    useMassAssigningBarOpen,
    useSelectAllAreas,
    useSelectedAreas,
    useSetMassAssigningBarOpen,
    useUsersOptions
} from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-mass-assigning-bar'
import { AssigningFilterMode } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/state/mass-assigning-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Icon } from '@shared-snap/snap/types/visuals.types'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import Input from 'components/atoms/input/input'
import { useEffect, useState } from 'react'
import { UsersModal } from '../users-modal/users-modal'
import { TeamWorkload } from './team-workload'

export function MassAssigningBar() {
    const {
        LL: {
            shared,
            housekeeping: { massAssignBar }
        }
    } = useI18nContext()
    const massAssigningBarOpen = useMassAssigningBarOpen()
    const setMassAssigningBarOpen = useSetMassAssigningBarOpen()
    const [selectedAreas, setSelectedAreas] = useSelectedAreas()
    const usersOptions = useUsersOptions()
    const { massAssign, massUnassign, sameAsLastAssign } = useMassAssign(asFirebase(firebaseWrapped))
    const [usersModalOpen, setUsersModalOpen] = useState(false)

    useEffect(() => {
        if (!massAssigningBarOpen) {
            setSelectedAreas(new Set())
        }
    }, [massAssigningBarOpen])

    if (!massAssigningBarOpen) return null
    function handleAssignment(assignmentFn: () => Promise<void>) {
        assignmentFn().then(() => setSelectedAreas(new Set()))
    }
    function onAssign(userKeys: string[]) {
        handleAssignment(() => massAssign(userKeys))
    }

    return (
        <>
            {usersModalOpen && (
                <UsersModal
                    usersOptions={usersOptions}
                    isOpen={usersModalOpen}
                    onClose={() => setUsersModalOpen(false)}
                    onAssign={onAssign}
                    closeByPressOutside={false}
                />
            )}
            <div
                style={{
                    filter: 'drop-shadow(0px -16px 16px rgba(0, 0, 0, 0.09)) drop-shadow(0px -63px 25px rgba(0, 0, 0, 0.01))'
                }}
                className="sticky inset-0 py-[24px] px-[65px] bg-snap-mid-silver z-20">
                <div className="flex justify-between">
                    <div className="flex flex-col gap-y-[24px]">
                        <UI.Button className="bg-snap-silver" text={shared.cancel()} onClick={() => setMassAssigningBarOpen(false)} />
                        <div>
                            <UI.Text size="xs" color="snap-silver">
                                {massAssignBar.selected({ num: selectedAreas.size })}
                            </UI.Text>
                        </div>
                    </div>

                    <div className="flex flex-row gap-x-[32px] h-min">
                        <FilterButton />
                        <SelectButtons />
                    </div>

                    <CleaningTaskNameInput />
                    <div className="flex flex-col items-end gap-2">
                        <div className="flex gap-5">
                            <div className="flex flex-col items-center gap-1">
                                <UI.Button
                                    disabled={!selectedAreas.size}
                                    className={!selectedAreas.size ? 'bg-snap-silver' : 'bg-snap-red'}
                                    text={shared.unassignBtn()}
                                    onClick={() => handleAssignment(massUnassign)}
                                />
                            </div>

                            <UI.Button
                                disabled={!selectedAreas.size}
                                className={!selectedAreas.size ? 'bg-snap-silver' : 'bg-snap-red'}
                                text={shared.assignBtn()}
                                onClick={() => setUsersModalOpen(true)}
                            />
                        </div>
                        <button
                            className="disabled:opacity-50"
                            disabled={!selectedAreas.size}
                            onClick={() => handleAssignment(sameAsLastAssign)}>
                            <span
                                className={`text-sm border-b ${
                                    !selectedAreas.size ? 'text-snap-gray border-snap-silver' : 'text-snap-dark-blue border-snap-dark-blue'
                                }`}>
                                {shared.sameAsLast()}
                            </span>
                        </button>
                    </div>
                </div>
                <TeamWorkload />
            </div>
        </>
    )
}

function FilterButton() {
    const {
        LL: {
            housekeeping: { massAssignBar }
        }
    } = useI18nContext()
    const [filterMode, setFilterMode] = useMassAssignFilterMode()
    const [text, setText] = useState(massAssignBar.showAll())
    const [icon, setIcon] = useState<Icon>('specta-all')

    useEffect(() => {
        switch (filterMode) {
            case 'un-assigned':
                setText(massAssignBar.showUnassigned())
                setIcon('specta-assigned-to')
                break
            case 'assigned':
                setText(massAssignBar.showAssigned())
                setIcon('specta-assign-user')
                break
            case 'all':
                setText(massAssignBar.showAll())
                setIcon('specta-all')
                break
        }
    }, [filterMode])

    useEffect(() => {
        return () => {
            setFilterMode(AssigningFilterMode['ALL'])
        }
    }, [])

    function onClick() {
        const assigningModeFilters = Object.values(AssigningFilterMode)
        const nextFilter = assigningModeFilters[(assigningModeFilters.indexOf(filterMode) + 1) % assigningModeFilters.length]
        setFilterMode(nextFilter)
    }

    return (
        <div className="flex gap-x-2 cursor-pointer items-center" onClick={onClick}>
            <div className="flex justify-center items-center w-[24px] h-[24px] rounded-full bg-white">
                <UI.Icon size="xs" color="snap-mid-gray" icon={icon} />
            </div>
            <UI.Text color="snap-mid-gray">{text}</UI.Text>
        </div>
    )
}

function SelectButtons() {
    const {
        LL: {
            shared,
            housekeeping: { massAssignBar }
        }
    } = useI18nContext()
    const { selectAllAreas, clearAllAreas } = useSelectAllAreas()

    return (
        <div className="flex gap-x-[32px]">
            <div className="flex gap-x-2 cursor-pointer items-center" onClick={selectAllAreas}>
                <div className="flex justify-center items-center w-[24px] h-[24px] rounded-full bg-white">
                    <UI.Icon size="xs" color="snap-mid-gray" icon="specta-check-all" />
                </div>
                <UI.Text color="snap-mid-gray">{shared.selectAll()}</UI.Text>
            </div>

            <div className="flex gap-x-2 cursor-pointer items-center" onClick={clearAllAreas}>
                <div className="flex justify-center items-center w-[24px] h-[24px] rounded-full bg-white">
                    <UI.Icon size="xs" color="snap-mid-gray" icon="specta-un-check-all" />
                </div>
                <UI.Text color="snap-mid-gray">{massAssignBar.clearAll()}</UI.Text>
            </div>
        </div>
    )
}

function CleaningTaskNameInput() {
    const {
        LL: {
            housekeeping: { massAssignBar }
        }
    } = useI18nContext()
    const [cleaningTaskName, setCleaningTaskName] = useCleaningTaskName()

    useEffect(() => {
        return () => {
            setCleaningTaskName('')
        }
    }, [])

    return (
        <div className="flex flex-col w-[190px] justify-between">
            <Input
                className="rounded-[8px] p-[14px]"
                initialValue={cleaningTaskName}
                name={'name'}
                placeholder={massAssignBar.cleaningTaskName()}
                onChange={setCleaningTaskName}
            />
            <UI.Text size="xs" color="snap-mid-gray" align="center">
                {massAssignBar.cleaningTaskDescr()}
            </UI.Text>
        </div>
    )
}
