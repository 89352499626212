import type { Firebase } from '@shared/firebase'
import { setPriorityToIssue } from '@shared/issue-data'
import { clone } from 'ramda'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useIssueData, useSetIssueState } from './use-issues'

export function useChangeIssuePriority(firebase: Firebase, issueKey: string) {
    const issue = useIssueData(issueKey)
    const currentUser = useCurrentUser()
    const setIssueState = useSetIssueState()

    return async () => {
        if (!issue) throw new Error('Issue not found')
        const nextPriority = !clone(issue.priority)

        setIssueState({ key: issue.key, priority: nextPriority })

        try {
            await setPriorityToIssue(firebase, {
                issueKey: issueKey,
                priority: nextPriority,
                currentUser
            })
        } catch (error) {
            //TODO: proper error handling
            console.error(error)
            throw new Error('Error changing issue priority')
        }
    }
}
