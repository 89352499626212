import moment from 'moment-timezone'
import { useRecoilValue } from 'recoil'
import { UI } from '../../../../registry/ui-elements-registry'
import { useCurrentOrg } from '../../../auth/hooks/use-auth-state'
import { useAreaSummaryDate } from '../hooks/use-area-summary'
import { areaState, bookingsAtom } from '../state/area-summary-state'

const CopyToClipboard = ({ text, textVisible = true }: { text: string; textVisible?: boolean }) => {
    return (
        <span>
            {textVisible && text}
            <a
                className={'cursor-pointer'}
                onClick={async () => {
                    await copyTextToClipboard(text)
                }}>
                ✂️
            </a>
        </span>
    )
}

export function DeveloperBookings() {
    const bookings = useRecoilValue(bookingsAtom)
    const currentOrg = useCurrentOrg()

    return (
        <UI.List
            items={bookings}
            render={(booking, i) => {
                return (
                    <UI.View key={`devsection-${i}`} className="flex flex-col">
                        <UI.Text>In - {moment.tz(booking.checkinDate, currentOrg.timezone).format('YYYY-MM-DD')}</UI.Text>
                        {booking.optInDates && (
                            <UI.Text>
                                Opt in -{' '}
                                {booking.optInDates.map(d => moment.tz(parseInt(d), currentOrg.timezone).format('YYYY-MM-DD')).join(',')}
                            </UI.Text>
                        )}
                        {booking.optOutDates && (
                            <UI.Text>
                                Opt out -{' '}
                                {booking.optOutDates.map(d => moment.tz(parseInt(d), currentOrg.timezone).format('YYYY-MM-DD')).join(',')}
                            </UI.Text>
                        )}
                        <UI.Text>Out - {moment.tz(booking.checkoutDate, currentOrg.timezone).format('YYYY-MM-DD')}</UI.Text>
                        <UI.Text>Guest name:{booking.guestName}</UI.Text>
                        <UI.Text>
                            Booking key: <CopyToClipboard text={booking.key ?? 'unknown'} />{' '}
                        </UI.Text>
                    </UI.View>
                )
            }}
        />
    )
}

export function DeveloperArea() {
    const area = useRecoilValue(areaState)
    const { date: areaSummaryDate } = useAreaSummaryDate()

    return (
        <UI.Text>
            Area: {area.name} (<CopyToClipboard text={area.key} />
            ), cleaning status: {area.cleaningStatus}, projection path
            <CopyToClipboard
                text={`housekeeping-overview-v2/${area.organizationKey}/dates/${areaSummaryDate.format('YYYY-MM-DD')}/area-summary/${
                    area.key
                }`}
                textVisible={false}
            />
        </UI.Text>
    )
}

export function DeveloperOrganization() {
    const org = useCurrentOrg()

    return (
        <>
            <UI.Text>
                Org: {org.name} (<CopyToClipboard text={org.key} />
                ). {org.allowOptIn ? 'With opt-in' : org.allowOptOut ? 'With opt-out' : ''}
            </UI.Text>
            <UI.Text>
                {org.featuresEnabled ? `Features enabled: ${org.featuresEnabled}` : ''}
                {org.featuresDisabled ? `Features disabled: ${org.featuresDisabled}` : ''}
            </UI.Text>
        </>
    )
}

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

export const DeveloperSection = () => {
    return (
        <UI.View className="w-full">
            <UI.Text weight="bold" size="md">
                Developer section
            </UI.Text>
            <UI.View className="flex flex-col gap-3">
                <DeveloperOrganization />
                <DeveloperArea />
                <DeveloperBookings />
            </UI.View>
        </UI.View>
    )
}
