import type { ProjectionMetadata } from '@shared/projections-v2'
import { DefaultValue, type RecoilState, type RecoilValueReadOnly, atom, selector } from 'recoil'

// This overrideableState function is used to create a Recoil state that support local overrides.
export function overrideableStateNoTime<
    T extends ProjectionMetadata,
    Updates extends Partial<T> & ProjectionMetadata = Partial<T> & ProjectionMetadata
>({
    id,
    backendSelector,
    assembleOutput = (backend, override) => ({ ...backend, ...override }),
    overridesMerger = (prev, incoming) => ({ ...prev, ...incoming }),
    overrideDiscarder
}: {
    id: string
    backendSelector?: (backend: RecoilState<{ [key: string]: T }>) => RecoilValueReadOnly<{ [key: string]: T }>
    assembleOutput?: (backend: T, override: Updates) => T
    overridesMerger?: (prev: Updates, incoming: Updates) => Updates // Merges two overrides together. Used when setting a new override
    overrideDiscarder: (backend: T, override: Updates, id: string) => 'discard' | 'keep'
}) {
    const backendAtom = atom<{ [key: string]: T }>({
        key: `${id}-backendAtom`,
        default: {}
    })

    const backendGetter = backendSelector ? backendSelector(backendAtom) : backendAtom

    const overrideAtom = atom<{ [key: string]: Updates[] }>({
        key: `${id}-overrideAtom`,
        default: {}
    })

    const resolverValue = selector<{ [key: string]: T }>({
        key: `${id}-resolver`,
        get: ({ get }) => {
            const backend = get(backendGetter)
            const overrides = get(overrideAtom)

            if (!overrides || Object.keys(overrides).length === 0) {
                return backend
            }

            return Object.fromEntries(
                Object.entries(backend).map(([key, value]) => {
                    const override = overrides[key]
                    if (!override) {
                        return [key, value]
                    } else {
                        console.debug(`<${id}>: Using local override for ${key} - ${JSON.stringify(override)}`)
                        return [key, assembleOutput(value, override.reduce(overridesMerger))]
                    }
                })
            )
        }
    })

    const backendSetter = selector<{ [key: string]: T }>({
        key: `${id}-backend`,
        set: ({ set, get }, newValue) => {
            const override = get(overrideAtom)
            if (!(newValue instanceof DefaultValue)) {
                const newOverrides = Object.entries(override).reduce(
                    (acc, [key, value]) => {
                        const filteredValues = newValue[key]
                            ? value.filter(v => {
                                  const keep = overrideDiscarder(newValue[key], v, key) === 'keep'
                                  if (keep) {
                                      console.debug(`<${id}>: Keeping override for ${key} - ${JSON.stringify(v)}`)
                                  } else {
                                      console.debug(`<${id}>: Discarding override for ${key}`)
                                  }
                                  return keep
                              })
                            : value
                        if (filteredValues.length === 0) {
                            delete acc[key]
                            return acc
                        } else {
                            acc[key] = filteredValues
                            return acc
                        }
                    },
                    {} as { [key: string]: Updates[] }
                )
                set(overrideAtom, newOverrides)
                set(backendAtom, newValue)
            }
        },
        get: () => {
            throw new Error('Do not use this selector for reading. Writing only.')
        }
    })
    const frontendSetter = selector<{ [key: string]: Updates }>({
        key: `${id}-frontend`,
        get: () => {
            throw new Error('Do not use this selector for reading. Writing only.')
        },
        set: ({ set, get }, newValue) => {
            if (newValue instanceof DefaultValue) {
                throw new Error('Cannot reset local override')
            } else {
                const currentOverrides = get(overrideAtom)
                if (newValue) {
                    const mergedOverride = Object.entries(newValue).reduce(
                        (acc, [key, value]) => {
                            const updatingLastChangeWithTicks = Object.keys(value).length === 1 && Object.keys(value)[0] === 'ticks'
                            let result = currentOverrides[key]
                            if (updatingLastChangeWithTicks && result) {
                                const last = result.at(-1)
                                result = [...result.slice(0, -1), { ...last!, ticks: value.ticks }]
                            } else {
                                result = result ? result.concat(value) : [value]
                            }
                            acc[key] = result
                            return acc
                        },
                        {} as { [key: string]: Updates[] }
                    )
                    const combined = { ...currentOverrides, ...mergedOverride }
                    console.debug(`${id}: Setting local override - ${JSON.stringify(newValue)}.`)
                    set(overrideAtom, combined)
                }
            }
        }
    })
    return { resolverValue, backendSetter, frontendSetter }
}
