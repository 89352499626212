import React from 'react'

interface Props<T> {
    items: T[]
    render: (item: T, index: number, arr: T[]) => JSX.Element
}

function List<T>({ items, render }: Props<T>) {
    if (items.length === 0) return null
    return (
        <>
            {items.map((item, i) => (
                <React.Fragment key={`list-${items.length}-${i}`}>{render(item, i, items)}</React.Fragment>
            ))}
        </>
    )
}

export default List
