import React, { type ButtonHTMLAttributes } from 'react'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

export function Button({ children, disabled, className, ...rest }: ButtonProps) {
    return (
        <button
            disabled={disabled}
            className={`px-[32px] rounded-[4px] h-[42px] font-bold text-white text-[14px] bg-snap-red ${
                !disabled && 'hover:brightness-90'
            } ${className}`}
            {...rest}>
            {children}
        </button>
    )
}
