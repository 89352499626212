import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { Link, Outlet, useNavigate, useRouterState } from '@tanstack/react-router'
import { useEffect } from 'react'
import { prefix } from 'routes/__root'
import { Route } from 'routes/snap/_authenticated/settings.account'

const tabs = [
    { value: `${prefix}/settings/account/information`, label: 'General' },
    { value: `${prefix}/settings/account/subscription`, label: 'Subscription' }
]

export function AccountLayout() {
    const navigate = useNavigate({ from: Route.fullPath })
    const routeState = useRouterState()

    useEffect(() => {
        if (!routeState.location.pathname.endsWith('/information') && !routeState.location.pathname.endsWith('/subscription')) {
            navigate({ to: `${prefix}/settings/account/information`, replace: true })
        }
    }, [])

    return (
        <div className="flex flex-col grow shrink-0 p-4 gap-y-4">
            <Header />
            <Outlet />
        </div>
    )
}

function Header() {
    const routeState = useRouterState()
    const currentTab = routeState.location.pathname
    const {
        LL: { navigation }
    } = useI18nContext()

    return (
        <>
            <h1 className="text-3xl font-bold">{navigation.account()}</h1>
            <div className="flex gap-y-3 mb-[30px] w-fit max-w-[100%] flex-wrap">
                <UI.List
                    items={tabs}
                    render={({ value, label }) => {
                        const selected = currentTab === value

                        return (
                            <div
                                key={value}
                                className="relative flex items-center px-2 gap-x-2 pb-[8px] cursor-pointer border-b border-sweeply-light-gray">
                                <Link to={value} className={`text-${selected ? 'sweeply-red' : 'snap-silver'}`}>
                                    {label}
                                </Link>

                                {selected && <div className="absolute left-0 right-0 bottom-[-2px] h-[3px] bg-snap-yellow rounded" />}
                            </div>
                        )
                    }}
                />
            </div>
        </>
    )
}
