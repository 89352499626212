import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useSaveGeneralTask } from '@shared-snap/snap/components/my-tasks/hooks/use-save-general-task'
import { useAreasOptions, useUsersOptions } from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import { taskboardContextAtom } from '@shared-snap/snap/components/my-tasks/state/my-tasks'
import type { GeneralTaskForm } from '@shared-snap/snap/components/my-tasks/types'
import { constructDefaultTaskFormValues } from '@shared-snap/snap/components/my-tasks/utils'
import { integrationParamsAtom } from '@shared-snap/snap/components/traces/state/atoms'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { NonReservationTaskStruct, ReservationTaskStruct } from '@shared/firestore-structs'
import generalTaskIcon from '@shared/img/general-task.svg'
import { TaskboardContext } from '@shared/traces-types'
import { useNavigate } from '@tanstack/react-router'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { FieldLabel } from 'components/atoms/field-label'
import { FieldSelect } from 'components/atoms/field-select'
import { InitialsCircle } from 'components/atoms/initials-circle/initials-circle'
import Input from 'components/atoms/input/input'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { DateField } from 'components/molecules/date-field'
import { PriorityCheckbox } from 'components/molecules/priority-checkbox'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useRecoilValue } from 'recoil'
import { prefix } from '../../routes/__root'
import { FieldErrorMessage } from '../atoms/field-error-message'

const initialGeneralTaskForm: Partial<GeneralTaskForm> = {
    dueDate: moment().startOf('day').format('YYYY-MM-DD')
}

interface Props {
    task?: NonReservationTaskStruct | ReservationTaskStruct
}

export function GeneralTaskModal({ task }: Props) {
    const {
        LL: { tasks, shared }
    } = useI18nContext()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const areasOptions = useAreasOptions()
    const usersOptions = useUsersOptions()
    const currentOrg = useCurrentOrg()
    const saveGeneralTask = useSaveGeneralTask(asFirebase(firebaseWrapped))
    const area = areasOptions.find(area => area.value === (task?.area?.key ?? task?.areaKey))
    const integrationParams = useRecoilValue(integrationParamsAtom)
    const taskboardContext = useRecoilValue(taskboardContextAtom)

    const defaultValues = useMemo(() => {
        if (typeof task?.areaKey === 'undefined') {
            // reservation task
            return task ? constructDefaultTaskFormValues(task, currentOrg) : initialGeneralTaskForm
        } else {
            // non-reservation task
            return task
                ? constructDefaultTaskFormValues(
                      area
                          ? {
                                ...task,
                                area
                            }
                          : task,
                      currentOrg
                  )
                : initialGeneralTaskForm
        }
    }, [task])

    const form = useForm<GeneralTaskForm>({ defaultValues })
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = form

    async function onSubmit(data: GeneralTaskForm) {
        setLoading(true)
        data.assignees?.length > 0 && localStorage.setItem('lastAssignees', JSON.stringify(data.assignees))
        await saveGeneralTask({ ...data, ...integrationParams }, task?.key)
        setLoading(false)
        navigate({ to: `${prefix}/taskboard` })
    }

    return (
        <Modal className="w-3/5 h-3/4 overflow-y-auto flex flex-col max-h-[662px]" onClose={() => navigate({ to: `${prefix}/taskboard` })}>
            <ModalHeader className="bg-white pb-8" onClose={() => navigate({ to: `${prefix}/taskboard` })}>
                <UI.Text size="xl" weight="bold" color="snap-black">
                    {task ? tasks.editTaskHeader() : tasks.newTaskHeader()}
                </UI.Text>
            </ModalHeader>
            <FormProvider {...form}>
                <div className="flex justify-between items-end px-6">
                    <SelectedUnit />
                    {task && 'reservationId' in task && task.reservationId && task.pmsLinkUrl && (
                        <a className="pb-5" href={task.pmsLinkUrl} rel="noopener noreferrer" target="_blank">
                            <UnderlineButton text={tasks.reservation({ id: task.reservationId })} />
                        </a>
                    )}
                </div>
                <form className="flex flex-col h-fit justify-between px-6 pb-6 grow" onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col gap-8">
                        <FieldLabel label={`${shared.nameLabel()}:`} fieldName="name">
                            <Input required name="name" placeholder={tasks.namePlaceholder()} disabled={loading} register={register} />
                            {errors.name && <FieldErrorMessage message={shared.defaultError()} />}
                        </FieldLabel>

                        <FieldLabel horizontal label={`${shared.dueDate()}:`} fieldName="dueDate">
                            <div className="flex items-center gap-4">
                                <UI.Icon icon="specta-duedate" color="snap-light-blue" />
                                <DateField defaultValue={defaultValues.dueDate!} />
                            </div>
                        </FieldLabel>

                        {taskboardContext !== TaskboardContext.RESERVATIONS && !(task && 'reservationId' in task) && (
                            <FieldLabel horizontal label={`${shared.unit()}:`} fieldName="area">
                                <FieldSelect
                                    defaultValue={defaultValues.area}
                                    name={'area'}
                                    options={areasOptions}
                                    disabled={loading}
                                    Option={({ data, innerProps }) => (
                                        <div {...innerProps} className="flex flex-col w-full gap-1 p-2 border-b cursor-pointer">
                                            <UI.Text size="md" weight="bold">
                                                {data.label}
                                            </UI.Text>
                                            <UI.Text color="snap-silver" size="sm" weight="normal">
                                                {data.group}
                                            </UI.Text>
                                        </div>
                                    )}
                                    placeholder={shared.unitPlaceholder()}
                                    rules={{ required: shared.errorFieldIsRequired({ field: shared.unit() }) }}
                                />
                            </FieldLabel>
                        )}
                        <FieldLabel horizontal label={`${shared.assignUsersHeader()}:`} fieldName="assignees">
                            <FieldSelect
                                defaultValue={defaultValues.assignees}
                                name={'assignees'}
                                placeholder={shared.selectUsers()}
                                isMulti
                                options={usersOptions}
                                Option={({ data, innerProps }) => (
                                    <div
                                        {...innerProps}
                                        className="flex items-center w-full gap-2 p-2 border-b border-snap-light-gray cursor-pointer">
                                        <InitialsCircle initials={data.initials} size="sm" />
                                        <UI.Text size="sm" weight="normal">
                                            {data.label}
                                        </UI.Text>
                                    </div>
                                )}
                            />
                        </FieldLabel>
                        <div className="flex justify-between items-center">
                            <PriorityCheckbox />
                            <UnderlineButton
                                text={shared.assignSameAsLast()}
                                onClick={() => {
                                    const lastAssignees = JSON.parse(localStorage.getItem('lastAssignees') ?? '[]')
                                    setValue('assignees', lastAssignees)
                                }}
                            />
                        </div>
                        <div className="flex items-center justify-end">
                            <div className="flex gap-6 justify-end">
                                {loading ? (
                                    <UI.Loader />
                                ) : (
                                    <>
                                        <Button
                                            className="bg-snap-silver"
                                            type="button"
                                            onClick={() => navigate({ to: `${prefix}/taskboard` })}>
                                            {shared.cancel()}
                                        </Button>
                                        <Button type="submit">{shared.save()}</Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    )
}

function SelectedUnit() {
    const {
        LL: { tasks }
    } = useI18nContext()
    const { watch } = useFormContext()
    const selectedUnit = watch('area')
    return (
        <div className="flex flex-col gap-2 mb-6">
            <UI.Text size="sm" color="snap-mid-gray" weight="bold">
                {selectedUnit?.label ?? tasks.noUnit()}
            </UI.Text>
            <div className="w-16 h-16 border flex items-center justify-center rounded-md border-snap-silver">
                <img src={generalTaskIcon} />
            </div>
        </div>
    )
}

function UnderlineButton({ text, onClick }: { text: string; onClick?: () => void }) {
    return (
        <button type="button" className="w-fit underline decoration-snap-yellow underline-offset-4" onClick={onClick}>
            <UI.Text weight="semibold" color="snap-red">
                {text}
            </UI.Text>
        </button>
    )
}
