import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/storage'
import { getBrand } from '@shared/brand'
import type { Firebase, FirebaseFirestore } from '@shared/firebase'
import firebase from 'firebase/compat/app'
const config = getBrand().firebase!
export class RecaptchaVerifier extends firebase.auth.RecaptchaVerifier {}

firebase.initializeApp(config)
// const firebaseWrapped = spy(firebase)
const firebaseWrapped = firebase
export const db = firebaseWrapped.firestore()
export const storage = firebase.storage()
export const auth = firebase.auth()
export const asFirebase = (firebase2: typeof firebase): Firebase => firebase2 as unknown as Firebase
export const asFirestore = (firebase2: typeof db): FirebaseFirestore =>
    Object.assign(firebase2 as unknown as FirebaseFirestore, {
        documentId: () => {
            return '__name__'
        }
    })
if (process.env.VITE_ENV === 'dev') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    globalThis.db = db
}
export default firebaseWrapped

if (location.hostname === 'localhost' && process.env.VITE_LOCAL_STACK) {
    db.useEmulator('localhost', 8080)
    firebase.functions().useEmulator('localhost', 5001)
}
