import type { Firebase } from '@shared/firebase'
import type { IssueStruct } from '@shared/firestore-structs'
import { getHashTags } from '@shared/helpers'
import { addHashtagToIssue, removeHashtagFromIssue, updateIssueHashtags } from '@shared/issue-data'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'

export function useAddHashtagsToIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (issueKey: string, hashtag: string, name: IssueStruct['name']) => {
        try {
            await addHashtagToIssue(firebase, {
                currentUser,
                issueKey,
                hashtag,
                currentName: name
            })
        } catch (error: unknown) {
            //TODO: add proper error handling
            console.error(error)
            throw new Error('Error adding hashtags to issue', { cause: error })
        }
    }
}
export function useRemoveHashtagsFromIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (issueKey: string, hashtag: string, name: IssueStruct['name']) => {
        try {
            await removeHashtagFromIssue(firebase, {
                currentUser,
                issueKey,
                hashtag,
                currentName: name
            })
        } catch (error: unknown) {
            //TODO: add proper error handling
            console.error(error)
            throw new Error('Error adding hashtags to issue', { cause: error })
        }
    }
}
export function useUpdateIssueHashtags(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (issueKey: string, hashtags: string[], name: IssueStruct['name']) => {
        try {
            await updateIssueHashtags(firebase, {
                currentUser,
                issueKey,
                hashtags,
                currentName: name
            })
        } catch (error: unknown) {
            //TODO: add proper error handling
            console.error(error)
            throw new Error('Error adding hashtags to issue', { cause: error })
        }
    }
}

export function useMassUpdateHashtagsToIssues(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (type: 'add' | 'remove', issues: IssueStruct[], hashtags: string[]) => {
        const db = firebase.firestore()
        const batch = db.batch()

        const filterHashtags = (name: string) => getHashTags(name).filter(h => !hashtags.includes(h))
        const getTargetHashtags = type === 'add' ? () => hashtags : (name: string) => filterHashtags(name)

        try {
            await Promise.all([
                ...issues.map(issue =>
                    updateIssueHashtags(
                        firebase,
                        {
                            currentUser,
                            issueKey: issue.key,
                            hashtags: getTargetHashtags(issue.name),
                            currentName: issue.name
                        },
                        batch
                    )
                )
            ])

            await batch.commit()
        } catch (error: unknown) {
            console.error(error)
            throw new Error('Error mass updating hashtags to issues', { cause: error })
        }
    }
}
