import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { createFileRoute } from '@tanstack/react-router'
import { CategoryModal } from 'components/organisms/category-modal'

export const Route = createFileRoute('/snap/_authenticated/settings/categories/$key')({
    component: () => {
        const org = useCurrentOrg()
        const categoryId = Route.useParams().key
        return <CategoryModal categoryToEdit={org.categories?.find(cat => cat.id === categoryId)} />
    }
})
