import type { Firebase } from '@shared/firebase'
import { createIssue } from '@shared/issue-data'
import moment from 'moment'
import { useRecoilValue } from 'recoil'
import { findSelectedItemsByKeys } from '../../../utils'
import { useCurrentOrg, useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useUsers } from '../../auth/hooks/use-users'
import { areasSelector } from '../../auth/state/login'
import type { IssueForm } from '../types'

export function useCreateIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const areas = useRecoilValue(areasSelector)
    const users = useUsers()
    const currentOrg = useCurrentOrg()

    return async (formData: IssueForm) => {
        const { area, assignees, priority, title, hashtags, images, dueDate } = formData
        const [selectedArea] = findSelectedItemsByKeys(areas, [area.value])
        const selectedUsers =
            assignees && assignees.length > 0
                ? findSelectedItemsByKeys(
                      users,
                      assignees.map(u => u.value)
                  )
                : []
        const selectedHashtags = hashtags.map(h => h.value)
        const startDate = moment.tz(dueDate, currentOrg.timezone).startOf('day').valueOf()

        try {
            await createIssue(firebase, {
                currentUser,
                name: title,
                area: selectedArea,
                hashtags: selectedHashtags,
                dueDate: startDate,
                priority,
                images,
                assignedTo: selectedUsers,
                categories: formData.categories
            })
        } catch (error) {
            //TODO: proper error handling
            console.error(error)
            throw new Error('Failed to create issue')
        }
    }
}
