import { useEffect, useMemo, useState } from 'react'

export function usePaginatedData<T>(initialData: T[], limit = 10) {
    const [data, setData] = useState(initialData)
    const [currentPage, setCurrentPage] = useState(1)
    const totalPages = Math.ceil(data.length / limit)

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * limit
        const endIndex = startIndex + limit
        return data.slice(startIndex, endIndex)
    }, [data, currentPage, limit])

    useEffect(() => {
        setCurrentPage(1)
    }, [data])

    return [paginatedData, { currentPage, totalPages, setCurrentPage, setData }] as const
}
