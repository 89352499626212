import type { Firebase } from '@shared/firebase'
import type { TaskStruct } from '@shared/firestore-structs'
import { completeIssue } from '@shared/issue-data'
import { completeTask } from '@shared/task-data'
import moment from 'moment-timezone'
import { useCurrentOrg, useCurrentUser } from '../../auth/hooks/use-auth-state'

export function useCompleteTask(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()
    const completedDate = moment.tz(currentOrg.timezone).startOf('day')

    return async (task: TaskStruct) => {
        try {
            if (task.type === 'issue') {
                await completeIssue(firebase, {
                    issueKey: task.key,
                    currentUser,
                    completedDate
                })
            } else {
                await completeTask(firebase, currentUser, task.key)
            }
        } catch (error) {
            //TODO: Add proper error handling
            console.error(error)
            throw new Error('Failed to complete task')
        }
    }
}
