import type { UserStruct } from '@shared/firestore-structs'

const superUsers = [
    '+3548242728', // Petar
    '+3548495890', // Frans
    '+3548400101', // Brynja
    '+3547874040', // Þórhildur
    '+3548609100', // Elli
    '+3549991111', // Support
    '+3548564798' //Valdi
]
export const isSuperUser = (user: Pick<UserStruct, 'phoneNumber'>) => superUsers.includes(user.phoneNumber)
