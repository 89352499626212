import type { PrimitivesProps } from '@shared-snap/snap/registry/ui-elements-registry'
import { useClickOutside } from 'app/hooks/use-click-outside'
import { useRef } from 'react'

type Overflow = 'visible' | 'hidden' | 'scroll' | 'auto'

interface Props extends PrimitivesProps {
    onBlur: () => void
    width?: number
    overflow?: Overflow
    height?: number
    direction?: 'down' | 'up'
}

function ContextMenu({ children, onBlur, width, overflow, height, className, direction = 'down' }: Props) {
    const wrapRef = useRef<HTMLDivElement>(null)

    useClickOutside(wrapRef, onBlur)

    return (
        <div
            className={`absolute flex flex-col bg-white animate-fadeInDown shadow-md z-10 p-1 rounded-lg overflow-${overflow || 'visible'} 
                        ${width ? `w-${width}` : 'w-60'} 
                        ${height ? `h-${height}` : 'h-auto'} 
                        ${direction === 'up' ? 'bottom-full' : 'top-full'} 
                        ${className}`}
            ref={wrapRef}>
            {children}
        </div>
    )
}

export default ContextMenu
