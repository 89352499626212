import { getBrand } from '@shared/brand'
import type { OrgStruct, UserStruct } from '@shared/dataObjects'
import moment from 'moment'
import { capitalize } from '../../../utils'

type IntercomFunctions = (props?: any) => void

interface UserDataParams {
    currentUser: UserStruct
    currentOrganization: OrgStruct
}

function createUserData(INTERCOM_APP_ID?: string, params?: UserDataParams) {
    if (!params) {
        return { appId: INTERCOM_APP_ID }
    }

    const { currentUser, currentOrganization } = params
    const userData = {
        appId: INTERCOM_APP_ID,
        userId: currentUser.key,
        name: currentUser.name,
        email: currentUser.email,
        phone: currentUser.phoneNumber,
        createdAt: moment(currentUser.created).unix(),
        customAttributes: {
            teammates: currentOrganization.userCount - 1,
            role: capitalize(currentUser.authRole)
        }
    }

    return !currentUser.authSuper
        ? {
              ...userData,
              company: {
                  companyId: currentOrganization.key,
                  name: currentOrganization.name,
                  createdAt: moment(currentOrganization.created).unix(),
                  customAttributes: {
                      syncedAreas: currentOrganization.areasSyncedTotal,
                      unsyncedAreas: currentOrganization.areasNotSyncedTotal,
                      userCount: currentOrganization.userCount
                  }
              }
          }
        : { ...userData, customAttributes: { role: 'Super admin' } }
}

export function useIntercomStateChange(
    boot: IntercomFunctions,
    update: IntercomFunctions,
    shutdown: IntercomFunctions,
    params?: UserDataParams
) {
    const INTERCOM_APP_ID = getBrand().intercomAppId

    if (!INTERCOM_APP_ID) {
        console.warn('Intercom App ID is not configured')
        return
    }

    const userData = createUserData(INTERCOM_APP_ID, params)

    if (!('userId' in userData)) {
        shutdown()
        userData.appId && boot(userData)
        return
    }

    // @ts-ignore
    window.Intercom?.booted ? update(userData) : boot(userData)
}
