import type { Firebase } from '@shared/firebase'
import type { UserAnalytics } from '@shared/firestore-structs'
import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useCurrentOrg } from '../../auth/hooks/use-auth-state'
import { usersAtom } from '../state/users-state'

export function useUsers() {
    return useRecoilValue(usersAtom)
}

export function useSetUsers() {
    return useSetRecoilState(usersAtom)
}

export function useUsersWithAnalytics(firebase: Firebase) {
    const users = useUsers()
    const currentOrganization = useCurrentOrg()
    const [analytics, setAnalytics] = useState<{ [key: string]: UserAnalytics }>({})
    useEffect(() => {
        if (users.length === 0) return
        firebase
            .firestore()
            .collection<UserAnalytics>('user-analytics')
            .where('organizationKey', '==', currentOrganization.key)
            .get()
            .then(snap => {
                const orgUserAnalytics = snap.docs.filter(doc => users.some(user => user.key === doc.id))
                setAnalytics(
                    orgUserAnalytics.reduce<{ [key: string]: UserAnalytics }>((acc, doc) => {
                        acc[doc.id] = doc.data()
                        return acc
                    }, {})
                )
            })
    }, [users.length, currentOrganization.key])
    return users.map(user => ({ ...user, analytics: analytics[user.key!] }))
}

export function useEditedUser(userKey?: string | null) {
    const users = useRecoilValue(usersAtom)

    if (!userKey) return null

    return users.find(user => user.key === userKey)
}
