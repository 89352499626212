import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useSelection } from '@shared-snap/snap/hooks/use-selection'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { search } from '@shared-snap/snap/utils'
import type { UserOption } from '@shared/user-data'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { SearchInput } from 'components/atoms/search-input'
import { useEffect, useMemo, useState } from 'react'

interface Props {
    usersOptions: UserOption[]
    isOpen: boolean
    onClose: () => void
    onAssign: (userKeys: string[]) => void
    initialSelected?: string[]
    closeByPressOutside?: boolean
}

interface UserOptionProps {
    user: UserOption
    onClick: (userKey: string) => void
    selected: boolean
}

export function UsersModal({ usersOptions, isOpen, onClose, onAssign, initialSelected = [], closeByPressOutside = true }: Props) {
    const {
        LL: { shared }
    } = useI18nContext()
    const [searchVal, setSearchVal] = useState('')
    const [users, setUsers] = useState(usersOptions ?? [])
    const { selectedOptions, handleSelect, handleClear } = useSelection(initialSelected)
    const usersQuantity = useMemo(() => usersOptions.length - selectedOptions.size, [usersOptions, selectedOptions])
    const selectedUsers = useMemo(() => usersOptions.filter(user => selectedOptions.has(user.value)), [usersOptions, selectedOptions])

    const handleAssign = () => {
        onClose()
        onAssign(Array.from(selectedOptions))
    }

    const handleUnassign = () => {
        handleClear()
    }

    const handleSearch = (value: string) => {
        setSearchVal(value)
        const filteredUsers = search(value, usersOptions, user => user.label)
        setUsers(filteredUsers)
    }

    useEffect(() => {
        setSearchVal('')
        setUsers(usersOptions)
    }, [])

    return (
        <Modal
            className="z-20 w-1/2 overflow-none h-4/6 flex flex-col"
            isOpen={isOpen}
            closeByPressOutside={closeByPressOutside}
            onClose={onClose}>
            <ModalHeader onClose={onClose} closeColor="snap-black" className="bg-white pb-3 z-50 rounded-t-lg">
                <div className="flex items-center gap-3 mb-3">
                    <UI.Text weight="bold" size="md" color="snap-black">
                        {shared.usersModalHeader()}
                    </UI.Text>
                    <QuantityLabel quantity={usersQuantity} size="md" />
                </div>
                <SearchInput value={searchVal} onChange={handleSearch} placeholder={shared.selectUsers()} />
            </ModalHeader>
            <div className="px-6 pb-3 overflow-y-auto grow">
                <div className="flex flex-col gap-4">
                    <UI.List
                        items={users}
                        render={user => (
                            <UserOptionItem
                                key={user.value}
                                selected={selectedOptions.has(user.value)}
                                user={user}
                                onClick={handleSelect}
                            />
                        )}
                    />
                </div>
            </div>
            {selectedOptions.size > 0 && (
                <div className="px-6 bg-white w-full min-h-[80px] flex justify-between items-center border-t border-sweeply-light-gray rounded-b-lg">
                    <div className="flex gap-[8px]">
                        {selectedUsers.slice(0, 6).map(user => (
                            <UI.InitialsCircle key={`${user.value}-${user.label}`} initials={user.initials} name={user.label} />
                        ))}
                        {selectedUsers.length > 6 && <UI.InitialsCircle initials={`+${selectedUsers.length - 6}`} />}
                        <UI.Icon className="cursor-pointer h-fit" onClick={handleUnassign} icon="specta-close" size="xs" />
                    </div>
                    <UI.Button text={shared.assignBtn()} onClick={handleAssign} />
                </div>
            )}
        </Modal>
    )
}

function UserOptionItem({ user, onClick, selected }: UserOptionProps) {
    return (
        <UI.Pressable onPress={() => onClick(user.value)}>
            <div className={`flex items-center gap-3 cursor-pointer ${selected ? 'opacity-50' : ''}`}>
                <UI.InitialsCircle initials={user.initials} name={user.label} />
                <span>{user.label}</span>
            </div>
        </UI.Pressable>
    )
}
