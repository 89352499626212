import type { IssueArea, OrgStruct, TaskStruct } from '@shared/firestore-structs'
import { removeUndefinedValues } from '@shared/helpers'
import moment from 'moment'
import type { GeneralTaskForm } from './types'

export function constructDefaultTaskFormValues(task: TaskStruct, org: OrgStruct): Partial<GeneralTaskForm> {
    let area: GeneralTaskForm['area'] | undefined = undefined
    if (task.area && 'group' in task.area && 'name' in task.area) {
        area = { value: task.areaKey, label: task.area.name, group: task.area.group ?? '' }
    }
    const assignees =
        task.assignedTo && task.assignedTo.length > 0
            ? task.assignedTo.map(user => ({ value: user.key, label: user.name, initials: user.initials }))
            : []

    const defaultValues: Partial<GeneralTaskForm> = {
        name: task.name,
        dueDate: moment.tz(task.startDate, org.timezone).startOf('day').format('YYYY-MM-DD'),
        area,
        assignees,
        priority: task.priority ?? false
    }

    return removeUndefinedValues(defaultValues)
}

export function taskAreaToIssueArea(area: TaskStruct['area']): IssueArea {
    if (
        !area ||
        typeof area.address !== 'string' ||
        typeof area.key !== 'string' ||
        typeof area.name !== 'string' ||
        typeof area.group !== 'string'
    ) {
        throw new Error('Missing or invalid required fields in area object')
    }

    return {
        address: area.address,
        key: area.key,
        description: area.description,
        name: area.name,
        group: area.group
    }
}
