import { getArea } from '@shared/area-data'
import type { Firebase } from '@shared/firebase'
import type { TaskStruct } from '@shared/firestore-structs'
import { setDueDateToIssue } from '@shared/issue-data'
import { setTaskUpdate } from '@shared/task-data'
import moment from 'moment'
import { useCurrentOrg, useCurrentUser } from '../../auth/hooks/use-auth-state'

export function useChangeTaskDueDate(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()
    return async (task: TaskStruct, date: string) => {
        const area = task.areaKey ? await getArea(firebase, task.areaKey) : undefined
        const dueDateNumber = moment.tz(date, currentOrg.timezone).startOf('day')
        const taskObject = { startDate: dueDateNumber }

        try {
            if (task.type === 'issue') {
                if (area === undefined) {
                    throw new Error('Area is undefined')
                }

                await setDueDateToIssue(firebase, {
                    issueKey: task.key,
                    dueDate: dueDateNumber.valueOf(),
                    assignedTo: task.assignedTo ?? null,
                    currentUser
                })
            } else {
                await setTaskUpdate(firebase, currentUser, task.key, taskObject)
            }
        } catch (error: unknown) {
            console.error(error)
            throw new Error('Error changing task due date')
        }
    }
}
