import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Translation } from '@shared-snap/i18n/i18n-types'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useSubscription } from '@shared-snap/snap/components/settings/hooks/use-subscription'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Colors } from '@shared-snap/snap/types/visuals.types'
import type { SubscriptionPlan } from '@shared/firestore-structs'
import { FeatureId } from '@shared/subscriptions.types'
// import { ALL_FEATURES } from '@shared/subscriptions/feature-items'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button'
import { ConfirmModal } from 'components/layouts/settings/confirm-modal'
import { useEffect, useState } from 'react'

const BASIC_PLAN = [
    'unlimited-users',
    'tasks-2',
    'simple-cleaning-rules',
    'start-stop-housekeeping',
    'start-stop-housekeeping-2',
    'ios-android-apps',
    'booking-data-updated-from-godo-property'
]
const PRO_PLAN = [
    'everything-basic',
    'unlimited-areas',
    'complex-rules',
    'assign-housekeeping',
    'assign-tasks',
    'issues',
    'issue-reports',
    'user-access-control'
]

export const MARKETING_PLANS: { [name in 'basic' | 'pro' | 'pronto-basic' | 'pronto-pro']: string[] } = {
    basic: BASIC_PLAN,
    pro: PRO_PLAN,
    'pronto-basic': BASIC_PLAN,
    'pronto-pro': PRO_PLAN
}

const PLAN_TYPES = {
    PRO: 'pro',
    PREMIUM: 'premium',
    BASIC: 'basic'
} as const

export const renderIcon = (key: string): string => {
    if (!key) return '🏠'

    const normalizedKey = key?.toLowerCase()

    if (normalizedKey.includes(PLAN_TYPES.PRO)) {
        return '🏨'
    } else if (normalizedKey.includes(PLAN_TYPES.PREMIUM)) {
        return '🏢'
    } else if (normalizedKey.includes(PLAN_TYPES.BASIC)) {
        return '🏠'
    }
    return ''
}

function getPrice(price: number, currency: string) {
    const lang = navigator.languages != undefined ? navigator.languages[0] : navigator.language

    return new Intl.NumberFormat(lang, {
        style: 'currency',
        currency,
        maximumSignificantDigits: 3
    }).format(price / 100)
}

function CartContainer({ children, isEnterprize, isPro }: { children: React.ReactNode; isEnterprize?: boolean; isPro?: boolean }) {
    return (
        <div
            className={`flex flex-col gap-y-8 w-[275px] rounded-b shadow-lg px-6 pt-8 pb-16 border-t-8 ${
                isPro ? 'border-sweeply-teal' : isEnterprize ? 'border-black' : 'border-sweeply-yellow'
            }`}>
            {children}
        </div>
    )
}

function CartHeader({
    name,
    currencyCode,
    mainColor,
    pricing,
    hasExtraDetails,
    isPro
}: {
    name: string
    currencyCode: string
    isPro: boolean
    pricing: {
        period: number
        periodUnit: string
        startingPrice: number
    }
    hasExtraDetails?: boolean
    isEnterprize?: boolean
    mainColor: Colors
}) {
    return (
        <div className={`pb-6 border-b border-b-2 border-${mainColor}`}>
            <div className="mb-4">
                <h2 className="text-[26px] font-medium">{name}</h2>
            </div>

            <div className="flex gap-x-4 items-center">
                <span
                    className={`text-[40px] font-semibold relative ${
                        hasExtraDetails ? `after:content-["*"] after:relative after:top-[-15px] after:text-${mainColor}` : ''
                    }`}>
                    {getPrice(pricing.startingPrice ?? 0, currencyCode)}
                </span>
                {isPro && (
                    <div className="flex flex-col text-sweeply-gray">
                        <span>Room / {pricing.period > 1 ? pricing.period : null}</span>
                        <span>{pricing.period > 1 ? pricing.periodUnit + '`s' : pricing.periodUnit}</span>
                    </div>
                )}
            </div>
        </div>
    )
}

function CartFeatures({ features, mainColor }: { features: string[]; mainColor: Colors }) {
    return (
        <div className="flex flex-col gap-y-6">
            {features.map((feature, index) => (
                <div key={feature} className="flex gap-x-2 items-center">
                    <div className={`w-3 h-[1px] bg-${mainColor}`} />
                    <UI.Text weight={index === 0 ? 'bold' : 'medium'}>{feature}</UI.Text>
                </div>
            ))}
        </div>
    )
}

export function Subscription() {
    const [plans, setPlans] = useState<SubscriptionPlan[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const getPlans = async () => {
            const plansQuery = await asFirebase(firebase).firestore().collection('plans').where('visible', '==', true).get()

            const plans = plansQuery.docs.map(item => item.data())

            return plans as SubscriptionPlan[]
        }

        getPlans()
            .then(fetchedPlans => {
                setPlans(fetchedPlans)
            })
            .catch(error => {
                console.error('Error fetching plans:', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    if (loading) return <UI.Loader />

    return (
        <div className="flex justify-center items-start gap-4 grow shrink-0">
            {plans.map(plan => (
                <SubscriptionCart key={plan.key} plan={plan} />
            ))}
            <EnterpriseCart />
        </div>
    )
}

function SubscriptionCart({ plan }: { plan: SubscriptionPlan }) {
    const [isConfirmLoading, setIsConfirmLoading] = useState(false)
    const [cancelSubscriptionModalIsOpen, setCancelSubscriptionModalIsOpen] = useState(false)
    const currentOrganization = useCurrentOrg()
    const { cancelSubscription } = useSubscription(asFirebase(firebase))
    const {
        LL: {
            settingsWeb: { accountSubscription, subscriptionFeatures }
        }
    } = useI18nContext()

    const currencyCode = currentOrganization.currencyCode
    const isPro = plan.upgrades.length === 0
    const pricing = plan.pricing[`${currencyCode}:month`]
    const mainColor = isPro ? 'sweeply-teal' : 'sweeply-yellow'
    const planKey = plan.key as keyof typeof MARKETING_PLANS
    const isActiveSubscription = currentOrganization.subscription === planKey
    const isPaidSubscription = isPro && isActiveSubscription
    const planFeatures = MARKETING_PLANS[planKey].map(feature =>
        subscriptionFeatures[feature as keyof Translation['settingsWeb']['subscriptionFeatures']]()
    )

    const handleCancel = async () => {
        setIsConfirmLoading(true)

        try {
            await cancelSubscription()
            setCancelSubscriptionModalIsOpen(false)
        } catch (error) {
            console.error('Failed to cancel subscription:', error)
        } finally {
            setIsConfirmLoading(false)
        }
    }

    return (
        <div className="relative">
            {isActiveSubscription && (
                <div className="absolute left-[5px] top-[-40px] flex items-center gap-x-2">
                    <UI.Text size="lg" color="sweeply-gray">
                        ★
                    </UI.Text>
                    <UI.Text color="sweeply-gray">{accountSubscription.currentSubscription()}</UI.Text>
                </div>
            )}
            <CartContainer isPro={isPro}>
                <CartHeader
                    name={`${plan.name} ${renderIcon(planKey)}`}
                    currencyCode={currencyCode}
                    pricing={pricing}
                    hasExtraDetails={isPro}
                    mainColor={mainColor}
                    isPro={isPro}
                />
                <CartFeatures features={planFeatures} mainColor={mainColor} />
                {isPro && (
                    <div className="relative pl-8 before:content-['*'] before:text-sweeply-teal before:text-[40px] before:absolute before:top-[-15px] before:left-0">
                        <UI.Text color="sweeply-gray">{accountSubscription.moreRoomsText()}</UI.Text>{' '}
                        <a
                            className="text-sweeply-red border-b-2 border-sweeply-yellow"
                            href="https://getsweeply.com/pricing"
                            target="_blank">
                            {accountSubscription.pricingDetails()}
                        </a>
                    </div>
                )}
                {isPaidSubscription && (
                    <Button onClick={() => setCancelSubscriptionModalIsOpen(true)}>{accountSubscription.cancelSubscriptionBtn()}</Button>
                )}
            </CartContainer>

            <ConfirmModal
                isOpen={cancelSubscriptionModalIsOpen}
                onClose={() => setCancelSubscriptionModalIsOpen(false)}
                onConfirm={handleCancel}
                headerText={accountSubscription.cancelingSubscriptionHeader()}
                bodytext={accountSubscription.cancelingSubscriptionBody()}
                isLoading={isConfirmLoading}
            />
        </div>
    )
}

function EnterpriseCart() {
    const {
        LL: {
            settingsWeb: { accountSubscription, subscriptionFeatures }
        }
    } = useI18nContext()
    const pricing = {
        startingPrice: 500,
        period: 1,
        periodUnit: 'month'
    }
    const features = [subscriptionFeatures['everything-pro'](), subscriptionFeatures['optional-housekeeping']()]

    return (
        <CartContainer isEnterprize>
            <CartHeader name="Premium&nbsp;🏢" currencyCode="EUR" isPro pricing={pricing} mainColor="sweeply-black" />
            <CartFeatures features={features} mainColor="sweeply-black" />
            <a
                className="flex items-center justify-center px-[32px] rounded-[4px] h-[42px] w-fit mx-auto font-bold text-white text-[14px] bg-snap-red"
                type="button"
                href="https://calendly.com/d/d2q-gdh-cng/sweeply-demo"
                rel="noopener noreferrer"
                target="_blank">
                {accountSubscription.contactSalesBtn()}
            </a>
        </CartContainer>
    )
}
