import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'

interface PaginationProps {
    currentPage: number
    totalPages: number
    onPageChange: (page: number) => void
    onNextPage?: () => void
    onPrevPage?: () => void
}

export function Pagination({ currentPage, totalPages, onPageChange, onNextPage, onPrevPage }: PaginationProps) {
    const {
        LL: {
            issues: { pagination }
        }
    } = useI18nContext()
    const paginationBtnStyle = 'py-1 bg-gray-200 rounded-md hover:bg-gray-300 w-8 disabled:pointer-events-none disabled:opacity-50'
    if (totalPages <= 1) {
        return null
    }

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page)
        }
    }

    const handleGoToNextPage = () => {
        if (onNextPage) {
            onNextPage()
        } else {
            handlePageChange(currentPage + 1)
        }
    }

    const handleGoToPrevPage = () => {
        if (onPrevPage) {
            onPrevPage()
        } else {
            handlePageChange(currentPage - 1)
        }
    }

    return (
        <nav className="flex justify-center mt-4 w-full">
            <ul className="flex items-center space-x-2 w-full">
                <li>
                    <button className={paginationBtnStyle} onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                        {'<<'}
                    </button>
                </li>
                <li>
                    <button className={paginationBtnStyle} onClick={handleGoToPrevPage} disabled={currentPage === 1}>
                        {'<'}
                    </button>
                </li>
                <li>
                    <button className={paginationBtnStyle} onClick={handleGoToNextPage} disabled={currentPage === totalPages}>
                        {'>'}
                    </button>
                </li>
                <li>
                    <button
                        className={paginationBtnStyle}
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}>
                        {'>>'}
                    </button>
                </li>
                <li>
                    <div className="flex gap-1 items-center">
                        <UI.Text size="md" weight="normal">
                            {pagination.page()}
                        </UI.Text>
                        <UI.Text size="md">{pagination.currentPage({ currentPage, totalPages })}</UI.Text>
                    </div>
                </li>
                <li>
                    <div className="flex gap-1 items-center">
                        <UI.Text size="md" weight="normal">
                            {pagination.goTo()}
                        </UI.Text>
                        <input
                            type="number"
                            min="1"
                            max={totalPages}
                            defaultValue={currentPage}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) : 1
                                onPageChange(page)
                            }}
                            className="border-b p-1 w-16 border-snap-light-silver focus:outline-none"
                        />
                    </div>
                </li>
            </ul>
        </nav>
    )
}
