import type { Option } from '@shared-snap/snap/types/ui-types'
import { SnapColors } from '@shared/colors'
import { ZIndex } from '@shared/css-constants'
import { Controller, type FieldValues, type Path, type PathValue, type RegisterOptions, useFormContext } from 'react-hook-form'
import ReactSelect, { type GroupBase, type OptionProps, type StylesConfig } from 'react-select'
import CreatableSelect from 'react-select/creatable'

interface Props<TFieldValues extends FieldValues = FieldValues, TOption extends Option = Option> {
    defaultValue: PathValue<TFieldValues, any>
    name: Path<TFieldValues>
    options: TOption[]
    isMulti?: boolean
    placeholder?: string
    Option?: (props: OptionProps<TOption, boolean>) => JSX.Element
    disabled?: boolean
    rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'> | undefined
    isCreatable?: boolean
}

export function FieldSelect<TFieldValues extends FieldValues = FieldValues, TOption extends Option = Option>({
    defaultValue,
    name,
    options,
    isMulti = false,
    Option,
    disabled,
    placeholder,
    rules,
    isCreatable
}: Props<TFieldValues, TOption>) {
    const {
        control,
        formState: { errors }
    } = useFormContext()
    const selectStyles: StylesConfig<TOption> = {
        control: (styles, state) => ({
            ...styles,
            borderRadius: 10,
            borderColor: errors[name]
                ? `${SnapColors.red} !important`
                : state.hasValue
                  ? `${SnapColors.lightBlue} !important`
                  : `${SnapColors.lightGray} !important`,
            fontWeight: 600,
            boxShadow: 'none',
            cursor: 'pointer'
        }),
        placeholder: styles => ({
            ...styles,
            fontWeight: 400,
            color: errors[name] ? `${SnapColors.red} !important` : `${SnapColors.silver} !important`
        }),
        singleValue: styles => ({
            ...styles,
            fontWeight: 400,
            color: `${SnapColors.lightBlue} !important`
        }),
        multiValue: styles => ({
            ...styles,
            fontWeight: 400,
            background: 'none'
        }),
        multiValueLabel: styles => ({
            ...styles,
            color: `${SnapColors.lightBlue} !important`
        }),
        multiValueRemove: () => ({
            display: 'none'
        }),
        menu: styles => ({
            ...styles,
            boxShadow:
                '0px 101px 28px 0px rgba(0, 0, 0, 0.00), 0px 64px 26px 0px rgba(0, 0, 0, 0.01), 0px 36px 22px 0px rgba(0, 0, 0, 0.05), 0px 16px 16px 0px rgba(0, 0, 0, 0.09), 0px 4px 9px 0px rgba(0, 0, 0, 0.10)',
            borderRadius: 8,
            padding: 8,
            width: 254,
            right: 0,
            zIndex: ZIndex.dropdown
        }),
        menuPortal: styles => ({ ...styles, zIndex: ZIndex.modal, overflow: 'scroll' }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
            backgroundColor: state.isFocused ? `${SnapColors.lightGray} !important` : ''
        })
    }
    const components = {
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        ...(Option && { Option })
    }
    return (
        <Controller
            control={control}
            defaultValue={defaultValue}
            rules={rules ? rules : undefined}
            render={({ field }) =>
                isCreatable ? (
                    <CreatableSelect<TOption, boolean, GroupBase<TOption>>
                        {...field}
                        styles={selectStyles}
                        isSearchable={options.length > 10}
                        isDisabled={disabled}
                        placeholder={placeholder}
                        options={options}
                        isMulti={isMulti}
                        components={components}
                        id={name}
                        menuPlacement="auto"
                    />
                ) : (
                    <ReactSelect<TOption, boolean, GroupBase<TOption>>
                        {...field}
                        styles={selectStyles}
                        isSearchable={options.length > 10}
                        isDisabled={disabled}
                        placeholder={placeholder}
                        options={options}
                        isMulti={isMulti}
                        components={components}
                        id={name}
                        menuPlacement="auto"
                    />
                )
            }
            name={name}
        />
    )
}
