import type { Firebase } from '@shared/firebase'
import type { IssueStruct } from '@shared/firestore-structs'
import { assignIssue, unassignIssue } from '@shared/issue-data'
import { constructAssignedTo } from '@shared/task-helpers'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useUsers } from '../../auth/hooks/use-users'
import { useIssueData, useSetIssueState } from './use-issues'

export function useAssignIssue(firebase: Firebase, issueKey: string) {
    const currentUser = useCurrentUser()
    const allUsers = useUsers()
    const issue = useIssueData(issueKey)
    const setIssueState = useSetIssueState()
    if (!issue) {
        return async (): Promise<void> => {}
    }

    return async (userKeys: string[]) => {
        const users = allUsers.filter(user => userKeys.includes(user.key))
        const assignedTo = users.length > 0 ? users.map(user => constructAssignedTo(user)) : []

        setIssueState({
            key: issue.key,
            assignedTo: assignedTo.length > 0 ? assignedTo : null,
            status: assignedTo.length > 0 ? 'assigned' : 'open'
        })

        try {
            if (assignedTo.length > 0) {
                await assignIssue(firebase, {
                    issueKey: issue.key,
                    assignedTo,
                    dueDate: issue.startDate,
                    currentUser
                })
            } else {
                issue.key &&
                    (await unassignIssue(firebase, {
                        issueKey: issue.key,
                        currentUser
                    }))
            }
        } catch (error: unknown) {
            //TODO: Add proper error handling
            console.error(error)
            throw new Error('Error assigning issue')
        }
    }
}

export function useMassAssignIssues(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const allUsers = useUsers()
    const setIssueState = useSetIssueState()

    return async (issues: IssueStruct[], userKeys: string[]) => {
        const users = allUsers.filter(user => userKeys.includes(user.key))
        const assignedTo = users.length > 0 ? users.map(user => constructAssignedTo(user)) : []

        issues.forEach(issue => {
            setIssueState({
                key: issue.key,
                assignedTo: assignedTo.length > 0 ? assignedTo : null,
                status: assignedTo.length > 0 ? 'assigned' : 'open'
            })
        })

        const db = firebase.firestore()
        const batch = db.batch()

        try {
            await Promise.all([
                ...issues.map(issue =>
                    assignedTo.length > 0
                        ? assignIssue(
                              firebase,
                              {
                                  issueKey: issue.key,
                                  assignedTo,
                                  dueDate: issue.startDate,
                                  currentUser
                              },
                              batch
                          )
                        : issue.key &&
                          unassignIssue(
                              firebase,
                              {
                                  issueKey: issue.key,
                                  currentUser
                              },
                              batch
                          )
                )
            ])

            await batch.commit()
        } catch (error: unknown) {
            console.error(error)
            throw new Error('Failed to mass assign issues')
        }
    }
}
