import { useMemo } from 'react'
import type { LocalizedString } from 'typesafe-i18n'
import { useI18nContext } from '../../../../../i18n/i18n-react'
import { UI } from '../../../../registry/ui-elements-registry'
import type { Icon } from '../../../../types/icon-font.enum'
import { RoundedButton } from '../../../molecules/rounded-button'
import { useNotes, useSelectedTab, useSetSelectedTab, useTabs } from '../hooks/use-comments-and-notes'
import type { Note, NotesTab } from '../state/comments-and-notes-state'

interface NavigationProps {
    Tab: (props: NotesTab) => JSX.Element
    isTaskView?: boolean
}

export interface CommentsAndNotesListProps {
    Title?: (props: { title: LocalizedString; count: number }) => JSX.Element
    TitleWithIcon?: (props: { title: LocalizedString; icon: Icon }) => JSX.Element
    NoteItem: (props: Note) => JSX.Element
}

export function CommentsAndNotesButton({ onPress }: { onPress?: () => void }) {
    return <RoundedButton icon={'specta-daily-comment'} backgroundColor="sweeply-mid-gray" onClick={() => (onPress ? onPress() : null)} />
}

export function Navigation({ Tab, isTaskView = false }: NavigationProps) {
    const tabs = useTabs(isTaskView)
    const setSelectedTab = useSetSelectedTab()

    return (
        <UI.List
            items={tabs}
            render={tab => (
                <UI.Pressable className="w-[64px]" key={tab.type} onPress={() => setSelectedTab(tab.type)}>
                    <Tab {...tab} />
                </UI.Pressable>
            )}
        />
    )
}

export function CommentsAndNotesList({ NoteItem, Title, TitleWithIcon }: CommentsAndNotesListProps) {
    const tabs = useTabs()
    const selectedTabType = useSelectedTab()
    const { title, icon } = useMemo(() => tabs.find(tab => tab.type === selectedTabType) ?? tabs[0], [selectedTabType])
    const notes = useNotes(selectedTabType)
    const { LL } = useI18nContext()
    return (
        <div>
            {Title && <Title title={LL.commentAndNotes[title]()} count={notes.length} />}
            {TitleWithIcon && <TitleWithIcon title={LL.commentAndNotes[title]()} icon={icon} />}
            <ul>
                <UI.List
                    items={notes}
                    render={(note, index) => (
                        <li
                            key={index}
                            className={`border-b border-snap-light-gray p-3 px-1 flex flex-col gap-2 ${index === 0 ? 'border-t' : ''}`}>
                            <NoteItem key={note.areaName + index} {...note} />
                        </li>
                    )}
                />
            </ul>
        </div>
    )
}
