import { useSetRecoilState } from 'recoil'
import { isAuthenticatedAtom, stopAuthListenerAtom } from '../state/login'

export function useSignOut(perform: () => Promise<void>) {
    const setStopAutListener = useSetRecoilState(stopAuthListenerAtom)
    const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom)

    const onPress = async () => {
        setStopAutListener(true)

        await perform()
        console.log('Logged out')

        setIsAuthenticated(false)
        setStopAutListener(false)
    }
    return onPress
}
