import type { ConfirmationResult, Firebase } from '@shared/firebase'
import { getActiveUserByPhoneNumber } from '@shared/user-data'
import { useSetRecoilState } from 'recoil'
import { confirmationRef, isAuthenticatedAtom, loggedInLoading, loggedInUser } from '../state/login'

// This is a one-step sign-in function that takes a function that performs the sign-in
export function useSignInWithUserPass<T>(perform: (ctx: T) => Promise<void>) {
    const setLoading = useSetRecoilState(loggedInLoading)
    const handleLogin = async (ctx: T) => {
        try {
            setLoading(true)
            await perform(ctx)
            return 'success'
        } catch (error: any) {
            setLoading(false)
            return 'error'
        }
    }
    return handleLogin
}

export function useSignInWithPhone<T>(perform: (ctx: T) => Promise<ConfirmationResult>) {
    const setLoading = useSetRecoilState(loggedInLoading)
    const handleLogin = async (ctx: T) => {
        try {
            setLoading(true)
            const ctx2 = await perform(ctx)
            if (ctx2) {
                console.log(`Got confirmation result`)
            } else {
                console.log(`No confirmation result`)
            }
            confirmationRef.current = ctx2
            return 'success'
        } catch (error: any) {
            setLoading(false)
            return 'error'
        }
    }
    return handleLogin
}

export const usePhoneCode = (firebase: Firebase) => {
    const setIsAuthenticated = useSetRecoilState(isAuthenticatedAtom)
    const setUser = useSetRecoilState(loggedInUser)
    const setLoading = useSetRecoilState(loggedInLoading)

    const confirmPhoneCode = async (code: string) => {
        const confirmation = confirmationRef.current
        if (!confirmation) {
            throw new Error('Confirmation not set or ID mismatch.')
        }

        try {
            const userCredentials = await confirmation.confirm(code)

            if (!userCredentials?.user) {
                throw new Error('Phone number login process failed.')
            }

            const currentUser = await getActiveUserByPhoneNumber(firebase.firestore(), userCredentials.user)
            setUser(currentUser)
            setLoading(false)
            setIsAuthenticated(true)
        } catch (error) {
            console.error('Error confirming phone code:', error)
            throw error
        }
    }

    return confirmPhoneCode
}
