import moment, { duration } from 'moment-timezone'
import type { CleaningStruct } from './firestore-structs'
import { sortTimeStampAscending } from './helpers'

export interface TimerDisplay {
    hours: string
    min: string
    sec: string
}

const getLastStopTime = (stops: number[] | undefined): number | null => {
    return stops?.length ? stops[stops.length - 1] : null
}

export const getEndTime = (cleaningObj: CleaningStruct): number | null => {
    return cleaningObj.end ?? getLastStopTime(cleaningObj.stop)
}

type TimelineEntry = { action: 'play' | 'pause' | 'stop'; timestamp: number }

function timelineToPeriods(segment: TimelineEntry[]) {
    return segment.flatMap<{ type: 'action' | 'pause'; durationMs: number }>((entry, index) => {
        if (index === 0) return []
        const prev = segment[index - 1]
        if (prev.action === 'play') return [{ type: 'action', durationMs: entry.timestamp - prev.timestamp }]
        if (prev.action === 'pause') return [{ type: 'pause', durationMs: entry.timestamp - prev.timestamp }]
        return [] // should never happen since 'stop' is always the last entry
    })
}

function getClassifiedSegments(cleaningObj: CleaningStruct) {
    const timeline: TimelineEntry[] = [
        ...(cleaningObj.play || []).map(x => ({ timestamp: x, action: 'play' }) as const),
        ...(cleaningObj.stop || []).map(x => ({ timestamp: x, action: 'stop' }) as const),
        ...(cleaningObj.pause || []).map(x => ({ timestamp: x, action: 'pause' }) as const)
    ]
    timeline.sort((a, b) => sortTimeStampAscending(a.timestamp, b.timestamp))

    // Add missing start and end events to the timeline
    if (cleaningObj.start && timeline.find(x => x.action === 'play' && x.timestamp === cleaningObj.start) === undefined) {
        timeline.unshift({ timestamp: cleaningObj.start, action: 'play' })
    }
    if (cleaningObj.end && timeline.find(x => x.action === 'stop' && x.timestamp === cleaningObj.end) === undefined) {
        timeline.push({ timestamp: cleaningObj.end, action: 'stop' })
    }

    return timeline.reduce(
        (acc, curr) => {
            if (curr.action === 'stop') {
                acc.incomplete.push(curr)
                acc.completed.push(acc.incomplete)
                acc.incomplete = []
            } else {
                if (curr.action !== 'play' && acc.incomplete.length === 0) {
                    console.warn(`Ignoring ${curr.action} event without a preceding play event`)
                } else {
                    acc.incomplete.push(curr)
                }
            }
            return acc
        },
        { completed: [], incomplete: [] } as { completed: TimelineEntry[][]; incomplete: TimelineEntry[] }
    )
}

function getActionTimeMs(segment: TimelineEntry[], type: 'action' | 'pause' = 'action') {
    return timelineToPeriods(segment)
        .filter(x => x.type === type)
        .map(x => x.durationMs)
        .reduce((acc, curr) => acc + curr, 0)
}

export function getRunningCleaningDurationMs(cleaningObj: CleaningStruct, now: number = moment().valueOf()) {
    const segment = getClassifiedSegments(cleaningObj).incomplete
    segment.push({ action: 'stop', timestamp: now }) // terminate with current time to calculate running duration this far
    return getActionTimeMs(segment)
}

export function getTimerStatus(cleaningObj: CleaningStruct, now: number = moment().valueOf()) {
    const segment = getClassifiedSegments(cleaningObj).incomplete
    if (segment.length === 0) return { status: 'stopped' } as const
    const last = segment[segment.length - 1]
    segment.push({ action: 'stop', timestamp: now }) // terminate with current time to calculate running duration this far
    const value = getActionTimeMs(segment)
    if (last.action === 'pause') return { status: 'paused', duration: value } as const
    if (last.action === 'play') return { status: 'running', duration: value } as const
    throw new Error(`Unexpected last action ${last.action}`)
}

export function getCompletedCleaningDurationMs(cleaningObj: CleaningStruct) {
    const completedSegments = getClassifiedSegments(cleaningObj).completed
    if (completedSegments.length > 1) completedSegments.pop() // remove the last segment since we assume that is inspection
    return completedSegments.map(segment => getActionTimeMs(segment)).reduce((acc, curr) => acc + curr, 0)
}

export function getPausedDurationMs(cleaningObj: CleaningStruct) {
    const completedSegments = getClassifiedSegments(cleaningObj).completed
    if (completedSegments.length > 1) completedSegments.pop() // remove the last segment since we assume that is inspection
    return completedSegments.map(segment => getActionTimeMs(segment, 'pause')).reduce((acc, curr) => acc + curr, 0)
}

export function getTimerDisplay(cleaningObj: CleaningStruct | null): TimerDisplay {
    if (!cleaningObj)
        return {
            hours: '00',
            min: '00',
            sec: '00'
        }

    const sinceStart = duration(getRunningCleaningDurationMs(cleaningObj), 'milliseconds').asSeconds()

    const timerDisplay = {
        hours: moment().hour(0).minute(0).second(sinceStart).format('HH'),
        min: moment().hour(0).minute(0).second(sinceStart).format('mm'),
        sec: moment().hour(0).minute(0).second(sinceStart).format('ss')
    }

    return timerDisplay
}
