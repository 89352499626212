import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { InitialsCircle } from 'components/atoms/initials-circle/initials-circle'

interface Props {
    assignees: { name: string; initials: string; key: string }[]
    onUnassign?: () => void
}

export function AssigneesBlock({ assignees, onUnassign }: Props) {
    const {
        LL: { shared }
    } = useI18nContext()
    return (
        <div className="w-fit max-w-9/12">
            <div className="flex items-center justify-between mb-4 gap-2">
                <UI.Text size="md" weight="normal">
                    {shared.assignedUsers()}{' '}
                </UI.Text>
                {onUnassign ? (
                    <UI.Pressable onPress={onUnassign}>
                        <UI.Icon icon="specta-close" color="sweeply-gray" size="xs" />
                    </UI.Pressable>
                ) : null}
            </div>

            <div className={`flex gap-2 ${assignees.length < 3 ? 'flex-col' : ''}`}>
                <UI.List
                    items={assignees}
                    render={assignee => (
                        <div key={assignee.key} className="flex items-center gap-2">
                            <InitialsCircle size="md" initials={assignee.initials} name={assignee.name} />
                            {assignees.length < 3 && (
                                <UI.Text size="md" weight="normal" color="snap-mid-gray">
                                    {assignee.name}
                                </UI.Text>
                            )}
                        </div>
                    )}
                />
            </div>
        </div>
    )
}
