import { useEffect, useRef, useState } from 'react'

export function useSelection(initialSelected: string[] = []) {
    const [selectedOptions, setSelectedOptions] = useState(new Set<string>(initialSelected))
    const prevInitialSelectedRef = useRef<string[]>([])

    useEffect(() => {
        if (
            initialSelected.length !== prevInitialSelectedRef.current.length ||
            initialSelected.some((item, index) => item !== prevInitialSelectedRef.current[index])
        ) {
            setSelectedOptions(new Set(initialSelected))
            prevInitialSelectedRef.current = [...initialSelected]
        }
    }, [initialSelected])

    const handleSelect = (key: string) => {
        setSelectedOptions((prev: Set<string>) => {
            const next: Set<string> = new Set(prev)
            if (next.has(key)) {
                next.delete(key)
            } else {
                next.add(key)
            }
            return next
        })
    }

    const handleCancel = () => {
        setSelectedOptions(new Set(initialSelected))
    }

    const handleClear = () => {
        setSelectedOptions(new Set())
    }

    const handleSelectAll = (keys: string[]) => {
        setSelectedOptions(new Set(keys))
    }

    const addItem = (key: string) => {
        setSelectedOptions((prev: Set<string>) => {
            const next: Set<string> = new Set(prev)
            next.add(key)
            return next
        })
    }

    const removeItem = (key: string) => {
        setSelectedOptions((prev: Set<string>) => {
            const next: Set<string> = new Set(prev)
            next.delete(key)
            return next
        })
    }

    return { selectedOptions, handleSelect, handleClear, handleSelectAll, addItem, removeItem, handleCancel }
}
