import type { Firebase } from '@shared/firebase'
import { useRecoilValue } from 'recoil'
import { useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUserAreaSummaryAction } from '../../use-user-action'
import { areaSummaryPrioritySelector } from '../state/area-summary-state'

export function useAreaSummaryPriorityUpdate(firebase: Firebase) {
    const priority = useRecoilValue(areaSummaryPrioritySelector)
    const currentUser = useCurrentUser()
    const action = useUserAreaSummaryAction('set-priority', firebase)

    function updatePriority() {
        action(!priority, currentUser)
    }

    return updatePriority
}
