import type { CommentivityStruct } from '@shared/firestore-structs'
import * as c from '@shared/txt-constants'
import moment from 'moment'
import { UI } from '../../../registry/ui-elements-registry'
import { useCurrentOrg } from '../../auth/hooks/use-auth-state'

interface CommentivityRowRenderer {
    createdTime: string
    createdDay: string
    text: string
}
type CommentRowRenderer = CommentivityRowRenderer & { initials: string; name: string }

export function CommentivitiesList({
    RenderActivity,
    RenderComment,
    commentivities
}: {
    RenderActivity: (props: CommentivityRowRenderer) => JSX.Element
    RenderComment: (props: CommentRowRenderer) => JSX.Element
    commentivities: CommentivityStruct[]
}) {
    if (commentivities.length === 0) return null

    return (
        <UI.List
            items={commentivities}
            render={commentivity => {
                return (
                    <CommentivityRow
                        key={commentivity.key}
                        commentivity={commentivity}
                        RenderActivity={RenderActivity}
                        RenderComment={RenderComment}
                    />
                )
            }}
        />
    )
}

export function CommentivityRow({
    commentivity,
    RenderActivity,
    RenderComment
}: {
    commentivity: CommentivityStruct
    RenderActivity: (props: CommentivityRowRenderer) => JSX.Element
    RenderComment: (props: CommentRowRenderer) => JSX.Element
}) {
    const currentOrg = useCurrentOrg()
    const createdDay = moment.tz(commentivity.created, currentOrg.timezone).format(c.DATE_FORMAT_WITH_WEEK_DAY)
    const createdTime = moment.tz(commentivity.created, currentOrg.timezone).format('HH:mm')
    const text = commentivity.creator.name + ' ' + commentivity.text

    if (commentivity.type === 'comment') {
        return (
            <RenderComment
                initials={commentivity.creator.initials ?? ''}
                name={commentivity.creator.name ?? ''}
                createdDay={createdDay}
                createdTime={createdTime}
                text={commentivity.text}
            />
        )
    }

    return <RenderActivity createdTime={createdTime} createdDay={createdDay} text={text} />
}
