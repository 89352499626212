import { useEffect } from 'react'
import { type RecoilState, useRecoilState } from 'recoil'

interface DropdownProps {
    onDropdownClose?: () => void
    onDropdownOpen?: () => void
}

export function useDropdown(atom: RecoilState<boolean>, props?: DropdownProps) {
    const [dropdown, setDropdownOpen] = useRecoilState(atom)

    useEffect(() => {
        if (dropdown) {
            props?.onDropdownOpen?.()
        } else {
            props?.onDropdownClose?.()
        }
    }, [dropdown])

    return { dropdownIsOpen: dropdown, openDropdown: () => setDropdownOpen(true), closeDropdown: () => setDropdownOpen(false) }
}
