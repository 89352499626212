interface Props {
    date: string
    icon?: 'specta-duedate'
    overdue: boolean
}

export function DueDateSign({ date, icon, overdue }: Props) {
    return (
        <div className={`flex gap-1 text-${overdue ? 'sweeply-red' : 'sweeply-gray'} text-sm`}>
            <i className={icon ?? 'specta-duedate'} />
            <span>{date}</span>
        </div>
    )
}
