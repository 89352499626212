import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg, useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useEditedUser, useSetUsers, useUsers } from '@shared-snap/snap/components/settings/hooks/use-users'
import { useFeatureToggle } from '@shared-snap/snap/hooks/use-feature-toggles'
import { type Option, UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { capitalize } from '@shared-snap/snap/utils'
import { fetchAreaGroups } from '@shared/area-data'
import { isFeatureOn } from '@shared/feature-toggles'
import type { UserRole, UserStruct } from '@shared/firestore-structs'
import { getInitials, validPhoneNumber } from '@shared/helpers'
import type { CategorySelection } from '@shared/issue-data'
import { getRolesForOrg } from '@shared/roles/roles'
import { createUser } from '@shared/user-data'
import { useNavigate } from '@tanstack/react-router'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { Checkbox } from 'components/atoms/checkbox'
import { FieldErrorMessage } from 'components/atoms/field-error-message'
import { FieldLabel } from 'components/atoms/field-label'
import { FieldSelect } from 'components/atoms/field-select'
import Input from 'components/atoms/input/input'
import { Modal, ModalHeader } from 'components/atoms/modal/modal'
import { useCallback, useEffect, useState } from 'react'
import { c } from 'react-compiler-runtime'
import { FormProvider, useForm } from 'react-hook-form'
import { prefix } from 'routes/__root'
import { CategorySelectionBlock } from './categories-modal/categories-modal'

interface UserForm {
    name: string
    email: string
    phoneNumber: string
    authRole: Option<UserRole>
    areaGroups: Option<string>[]
    issueHashtags: Option<string>[]
    showCleaningTaskNote: boolean
    assignIssueHashtags: boolean
    assignIssueCategories: boolean
    categories: CategorySelection
}

export function UserModal({ userKey }: { userKey: string }) {
    const editedUser = useEditedUser(userKey)
    const users = useUsers()
    const setUsers = useSetUsers()

    const navigate = useNavigate()
    const organization = useCurrentOrg()
    const currentUser = useCurrentUser()

    const [userRolesOptions, setUserRolesOptions] = useState<Option<string>[]>([])
    const [areaGroups, setAreaGroups] = useState<Option<string>[]>([])
    const [isLoading, setIsLoading] = useState(false)

    const {
        LL: { settingsWeb, settings, shared, roles }
    } = useI18nContext()

    useEffect(() => {
        const loadData = async () => {
            try {
                const groups = await fetchAreaGroups(asFirebase(firebase), currentUser)
                const areaGroups = groups.map(group => ({ value: group, label: group }))
                const orgRoles = await getRolesForOrg(organization)

                const userRoles = (Object.keys(orgRoles) as (keyof typeof orgRoles)[]).reduce<{ value: string; label: string }[]>(
                    (acc, roleKey) => {
                        const role = orgRoles[roleKey]
                        if (role?.roleName && role?.roleDisplayName) {
                            acc.push({
                                value: role.roleName,
                                label: roles[role.roleName]()
                            })
                        }
                        return acc
                    },
                    []
                )

                setUserRolesOptions(userRoles)
                setAreaGroups(areaGroups)
            } catch (error) {
                console.error('Failed to load user data:', error)
                // TODO: Show user-friendly error message
            }
        }

        loadData()
    }, [])

    const defaultValues: Readonly<UserForm> = {
        name: '',
        email: '',
        phoneNumber: '',
        authRole: { value: 'user', label: roles.user() },
        areaGroups: [{ value: 'all', label: shared.all() }],
        issueHashtags: [],
        showCleaningTaskNote: false,
        assignIssueHashtags: false,
        assignIssueCategories: editedUser?.assignIssueCategories ?? false,
        categories: editedUser?.issueCategories ?? {}
    }
    const form = useForm<UserForm>({
        defaultValues: { ...defaultValues }
    })
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
        setValue,
        setError,
        getValues
    } = form

    const { isFeatureOn } = useFeatureToggle()

    const [categorySelection, setCategorySelection] = useState<CategorySelection>(editedUser?.issueCategories ?? {})

    const watchedIssueHashtags = watch('issueHashtags')
    const watchedAreaGroups = watch('areaGroups')
    const watchedCategories = watch('categories')

    useEffect(() => {
        const hasAll = watchedAreaGroups.some(group => group.value === 'all')

        if (watchedAreaGroups.length === 0 && !hasAll) {
            setValue('areaGroups', [{ value: 'all', label: shared.all() }], { shouldValidate: true })
        } else if (watchedAreaGroups.length > 1 && hasAll) {
            setValue(
                'areaGroups',
                watchedAreaGroups.filter(group => group.value !== 'all'),
                { shouldValidate: true }
            )
        }
    }, [watchedAreaGroups, setValue])

    useEffect(() => {
        if (editedUser) {
            setValue('name', editedUser.name ?? '')
            setValue('email', editedUser.email ?? '')
            setValue('phoneNumber', editedUser.phoneNumber ?? '')
            setValue(
                'authRole',
                editedUser.authRole
                    ? { value: editedUser.authRole, label: capitalize(editedUser.authRole) }
                    : { value: 'user', label: roles.user() }
            )
            setValue(
                'areaGroups',
                editedUser?.areaGroups
                    ? editedUser.areaGroups.map(group => ({ value: group, label: group }))
                    : [{ value: 'all', label: shared.all() }]
            )
            setValue(
                'issueHashtags',
                editedUser?.issueHashtags ? editedUser.issueHashtags.map(hashtag => ({ value: hashtag, label: hashtag })) : []
            )
            setValue('categories', editedUser.issueCategories ?? {})
            setValue('assignIssueCategories', editedUser.assignIssueCategories ?? false)
            setValue('showCleaningTaskNote', editedUser.showCleaningTaskNote ?? false)
            setValue('assignIssueHashtags', editedUser.assignIssueHashtags ?? false)
        }
    }, [editedUser, reset])

    useEffect(() => {
        setValue('categories', categorySelection)
    }, [categorySelection, setValue])

    const closeUserModal = useCallback(() => navigate({ to: `${prefix}/settings/users` }), [navigate])

    const handlePasswordReset = async () => {
        if (!editedUser?.email) return

        try {
            await firebase.auth().sendPasswordResetEmail(editedUser.email)
        } catch (error) {
            // TODO: Add error handling and confirmation message for password reset
            console.error(error)
        }
    }

    const validatePhoneNumber = useCallback(() => {
        const { phoneNumber } = getValues()

        if (!phoneNumber) {
            setError('phoneNumber', { message: shared.errorFieldIsRequired({ field: settingsWeb.users.phoneNumberColumn() }) })
            return false
        }

        try {
            const parsedPhoneNumber = validPhoneNumber(phoneNumber)

            if (!parsedPhoneNumber?.isValid()) {
                throw new Error('Invalid phone number')
            }

            return true
        } catch (error) {
            setError('phoneNumber', { message: settings.users.phoneError() })
            return false
        }
    }, [getValues, setError, settings.users.phoneError, settingsWeb.users.phoneNumberColumn, shared.errorFieldIsRequired])

    const onSubmit = useCallback(
        async (data: UserForm) => {
            const {
                name,
                email,
                phoneNumber,
                areaGroups,
                issueHashtags,
                showCleaningTaskNote,
                authRole,
                assignIssueHashtags,
                assignIssueCategories,
                categories
            } = data

            const isPhoneNumberValid = validatePhoneNumber()
            if (!isPhoneNumberValid) return

            const userInitials = getInitials(name)

            setIsLoading(true)

            try {
                const formattedAreaGroups = areaGroups.map(group => group.value)
                const formattedIssueHashtags = issueHashtags.map(hashtag => hashtag.value)

                const userKey = await createUser(asFirebase(firebase), {
                    key: editedUser?.key ?? null,
                    userName: name,
                    userEmail: email,
                    userNewPhoneNumber: phoneNumber,
                    userInitials,
                    userAreaGroups: formattedAreaGroups,
                    userIssueHashtags: formattedIssueHashtags,
                    userShowCleaningTaskNote: showCleaningTaskNote,
                    userOrganizationKey: currentUser?.organizationKey,
                    currentUserKey: currentUser?.key,
                    userRole: authRole.value,
                    userAssignIssueHashtags: assignIssueHashtags,
                    userIssueCategories: categories,
                    userAssignIssueCategories: assignIssueCategories
                })

                const user: Partial<UserStruct> = {
                    key: userKey,
                    name,
                    email,
                    phoneNumber,
                    areaGroups: formattedAreaGroups,
                    issueHashtags: issueHashtags.map(hashtag => hashtag.value),
                    showCleaningTaskNote,
                    authRole: authRole.value,
                    assignIssueHashtags,
                    issueCategories: categories,
                    assignIssueCategories
                }

                if (editedUser) {
                    setUsers(() => users.map(u => (u.key === userKey ? { ...u, ...user } : u)))
                } else {
                    setUsers(() => [...users, user])
                }

                closeUserModal()
            } catch (error) {
                console.error(error)
                if (error instanceof Error) {
                    setError('root', { message: error.message || shared.error() })
                } else {
                    setError('root', { message: shared.error() })
                }
            } finally {
                setIsLoading(false)
            }
        },
        [editedUser, setUsers, users, currentUser, setError, closeUserModal, shared.error, validatePhoneNumber]
    )

    return (
        <Modal className="w-3/5 max-h-[90%] min-h-[400px] flex flex-col rounded-md overflow-hidden" onClose={closeUserModal}>
            <ModalHeader className="bg-white pb-4" onClose={closeUserModal}>
                <UI.Text size="2xl" color="snap-black">
                    {userKey ? settings.users.editModalHeader() : settings.users.addModalHeader()}
                </UI.Text>
            </ModalHeader>
            <div className="grow px-6 pb-6 overflow-y-auto">
                <FormProvider {...form}>
                    <form className="flex flex-col h-full justify-between" onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col gap-8 mb-8">
                            <FieldLabel label={`${settingsWeb.users.nameColumn()}:`} required>
                                <Input name="name" placeholder={settingsWeb.users.namePlaceholder()} required register={register} />
                                {errors.name && (
                                    <FieldErrorMessage message={shared.errorFieldIsRequired({ field: settingsWeb.users.nameColumn() })} />
                                )}
                            </FieldLabel>
                            <FieldLabel label={`${settingsWeb.users.phoneNumberColumn()}:`} required>
                                <Input
                                    name="phoneNumber"
                                    placeholder={settingsWeb.users.phoneNumberPlaceholder()}
                                    required
                                    register={register}
                                />
                                {errors.phoneNumber && (
                                    <FieldErrorMessage
                                        message={
                                            errors.phoneNumber.message ||
                                            shared.errorFieldIsRequired({ field: settingsWeb.users.phoneNumberColumn() })
                                        }
                                    />
                                )}
                            </FieldLabel>
                            <FieldLabel label={`${settingsWeb.users.emailColumn()}:`}>
                                <Input name="email" placeholder={settingsWeb.users.emailPlaceholder()} register={register} />
                            </FieldLabel>
                            {editedUser?.email && (
                                <Button className="w-[200px]" type="button" disabled={!editedUser.email} onClick={handlePasswordReset}>
                                    Reset Password
                                </Button>
                            )}
                            <FieldLabel horizontal label={`${settingsWeb.users.roleColumn()}:`}>
                                <FieldSelect
                                    defaultValue=""
                                    name="authRole"
                                    options={userRolesOptions}
                                    Option={({ data, innerProps }) => (
                                        <div {...innerProps} className="flex flex-col w-full gap-1 p-2 border-b cursor-pointer">
                                            <UI.Text size="md" weight="bold">
                                                {data.label}
                                            </UI.Text>
                                        </div>
                                    )}
                                />
                            </FieldLabel>
                            <div className="flex items-center gap-x-2">
                                <Checkbox name="showCleaningTaskNote" />
                                <label htmlFor="showCleaningTaskNote">{`${settings.users.bookingNotesLabel()}:`}</label>
                            </div>
                            <FieldLabel horizontal label={settingsWeb.users.unitGroupColumn()} required>
                                <FieldSelect
                                    defaultValue=""
                                    name="areaGroups"
                                    options={areaGroups}
                                    Option={({ data, innerProps }) => (
                                        <div {...innerProps} className="flex flex-col w-full gap-1 p-2 border-b cursor-pointer">
                                            <UI.Text size="md" weight="bold">
                                                {data.label}
                                            </UI.Text>
                                        </div>
                                    )}
                                    isMulti
                                />
                            </FieldLabel>
                            {isFeatureOn('hashtag-killer') ? (
                                <>
                                    <FieldLabel horizontal={false} label={`${settingsWeb.categories.subscribeToCategories()}:`}>
                                        <div className="pl-4">
                                            <CategorySelectionBlock
                                                categorySelection={categorySelection}
                                                setCategorySelection={setCategorySelection}
                                                categoryTextProps={{
                                                    size: 'xs',
                                                    color: 'snap-dark-gray',
                                                    weight: 'normal',
                                                    align: 'left',
                                                    style: 'normal'
                                                }}
                                            />
                                        </div>
                                    </FieldLabel>
                                    {Object.keys(watchedCategories).length > 0 && (
                                        <div className="flex items-center gap-x-2">
                                            <Checkbox name="assignIssueCategories" />
                                            <label htmlFor="assignIssueCategories">{settings.users.assignIssues()}</label>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <FieldLabel horizontal label={`${settings.users.subscriptionLabel()}:`}>
                                        <FieldSelect
                                            defaultValue=""
                                            name="issueHashtags"
                                            isMulti
                                            options={organization.issueHashtags.map(hashtag => ({ value: hashtag, label: hashtag }))}
                                            Option={({ data, innerProps }) => (
                                                <div {...innerProps} className="flex flex-col w-full gap-1 p-2 border-b cursor-pointer">
                                                    <UI.Text size="md" weight="bold">
                                                        {data.label}
                                                    </UI.Text>
                                                </div>
                                            )}
                                            placeholder={settings.users.subscriptionPlaceholder()}
                                        />
                                    </FieldLabel>
                                    {watchedIssueHashtags.length > 0 && (
                                        <div className="flex items-center gap-x-2">
                                            <Checkbox name="assignIssueHashtags" />
                                            <label htmlFor="assignIssueHashtags">{settings.users.assignIssues()}</label>
                                        </div>
                                    )}
                                </>
                            )}
                            {errors.root && <FieldErrorMessage message={errors.root.message ?? ''} />}
                        </div>

                        <div className="w-full flex justify-end gap-x-4">
                            {isLoading ? (
                                <div>
                                    <UI.Loader />
                                </div>
                            ) : (
                                <>
                                    <Button className="bg-snap-silver" type="button" onClick={closeUserModal}>
                                        {shared.cancel()}
                                    </Button>
                                    <Button type="submit">{shared.save()}</Button>
                                </>
                            )}
                        </div>
                    </form>
                </FormProvider>
            </div>
        </Modal>
    )
}
