import { useEffect, useState } from 'react'
import { Controller, type FieldValues, type Path, useFormContext } from 'react-hook-form'

interface Props<TFieldValues extends FieldValues = FieldValues> {
    checked?: boolean
    onChange?: (checked: boolean) => void
    name?: Path<TFieldValues>
    disabled?: boolean
    className?: string
}

export function Checkbox<TFieldValues extends FieldValues = FieldValues>({
    disabled,
    checked,
    onChange,
    name,
    className
}: Props<TFieldValues>) {
    const [isChecked, setIsChecked] = useState(checked)
    const form = useFormContext()

    const handleCheckboxChange = () => {
        const newChecked = !isChecked
        setIsChecked(newChecked)
        onChange?.(newChecked)
    }

    useEffect(() => {
        if (checked !== undefined) {
            setIsChecked(checked)
        }
    }, [checked])

    if (form.control && name) {
        const { control } = form
        return (
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <input
                        disabled={disabled}
                        id={name}
                        type="checkbox"
                        className={`w-5 h-5 text-indigo-600 form-checkbox ${className}`}
                        checked={field.value}
                        onChange={() => field.onChange(!field.value)}
                    />
                )}
            />
        )
    }

    return (
        <input
            type="checkbox"
            className={`w-5 h-5 text-indigo-600 form-checkbox ${className}`}
            checked={isChecked}
            onChange={handleCheckboxChange}
        />
    )
}
