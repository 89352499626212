import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { loadLocaleAsync } from '@shared-snap/i18n/i18n-util.async'
import { useIntercomStateChange } from '@shared-snap/snap/components/auth/hooks/use-intercom-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import Logo from '@shared/img/brand/sweeply/logo-long.svg'
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'

export const Route = createFileRoute('/snap/_unauthenticated')({
    component: UnauthenticatedLayout
})

function UnauthenticatedLayout() {
    const { setLocale } = useI18nContext()
    const [isLoading, setIsLoading] = useState(true)
    const { boot, update, shutdown } = useIntercom()
    const isEmbeddedInLegacyWeb = process.env.VITE_EMBEDDED

    useEffect(() => {
        const handleIntercomState = () => {
            if (!isEmbeddedInLegacyWeb) {
                useIntercomStateChange(boot, update, shutdown)
            }
        }
        handleIntercomState()

        const loadLocale = async () => {
            try {
                await loadLocaleAsync('en')
                setLocale('en')
            } catch (error) {
                console.error('Failed to load locale:', error)
            } finally {
                setIsLoading(false)
            }
        }

        loadLocale()
    }, [])

    return (
        <div className="bg-[linear-gradient(134.14deg,_rgba(255,220,0,0.25)_18.05%,_rgba(255,52,66,0.25)_85.85%)] overflow-scroll">
            <div className="flex items-center justify-center min-h-screen py-4 px-4 overflow-auto">
                <div className="flex flex-col w-[584px] h-2/3 min-h-fit bg-white rounded shadow pt-16 px-20 pb-8">
                    <div className="flex items-center justify-center mb-16">
                        <img alt="logo" src={Logo} />
                    </div>

                    <div className="flex flex-col justify-start grow">{isLoading ? <UI.Loader /> : <Outlet />}</div>
                    <div id="recaptcha-container" />
                </div>
            </div>
        </div>
    )
}
