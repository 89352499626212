import { rulesAtom } from '@shared-snap/snap/components/auth/state/login'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { countWorkload } from '@shared-snap/snap/utils/housekeeping-utils'
import type { AreaStruct } from '@shared/firestore-structs'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

interface Props {
    areas: Pick<AreaStruct, 'occupancy' | 'cleaningStatus'>[]
}

export function Workload({ areas }: Props) {
    const rules = useRecoilValue(rulesAtom)

    const counts = useMemo(() => countWorkload(areas, rules), [areas])
    const workloads = useMemo(
        () =>
            Object.entries(counts)
                .filter(([key, { count }]) => key !== 'otherWorkloadCount' && count > 0)
                .map(([, v]) => v),
        [counts]
    )

    return (
        <div className="flex justify-end w-full items-center gap-x-2.5">
            <UI.List
                items={workloads}
                render={workload => {
                    return (
                        <div key={workload.icon} className="flex gap-x-1">
                            <div className="max-w-[22px]">
                                <UI.Icon icon={workload.icon} size="sm" color="snap-silver" />
                            </div>
                            <div>
                                <UI.Text size="xs" color="snap-silver">
                                    {workload.count}
                                </UI.Text>
                            </div>
                        </div>
                    )
                }}
            />
        </div>
    )
}
