import { updateArea } from '@shared/area-data'
import type { Firebase } from '@shared/firebase'
import type { AreaCleaningStatus, AreaStruct } from '@shared/firestore-structs'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import type { UnitForm } from '../../my-tasks/types'
import { useSelectedUnits, useUnits } from './use-units'

export function useEditUnit(firebase: Firebase) {
    const currentUser = useCurrentUser()

    async function editUnit(formData: UnitForm, areaKey: string) {
        const { name, description, address, group, displayCleaningStatus } = formData
        const updatedArea = {
            name,
            description,
            address,
            group,
            displayCleaningStatus
        }
        try {
            await updateArea(firebase, areaKey, updatedArea, currentUser)
        } catch (error) {
            console.error(error)
        }
    }
    return editUnit
}

export function useMassEditUnits(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const [units, setUnits] = useUnits()
    const [, setSelectedUnits] = useSelectedUnits()

    async function editUnits(
        selectedUnits: Partial<AreaStruct>[],
        updated: { description?: string; group?: string; cleaningStatus?: AreaCleaningStatus }
    ) {
        const batch = firebase.firestore().batch()

        try {
            await Promise.all(
                selectedUnits.map(({ key, description, group, cleaningStatus }) => {
                    if (!key) return

                    return updateArea(
                        firebase,
                        key,
                        {
                            description: updated.description ?? description,
                            group: updated.group ?? group,
                            cleaningStatus: updated.cleaningStatus ?? cleaningStatus
                        },
                        currentUser,
                        batch
                    )
                })
            )
        } catch (error) {
            console.error(error)
        } finally {
            await batch.commit()
            setUnits(
                units.map(unit => {
                    const selectedUnit = selectedUnits.find(selectedUnit => selectedUnit.key === unit.key)
                    if (!selectedUnit) return unit
                    return {
                        ...unit,
                        description: updated.description ?? unit.description,
                        group: updated.group ?? unit.group,
                        cleaningStatus: updated.cleaningStatus ?? unit.cleaningStatus
                    }
                })
            )
            setSelectedUnits([])
        }
    }

    return editUnits
}
