import type { Firebase } from '@shared/firebase'
import type { IssueStruct } from '@shared/firestore-structs'
import { setDueDateToIssue } from '@shared/issue-data'
import moment from 'moment'
import { useCurrentOrg, useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useSetIssueState } from './use-issues'

export function useChangeIssueDueDate(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()
    const setIssueState = useSetIssueState()

    return async (issue: IssueStruct, date: string | null) => {
        const dueDate = date ? moment.tz(date, currentOrg.timezone).startOf('day').valueOf() : null
        setIssueState({ key: issue.key, startDate: dueDate })

        try {
            await setDueDateToIssue(firebase, {
                currentUser,
                dueDate,
                issueKey: issue.key,
                assignedTo: issue.assignedTo ?? null
            })
        } catch (error: unknown) {
            //TODO: add proper error handling
            console.error(error)
            throw new Error('Error changing task due date')
        }
    }
}
