import { logActivity } from '@shared/area-data'
import type { Firebase } from '@shared/firebase'
import type { ActivityChangeObject } from '@shared/firestore-structs'
import { setPriorityToIssue } from '@shared/issue-data'
import { setTaskPriority } from '@shared/task-data'
import { useRecoilValue } from 'recoil'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'
import { startDateAtom } from '../state/my-tasks'
import { useTaskData } from './use-tasks'

export function useChangeTaskPriority(firebase: Firebase, taskKey: string) {
    const currentUser = useCurrentUser()
    const startDate = useRecoilValue(startDateAtom)
    const task = useTaskData(taskKey)

    return async () => {
        const { priority, type } = task

        try {
            const taskUpdate = { priority: !priority }

            await setTaskPriority(firebase, currentUser, task.key, taskUpdate)

            if (task.type === 'issue') {
                await setPriorityToIssue(firebase, {
                    issueKey: task.key,
                    priority: taskUpdate.priority,
                    currentUser
                })
            }

            if (task.area && type === 'cleaning') {
                const changeObj: ActivityChangeObject = {
                    field: 'cleaning-priority',
                    before: priority,
                    after: !priority
                }

                await logActivity(firebase, currentUser, task.area.key as string, 'cleaning-priority', startDate, changeObj)
            }
        } catch (error: unknown) {
            //TODO: proper error handling
            console.error(error)
            throw new Error('Error changing task priority')
        }
    }
}
