import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useAddHashtagsToIssue, useRemoveHashtagsFromIssue } from '@shared-snap/snap/components/issues/hooks/use-hashtags-issue'
import { useHashtagsOptions } from '@shared-snap/snap/components/issues/hooks/use-issues'
import { convertHashtagsToOptions } from '@shared-snap/snap/components/issues/utils'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { SnapColors } from '@shared/colors'
import { getHashTags } from '@shared/helpers'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import { type CustomOptionProps, Select } from 'components/atoms/select/select'
import { useMemo } from 'react'

type Option = {
    value: string
    label: string
}

export function HashtagsSelect<T extends string>({ issueKey, name }: { issueKey: T; name: T }) {
    const hashtagsOptions = useHashtagsOptions()
    const addHashtags = useAddHashtagsToIssue(asFirebase(firebaseWrapped))
    const removeHashtags = useRemoveHashtagsFromIssue(asFirebase(firebaseWrapped))
    const value = useMemo(() => convertHashtagsToOptions(getHashTags(name)), [name])
    const onChange = async (hashtag: string) => {
        if (value.some(val => val.value === hashtag)) {
            await removeHashtags(issueKey, hashtag, name)
        } else {
            await addHashtags(issueKey, hashtag, name)
        }
    }
    return <SelectHashtagsInput value={value} hashtagsOptions={hashtagsOptions} onChange={onChange} />
}

export function SelectHashtagsInput({
    value,
    hashtagsOptions,
    onChange
}: {
    value: Option[]
    hashtagsOptions: Option[]
    onChange: (hashtag: string) => void
}) {
    const {
        LL: { tooltips }
    } = useI18nContext()
    return (
        <Select
            value={value}
            options={hashtagsOptions}
            onChange={onChange}
            isMulti
            placeholder={tooltips.selectHashtags()}
            customStyles={{
                control: { border: 'none', borderBottom: '1px solid' + SnapColors.midSilver, paddingBottom: 4 },
                multiValueRemove: { ':hover': { background: 'none' } },
                clearIndicator: { display: 'none' }
            }}
            CustomOption={({ data, innerProps }) => <HashtagOption option={data} innerProps={innerProps} />}
        />
    )
}

function HashtagOption<T extends string>({ option, innerProps }: CustomOptionProps<T>) {
    return (
        <div {...innerProps} className="px-5 py-2 border-b border-snap-light-gray hover:bg-snap-light-gray cursor-pointer">
            <UI.Text size="sm" weight="normal" color="snap-light-blue">
                {option.label}
            </UI.Text>
        </div>
    )
}
