import { UI } from '../../../registry/ui-elements-registry'
import { useHashtagsFilter, useHashtagsWithCount } from '../hooks/use-issues'

export function HashtagsList({
    HashtagRenderer
}: {
    HashtagRenderer: (props: { chosen: boolean; hashtag: string; count: number }) => JSX.Element
}) {
    const hashtagsWithCount = useHashtagsWithCount()
    const [hashtagsFilter, setHashtagsFilter] = useHashtagsFilter()

    function onHashtagPress(hashtag: string) {
        if (hashtagsFilter[0] === 'all') {
            setHashtagsFilter([hashtag])
            return
        }

        if (hashtagsFilter.includes(hashtag)) {
            const newFilter = hashtagsFilter.filter(h => h !== hashtag)

            if (newFilter.length === 0) {
                setHashtagsFilter(['all'])
            } else {
                setHashtagsFilter(newFilter)
            }
        } else {
            setHashtagsFilter([...hashtagsFilter, hashtag])
        }
    }

    return (
        <UI.List
            items={hashtagsWithCount}
            render={({ hashtag, count }, i) => {
                return (
                    <UI.Pressable key={hashtag + i} onPress={() => onHashtagPress(hashtag)}>
                        <HashtagRenderer chosen={hashtagsFilter.includes(hashtag)} hashtag={hashtag} count={count} />
                    </UI.Pressable>
                )
            }}
        />
    )
}
