import { logActivity } from './area-data'
import type { FirebaseFirestore } from './firebase'
import type { UserStruct } from './firestore-structs'
import { setTaskPriority } from './task-data'

export async function updateAreaPriority(
    firebase: FirebaseFirestore,
    currentUser: UserStruct,
    areaKey: string,
    date: number,
    nextPriority: boolean,
    taskKey?: string | null,
    activityKey?: string,
    createdTimestamp?: number
) {
    try {
        await logActivity(
            firebase,
            currentUser,
            areaKey,
            'cleaning-priority',
            date,
            {
                before: !nextPriority,
                after: nextPriority,
                field: 'cleaning-priority'
            },
            undefined,
            activityKey,
            createdTimestamp
        )

        if (taskKey) {
            await setTaskPriority(firebase, currentUser, taskKey, { priority: nextPriority })
        }
    } catch (error) {
        console.error('(updateAreaPriority) ', error)
        throw error
    }
}
