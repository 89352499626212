import type { BookingStruct, TaskStruct, UserStruct } from './firestore-structs'
import { TaskboardContext } from './traces-types'

export const constructBreadcrumbs = (
    task: TaskStruct,
    context: TaskboardContext,
    bookings: BookingStruct[] = [],
    selectedArea: { label: string; value: string; subText: string } | null = null
) => {
    const breadcrumbItems = []
    const isReservationTask = 'reservationId' in task && task.reservationId
    const hasPropertyName = 'propName' in task && task.propName
    const isAccountContext = [TaskboardContext.ACCOUNT, TaskboardContext.EMBEDDED].includes(context)
    const hasArea = !!task.area || !!selectedArea
    const area = task.area
        ? task.area
        : selectedArea
          ? { name: selectedArea.label, key: selectedArea.value, group: selectedArea.subText }
          : undefined

    if (isAccountContext && hasPropertyName) {
        breadcrumbItems.push(task.propName)
    }
    if (hasArea && area?.group) {
        if (hasPropertyName && task.propName !== area.group) {
            breadcrumbItems.push(area.group)
        } else if (isAccountContext && !hasPropertyName) {
            breadcrumbItems.push(area.group)
        } else if (!hasPropertyName) {
            breadcrumbItems.push(area.group)
        }
        breadcrumbItems.push(area.name)
    }
    if (!hasArea) {
        breadcrumbItems.push('No unit')
    }
    if (isReservationTask && task.reservationId) {
        const taskBooking = bookings.find(booking => booking._external?.parentReservationID === task.reservationId)
        if (taskBooking) {
            breadcrumbItems.push(taskBooking.guestName)
        } else {
            breadcrumbItems.push(task.reservationId)
        }
    }
    return breadcrumbItems
}

export function constructAssignedTo(user: UserStruct) {
    return {
        key: user.key,
        initials: user.initials,
        name: user.name
    }
}
