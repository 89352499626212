import type React from 'react'
import { useRef, useState } from 'react'
import type { FieldValues, Path, UseFormRegister } from 'react-hook-form'

export interface TextAreaInputProps<TFieldValues extends FieldValues = FieldValues> {
    name: Path<TFieldValues>
    placeholder: string
    required?: boolean
    initialValue?: string
    onChange?: (value: string) => void
    className?: string
    disabled?: boolean
    onSave?: (value: string) => void
    register?: UseFormRegister<TFieldValues>
}

function TextAreaInput<TFieldValues extends FieldValues = FieldValues>({
    placeholder,
    initialValue,
    className,
    onChange,
    disabled,
    onSave,
    name,
    register,
    required = false
}: TextAreaInputProps<TFieldValues>) {
    const [value, setValue] = useState(initialValue || '')
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value

        setValue(newValue)
        if (onChange) {
            onChange(newValue)
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            if (event.shiftKey) {
                event.preventDefault()

                const cursorPosition = textareaRef.current?.selectionStart || 0
                const newValue = value.slice(0, cursorPosition) + '\n' + value.slice(cursorPosition)
                setValue(newValue)

                setTimeout(() => {
                    if (textareaRef.current) {
                        textareaRef.current.scrollTop = textareaRef.current.scrollHeight
                    }
                }, 0)
            } else {
                event.preventDefault()
                if (onSave) {
                    onSave(value)
                    event.currentTarget.blur()
                }
            }
        }
    }

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value
        setValue(newValue)
        if (onSave) {
            onSave(newValue)
        }
    }

    return (
        <textarea
            id={name}
            disabled={disabled}
            className={`py-2 border-b border-snap-light-gray focus:outline-none focus:ring-0 placeholder:text-sm overflow-hidden ${className}`}
            placeholder={placeholder}
            rows={1}
            value={register ? undefined : value}
            {...(register
                ? register(name, { required })
                : { onChange: handleChange, onKeyDown: handleKeyDown, onBlur: handleBlur, ref: textareaRef })}
        />
    )
}

export default TextAreaInput
