import type { IssueStruct } from '@shared/firestore-structs'
import { useMemo } from 'react'
import type { Translation } from '../../../../i18n/i18n-types'
import { UI } from '../../../registry/ui-elements-registry'
import { RoundedButton } from '../../molecules/rounded-button'
import { useCurrentStatusFilter } from '../hooks/use-issues'

interface Option {
    value: IssueStruct['status']
    label: keyof Translation['issues']['statuses']
    selected: boolean
}

export function StatusFilterList({
    StatusOptionRenderer,
    ResetRenderer,
    onResetPress
}: {
    StatusOptionRenderer: (props: Option) => JSX.Element
    ResetRenderer: () => JSX.Element
    onResetPress?: () => void
}) {
    const [currentStatusFilter, setCurrentStatusFilter] = useCurrentStatusFilter()
    const options = useMemo(() => createStatusOptions(currentStatusFilter), [currentStatusFilter.length])

    function onSelect(value: Option['value']) {
        setCurrentStatusFilter(prev => {
            if (prev.includes(value)) {
                return prev.filter(status => status !== value)
            }
            return [...prev, value]
        })
    }

    function onReset() {
        setCurrentStatusFilter(['open', 'assigned'])
    }

    return (
        <>
            <UI.List
                items={options}
                render={(option, index) => {
                    return (
                        <UI.Pressable key={option.value + index} onPress={() => onSelect(option.value)}>
                            <StatusOptionRenderer {...option} />
                        </UI.Pressable>
                    )
                }}
            />
            <UI.Pressable
                onPress={() => {
                    onReset()
                    onResetPress?.()
                }}>
                <ResetRenderer />
            </UI.Pressable>
        </>
    )
}

function createStatusOptions(statusFilterValues: IssueStruct['status'][]): Option[] {
    const statuses: Array<keyof Translation['issues']['statuses']> = ['open', 'assigned', 'deleted', 'completed']
    const allOptions: Option[] = statuses.map(status => ({
        label: status,
        selected: false,
        value: status
    }))

    return allOptions.map(option => {
        if (statusFilterValues.includes(option.value)) {
            return { ...option, selected: true }
        }
        return option
    })
}
