import type { AreaCleaningStatus } from '@shared/firestore-structs'
import { useEffect, useState } from 'react'
import { useI18nContext } from '../../../../../i18n/i18n-react'
import type { Translation } from '../../../../../i18n/i18n-types'
import { UI } from '../../../../registry/ui-elements-registry'
import { cleaningStatusOptions } from '../../../../utils/housekeeping-utils'
import { RoundedButton } from '../../../molecules/rounded-button'
import {
    useCleaningStatusFilterAtom,
    useCleaningStatusFilterValues,
    useSetCleaningStatusFilterValues
} from '../hooks/use-housekeeping-overview'

export interface Option {
    value: AreaCleaningStatus | 'all'
    label: keyof Translation['shared']['cleaningStatuses']
    selected: boolean
}

interface OptionItemProps {
    option: Option
    index: number
    onSelect: (value: AreaCleaningStatus | 'all') => void
    cleaningStatusOptionRenderer: (option: Option, i: number) => JSX.Element
}

export function CleaningStatusButton() {
    const { dropdownIsOpen, openDropdown, closeDropdown } = useCleaningStatusFilterAtom()

    return (
        <RoundedButton
            icon={'specta-housekeeping-filter'}
            backgroundColor="sweeply-mid-gray"
            onClick={() => (dropdownIsOpen ? closeDropdown() : openDropdown())}
        />
    )
}

export function ResetCleaningStatusButton() {
    const {
        LL: { filters }
    } = useI18nContext()
    const setCleaningStatusFilter = useSetCleaningStatusFilterValues()
    return <UI.Button onClick={() => setCleaningStatusFilter(['all'])} text={filters.reset()} />
}

export function ListCleaningStatusOptions(props: { cleaningStatusOptionRenderer: (option: Option, i: number) => JSX.Element }) {
    const cleaningStatusFilterValues = useCleaningStatusFilterValues()
    const filterOptions = createCleaningStatusOptions(cleaningStatusFilterValues)
    const [options, setOptions] = useState(filterOptions)
    const setCleaningStatusFilter = useSetCleaningStatusFilterValues()

    useEffect(() => {
        setOptions(createCleaningStatusOptions(cleaningStatusFilterValues))
    }, [cleaningStatusFilterValues])

    function onSelect(value: Option['value']) {
        let newOptions

        if (value === 'all') {
            const isAllSelected = !options[0].selected
            newOptions = options.map(option => ({
                ...option,
                selected: isAllSelected
            }))
        } else {
            newOptions = options.map(option => ({
                ...option,
                selected: option.value === value ? !option.selected : option.selected
            }))

            const allOtherSelected = newOptions.slice(1).every(option => option.selected)
            newOptions[0].selected = allOtherSelected
        }

        setOptions(newOptions)

        const newSelectedOptions: AreaCleaningStatus[] = newOptions
            .filter(option => option.selected && option.value !== 'all')
            .flatMap(option => (option.value === 'no-service' ? ['out-of-service', 'no-service'] : [option.value]))

        setCleaningStatusFilter(newSelectedOptions)
    }

    return (
        <>
            <UI.List
                items={options}
                render={(option, i) => (
                    <OptionItem
                        key={option.value}
                        option={option}
                        index={i}
                        onSelect={onSelect}
                        cleaningStatusOptionRenderer={props.cleaningStatusOptionRenderer}
                    />
                )}
            />
        </>
    )
}

function OptionItem({ option, index, onSelect, cleaningStatusOptionRenderer }: OptionItemProps) {
    return <UI.Pressable onPress={() => onSelect(option.value)}>{cleaningStatusOptionRenderer(option, index)}</UI.Pressable>
}

function createCleaningStatusOptions(cleaningStatusFilterValues: AreaCleaningStatus[]): Option[] {
    const allSelected = cleaningStatusFilterValues.length === cleaningStatusOptions.length

    const all: Option = {
        value: 'all',
        label: 'all',
        selected: allSelected
    }

    return [
        all,
        ...cleaningStatusOptions.map(option => ({
            ...option,
            selected: cleaningStatusFilterValues.includes(option.value)
        }))
    ]
}
