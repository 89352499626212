import type { CommentivityStruct, IssueStruct } from '@shared/firestore-structs'
import { getHashTags } from '@shared/helpers'
import type { CategorySelection } from '@shared/issue-data'
import moment from 'moment'
import { DefaultValue, atom, selector, selectorFamily } from 'recoil'
import { search } from '../../../utils'
import { currentOrg } from '../../auth/state/login'
import { areasBoxesSelector } from '../../housekeeping/housekeeping-overview/state/housekeeping-overview-state'

interface IssueImage {
    uri: string
    width: number
    height: number
}

export const issueImageAtom = atom<IssueImage>({
    key: 'issues-issueImageAtom',
    default: { uri: '', width: 0, height: 0 }
})

export const searchValueAtom = atom<string>({
    key: 'issues-searchValueAtom',
    default: ''
})

export const issuesAtom = atom<IssueStruct[]>({
    key: 'issues-issuesAtom',
    default: []
})

export const priorityFilterAtom = atom<boolean>({
    key: 'issues-priorityFilterAtom',
    default: false
})

export const statusFilterOpenAtom = atom<boolean>({
    key: 'issues-statusFilterOpenAtom',
    default: false
})

export const currentStatusFilterAtom = atom<IssueStruct['status'][]>({
    key: 'issues-currentStatusFilterAtom',
    default: ['open', 'assigned']
})

export const hashtagsFilterAtom = atom<string[]>({
    key: 'issues-hashtagsFilterAtom',
    default: ['all']
})

export const categoryFilterAtom = atom<CategorySelection>({
    key: 'issues-categoryFilterAtom',
    default: {}
})

export const selectedIssuesAtom = atom<Set<IssueStruct['key']>>({
    key: 'issues-selectedIssuesAtom',
    default: new Set()
})

export const issuesSelector = selector<IssueStruct[]>({
    key: 'issues-issuesSelector',
    get: ({ get }) => {
        const issues = get(issuesAtom)
        const areaNames = get(areasBoxesSelector).map(area => area.name)
        const searchValue = get(searchValueAtom)
        const priorityFilter = get(priorityFilterAtom)
        const hashtagsFilter = get(hashtagsFilterAtom)
        const categoryFilter = get(categoryFilterAtom)

        let result = issues

        if (searchValue.length > 0) {
            result = searchIssues(searchValue, result, areaNames)
        }

        if (priorityFilter) {
            result = result.filter(issue => issue.priority)
        }

        const categoryFilterHasItems = Object.keys(categoryFilter).some(key => (categoryFilter[key] ?? []).length > 0)
        const categoryFilterEmpty = Object.keys(categoryFilter).length === 0 || !categoryFilterHasItems

        if (hashtagsFilter[0] !== 'all') {
            if (hashtagsFilter.length === 0) {
                result = result.filter(issue => !issue.name.includes('#'))
            } else {
                result = result.filter(issue => hashtagsFilter.some(hashtag => issue.name.includes(hashtag)))
            }
        }

        if (categoryFilterEmpty === false) {
            result = result.filter(issue => {
                return Object.keys(categoryFilter).every(key => {
                    const selectedSubItems = issue.categories?.[key] ?? []
                    const filterResult =
                        categoryFilter[key]?.every(filter => {
                            const comparison = selectedSubItems.includes(filter)
                            return comparison
                        }) ?? false
                    return filterResult
                })
            })
        }

        return result
    },
    set: ({ set }, newValue) => {
        if (newValue instanceof DefaultValue) return

        const sortedIssues = newValue.sort((a, b) => {
            if (a.priority !== b.priority) {
                return Number(b.priority) - Number(a.priority)
            }

            return moment(b.updated).diff(moment(a.updated))
        })

        set(issuesAtom, sortedIssues)
    }
})

export const hashtagsSelector = selector<string[]>({
    key: 'issues-hashtagsSelector',
    get: ({ get }) => {
        const currentOrganization = get(currentOrg)
        const issues = get(issuesAtom)

        if (!currentOrganization) {
            throw new Error('No current organization')
        }

        const issuesHashtags = new Set<string>([...currentOrganization.issueHashtags, ...issues.flatMap(issue => getHashTags(issue.name))])

        return Array.from(issuesHashtags)
    }
})

export const hashtagsWithCountSelector = selector<{ hashtag: string; count: number }[]>({
    key: 'issues-hashtagsWithCountSelector',
    get: ({ get }) => {
        const hashtags = get(hashtagsSelector)
        const issues = get(issuesSelector)

        return hashtags
            .map(hashtag => ({
                hashtag,
                count: issues.filter(issue => issue.name.includes(hashtag)).length
            }))
            .filter(({ count }) => count > 0)
            .sort((a, b) => b.count - a.count)
    }
})

export const issueSelector = selectorFamily<IssueStruct | undefined, string>({
    key: 'issues-issueSelector',
    get:
        key =>
        ({ get }) => {
            const issueFromList = get(issuesAtom).find(issue => issue.key === key)
            return issueFromList
        },
    set:
        issueKey =>
        ({ set, get }, newValue) => {
            if (newValue instanceof DefaultValue) return

            const issues = get(issuesAtom)
            const updatedIssues = issues.map(issue => (issue.key === issueKey ? { ...issue, ...newValue } : issue))

            set(issuesAtom, updatedIssues)
        }
})

function searchExtract(issue: IssueStruct) {
    const assignees = issue.assignedTo ? issue.assignedTo.map(assignee => assignee.name + ' ' + assignee.initials).join(' ') : ''
    const areaName = issue.area ? issue.area.name : ''
    const group = issue.area ? issue.area.group : ''
    return [issue.name, assignees, areaName, group].join(' ')
}

function searchIssues(searchTerm: string, issues: IssueStruct[], areaNames: string[]) {
    if (areaNames.includes(searchTerm)) {
        return issues.filter(issue => issue.area?.name === searchTerm)
    }
    return search(searchTerm, issues, searchExtract)
}

export const areFiltersAppliedSelector = selector({
    key: 'issues-areFiltersAppliedSelector',
    get: ({ get }) => {
        const priorityFilter = get(priorityFilterAtom)
        const hashtagsFilter = get(hashtagsFilterAtom)
        const statusFilter = get(currentStatusFilterAtom)
        return priorityFilter || hashtagsFilter[0] !== 'all' || statusFilter.length !== 2
    }
})
