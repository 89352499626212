import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { Link } from '@tanstack/react-router'
import type { MouseEvent, MouseEventHandler } from 'react'
import { useIntercom } from 'react-use-intercom'
import { prefix } from 'routes/__root'
import { LanguageSwitcher } from '../language-switcher'

export function FormLinks({ type, sendMagicLink }: { type: 'phoneNumber' | 'email'; sendMagicLink?: MouseEventHandler }) {
    const {
        LL: { login: loginI18n }
    } = useI18nContext()
    const isPhone = type === 'phoneNumber'
    const { showMessages } = useIntercom()

    return (
        <div className="relative flex flex-col items-center justify-center gap-y-2">
            {!!sendMagicLink && (
                <UI.Text>
                    {loginI18n.missingCode()}{' '}
                    <span
                        onClick={e => {
                            sendMagicLink(e as MouseEvent)
                        }}
                        className="cursor-pointer">
                        <UI.Text color="snap-red">{loginI18n.useMagicLink()}</UI.Text>
                    </span>
                </UI.Text>
            )}
            <Link to={`${prefix}/login/${isPhone ? 'phone' : 'email'}`}>
                <UI.Text color="snap-light-blue">{isPhone ? loginI18n.useNumber() : loginI18n.useEmail()}</UI.Text>
            </Link>
            <UI.Text>{loginI18n.troubleLoggingIn()}</UI.Text>
            <UI.Pressable type="button" onPress={showMessages}>
                <UI.Text color="snap-red">{loginI18n.contactSupport()}</UI.Text>
            </UI.Pressable>

            <div className="absolute right-0 bottom-0">
                <LanguageSwitcher />
            </div>
        </div>
    )
}
