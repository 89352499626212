import { useCurrentUser } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useAreaBox } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-area-box'
import {
    useMassAssigningBarOpen,
    useSelectedAreas
} from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-mass-assigning-bar'
import AssignedInitials from '@shared-snap/snap/components/housekeeping/housekeeping-overview/ui/assigned-initials'
import { PrioritySign } from '@shared-snap/snap/components/molecules/priority-sign'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { HousekeepingOverviewCombined } from '@shared-snap/snap/user-actions/housekeeping-overview-actions'
import { getFontSize } from '@shared-snap/snap/utils/housekeeping-utils'
import { isSuperUser } from '@shared-snap/snap/utils/is-super-user'
import { useNavigate } from '@tanstack/react-router'
import Tooltip from 'components/atoms/tooltip'
import { useMemo } from 'react'
import { prefix } from '../../../../routes/__root'
import { CustomChar } from './custom-char'

interface Props {
    areaKey: string
    withAssignedUser?: boolean
    size?: 'sm' | 'md'
    areaBoxSummary: HousekeepingOverviewCombined[0]
}

export function AreaBox({ areaKey, areaBoxSummary, withAssignedUser = true, size = 'md' }: Props) {
    const navigate = useNavigate()
    const {
        name,
        setSearchTerm,
        customChar,
        hasBottomIcons,
        hasTopIcons,
        color,
        assignedTo,
        priority,
        cleaningStatus,
        topIcons,
        bookingsProblems,
        bottomIcons
    } = useAreaBox({
        areaKey,
        areaBoxSummary
    })

    const massAssigningBarOpen = useMassAssigningBarOpen()
    const [selectedAreas, setSelectedAreas] = useSelectedAreas()
    const currentUser = useCurrentUser()
    const showProblems = isSuperUser(currentUser) && bookingsProblems
    const selected = useMemo(() => massAssigningBarOpen && selectedAreas.has(areaKey), [massAssigningBarOpen, selectedAreas, areaKey])

    const nameTextSize = getFontSize(name, size === 'md' ? 1 : 0.8)

    const onClick = () => {
        if (massAssigningBarOpen) {
            setSelectedAreas(prev => {
                const set = new Set(prev)
                set.has(areaKey) ? set.delete(areaKey) : set.add(areaKey)
                return set
            })
            return
        }
        navigate({ to: `${prefix}/housekeeping/$areaKey`, params: { areaKey } })
    }
    const sizes = {
        sm: {
            width: 'w-20',
            height: 'h-20',
            icons: 'xs',
            gap: 'gap-1',
            priority: 'sm'
        },
        md: {
            width: 'w-[110px]',
            height: 'h-[110px]',
            icons: 'sm',
            gap: 'gap-[6px]',
            priority: 'md'
        }
    } as const

    return (
        <div className="relative">
            {withAssignedUser && (
                <>
                    <button
                        className="absolute -right-1 -top-1 z-[1]"
                        onClick={() => {
                            const assignedToSearch = assignedTo.map(u => u.name).join(' ')
                            return setSearchTerm(assignedToSearch)
                        }}
                        data-tooltip-id={`assigned-${areaKey}-tooltip`}>
                        <AssignedInitials assignedTo={assignedTo} textColor={color} cleaningStatus={cleaningStatus} />
                    </button>
                    <Tooltip text={assignedTo.map(u => u.name)} id={`assigned-${areaKey}-tooltip`} />
                </>
            )}
            <div
                onClick={onClick}
                className={`relative ${sizes[size].width} py-1 ${
                    sizes[size].height
                } group rounded-[6px] flex justify-center items-center flex-col bg-${color} cursor-pointer opacity-${
                    selected ? '50' : showProblems ? '50' : '100'
                }`}>
                <div className="absolute -top-1 -left-1">
                    <PrioritySign size={sizes[size].priority} priority={priority} />
                </div>
                <div className={`flex justify-center ${sizes[size].gap} h-1/4`}>
                    <UI.List
                        items={topIcons}
                        render={(icon, i) => (
                            <UI.Icon key={`areabox-top-${areaKey}-${i}`} icon={icon} color="white" size={sizes[size].icons} opacity={70} />
                        )}
                    />
                </div>
                <div className="m-auto max-h-2/4 p-1">
                    <span style={{ fontSize: nameTextSize }} className="line-clamp-2 text-white leading-tight font-bold text-center">
                        {name}
                    </span>
                </div>
                <div className={`flex justify-center items-center ${sizes[size].gap} h-1/4`}>
                    <UI.List
                        items={bottomIcons}
                        render={(icon, i) => (
                            <UI.Icon
                                key={`areabox-bottom-${areaKey}-${i}`}
                                icon={icon}
                                color="white"
                                size={sizes[size].icons}
                                opacity={70}
                            />
                        )}
                    />
                    {customChar && <CustomChar customChar={customChar} />}
                </div>
                {massAssigningBarOpen && (
                    <div
                        onClick={e => {
                            e.stopPropagation()
                            navigate({ to: `${prefix}/housekeeping/$areaKey`, params: { areaKey } })
                        }}
                        className="w-[22px] h-[22px] border-8 border-white rounded-full absolute left-[-5px] bottom-[-5px] hidden group-hover:flex"
                    />
                )}
            </div>
        </div>
    )
}
