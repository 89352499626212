import { getNextBooking } from '@shared/booking-service'
import type { Firebase } from '@shared/firebase'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { mapBookingToProjection_v2 } from '../../../../utils/housekeeping-utils'
import { areaSummaryDate } from '../../date/state/date-selection-state'
import { areaState, bookingToDisplay, leadBookingSelector } from '../state/area-summary-state'
import { useCreateBookingDisplay, useIsFutureBooking, useUnknownBooking } from './use-area-summary'

export const useFetchNextBooking = (firebase: Firebase) => {
    const isFutureBooking = useIsFutureBooking()
    const createBookingDisplay = useCreateBookingDisplay()
    const unknownBooking = useUnknownBooking()
    const leadBooking = useRecoilValue(leadBookingSelector)
    const [booking, setBooking] = useRecoilState(bookingToDisplay)
    const area = useRecoilValue(areaState)
    const date = useRecoilValue(areaSummaryDate)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const fetchBooking = async () => {
            setIsLoading(true)

            try {
                if (isFutureBooking) {
                    const next = await getNextBooking(firebase, area.key, area.organizationKey, date.valueOf())
                    if (next) {
                        const mappedNext = mapBookingToProjection_v2(next)
                        const booking = createBookingDisplay(mappedNext, date)
                        setBooking(booking)
                    } else {
                        setBooking(unknownBooking)
                    }
                } else {
                    if (!leadBooking) {
                        setBooking(unknownBooking)
                        return
                    }
                    const booking = createBookingDisplay(leadBooking, date)
                    setBooking(booking)
                }
            } catch (error) {
                setBooking(unknownBooking)
                console.error('Failed to fetch next booking', error)
            } finally {
                setIsLoading(false)
            }
        }

        fetchBooking()
    }, [firebase, area.key, area.organizationKey, date, isFutureBooking, leadBooking, setBooking])
    return { booking, isLoading }
}
