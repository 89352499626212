import { createFileRoute } from '@tanstack/react-router'
import { RuleEdit } from 'components/organisms/rule-form/rule-edit-form'

export const Route = createFileRoute('/snap/_authenticated/settings/rules_/$key')({
    validateSearch: (search: Record<string, unknown>): { step: number } => {
        return {
            step: Number(search?.step ?? 1)
        }
    },
    component: RuleEdit
})
