import type { Firebase } from '@shared/firebase'
import type { UserStruct } from '@shared/firestore-structs'
import { useRecoilValue } from 'recoil'
import { useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUsers } from '../../../auth/hooks/use-users'
import { useUserHousekeepingOverviewAction } from '../../use-user-action'
import { selectedAreasAtom } from '../state/mass-assigning-state'

export function useMassAssign(firebase: Firebase) {
    const selectedAreas = useRecoilValue(selectedAreasAtom)
    const users = useUsers()
    const currentUser = useCurrentUser()
    const action = useUserHousekeepingOverviewAction('mass-assign', firebase)

    async function massUnassign() {
        await action([], Array.from(selectedAreas), currentUser)
    }

    async function massAssign(userKeys: string[]) {
        const assignedTo = userKeys
            .map(key => users.find(user => user.key === key))
            .filter((user): user is UserStruct => user !== undefined)
        await action(assignedTo, Array.from(selectedAreas), currentUser)
        localStorage.setItem('last-assigned-users', JSON.stringify(userKeys))
    }

    async function sameAsLastAssign() {
        try {
            const lastAssigned = localStorage.getItem('last-assigned-users')
            if (!lastAssigned) return

            const userKeys = JSON.parse(lastAssigned)
            if (!Array.isArray(userKeys) || !userKeys.every(key => typeof key === 'string')) {
                console.warn('Invalid format for stored user keys')
                return
            }

            const belongToOrg = userKeys.every(userKey => users.some(user => user.key === userKey))
            if (belongToOrg) {
                await massAssign(userKeys)
            }
        } catch (error) {
            console.warn('Failed to process last assigned users:', error)
        }
    }

    return { massAssign, massUnassign, sameAsLastAssign }
}
