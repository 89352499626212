import type { Firebase } from '@shared/firebase'
import { useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUserAreaSummaryAction } from '../../use-user-action'

export function useAddDailyComment(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const action = useUserAreaSummaryAction('set-daily-comment', firebase)

    return (comment: string) => {
        action(comment, currentUser)
    }
}
