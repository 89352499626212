import type { Firebase } from '@shared/firebase'
import type { TaskStruct } from '@shared/firestore-structs'
import { uncompleteIssue } from '@shared/issue-data'
import { setTaskUpdate } from '@shared/task-data'
import { useCurrentUser } from '../../auth/hooks/use-auth-state'

export function useUncompleteTask(firebase: Firebase) {
    const currentUser = useCurrentUser()

    return async (task: TaskStruct) => {
        try {
            const status: TaskStruct['status'] = task.assignedTo && task.assignedTo.length > 0 ? 'assigned' : 'open'

            if (task.type === 'issue') {
                await uncompleteIssue(firebase, {
                    issueKey: task.key,
                    currentUser,
                    assignedContacts: task.assignedTo
                })
            } else {
                await setTaskUpdate(firebase, currentUser, task.key, { status })
            }
        } catch (error: unknown) {
            //TODO: add proper error handling
            console.error(error)
            throw new Error('Failed to uncomplete task')
        }
    }
}
