import crypto from 'crypto-js'
import { compressAccurately } from 'image-conversion'
import moment from 'moment-timezone'
import type { Firebase } from './firebase'

/* global Blob */

const getEncoder = (): { encode: (_input: string) => Uint8Array } => {
    if (typeof TextEncoder === 'undefined') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require('fast-text-encoding')
    }
    return new TextEncoder()
}
export const COMPRESSED_IMAGE_WIDTH = 350
export const COMPRESSED_IMAGE_HEIGHT = 350
export const COMPRESSED_IMAGE_QUALITY = 50
const COMPRESSED_IMAGE_SCALE = 0.5

export interface ImageUpload {
    data: Blob | string
    fileName: string
}

export interface ImageMetadata {
    name: string
    contentType: string
}

export interface Image extends ImageMetadata {
    key: string
    url: string
    thumbUrl: string
}

export async function webConvertImageToJpeg(imageFile: Blob, shouldCompress = false) {
    const options = {
        scale: shouldCompress ? COMPRESSED_IMAGE_SCALE : 1,
        quality: COMPRESSED_IMAGE_QUALITY,
        mimeType: 'image/jpeg'
    }

    const data = await compressAccurately(imageFile, options)

    const info = {
        size: data.size,
        type: data.type
    }

    return { data, info }
}

export function createFileName(currentUserKey: string, extension?: string) {
    const unHashed = currentUserKey + '~' + moment().valueOf() + '~' + Math.random()
    const msgUint8 = getEncoder().encode(unHashed)
    const wordArray = crypto.lib.WordArray.create(msgUint8)
    const hashString = crypto.SHA256(wordArray).toString(crypto.enc.Hex)

    const hashArray = []
    for (let i = 0; i < hashString.length; i += 2) {
        hashArray.push(parseInt(hashString.substr(i, 2), 16))
    }

    const hashHex = hashArray.map(buffer => buffer.toString(16).padStart(2, '0')).join('')

    return hashHex + (extension ? extension : '')
}

export async function uploadImage(firebase: Firebase, image: Blob | string, fileName: string): Promise<ImageMetadata> {
    const finalImage = typeof image === 'string' ? image.replace('file://', '') : image

    const storagePath = firebase.storage().ref().child('media').child(fileName)
    const uploadTask = finalImage instanceof Blob ? storagePath.put(finalImage) : storagePath.putFile(finalImage)
    try {
        const snapshot = await uploadTask

        return { name: snapshot.metadata.name, contentType: snapshot.metadata.contentType! }
    } catch (error) {
        console.error('(files-storage-data uploadImage)', error)
        uploadTask.cancel()
        throw new Error('Failed to upload image, please try again')
    }
}

export async function downloadImage(firebase: Firebase, fileName: string): Promise<string> {
    try {
        const fileRef = firebase
            //@ts-ignore
            .storage()
            .ref()
            .child('media')
            .child(fileName.replace('media/', ''))

        return await fileRef.getDownloadURL()
    } catch (error) {
        console.error('(files-storage-data downloadImage)', error)
        throw new Error('Failed to download image, please try again')
    }
}

export async function formatImagesToUpload(
    imagesBuffer: Blob[] | string[],
    params: { currentUserKey: string; extension: '.jpg' | '.png' | '_thumb.jpg' },
    shouldCompress = false
): Promise<ImageUpload[]> {
    const { currentUserKey, extension } = params

    return await Promise.all(
        imagesBuffer.map(async image => {
            let imageData
            if (typeof image === 'string') {
                imageData = image
            } else {
                const { data } = await webConvertImageToJpeg(image, shouldCompress)
                imageData = data
            }
            const fileName = createFileName(currentUserKey, extension)

            return { data: imageData, fileName }
        })
    )
}

export async function uploadListOfImages(firebase: Firebase, images: ImageUpload[]): Promise<ImageMetadata[]> {
    return await Promise.all(images.map(async image => await uploadImage(firebase, image.data, image.fileName)))
}

export async function downloadListOfImages(firebase: Firebase, imagesMetadata: ImageMetadata[]): Promise<string[]> {
    return await Promise.all(imagesMetadata.map(async image => await downloadImage(firebase, image.name)))
}
