import { useNavigate } from '@tanstack/react-router'
import { useCallback } from 'react'
import { Route } from 'routes/snap/_authenticated/settings.rules_.new'
import { RuleForm } from './rule-form'

export function RuleAdd() {
    const { step } = Route.useSearch()
    const navigate = useNavigate({ from: Route.fullPath })

    const handleStepNavigate = useCallback(
        (step: number) => {
            navigate({ search: { step } })
        },
        [navigate]
    )

    return <RuleForm navigate={handleStepNavigate} step={step} ruleKey={null} />
}
