import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { housekeepingOverview } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/state/housekeeping-overview-state'
import { PrioritySign } from '@shared-snap/snap/components/molecules/priority-sign'
import { useAssignTask } from '@shared-snap/snap/components/my-tasks/hooks/use-assign-task'
import { useChangeTaskDueDate } from '@shared-snap/snap/components/my-tasks/hooks/use-change-task-due-date'
import { useChangeTaskPriority } from '@shared-snap/snap/components/my-tasks/hooks/use-change-task-priority'
import { useCompleteTask } from '@shared-snap/snap/components/my-tasks/hooks/use-complete-task'
import { useDeleteTask } from '@shared-snap/snap/components/my-tasks/hooks/use-delete-task'
import { useUsersOptions } from '@shared-snap/snap/components/my-tasks/hooks/use-tasks'
import { useUncompleteTask } from '@shared-snap/snap/components/my-tasks/hooks/use-uncomplete-task'
import {
    generalActiveTasksAtom,
    generalCompletedTasksAtom,
    housekeepingTasksAtom,
    issueTasksAtom,
    taskboardContextAtom
} from '@shared-snap/snap/components/my-tasks/state/my-tasks'
import {
    AreaInfo,
    DueDate,
    type GeneralVisualsRenderer,
    type HousekeepingVisualsRenderer,
    type IssueVisualsRenderer,
    TaskName,
    Visuals
} from '@shared-snap/snap/components/my-tasks/ui/render-task'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { TaskStruct } from '@shared/firestore-structs'
import { useNavigate } from '@tanstack/react-router'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import AssignedContacts from 'components/atoms/assigned-contacts'
import { DatePicker } from 'components/atoms/date-picker/date-picker'
import { IconButton } from 'components/atoms/icon-button'
import Tooltip from 'components/atoms/tooltip'
import { UsersModal } from 'components/organisms/users-modal/users-modal'
import moment from 'moment'
import { type Dispatch, type SetStateAction, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { prefix } from '../../../routes/__root'
import { AreaBox } from '../housekeeping/area-box/area-box'

export function TaskRow({ item }: { item: TaskStruct }) {
    const {
        LL: { shared }
    } = useI18nContext()
    const navigate = useNavigate()
    const [actionBarVisible, setActionBarVisible] = useState(false)
    const [keepOpen, setKeepOpen] = useState(false)
    const currentOrg = useCurrentOrg()
    const context = useRecoilValue(taskboardContextAtom)

    return (
        <div
            onMouseLeave={() => !keepOpen && setActionBarVisible(false)}
            className="pb-2 px-3 rounded-xl shadow-lg border border-snap-light-silver cursor-pointer">
            <div
                onMouseEnter={() => setActionBarVisible(true)}
                onClick={() => navigate({ to: `${prefix}/taskboard/$key`, params: { key: item.key } })}>
                <AreaInfo context={context} item={item} bold />
                <div className="flex gap-2 mt-2">
                    <Visuals
                        task={item}
                        Render={{
                            General: GeneralVisuals,
                            Housekeeping: HousekeepingVisuals,
                            Issue: IssueVisuals
                        }}
                    />

                    <div className="flex flex-col justify-between grow">
                        <TaskName task={item} />
                        <UI.Text size="xs" color="snap-mid-gray">
                            {shared.itemsMetadata.createdInfoAuthor({
                                createdDate: moment.tz(item.created, currentOrg.timezone).format('ddd, MMM D, YYYY'),
                                creatorName: item.type === 'issue' ? (item.creatorName ?? item.creator.name) : item.creator.name
                            })}
                        </UI.Text>
                        <div className="flex items-center justify-between w-full">
                            <DueDate
                                startDate={item.startDate}
                                Render={({ date }) => {
                                    return (
                                        <div className="flex flex-row items-center gap-2 text-md">
                                            <UI.Icon icon="specta-schedule" color="snap-red" size="xs" />
                                            <UI.Text size="xs" weight="bold" color="snap-red">
                                                {date}
                                            </UI.Text>
                                        </div>
                                    )
                                }}
                            />

                            <div className="flex gap-1">
                                <AssignedContacts data-tooltip-id="task-assignee" assignedContacts={item.assignedTo ?? []} size="sm" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ActionBar visible={actionBarVisible} task={item} setKeepOpen={setKeepOpen} setActionBarVisible={setActionBarVisible} />
        </div>
    )
}

function ActionBar({
    visible,
    task,
    setKeepOpen,
    setActionBarVisible
}: {
    visible: boolean
    task: TaskStruct
    setKeepOpen: Dispatch<SetStateAction<boolean>>
    setActionBarVisible: Dispatch<SetStateAction<boolean>>
}) {
    const {
        LL: { tooltips }
    } = useI18nContext()
    const [usersModalIsOpen, setUsersModalIsOpen] = useState(false)
    const [housekeepingTasks, setHousekeepingTasks] = useRecoilState(housekeepingTasksAtom)
    const [issueTasks, setIssueTasks] = useRecoilState(issueTasksAtom)
    const [generalActiveTasks, setGeneralActiveTasks] = useRecoilState(generalActiveTasksAtom)
    const [generalCompletedTasks, setGeneralCompletedTasks] = useRecoilState(generalCompletedTasksAtom)
    const deleteTask = useDeleteTask(asFirebase(firebaseWrapped))
    const changeTaskDueDate = useChangeTaskDueDate(asFirebase(firebaseWrapped))
    const changeTaskPriority = useChangeTaskPriority(asFirebase(firebaseWrapped), task.key)
    const assignTask = useAssignTask(asFirebase(firebaseWrapped), task.key)
    const completeTask = useCompleteTask(asFirebase(firebaseWrapped))
    const currentOrg = useCurrentOrg()
    const uncompleteTask = useUncompleteTask(asFirebase(firebaseWrapped))
    const usersOptions = useUsersOptions()
    const initialSelectedUsers = useMemo(() => (task.assignedTo ? task.assignedTo.map(user => user.key) : []), [task.assignedTo])
    const completed = task.status === 'completed'
    if (!visible) return null

    const onDeleteClick = async () => {
        const taskType = task.type
        const isCompleted = task.status === 'completed'
        const filterTasks = (tasks: TaskStruct[] | undefined) => {
            if (!tasks) return []
            return tasks.filter(t => t.key !== task.key)
        }
        switch (taskType) {
            case 'issue': {
                const filteredIssueTasks = filterTasks(issueTasks)
                setIssueTasks(filteredIssueTasks)
                break
            }
            case 'cleaning': {
                const filteredHousekeepingTasks = filterTasks(housekeepingTasks)
                setHousekeepingTasks(filteredHousekeepingTasks)
                break
            }
            case 'general': {
                const filteredGeneralTasks = filterTasks(isCompleted ? generalCompletedTasks : generalActiveTasks)
                isCompleted ? setGeneralCompletedTasks(filteredGeneralTasks) : setGeneralActiveTasks(filteredGeneralTasks)
                break
            }
            default:
                break
        }
        await deleteTask(task)
    }
    const onDueDateChange = async (date: string) => await changeTaskDueDate(task, date)
    const onPriorityChange = async () => await changeTaskPriority()
    const onAssign = async (userKeys: string[]) => await assignTask(userKeys)
    const onComplete = async () => await completeTask(task)
    const onUncomplete = async () => await uncompleteTask(task)

    return (
        <>
            {usersModalIsOpen && (
                <UsersModal
                    usersOptions={usersOptions}
                    isOpen={usersModalIsOpen}
                    onClose={() => {
                        setKeepOpen(false)
                        setActionBarVisible(false)
                        setUsersModalIsOpen(false)
                    }}
                    onAssign={onAssign}
                    initialSelected={initialSelectedUsers}
                    closeByPressOutside={false}
                />
            )}
            <div className="flex justify-between px-1 mt-3 pt-2 border-t border-snap-light-silver">
                <div data-tooltip-id={`delete-${task.key}-tooltip`}>
                    <IconButton icon="specta-trash" onClick={onDeleteClick} />
                </div>

                <div data-tooltip-id={`due-date-${task.key}-tooltip`}>
                    <DatePicker
                        disabled={completed}
                        value={(task.startDate ? moment.tz(task.startDate, currentOrg.timezone) : moment()).format('YYYY-MM-DD')}
                        onChange={onDueDateChange}
                        withTrigger={{
                            Trigger: <IconButton icon="specta-duedate" disabled={completed} />,
                            id: 'task-datepicker'
                        }}
                    />
                </div>

                <div data-tooltip-id={`priority-${task.key}-tooltip`}>
                    <IconButton
                        disabled={completed}
                        icon="specta-priority-low"
                        color={task.priority ? 'snap-red' : 'snap-silver'}
                        onClick={onPriorityChange}
                    />
                </div>
                <div data-tooltip-id={`assign-${task.key}-tooltip`}>
                    <IconButton
                        disabled={completed}
                        icon="specta-assign-user"
                        onClick={() => {
                            setKeepOpen(true)
                            setUsersModalIsOpen(true)
                        }}
                    />
                </div>
                <div data-tooltip-id={`complete-${task.key}-tooltip`}>
                    <IconButton
                        icon={completed ? 'specta-recycling' : 'specta-checkmark'}
                        size="sm"
                        onClick={completed ? onUncomplete : onComplete}
                    />
                </div>
            </div>
            <Tooltip id={`delete-${task.key}-tooltip`} text={tooltips.delete()} />
            <Tooltip id={`due-date-${task.key}-tooltip`} text={tooltips.dueDate()} />
            <Tooltip id={`priority-${task.key}-tooltip`} text={tooltips.setPriority()} />
            <Tooltip id={`assign-${task.key}-tooltip`} text={tooltips.assign()} />
            <Tooltip id={`complete-${task.key}-tooltip`} text={completed ? tooltips.incomplete() : tooltips.complete()} />
        </>
    )
}

function GeneralVisuals({ priority, src }: GeneralVisualsRenderer) {
    return (
        <div className="relative flex items-center justify-center w-16 h-16 border rounded border-sweeply-light-gray">
            <div className="absolute -inset-2">
                <PrioritySign priority={priority} backgroundColor="sweeply-red" iconColor={'white'} size={'sm'} />
            </div>
            <img src={src} />
        </div>
    )
}

function HousekeepingVisuals({ areaKey }: HousekeepingVisualsRenderer) {
    const areas = useRecoilValue(housekeepingOverview)
    return <AreaBox key={areaKey} areaKey={areaKey} areaBoxSummary={areas[areaKey]} withAssignedUser={false} size="sm" />
}

function IssueVisuals({ priority, src }: IssueVisualsRenderer) {
    return (
        <div className="relative flex items-center justify-center w-16 h-16 border rounded border-sweeply-light-gray">
            <div className="absolute -inset-2">
                <PrioritySign priority={priority} backgroundColor="sweeply-red" iconColor={'white'} size={'sm'} />
            </div>
            {src === 'specta-text-issue' ? (
                <i className={`${src} text-2xl text-sweeply-gray`} />
            ) : (
                <img className="w-16 h-16 rounded" src={src} />
            )}
        </div>
    )
}
