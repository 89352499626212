import type { Firebase } from '@shared/firebase'
import type { IssueStruct } from '@shared/firestore-structs'
import { completeIssue } from '@shared/issue-data'
import moment from 'moment-timezone'
import { useCurrentOrg, useCurrentUser } from '../../auth/hooks/use-auth-state'
import { useSetIssueState } from './use-issues'

export function useCompleteIssue(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const currentOrg = useCurrentOrg()
    const completedDate = moment.tz(currentOrg.timezone).startOf('day')
    const setIssueState = useSetIssueState()

    return async (issueKey: IssueStruct['key']) => {
        setIssueState({ key: issueKey, status: 'completed' })

        try {
            await completeIssue(firebase, {
                issueKey,
                currentUser,
                completedDate
            })
        } catch (error) {
            //TODO: Add proper error handling
            console.error(error)
            throw new Error('Failed to complete issue')
        }
    }
}

export function useMassCompleteIssues(firebase: Firebase) {
    const currentUser = useCurrentUser()
    const setIssueState = useSetIssueState()
    const currentOrg = useCurrentOrg()
    const completedDate = moment.tz(currentOrg.timezone).startOf('day')

    return async (issues: IssueStruct[]) => {
        issues.forEach(issue => {
            setIssueState({ key: issue.key, status: 'completed' })
        })

        const db = firebase.firestore()
        const batch = db.batch()

        try {
            await Promise.all([
                ...issues.map(issue =>
                    completeIssue(
                        firebase,
                        {
                            issueKey: issue.key,
                            currentUser,
                            completedDate
                        },
                        batch
                    )
                )
            ])
            await batch.commit()
        } catch (error) {
            console.error(error)
            throw new Error('Failed to mass complete issues')
        }
    }
}
