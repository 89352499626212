import type { Colors } from '@shared-snap/snap/types/visuals.types'
import { match } from 'ts-pattern'

interface Props {
    quantity: number | string
    size?: 'sm' | 'md' | 'lg'
    backgroundColor?: Colors
}

export function QuantityLabel({ quantity, size = 'lg', backgroundColor = 'snap-teal' }: Props) {
    const { textSize, containerSize } = match(size)
        .with('sm', () => ({ textSize: 'text-[10px]', containerSize: 'h-[13px]' }))
        .with('md', () => ({ textSize: 'text-[16px]', containerSize: 'h-[17px]' }))
        .with('lg', () => ({ textSize: 'text-[20px]', containerSize: 'h-[24px]' }))
        .otherwise(() => ({ textSize: 'text-[20px]', containerSize: 'h-[24px]' }))

    return (
        <div
            className={`rounded-[4px] w-fit flex justify-center items-center bg-${backgroundColor} p-[3px] text-white ${textSize} ${containerSize}`}>
            {quantity}
        </div>
    )
}
