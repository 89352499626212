import { type Features, featureAtScope, getFeaturesAtScope } from '@shared/feature-toggles'
import { useCurrentOrg, useCurrentUser } from '../components/auth/hooks/use-auth-state'

export function useFeatureToggle() {
    const currentOrganization = useCurrentOrg()
    const user = useCurrentUser()

    return {
        isFeatureOn: function (featureName: Features) {
            const orgFeature = getFeaturesAtScope(currentOrganization)
            const userFeatures = getFeaturesAtScope(user)

            return featureAtScope(userFeatures, featureName) ?? featureAtScope(orgFeature, featureName) ?? false
        }
    }
}
