import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

if (import.meta.env.PROD) {
    const clientToken = 'puba57a05e97667714cfc44156607119a3e'
    const site = 'us5.datadoghq.com'
    const env = import.meta.env.VITE_ENV === 'prod' ? 'prod' : 'dev'
    const version = import.meta.env.VITE_BUILD ?? 'unknown'
    const sessionSampleRate = 100
    const serviceName = 'web-snap'
    datadogRum.init({
        applicationId: 'd3497a42-f7a5-4bba-bb10-78ebbc89d5b3',
        clientToken: clientToken,
        site: site,
        env: env,
        version: version,
        sessionSampleRate: sessionSampleRate,
        sessionReplaySampleRate: 20,
        traceSampleRate: 100,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        service: serviceName,
        allowedTracingUrls: [
            `${window.location.protocol}//${window.location.host}/api`,
            // url => url.startsWith(`https://firebase.googleapis.com`) && !url.endsWith('/webConfig'),
            // /https?:\/\/(?!(identitytoolkit|www|securetoken|firebaseinstallations|firebaseremoteconfig|firebase|firebasestorage)\.)[a-zA-Z0-9-]+\.googleapis\.com/,
            /https:\/\/.*\.cloudfunctions\.net/
        ]
    })
    datadogLogs.init({
        clientToken: clientToken,
        site: site,
        env: env,
        version: version,
        service: serviceName,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
        forwardReports: 'all',
        sessionSampleRate: sessionSampleRate,
        beforeSend: log => {
            const knownErrorUrls = [
                'https://app.posthog.com',
                'https://firestore.googleapis.com/google.firestore.v1.Firestore/Listen/channel'
            ]
            return !knownErrorUrls.some(knownUrl => log.http?.url?.startsWith(knownUrl))
        }
    })
    datadogRum.startSessionReplayRecording()
} else {
    console.log('Datadog RUM disabled')
}
