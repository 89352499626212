import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useCompleteIssue } from '@shared-snap/snap/components/issues/hooks/use-complete-issue'
import {
    useCategoryFilter,
    useIssues,
    useIssuesList,
    useIssuesSearch,
    usePriorityFilter,
    useSelectedIssues,
    useStatusFilterOpenAtom
} from '@shared-snap/snap/components/issues/hooks/use-issues'
import { currentStatusFilterAtom } from '@shared-snap/snap/components/issues/state/issues-state'
import { HashtagsList } from '@shared-snap/snap/components/issues/ui/hashtags-filter'
import { ChangedInfo, CreatedInfo, IssueImage, IssueNumber } from '@shared-snap/snap/components/issues/ui/issue'
import { StatusFilterList } from '@shared-snap/snap/components/issues/ui/status-filter'
import { PrioritySign } from '@shared-snap/snap/components/molecules/priority-sign'
import { IssueName } from '@shared-snap/snap/components/my-tasks/ui/render-task'
import { useDropdown } from '@shared-snap/snap/hooks/use-dropdown'
import { useFeatureToggle } from '@shared-snap/snap/hooks/use-feature-toggles'
import { usePaginatedData } from '@shared-snap/snap/hooks/use-paginated-data'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { IssueStruct } from '@shared/firestore-structs'
import type { CategorySelection } from '@shared/issue-data'
import { Link, Outlet } from '@tanstack/react-router'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import NoIssues from 'assets/no-issues-found.svg'
import AssignedContacts from 'components/atoms/assigned-contacts'
import { Button } from 'components/atoms/button/button'
import ContextMenu from 'components/atoms/context-menu/context-menu'
import List from 'components/atoms/list/list'
import { Pagination } from 'components/atoms/pagination'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { RoundButton } from 'components/atoms/round-button'
import { SearchInput } from 'components/atoms/search-input'
import Tooltip from 'components/atoms/tooltip'
import { CategoriesModal, CategorySelectionBlock, Pill } from 'components/organisms/categories-modal/categories-modal'
import { ActionBar } from 'components/organisms/issues-action-bar/action-bar'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { prefix } from '../../../routes/__root'

export function IssuesLayout() {
    const { isFeatureOn } = useFeatureToggle()
    return (
        <div>
            <Outlet />
            <Header />
            <SearchAndFilters />
            {isFeatureOn('hashtag-killer') ? <CategoryFilter /> : <HashtagsFilter />}
            <Content />
            <ActionBar />
        </div>
    )
}

export function Header() {
    const {
        LL: { issues: issuesLocale }
    } = useI18nContext()
    const issues = useIssuesList()

    return (
        <div className="flex justify-between w-full pb-[30px]">
            <div className="flex items-center gap-x-[8px]">
                <h1 className="text-3xl font-bold">{issuesLocale.header()}</h1>
                <QuantityLabel quantity={issues.length} />
            </div>
            <Link to={`${prefix}/issues/new`}>
                <Button>{issuesLocale.newIssueBtn()}</Button>
            </Link>
        </div>
    )
}

function SearchAndFilters() {
    return (
        <div className="flex justify-between w-full pb-[30px]">
            <Search />
            <div className="flex items-center gap-x-[34px]">
                <PriorityFilter />
                <StatusFilter />
            </div>
        </div>
    )
}

function Search() {
    const {
        LL: { issues }
    } = useI18nContext()
    const [searchValue, setSearchValue] = useIssuesSearch()
    return <SearchInput placeholder={issues.searchPlaceholder()} value={searchValue} onChange={value => setSearchValue(value)} />
}

function PriorityFilter() {
    const {
        LL: { tooltips, shared }
    } = useI18nContext()
    const [priorityFilter, setPriorityFilter] = usePriorityFilter()

    return (
        <div data-tooltip-id="priority-tooltip">
            <RoundButton
                backgroundColor="snap-light-gray"
                icon="specta-priority-low"
                iconColor={priorityFilter ? 'snap-red' : 'snap-black'}
                onClick={() => setPriorityFilter(!priorityFilter)}
            />
            <Tooltip id="priority-tooltip" text={priorityFilter ? shared.seeAll() : tooltips.priorityOnlyHigh()} />
        </div>
    )
}

function StatusFilter() {
    const {
        LL: { tooltips, issues, filters }
    } = useI18nContext()
    const { dropdownIsOpen, openDropdown, closeDropdown } = useDropdown(useStatusFilterOpenAtom())
    const statusFilterValue = useRecoilValue(currentStatusFilterAtom)
    const arraysContainSameElements = (arr1: IssueStruct['status'][], arr2: IssueStruct['status'][]) => {
        const set1 = new Set(arr1)
        const set2 = new Set(arr2)
        if (set1.size !== set2.size) return false
        for (const value of set1) {
            if (!set2.has(value)) return false
        }
        return true
    }
    return (
        <div className="flex flex-col">
            <div data-tooltip-id="status-filter-tooltip">
                <RoundButton
                    backgroundColor={
                        dropdownIsOpen || !arraysContainSameElements(statusFilterValue, ['open', 'assigned'])
                            ? 'snap-light-yellow'
                            : 'snap-light-gray'
                    }
                    className={dropdownIsOpen ? 'border border-snap-yellow' : ''}
                    icon="specta-filter"
                    onClick={openDropdown}
                />
            </div>
            <div className="relative flex justify-end">
                {dropdownIsOpen && (
                    <ContextMenu onBlur={closeDropdown}>
                        <div className="flex flex-col gap-2 p-[10px]">
                            <StatusFilterList
                                StatusOptionRenderer={option => (
                                    <div className="flex justify-between items-center cursor-pointer py-[14px] px-[10px] border-b border-snap-light-gray">
                                        <div className="flex flex-row items items-center mr-[40px]">
                                            <UI.Text>{issues.statuses[option.label]() ?? option.label}</UI.Text>
                                        </div>
                                        <div
                                            className={`w-[30px] h-[30px] flex items-center justify-center rounded-full  ${
                                                option.selected ? 'bg-snap-teal' : 'border border-snap-light-gray'
                                            }`}>
                                            {option.selected && <UI.Icon icon="specta-checkmark" size="xs" color="white" />}
                                        </div>
                                    </div>
                                )}
                                ResetRenderer={() => (
                                    <div className="flex justify-center items-center rounded-[4px] h-[42px] font-bold text-white text-[14px] bg-snap-red w-full">
                                        {filters.reset()}
                                    </div>
                                )}
                            />
                        </div>
                    </ContextMenu>
                )}
            </div>
            <Tooltip id="status-filter-tooltip" text={tooltips.issueFilter()} place="top-end" />
        </div>
    )
}

function CategoryFilter() {
    const [isCategorySelectionOpen, setIsCategorySelectionOpen] = useState(false)
    const [categoriesFilter, setCategoriesFilter] = useCategoryFilter()

    const { LL } = useI18nContext()

    const org = useCurrentOrg()

    const openModal = () => setIsCategorySelectionOpen(true)
    const closeModal = () => setIsCategorySelectionOpen(false)

    const handleSelection = useCallback(
        (categorySelection: CategorySelection) => {
            console.log(categorySelection)
            setIsCategorySelectionOpen(false)
            setCategoriesFilter(categorySelection)
        },
        [setCategoriesFilter]
    )

    if (org.categories?.length === 0) {
        return null
    }

    const filterIsEmpty = Object.keys(categoriesFilter).length === 0 || Object.values(categoriesFilter).every(value => value.length === 0)

    return (
        <>
            {filterIsEmpty ? (
                <UI.Pressable onPress={openModal} className="cursor-pointer">
                    <Pill text={LL.issues.filterByCategory()} selected={false} />
                </UI.Pressable>
            ) : (
                <CategorySelectionBlock showClearButtons categorySelection={categoriesFilter} setCategorySelection={setCategoriesFilter} />
            )}
            <CategoriesModal
                key={`category-modal-${isCategorySelectionOpen}`}
                isOpen={isCategorySelectionOpen}
                onDone={handleSelection}
                headerTitleOverride={LL.issues.filterByCategory()}
                actionTitle={LL.issues.applyFilter()}
                initialSelected={categoriesFilter}
                categoryOptions={org.categories ?? []}
                onClose={closeModal}
            />
            <Tooltip id="category-selection-tooltip" />
        </>
    )
}

function HashtagsFilter() {
    return (
        <div className="flex flex-wrap gap-3">
            <HashtagsList
                HashtagRenderer={({ chosen, hashtag, count }) => (
                    <div
                        className={`flex items-center justify-center p-[8px] bg-transparent border rounded-[10px] hover:opacity-70 gap-x-[4px] border-${
                            chosen ? 'snap-teal' : 'sweeply-gray'
                        }`}>
                        <span className={`text-sm text-${chosen ? 'snap-teal' : 'sweeply-gray'}`}>{hashtag}</span>
                        <QuantityLabel backgroundColor={chosen ? 'snap-teal' : 'snap-silver'} size="sm" quantity={count} />
                    </div>
                )}
            />
        </div>
    )
}

function Content() {
    const data = useIssues()
    const { status } = data

    if (status === 'loading') {
        return <UI.Loader />
    }

    if (status === 'error') {
        return <UI.ErrorMessage>{`Error loading data ${data.errors}`}</UI.ErrorMessage>
    }

    return (
        <div>
            <IssuesList />
        </div>
    )
}

function IssuesList() {
    const {
        LL: { issues }
    } = useI18nContext()
    const rawIssues = useIssuesList()

    const [paginatedIssues, { currentPage, totalPages, setCurrentPage, setData }] = usePaginatedData(rawIssues)

    useEffect(() => {
        setData(rawIssues)
    }, [rawIssues, setData])

    return (
        <div className="relative py-5">
            {paginatedIssues.length ? (
                <>
                    <div className="flex flex-col gap-4 border-t border-snap-light-silver">
                        <List items={paginatedIssues} render={issue => <IssueRow key={issue.key} issue={issue} />} />
                    </div>
                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(page: number) => setCurrentPage(page)} />
                </>
            ) : (
                <div className="absolute start-1/2 translate-x-[-50%] translate-y-1/2 flex flex-col justify-center items-center">
                    <img src={NoIssues} alt="No issues found" className="mb-5" />
                    <UI.Text size="xl" weight="bold" color="snap-mid-gray">
                        {issues.notFoundLabel()}
                    </UI.Text>
                    <UI.Text size="md" color="snap-mid-gray">
                        {issues.notFoundSublabel()}
                    </UI.Text>
                </div>
            )}
        </div>
    )
}

function IssueRow({ issue }: { issue: IssueStruct }) {
    const {
        LL: { shared }
    } = useI18nContext()
    const completeIssue = useCompleteIssue(asFirebase(firebaseWrapped))
    const { selectIssue, selectedIssuesKeys } = useSelectedIssues()
    const handleCompleteIssue = async () => await completeIssue(issue.key)

    const {
        priority,
        lastThumbUrl,
        lastItemKey,
        isImagesLoading,
        key,
        area,
        issueNumber,
        status,
        name,
        updated,
        assignedTo,
        created,
        startDate
    } = issue

    return (
        <div className="flex px-[8px] border-b border-snap-mid-silver hover:bg-snap-teal-100">
            <div className="flex flex-col justify-between pr-[32px] py-[16px]">
                <UI.Pressable
                    className={`flex justify-center items-center w-[24px] h-[24px] rounded-full ${
                        status === 'completed' ? 'bg-snap-green' : 'bg-snap-mid-silver'
                    }`}
                    onPress={handleCompleteIssue}>
                    <UI.Icon size="xs" icon="specta-checkmark" color={status === 'completed' ? 'snap-white' : 'snap-black'} />
                </UI.Pressable>
                <input
                    checked={selectedIssuesKeys.has(issue.key)}
                    onChange={() => selectIssue(issue.key)}
                    className="cursor-pointer"
                    type="checkbox"
                />
            </div>
            <Link className="flex w-full gap-x-[24px] py-[16px]" to="/snap/issues/$key" params={{ key: key }}>
                <div className="min-w-[105px] min-h-[105px] w-[105px] h-[105px]">
                    <IssueImage
                        lastThumbUrl={lastThumbUrl}
                        isImagesLoading={isImagesLoading}
                        lastItemKey={lastItemKey}
                        priority={priority}
                        Render={({ priority, src }) => (
                            <div className="relative min-w-[105px] min-h-[105px] w-[105px] h-[105px] flex items-center justify-center border rounded">
                                <div className="absolute -right-2 -top-2">
                                    <PrioritySign priority={priority} backgroundColor="snap-red" iconColor="white" size="sm" />
                                </div>
                                {src === 'specta-text-issue' || src.startsWith('file') ? (
                                    <i className="specta-text-issue text-3xl" />
                                ) : (
                                    <img src={src} className="w-full h-full object-cover" alt="issue" />
                                )}
                            </div>
                        )}
                    />
                </div>

                <div className="relative flex flex-col w-full justify-between">
                    <div className="flex flex-col">
                        <div className="flex justify-between items-center w-full">
                            <div className="flex items-center gap-x-[4px]">
                                <UI.Text color="snap-mid-gray">{area.group}</UI.Text>
                                <UI.Icon color="snap-mid-gray" icon="specta-arrow-right" size="xs" />
                                <UI.Text color="snap-mid-gray">{area.name}</UI.Text>
                            </div>
                            <IssueNumber
                                issueNumber={issueNumber}
                                status={status}
                                Render={({ text, color }) => <UI.Text color={color}>{text}</UI.Text>}
                            />
                        </div>

                        <IssueName name={name} />

                        <div>
                            {startDate ? (
                                <div className="flex flex-row items-center gap-x-[6px]">
                                    <UI.Icon size="xs" color="snap-red" icon="specta-duedate" />
                                    <UI.Text color="snap-red">{moment(startDate).format('MMM D')}</UI.Text>
                                </div>
                            ) : (
                                <UI.Text size="xs" color="snap-silver">
                                    {shared.itemsMetadata.noDueDate()}
                                </UI.Text>
                            )}
                        </div>
                    </div>

                    <div className="absolute right-0 bottom-[24px]">
                        <AssignedContacts data-tooltip-id="my-tooltip-1" assignedContacts={assignedTo ?? null} />
                    </div>

                    <div className="flex items-end justify-between">
                        <ChangedInfo
                            updated={updated}
                            Render={({ text }) => (
                                <UI.Text size="xs" color="snap-silver">
                                    {text}
                                </UI.Text>
                            )}
                        />
                        <CreatedInfo
                            created={created}
                            Render={({ text }) => (
                                <UI.Text size="xs" color="snap-silver">
                                    {text}
                                </UI.Text>
                            )}
                        />
                    </div>
                </div>
            </Link>
        </div>
    )
}
