import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import { useSetUsers, useUsers, useUsersWithAnalytics } from '@shared-snap/snap/components/settings/hooks/use-users'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { UserAnalytics, UserStruct } from '@shared/firestore-structs'
import { deleteUser, getUsersQuery, onUsersSnapshot } from '@shared/user-data'
import { Link, Outlet, useNavigate } from '@tanstack/react-router'
import {
    type Row,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table'
import firebase, { asFirebase } from 'app/firebase'
import { Button } from 'components/atoms/button/button'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { SearchInput } from 'components/atoms/search-input'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { prefix } from 'routes/__root'
import { ActionsCell } from './actions-cell'
import { ConfirmModal } from './confirm-modal'
import { SortingHeader } from './sorting-header'
import { Table } from './table'

const columnHelper = createColumnHelper<Partial<UserStruct> & { analytics?: UserAnalytics }>()

export function UsersLayout() {
    return (
        <div className="flex flex-col grow shrink-0">
            <Outlet />
            <Header />
            <Content />
        </div>
    )
}

function Header() {
    const users = useUsers()
    const {
        LL: { settingsWeb, navigation }
    } = useI18nContext()

    return (
        <div className="flex justify-between items-center mb-10">
            <div className="flex items-center gap-x-[8px]">
                <h1 className="text-3xl font-bold">{navigation.users()}</h1>
                <QuantityLabel quantity={users.length} />
            </div>

            <Link to={`${prefix}/settings/users/new`}>
                <Button>{settingsWeb.users.newUser()}</Button>
            </Link>
        </div>
    )
}

function Content() {
    const navigate = useNavigate()
    const currentOrganization = useCurrentOrg()
    const users = useUsersWithAnalytics(asFirebase(firebase))
    const setUsers = useSetUsers()
    const [searchValue, setSearchValue] = useState('')
    const [loading, setLoading] = useState(true)
    const [deletingUser, setDeletingUser] = useState<string | null>(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const {
        LL: { settingsWeb }
    } = useI18nContext()

    const openEditModal = (row: Row<Partial<UserStruct>>) => {
        navigate({ to: `${prefix}/settings/users/${row.original.key}` as string })
    }

    const handleDeleteUser = useCallback(async () => {
        if (!deletingUser) return

        setIsDeleting(true)

        try {
            await deleteUser(asFirebase(firebase), deletingUser)
            setDeletingUser(null)
        } catch (error) {
            console.error('Error while deleting user', error)
        } finally {
            setIsDeleting(false)
        }
    }, [deletingUser])

    const columns = [
        columnHelper.accessor('name', {
            cell: info => (
                <UI.Text color="snap-black" size="sm" weight="bold">
                    {info.getValue()}
                </UI.Text>
            ),
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.users.nameColumn()} />
        }),
        columnHelper.accessor('email', {
            cell: info => info.getValue(),
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.users.emailColumn()} />
        }),
        columnHelper.accessor('phoneNumber', {
            cell: info => info.getValue(),
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.users.phoneNumberColumn()} />
        }),
        columnHelper.accessor('authRole', {
            cell: info => info.getValue(),
            enableSorting: true,
            sortingFn: 'text',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.users.roleColumn()} />
        }),
        columnHelper.accessor('areaGroups', {
            cell: info => info.getValue()?.join(', '),
            enableSorting: true,
            sortingFn: 'basic',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.users.unitGroupColumn()} />
        }),
        columnHelper.accessor('created', {
            cell: info => moment(info.getValue()).format('DD/MM/YYYY'),
            enableSorting: true,
            sortingFn: 'datetime',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.users.createdColumn()} />
        }),
        columnHelper.display({
            id: 'lastWebLogin',
            cell: info =>
                info.row.original.analytics?.web
                    ? moment(info.row.original.analytics.web.updated).fromNow()
                    : settingsWeb.users.neverLoggedIn(),
            enableSorting: true,
            sortingFn: 'datetime',
            header: ({ column }) => <SortingHeader column={column} text={`${settingsWeb.users.lastLoginColumn()}(web)`} />
        }),
        columnHelper.display({
            id: 'webVersion',
            cell: info => {
                return info.row.original.analytics?.web
                    ? `${info.row.original.analytics.web.browser}-${info.row.original.analytics.web.sha}`
                    : '--'
            },
            enableSorting: true,
            sortingFn: 'alphanumeric',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.users.webVersionColumn()} />
        }),
        columnHelper.display({
            id: 'lastMobileLogin',
            cell: info =>
                info.row.original.analytics?.mobile
                    ? moment(info.row.original.analytics.mobile.updated).fromNow()
                    : settingsWeb.users.neverLoggedIn(),
            enableSorting: true,
            sortingFn: 'datetime',
            header: ({ column }) => <SortingHeader column={column} text={`${settingsWeb.users.lastLoginColumn()}(mobile)`} />
        }),
        columnHelper.display({
            id: 'mobileVersion',
            cell: info => {
                return info.row.original.analytics?.mobile
                    ? `${info.row.original.analytics.mobile.applicationVersion}${
                          info.row.original.analytics.mobile.sha ? `(${info.row.original.analytics.mobile.sha})` : ''
                      }`
                    : '--'
            },
            enableSorting: true,
            sortingFn: 'alphanumeric',
            header: ({ column }) => <SortingHeader column={column} text={settingsWeb.users.appVersionColumn()} />
        }),
        columnHelper.display({
            id: 'actions',
            header: settingsWeb.users.actionsColumn(),
            cell: ({ row }) => <ActionsCell onEdit={() => openEditModal(row)} onDelete={() => setDeletingUser(row.original.key ?? null)} />
        })
    ]

    const table = useReactTable({
        data: users,
        columns,
        state: {
            globalFilter: searchValue
        },
        onGlobalFilterChange: setSearchValue,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel()
    })

    const loadUsers = useCallback(async () => {
        setLoading(true)

        try {
            await getUsersQuery(asFirebase(firebase), currentOrganization.key)
                .get()
                .then(snapshot => {
                    const users = snapshot.docs.map(doc => doc.data())
                    setUsers(users)
                })
        } catch (error) {
            console.error('Error while loading users', error)
        } finally {
            setLoading(false)
        }
    }, [currentOrganization.key, setUsers])

    useEffect(() => {
        loadUsers()
        const unSubscribe = onUsersSnapshot(asFirebase(firebase), currentOrganization.key, users => {
            setLoading(false)
            setUsers(users)
        })
        return () => unSubscribe()
    }, [setUsers, setLoading, currentOrganization.key])

    if (loading) {
        return (
            <div className="flex grow items-center justify-center">
                <UI.Loader />
            </div>
        )
    }

    return (
        <>
            <div className="mb-10">
                <SearchInput placeholder={settingsWeb.users.searchPlaceholder()} value={searchValue} onChange={setSearchValue} />
            </div>
            <Table table={table} rowOnCLick={row => openEditModal(row)} />
            <ConfirmModal
                isOpen={deletingUser !== null}
                onClose={() => setDeletingUser(null)}
                onConfirm={handleDeleteUser}
                headerText={settingsWeb.users.deleteHeader()}
                bodytext={settingsWeb.users.deleteBody()}
                isLoading={isDeleting}
            />
        </>
    )
}
