import { createFileRoute } from '@tanstack/react-router'
import { addressBase } from './reports.optional'

export const Route = createFileRoute('/snap/_authenticated/reports/housekeeping')({
    component: RouteComponent
})

function RouteComponent() {
    return <iframe width="100%" height="100%" src={`${addressBase}/reports/housekeeping`} style={{ border: 'none' }} />
}
