import { RecaptchaVerifier } from '../firebase'

type WindowWithRecaptchaVerifier = Window & { recaptchaVerifier: RecaptchaVerifier }
export function getRecaptchaVerifier() {
    const windowWithRecaptchaVerifier = window as unknown as WindowWithRecaptchaVerifier

    if (windowWithRecaptchaVerifier.recaptchaVerifier) {
        return windowWithRecaptchaVerifier.recaptchaVerifier
    }

    const recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: function () {
            console.log('captcha approved')
        }
    })

    windowWithRecaptchaVerifier.recaptchaVerifier = recaptchaVerifier

    return recaptchaVerifier
}
