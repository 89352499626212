import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Translation } from '@shared-snap/i18n/i18n-types'
import {
    useCleaningStatusSelector,
    useOccupancySelector
} from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-area-summary'
import { useChangeCleaningStatus } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-change-cleaning-status'
import { useChangeOccupancy } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-change-occupancy'
import { useFetchNextBooking } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-fetch-next-bookings'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { pickCleaningStatusColor, pickOccupancyIcons } from '@shared-snap/snap/utils/housekeeping-utils'
import type { AreaCleaningStatus, Occupancy } from '@shared/firestore-structs'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import { type CustomOptionProps, Select } from 'components/atoms/select/select'
import { useMemo } from 'react'
import Timer from '../timer/timer'

export function OccupancySelector() {
    const { currentValue, options } = useOccupancySelector()
    const {
        booking: { guestName, nrOfGuests, occupancyText, bedSetup },
        isLoading
    } = useFetchNextBooking(asFirebase(firebaseWrapped))
    const changeOccupancy = useChangeOccupancy(asFirebase(firebaseWrapped))
    async function handleOccupancyChange(value: Occupancy) {
        await changeOccupancy(value)
    }
    return (
        <div className="flex flex-col gap-2 w-full">
            <Select
                value={currentValue}
                options={options}
                onChange={handleOccupancyChange}
                CustomOption={({ data, innerProps }) => <OccupancyOption option={data} innerProps={innerProps} />}
                customStyles={{ control: { fontWeight: 500 } }}
            />
            <div className="flex flex-col gap-1">
                {isLoading ? (
                    <>
                        <UI.Skeleton />
                        <UI.Skeleton />
                        <UI.Skeleton />
                        <UI.Skeleton />
                    </>
                ) : (
                    <>
                        <UI.Text color="snap-mid-gray" size="sm">
                            {guestName}
                        </UI.Text>
                        <UI.Text color="snap-mid-gray" size="sm">
                            {nrOfGuests}
                        </UI.Text>
                        <UI.Text color="snap-mid-gray" size="sm">
                            {occupancyText}
                        </UI.Text>
                        <UI.Text color="snap-mid-gray" size="sm">
                            {bedSetup}
                        </UI.Text>
                    </>
                )}
            </div>
        </div>
    )
}

export function CleaningStatusSelector() {
    const { currentValue, options } = useCleaningStatusSelector()
    const changeCleaningStatus = useChangeCleaningStatus(asFirebase(firebaseWrapped))

    async function handleCleaningStatusChange(value: AreaCleaningStatus) {
        await changeCleaningStatus(value)
    }

    return (
        <div className="flex flex-col gap-2 w-full">
            <Select
                value={currentValue}
                options={options}
                onChange={handleCleaningStatusChange}
                CustomOption={({ data, innerProps }) => <CleaningStatusOption option={data} innerProps={innerProps} />}
                customStyles={{ control: { fontWeight: 500 } }}
            />
            <Timer />
        </div>
    )
}

function OccupancyOption<T extends Occupancy>({ option, innerProps }: CustomOptionProps<T>) {
    const icon = useMemo(() => pickOccupancyIcons(option.value), [option.value])
    const { LL } = useI18nContext()

    return (
        <div
            {...innerProps}
            className="flex items-center justify-between py-2 px-4 border-b border-snap-light-gray hover:bg-snap-light-gray">
            <UI.Text color="snap-black">{LL.shared.occupancy[option.label as keyof Translation['shared']['occupancy']]()}</UI.Text>
            <div className="rounded-full bg-snap-light-gray w-8 h-8 flex items-center justify-center">
                <UI.Icon icon={icon} color="snap-black" size="sm" />
            </div>
        </div>
    )
}

function CleaningStatusOption<T extends AreaCleaningStatus>({ option, innerProps }: CustomOptionProps<T>) {
    const { currentValue } = useOccupancySelector()
    const statusColor = useMemo(
        () => pickCleaningStatusColor({ cleaningStatus: option.value, occupancy: currentValue.value }),
        [option.value, currentValue.value]
    )
    const { LL } = useI18nContext()

    return (
        <div {...innerProps} className="flex items-center gap-2 px-4 py-4 border-b border-snap-light-gray hover:bg-snap-light-gray">
            <div className={`rounded-full bg-${statusColor} w-3 h-3`} />
            <UI.Text color="snap-black">
                {LL.shared.cleaningStatuses[option.label as keyof Translation['shared']['cleaningStatuses']]()}
            </UI.Text>
        </div>
    )
}
