import { type Size, UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { Colors, Icon } from '@shared-snap/snap/types/visuals.types'
import React, { forwardRef } from 'react'

interface Props {
    icon: Icon
    size?: Size
    onClick?: () => void
    disabled?: boolean
    color?: Colors
    className?: string
}

export const IconButton = forwardRef<HTMLDivElement, Props>(
    ({ icon, size = 'md', onClick, disabled = false, color = 'snap-silver', className }, ref) => {
        return (
            <div
                ref={ref}
                onClick={!disabled ? onClick : undefined}
                className={`flex items-center justify-center ${
                    disabled ? 'opacity-50 pointer-events-none cursor-default' : 'cursor-pointer hover:opacity-70'
                } ${className}`}>
                <UI.Icon icon={icon} size={size} color={color} />
            </div>
        )
    }
)
