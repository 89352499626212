import type { AreaCleaningStatus, AssignedUserInfo } from '@shared/firestore-structs'
import { CLEANING_STATUS_CLEAN } from '@shared/txt-constants'
import React from 'react'
import { UI } from '../../../../registry/ui-elements-registry'
import type { Colors } from '../../../../types/visuals.types'

interface Props {
    assignedTo: AssignedUserInfo[]
    textColor: Colors
    cleaningStatus: AreaCleaningStatus
}

function AssignedInitials({ assignedTo, textColor, cleaningStatus }: Props) {
    if (!assignedTo || assignedTo.length === 0) return null
    const initials = assignedTo[0].initials ?? '???'
    const isAreaClean = cleaningStatus === CLEANING_STATUS_CLEAN
    const bgColor = isAreaClean ? 'transparent' : 'snap-white'
    const initialsColor = isAreaClean ? 'snap-white' : textColor
    return (
        <UI.View
            className={`flex flex-col items-center justify-center w-9 h-9 text-xs text-white bg-${bgColor} border-white border border-solid rounded-full`}>
            <UI.View className={assignedTo.length > 1 ? 'absolute top-1 ml-1' : ''}>
                <UI.Text color={initialsColor} size="xs">
                    {initials}
                </UI.Text>
            </UI.View>
            {assignedTo.length > 1 && (
                <UI.View className="absolute bottom-0 ml-1">
                    <UI.Text size="xs" color={initialsColor}>
                        +{assignedTo.length - 1}
                    </UI.Text>
                </UI.View>
            )}
        </UI.View>
    )
}

export default AssignedInitials
