import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import type { Translation } from '@shared-snap/i18n/i18n-types'
import { cleaningStatusOptions } from '@shared-snap/snap/utils/housekeeping-utils'
import { SearchInput } from 'components/atoms/search-input'
import type { SetterOrUpdater } from 'recoil'
import { FilterSelect } from './filter-select'

interface TableFiltersProps {
    searchValue?: string
    setSearchValue?: SetterOrUpdater<string>
    searchPlaceholder?: string
    //
    groups?: string[]
    groupsFilter?: string[]
    setGroupsFilter?: SetterOrUpdater<string[]>
    groupsPlaceholder?: string
    //
    synced?: boolean | null
    setSynced?: SetterOrUpdater<boolean | null>
    syncedLabel?: string
    //
    statusFilter?: string
    setStatusFilter?: SetterOrUpdater<string>
    statusLabel?: string
}

export function TableFilters({
    searchValue,
    setSearchValue,
    searchPlaceholder,
    groups,
    groupsFilter,
    setGroupsFilter,
    groupsPlaceholder,
    synced,
    setSynced,
    syncedLabel,
    statusFilter,
    setStatusFilter,
    statusLabel
}: TableFiltersProps) {
    const isSearchInput = searchValue !== undefined && setSearchValue !== undefined
    const isGroupsFilter = groups !== undefined && groupsFilter !== undefined && setGroupsFilter !== undefined
    const isSyncedFilter = synced !== undefined && setSynced !== undefined
    const isStatusFilter = statusFilter !== undefined && setStatusFilter !== undefined

    const {
        LL: { shared }
    } = useI18nContext()

    const handleGroupFilterChange = (selectedValue: string) => {
        if (!isGroupsFilter) return

        if (selectedValue === 'all') {
            setGroupsFilter([])
            return
        }
        if (groupsFilter?.includes(selectedValue)) {
            setGroupsFilter(prev => prev.filter(group => group !== selectedValue))
            return
        }
        setGroupsFilter(prev => [...prev, selectedValue])
    }

    const handleSyncedFilterChange = (selectedValue: string) => {
        if (!isSyncedFilter) return

        switch (selectedValue) {
            case 'all':
                setSynced(null)
                break
            case 'yes':
                setSynced(true)
                break
            case 'no':
                setSynced(false)
                break
        }
    }

    const handleStatusFilterChange = (selectedValue: string) => {
        if (!isStatusFilter) return
        setStatusFilter(selectedValue)
    }

    return (
        <div className="flex w-full justify-between gap-x-10">
            {isSearchInput && (
                <div className="flex max-h-[40px]">
                    <SearchInput placeholder={searchPlaceholder} value={searchValue} onChange={setSearchValue} />
                </div>
            )}
            {isGroupsFilter && (
                <FilterSelect
                    value={
                        groupsFilter.length
                            ? groupsFilter.map(group => ({ value: group, label: group }))
                            : [{ value: 'all', label: shared.all() }]
                    }
                    options={[{ value: 'all', label: shared.all() }, ...groups.map(group => ({ value: group, label: group }))]}
                    onChange={handleGroupFilterChange}
                    isMulti
                    filterLabel={groupsPlaceholder}
                />
            )}
            {isSyncedFilter && (
                <FilterSelect
                    value={[
                        {
                            value: synced === null ? 'all' : synced ? 'yes' : 'no',
                            label: synced === null ? shared.all() : synced ? shared.yes() : shared.no()
                        }
                    ]}
                    options={['all', 'yes', 'no'].map(synced => ({ value: synced, label: shared[synced as 'all' | 'yes' | 'no']() }))}
                    onChange={handleSyncedFilterChange}
                    filterLabel={syncedLabel}
                />
            )}
            {isStatusFilter && (
                <FilterSelect
                    value={[
                        {
                            value: statusFilter,
                            label:
                                statusFilter === 'all'
                                    ? shared.all()
                                    : shared.cleaningStatuses[
                                          cleaningStatusOptions.find(status => status.value === statusFilter)
                                              ?.label as keyof Translation['shared']['cleaningStatuses']
                                      ]()
                        }
                    ]}
                    options={[
                        { value: 'all', label: shared.all() },
                        ...cleaningStatusOptions.map(status => ({
                            value: status.value,
                            label: shared.cleaningStatuses[status.label]()
                        }))
                    ]}
                    onChange={handleStatusFilterChange}
                    filterLabel={statusLabel}
                />
            )}
        </div>
    )
}
