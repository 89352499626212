import type { IssueStruct } from '@shared/firestore-structs'
import type { Colors, Icon } from '../../types/visuals.types'

export function getIssueNumberColor(status: IssueStruct['status']): Colors {
    switch (status) {
        case 'open':
            return 'black'
        case 'assigned':
            return 'sweeply-yellow'
        case 'completed':
            return 'sweeply-green'
        default:
            return 'black'
    }
}

export function convertHashtagsToOptions(hashtags: string[]) {
    return hashtags.map(hashtag => ({ value: hashtag, label: hashtag }))
}

export function getStatusColor(status: IssueStruct['status']): Colors {
    switch (status) {
        case 'completed':
            return 'sweeply-green'
        case 'assigned':
            return 'sweeply-yellow'
        case 'open':
            return 'sweeply-black'
        case 'deleted':
            return 'sweeply-red'
        default:
            return 'sweeply-black'
    }
}

export function pickIssueStatusIcon(status: IssueStruct['status']): { icon: Icon; color: Colors; bgColor?: Colors } {
    switch (status) {
        case 'open':
            return { icon: 'specta-checkmark', color: 'sweeply-black' }
        case 'assigned':
            return { icon: 'specta-assigned-to', color: 'snap-yellow' }
        case 'completed':
            return { icon: 'specta-checkmark', color: 'white', bgColor: 'sweeply-green' }
        case 'deleted':
            return { icon: 'specta-checkmark', color: 'sweeply-red' }
        default:
            throw new Error(`Unknown status: ${status}`)
    }
}
