import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useSignInWithUserPass } from '@shared-snap/snap/components/auth/hooks/use-sign-in'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { useNavigate } from '@tanstack/react-router'
import { auth } from 'app/firebase'
import { Button } from 'components/atoms/button'
import Input from 'components/atoms/input/input'
import { FormLinks } from 'components/molecules/login/form-links'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { prefix } from 'routes/__root'

interface LoginForm {
    email: string
    password: string
}

export function EmailLoginForm() {
    const {
        handleSubmit,
        control,
        formState: { isValid, errors }
    } = useForm<LoginForm>()
    const navigate = useNavigate()
    const {
        LL: { login: loginI18n, shared }
    } = useI18nContext()
    const [isLoading, setIsLoading] = useState(false)
    const [loginError, setLoginError] = useState<string | null>(null)

    const login = useSignInWithUserPass(async ({ password, email }: LoginForm) => {
        setIsLoading(true)
        setLoginError(null)

        try {
            await auth.signInWithEmailAndPassword(email, password)
            navigate({ to: `${prefix}/` })
        } catch (error) {
            console.error(error)
            setLoginError('Something went wrong')
        } finally {
            setIsLoading(false)
        }
    })

    const onSubmit = (data: LoginForm) => {
        const { email, password } = data
        login({ email, password })
    }

    if (isLoading) {
        return <UI.Loader />
    }

    return (
        <form className="flex flex-col grow justify-between" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col items-center grow shrink-0 mb-10">
                <div className="flex flex-col gap-10 w-full">
                    <div className="flex flex-col gap-10">
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                required: shared.errorFieldIsRequired({ field: loginI18n.email() }),
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: loginI18n.invalidEmail()
                                }
                            }}
                            render={({ field }) => (
                                <div className="flex flex-col">
                                    <Input {...field} type="email" placeholder={loginI18n.email()} />
                                    {errors.email && <UI.Text color="snap-red">{errors.email.message}</UI.Text>}
                                </div>
                            )}
                        />

                        <Controller
                            name="password"
                            control={control}
                            rules={{
                                required: shared.errorFieldIsRequired({ field: loginI18n.password() })
                            }}
                            render={({ field }) => (
                                <div className="flex flex-col">
                                    <Input {...field} type="password" placeholder={loginI18n.password()} />
                                    {errors.password && <UI.Text color="snap-red">{errors.password.message}</UI.Text>}
                                </div>
                            )}
                        />
                    </div>

                    <Button className="mx-auto" disabled={!isValid}>
                        {loginI18n.logInBtn()}
                    </Button>
                </div>
                {loginError && <UI.Text color="snap-red">{loginError}</UI.Text>}
            </div>

            <FormLinks type="phoneNumber" />
        </form>
    )
}
