import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { useCurrentOrg, usePermission } from '@shared-snap/snap/components/auth/hooks/use-auth-state'
import {
    useAreaSummaryCleaningStatusColor,
    useAreaSummaryDate,
    useAreaSummaryUsersOptions
} from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-area-summary'
import { useAreaSummaryAssign } from '@shared-snap/snap/components/housekeeping/area-summary/hooks/use-area-summary-assign'
import { areaRuleSelector, areaSummary } from '@shared-snap/snap/components/housekeeping/area-summary/state/area-summary-state'
import { AreaName, AssignButton, PrioritySign } from '@shared-snap/snap/components/housekeeping/area-summary/ui/area-summary'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import { pickAreaIcons } from '@shared-snap/snap/utils/housekeeping-utils'
import firebase, { asFirebase } from 'app/firebase'
import { DateNavigator } from 'components/atoms/date-picker/date-picker'
import { ModalHeader } from 'components/atoms/modal/modal'
import Tooltip from 'components/atoms/tooltip'
import { UsersModal } from 'components/organisms/users-modal/users-modal'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

export function AreaSummaryHeader({ onClose }: { onClose: () => void }) {
    const {
        LL: { tooltips }
    } = useI18nContext()
    const [usersModalIsOpen, setUsersModalIsOpen] = useState(false)
    const cleaningStatusColor = useAreaSummaryCleaningStatusColor()
    const {
        area: { occupancy, cleaningStatus, guestCheckedIn = false, note, key: areaKey },
        dailyComments
    } = useRecoilValue(areaSummary)
    const rules = useRecoilValue(areaRuleSelector(areaKey))
    const { selected, options } = useAreaSummaryUsersOptions()
    const { date, setDate } = useAreaSummaryDate()
    const org = useCurrentOrg()
    const assign = useAreaSummaryAssign(asFirebase(firebase))
    const havePermission = usePermission()
    const icons = useMemo(
        () => pickAreaIcons({ occupancy, cleaningStatus, guestCheckedIn, note, dailyComments, rules }),
        [occupancy, cleaningStatus, guestCheckedIn, note, dailyComments]
    )
    const canAssign = havePermission('assign-housekeeping-assign')

    return (
        <ModalHeader onClose={onClose} closeColor="snap-white" className={`bg-${cleaningStatusColor} px-6 z-50 rounded-t-lg`}>
            {canAssign && (
                <UsersModal
                    usersOptions={options}
                    isOpen={usersModalIsOpen}
                    onClose={() => setUsersModalIsOpen(false)}
                    onAssign={assign}
                    initialSelected={selected}
                />
            )}
            <div className="absolute flex gap-16 cursor-pointer left-6 top-6">
                <div data-tooltip-id="area-priority-tooltip">
                    <PrioritySign firebase={asFirebase(firebase)} clickable />
                </div>
                {canAssign && (
                    <div data-tooltip-id="assign-tooltip">
                        <AssignButton onClick={() => setUsersModalIsOpen(true)} />
                    </div>
                )}
            </div>
            <div className="m-auto w-fit">
                <DateNavigator
                    value={date.format('YYYY-MM-DD')}
                    onChange={value => setDate(moment.tz(value, org.timezone))}
                    todayBtnColor="snap-white"
                />
            </div>
            <div className="flex justify-between">
                <h2 className="text-2xl font-bold text-white mt-6 mb-4">
                    <AreaName color="snap-white" />
                </h2>
                <ul className="flex items-center gap-3">
                    <UI.List
                        items={icons}
                        render={(item, i) => (
                            <li key={item + i}>
                                <UI.Icon icon={item} size="md" color="white" />
                            </li>
                        )}
                    />
                </ul>
            </div>
            <Tooltip id="area-priority-tooltip" text={tooltips.setPriority()} />
            <Tooltip id="assign-tooltip" text={tooltips.assign()} />
        </ModalHeader>
    )
}
