import type { AreaSummaryProjectionBooking } from '@shared/firestore-structs'
import { daysBetween, isSameDate } from '@shared/get-booking-display'
import type { AreaSummaryStruct_v2 } from '@shared/projections-v2'
import { mapToAreaProjection_v2 } from '@shared/projections/v2/mapToAreaProjection'
import { getCompletedCleaningDurationMs, getEndTime } from '@shared/timer-helpers'
import moment, { type Moment } from 'moment-timezone'
import { clone } from 'ramda'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useI18nContext } from '../../../../../i18n/i18n-react'
import { getAreaSummaryQuery } from '../../../../api/queries'
import { useFetchStatus, useSplitFirestoreDocument } from '../../../../infra/firestore-atom'
import { useCurrentOrg, useCurrentUser } from '../../../auth/hooks/use-auth-state'
import { useUsers } from '../../../auth/hooks/use-users'
import { areasSelector, rulesAtom } from '../../../auth/state/login'
import { areaSummaryDate } from '../../date/state/date-selection-state'
import {
    activitiesAtom,
    areaState,
    areaSummaryBackend,
    areaSummaryPrioritySelector,
    assignedToSelector,
    cleaningSelector,
    cleaningStatusColorSelector,
    cleaningStatusIconsSelector,
    cleaningStatusSelector,
    currentTaskAtom,
    dailyCommentSelector,
    extraServiceSelector,
    isFutureBookingSelector,
    lastHousekeepingAtom,
    leadBookingSelector,
    occupancySelector,
    selectedAreaKey,
    unitNoteSelector
} from '../state/area-summary-state'

export function useAreaSummary(areaKey: string) {
    const user = useCurrentUser()
    const areasummaryDate = useRecoilValue(areaSummaryDate)
    const setAreaSummary = useSetRecoilState(areaSummaryBackend)
    const areas = useRecoilValue(areasSelector)
    const rules = useRecoilValue(rulesAtom)
    const currentOrganization = useCurrentOrg()
    const [selAreaKey, setSelAreaKey] = useRecoilState(selectedAreaKey)
    useEffect(() => {
        if (areaKey !== selAreaKey) {
            setSelAreaKey(areaKey)
        }
    }, [selAreaKey, areaKey])

    return useFetchStatus(
        useSplitFirestoreDocument<AreaSummaryStruct_v2>({
            onData: areaSummaryValue => {
                console.log(`AreaSummary for ${areaKey} fetched and ticks is ${areaSummaryValue.ticks}`)
                setAreaSummary({ [areaKey]: areaSummaryValue })
            },
            onError: () => {
                const cleanAreas = areas.filter(area => area.key === areaKey)
                const area = cleanAreas[0]
                const { result } = mapToAreaProjection_v2({
                    area: clone(area),
                    startOfDayInTZ: moment(areasummaryDate).startOf('day').valueOf(),
                    org: currentOrganization,
                    bookings: [],
                    activities: [],
                    rules: rules,
                    currentTask: undefined,
                    dailyComments: [],
                    lastCleaningTask: null
                })
                return result
            },
            query: db => getAreaSummaryQuery(db, user.organizationKey, areasummaryDate.format('YYYY-MM-DD'), areaKey),
            name: `Area summary for ${areaKey}`
        })
    )
}

export function useAreaSummaryAssignedTo() {
    return useRecoilValue(assignedToSelector)
}

export function useAreaSummaryUsersOptions() {
    const users = useUsers()
    const task = useRecoilValue(currentTaskAtom)

    const options = users
        .map(u => {
            return {
                value: u.key,
                label: u.name,
                initials: u.initials,
                data: u
            }
        })
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0
        })
    const selected = task?.assignedTo?.map(user => user.key)

    return { selected, options }
}
export function useAreaSummaryRulesOptions() {
    const rules = useRecoilValue(rulesAtom)

    const options = rules
        .map(r => {
            return {
                value: r.key,
                label: r.name,
                data: r
            }
        })
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0
        })

    return { options }
}

export function useAreaSummaryDate() {
    const [date, setDate] = useRecoilState(areaSummaryDate)
    return { date, setDate }
}

export function useAreaSummaryCleaningStatusColor() {
    return useRecoilValue(cleaningStatusColorSelector)
}

export function useCleaning() {
    return useRecoilValue(cleaningSelector)
}

export function useCleaningStatusSelector() {
    const {
        LL: { shared }
    } = useI18nContext()
    const status = useRecoilValue(cleaningStatusSelector)
    return { ...status, currentValue: { ...status.currentValue, label: shared.cleaningStatuses[status.currentValue.label]() } }
}
export function useOccupancySelector() {
    const {
        LL: { shared }
    } = useI18nContext()
    const status = useRecoilValue(occupancySelector)
    return { ...status, currentValue: { ...status.currentValue, label: shared.occupancy[status.currentValue.label]() } }
}
export function useAreaSummaryPriority() {
    return useRecoilValue(areaSummaryPrioritySelector)
}

export function useArea() {
    const area = useRecoilValue(areaState)
    if (!area) throw new Error('Area not found')
    return area
}

export function useUnitNote() {
    return useRecoilValue(unitNoteSelector)
}

export function useDailyComment() {
    return useRecoilValue(dailyCommentSelector)
}

export function useActivitiesQuantity() {
    return useRecoilValue(activitiesAtom).length
}

export function useExtraService() {
    return useRecoilValue(extraServiceSelector)
}

export function useLastHousekeeping() {
    const lastHousekeeping = useRecoilValue(lastHousekeepingAtom)
    const currentOrg = useCurrentOrg()
    if (!lastHousekeeping) return null

    const { cleaning, assignedTo } = lastHousekeeping
    if (!cleaning) return null

    const endTime = getEndTime(cleaning)
    if (!endTime || !cleaning.play) return null

    const lastPlayTime = moment(cleaning.play[cleaning.play.length - 1])
    if (lastPlayTime.isAfter(endTime)) {
        return null
    }

    const duration = moment.duration(getCompletedCleaningDurationMs(cleaning)).humanize()

    return {
        finished: moment.tz(endTime, currentOrg.timezone).format('HH:mm | ddd, MMM D, YYYY'),
        doneBy: assignedTo,
        duration: duration
    }
}

export function useCleaningStatusIcons() {
    return useRecoilValue(cleaningStatusIconsSelector)
}

export function useLeadBooking() {
    return useRecoilValue(leadBookingSelector)
}

export function useIsFutureBooking() {
    return useRecoilValue(isFutureBookingSelector)
}

const useOccupancyText = () => {
    const { LL } = useI18nContext()
    return (booking: AreaSummaryProjectionBooking, guestPostfix: string, date = moment(), isTaskRow = false) => {
        date.startOf('day')

        const nrOfDaysStayed = daysBetween(booking.checkinDate, date)
        const lengthOfStay = daysBetween(booking.checkinDate, booking.checkoutDate)
        const daysTillArrival = daysBetween(date, booking.checkinDate)

        if (isSameDate(booking.checkinDate, date.valueOf())) {
            if (isTaskRow) {
                return LL.area.arrivingToday()
            } else {
                return LL.area.arrivingWithGuests({ guestPostfix })
            }
        }
        if (moment(booking.checkoutDate).isSame(moment(date), 'day')) {
            return LL.area.checkoutToday()
        }
        if (daysTillArrival > 0) {
            return LL.area.arrivalInDays({ days: daysTillArrival })
        }
        return LL.area.lengthOfStay({ nrOfDaysStayed: nrOfDaysStayed + 1, lengthOfStay })
    }
}

export const useCreateBookingDisplay = () => {
    const { LL } = useI18nContext()
    const getOccupancyText = useOccupancyText()
    return (booking: AreaSummaryProjectionBooking, date: Moment) => {
        const guestPostfix = LL.area.newGuestPostfix({ nrAdults: booking.adults, nrChildren: booking.children })
        const occupancyText = getOccupancyText(booking, guestPostfix, moment(date))

        return {
            guestName: booking.guestName,
            nrOfGuests: guestPostfix,
            occupancyText: occupancyText,
            bedSetup: booking.bedSetup ?? LL.area.noBedSetupInfo(),
            notes: booking.notes
        }
    }
}

export const useUnknownBooking = () => {
    const { LL } = useI18nContext()
    return {
        guestName: '',
        nrOfGuests: '',
        occupancyText: LL.area.unknownNextArrival(),
        bedSetup: LL.area.noBedSetupInfo(),
        notes: ''
    }
}
