import type { Column } from '@tanstack/react-table'

export function SortingHeader<T>({ column, text }: { column: Column<T, unknown>; text: string }) {
    const isAscending = column.getIsSorted() === 'asc'
    const isDescending = column.getIsSorted() === 'desc'
    const isSorted = isAscending || isDescending

    return (
        <div
            className={`flex items-center gap-2 cursor-pointer ${isSorted && 'font-bold text-snap-black'}`}
            onClick={column.getToggleSortingHandler()}>
            {text}
            {isAscending && <span>&#9650;</span>}
            {isDescending && <span>&#9660;</span>}
        </div>
    )
}
