import { useI18nContext } from '@shared-snap/i18n/i18n-react'
import { rulesAtom } from '@shared-snap/snap/components/auth/state/login'
import { useMassAssign } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-mass-assign'
import { useTeamWorkload } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/hooks/use-mass-assigning-bar'
import { selectedAreasAtom } from '@shared-snap/snap/components/housekeeping/housekeeping-overview/state/mass-assigning-state'
import { UI } from '@shared-snap/snap/registry/ui-elements-registry'
import type { AssigneesWithCountAndAreas, TeamWorkloadGroup } from '@shared-snap/snap/types/housekeeping.types'
import { countWorkload } from '@shared-snap/snap/utils/housekeeping-utils'
import type { AreaStruct } from '@shared/firestore-structs'
import firebaseWrapped, { asFirebase } from 'app/firebase'
import AssignedContacts from 'components/atoms/assigned-contacts'
import { QuantityLabel } from 'components/atoms/quantity-label'
import { useMemo, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { useRecoilValue } from 'recoil'

interface WorkloadProps {
    areas: Pick<AreaStruct, 'occupancy' | 'cleaningStatus'>[]
}

const icons = ['specta-suitcase', 'specta-bed', 'specta-broom', 'specta-leaf', 'specta-check-in', 'specta-workload']

export function TeamWorkload() {
    const {
        LL: {
            housekeeping: { massAssignBar }
        }
    } = useI18nContext()
    const [showGroups, setShowGroups] = useState(false)
    const teamWorkloadList = useTeamWorkload(showGroups)
    const [isExpanded, setIsExpanded] = useState(false)
    const workloadListRef = useRef<HTMLDivElement>(null)

    const workloadCount = useMemo(
        () =>
            teamWorkloadList.reduce((acc, item) => {
                if ('group' in item) {
                    acc += item.users.reduce((acc, user) => acc + user.count, 0)
                } else {
                    acc += item.count
                }

                return acc
            }, 0),
        [teamWorkloadList]
    )

    if (teamWorkloadList.length === 0) return null

    return (
        <div
            ref={workloadListRef}
            className="flex flex-col absolute gap-y-[10px] right-[64px] bottom-[138px] p-[20px] bg-white border border-mid-silver rounded-t-[10px] min-w-[267px] overflow-hidden max-h-[calc(100vh-140px)]">
            <div className="flex flex-col gap-y-[16px]">
                <div onClick={() => setIsExpanded(prev => !prev)} className="flex justify-between cursor-pointer">
                    <div className="flex items-center gap-x-[8px]">
                        <UI.Text size="md" weight="bold">
                            {massAssignBar.teamWorkload()}
                        </UI.Text>
                        <QuantityLabel size="md" quantity={workloadCount} />
                    </div>
                    <ExpandIcon isExpanded={isExpanded} />
                </div>
                {isExpanded && (
                    <div className="flex gap-1">
                        <input type="checkbox" checked={showGroups} onChange={e => setShowGroups(e.currentTarget.checked)} />
                        <UI.Text color="snap-mid-gray" size="xs">
                            {massAssignBar.showGroups()}
                        </UI.Text>
                    </div>
                )}
            </div>

            {isExpanded && (
                <div style={{ scrollbarWidth: 'none' }} className="flex flex-col gap-2 overflow-y-auto">
                    <UI.List
                        // this "as" has no harm as it's really could be either
                        // we check for the type in the render function
                        items={teamWorkloadList as (TeamWorkloadGroup | AssigneesWithCountAndAreas)[]}
                        render={(item: TeamWorkloadGroup | AssigneesWithCountAndAreas, i) =>
                            'group' in item ? (
                                <Group key={item.group} group={item} />
                            ) : (
                                <Assignee key={item.areas[0].key + i} assignees={item} />
                            )
                        }
                    />
                </div>
            )}
        </div>
    )
}

function Group({ group }: { group: TeamWorkloadGroup }) {
    const [isExpanded, setIsExpanded] = useState(true)
    const assignedAreasCount = useMemo(() => group.users.reduce((acc, user) => acc + user.count, 0), [group.users])
    return (
        <div>
            <div onClick={() => setIsExpanded(prev => !prev)} className="my-2 flex flex-row justify-between cursor-pointer">
                <div className="flex flex-row items-center gap-x-[10px]">
                    <UI.Text>{group.group}</UI.Text>
                    <QuantityLabel size="md" quantity={assignedAreasCount} />
                </div>

                <ExpandIcon isExpanded={isExpanded} />
            </div>
            {isExpanded && (
                <div className="flex flex-col gap-2">
                    <UI.List
                        items={group.users}
                        render={(assignee: AssigneesWithCountAndAreas, i) => (
                            <Assignee key={assignee.areas[0].key + i} assignees={assignee} />
                        )}
                    />
                </div>
            )}
        </div>
    )
}

function Assignee({ assignees }: { assignees: AssigneesWithCountAndAreas }) {
    const { massAssign } = useMassAssign(asFirebase(firebaseWrapped))
    const setSelectedAreas = useSetRecoilState(selectedAreasAtom)

    function onPress() {
        massAssign(assignees.users.map(user => user.key)).then(() => setSelectedAreas(new Set()))
    }

    return (
        <UI.Pressable className="hover:bg-snap-teal-100" onPress={onPress}>
            <div className="flex gap-x-2 items-center">
                <div style={{ gridTemplateColumns: '115px 36px' }} className="grid items-center gap-x-2">
                    <AssignedContacts assignedContacts={assignees.users} />
                    <div className="justify-self-start">
                        <QuantityLabel size="md" quantity={assignees.count} />
                    </div>
                </div>

                <WorkloadIcons areas={assignees.areas} />
            </div>
        </UI.Pressable>
    )
}

function ExpandIcon({ isExpanded }: { isExpanded: boolean }) {
    return (
        <div className="flex rotate-90 w-[24px] justify-center items-center">
            <UI.Icon size="xs" icon={isExpanded ? 'specta-arrow-right' : 'specta-arrow-left'} />
        </div>
    )
}

export function WorkloadIcons({ areas }: WorkloadProps) {
    const rules = useRecoilValue(rulesAtom)
    const counts = useMemo(() => countWorkload(areas, rules), [areas, rules])
    const workloads = useMemo(() => Object.values(counts).filter(({ count }) => count > 0), [counts])
    const getWorkload = (icon: string) => workloads.find(workload => workload.icon === icon)

    return (
        <div style={{ gridTemplateColumns: `repeat(${icons.length}, minmax(0, 1fr))`, width: `${icons.length * 40}px` }} className="grid">
            <UI.List
                items={icons}
                render={workloadIcon => {
                    const workload = getWorkload(workloadIcon)
                    return (
                        <div key={workloadIcon} className="flex items-center min-w-[40px]">
                            {workload && (
                                <>
                                    <UI.Icon icon={workload.icon} size="sm" color="snap-silver" />
                                    <div className="flex justify-center w-full">
                                        <UI.Text size="xs" color="snap-silver">
                                            {workload.count}
                                        </UI.Text>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                }}
            />
        </div>
    )
}
