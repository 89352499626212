import moment from 'moment/moment'
import { findLeadBooking } from '../../booking-service'
import { calculateCleaningStatusSync } from '../../calculator/cleaning-calculator'
import { isFeatureOn } from '../../feature-toggles'
import type {
    ActivityStruct,
    AreaStruct,
    BookingStruct,
    DailyCommentStruct,
    OrgStruct,
    RuleStruct,
    TaskStruct
} from '../../firestore-structs'
import { sortTimeStampDescending } from '../../helpers'
import type { AreaSummaryStruct_v2 } from '../../projections-v2'
import { remapToNumber } from '../projection-ticks'

export function mapToAreaProjection_v2({
    area,
    startOfDayInTZ,
    org,
    bookings,
    activities,
    rules,
    currentTask,
    dailyComments,
    lastCleaningTask,
    ticks = 0
}: {
    area: Pick<
        AreaStruct,
        | 'inspection'
        | 'occupancy'
        | 'activeRule'
        | 'cleaningStatus'
        | 'key'
        | 'guestCheckedOut'
        | 'guestCheckedIn'
        | 'organizationKey'
        | 'name'
        | 'cleaningPriority'
    >
    startOfDayInTZ: number
    org: OrgStruct
    bookings: BookingStruct[]
    activities: ActivityStruct[]
    rules: RuleStruct[]
    currentTask?: TaskStruct
    dailyComments: DailyCommentStruct[]
    lastCleaningTask: TaskStruct | null
    ticks?: number
}) {
    // Bookings or tasks can have rule overrides, which can be used to change the cleaning status of an area, create a const with those values

    const updatedArea = calculateCleaningStatusSync(
        [area],
        startOfDayInTZ,
        org,
        bookings,
        activities,
        rules,
        false,
        undefined,
        currentTask?.ruleOverride
    )

    const isPriority: boolean =
        currentTask?.priority ||
        (activities.filter(x => x.type === 'cleaning-priority').sort((a, b) => sortTimeStampDescending(a.created, b.created))[0]?.change
            .after as boolean)

    const updatedAreaElement = updatedArea[0]
    const leadBooking = findLeadBooking(updatedAreaElement, bookings, startOfDayInTZ)
    const MAX_INLINE_ACTIVITIES = 20
    const latestActivities = activities.slice(-MAX_INLINE_ACTIVITIES)

    const result: AreaSummaryStruct_v2 = {
        bookings: bookings.map(b => {
            return {
                isLeadBooking: b.key === leadBooking?.key,
                nrOfGuests: b.adults + b.children,
                adults: b.adults,
                children: b.children,
                checkinDate: b.checkinDate,
                checkoutDate: b.checkoutDate,
                status: b.status,
                guestName: b.guestName,
                notes: b.notes,
                bedSetup: b.bedSetup,
                areaKey: b.areaKey,
                key: b.key,
                optInDates: b.optInDates,
                optOutDates: b.optOutDates,
                ruleOverride: b.ruleOverride,
                guestCheckedIn: b.guestCheckedIn,
                guestCheckedOut: b.guestCheckedOut
            }
        }),
        currentTask: currentTask
            ? {
                  key: currentTask.key,
                  cleaning: currentTask.cleaning,
                  checklist: currentTask.checklist,
                  name: currentTask.name,
                  assignedTo: currentTask.assignedTo ?? [],
                  startDate: currentTask.startDate,
                  status: currentTask.status
              }
            : null,
        activeRule: updatedAreaElement.activeRule
            ? {
                  customChar: updatedAreaElement.activeRule.customChar,
                  name: updatedAreaElement.activeRule.name,
                  description: updatedAreaElement.activeRule.description,
                  checklistTasks: updatedAreaElement.activeRule.checklistTasks ?? [],
                  mandatory: updatedAreaElement.activeRule.mandatory ?? false
              }
            : null,
        extras: leadBooking?.extras ?? [],
        bookingNotes: leadBooking?.notes,
        area: {
            key: updatedAreaElement.key,
            guestCheckedIn: updatedAreaElement.guestCheckedIn,
            cleaningStatus: updatedAreaElement.cleaningStatus,
            occupancy: updatedAreaElement.occupancy,
            activeRule: updatedAreaElement.activeRule,
            organizationKey: updatedAreaElement.organizationKey,
            cleaningPriority: updatedAreaElement.cleaningPriority,
            inspection: updatedAreaElement.inspection
        },
        dailyComments: (dailyComments || []).map(d => {
            return { key: d.key, comment: d.comment, date: d.date }
        }),
        assigned: currentTask?.assignedTo ?? [],
        activities: latestActivities,
        moreActivities: activities.length > MAX_INLINE_ACTIVITIES,
        priority: isPriority,
        bedSetup: leadBooking?.bedSetup,
        lastHousekeeping:
            lastCleaningTask && lastCleaningTask.cleaning
                ? {
                      cleaning: lastCleaningTask.cleaning,
                      assignedTo: lastCleaningTask.assignedTo ?? []
                  }
                : null,
        ticks: remapToNumber(ticks)
    }
    return { updatedArea, isPriority, leadBooking, result }
}
