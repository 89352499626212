/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TaskboardImport } from './routes/taskboard'
import { Route as IssuesImport } from './routes/issues'
import { Route as HousekeepingImport } from './routes/housekeeping'
import { Route as IndexImport } from './routes/index'
import { Route as SnapIndexImport } from './routes/snap/index'
import { Route as SnapUnauthenticatedImport } from './routes/snap/_unauthenticated'
import { Route as SnapAuthenticatedImport } from './routes/snap/_authenticated'
import { Route as SnapAuthenticatedTaskboardImport } from './routes/snap/_authenticated/taskboard'
import { Route as SnapAuthenticatedIssuesImport } from './routes/snap/_authenticated/issues'
import { Route as SnapAuthenticatedHousekeepingImport } from './routes/snap/_authenticated/housekeeping'
import { Route as IntegrationsApaleoTaskboardImport } from './routes/integrations.apaleo.taskboard'
import { Route as SnapUnauthenticatedLoginIndexImport } from './routes/snap/_unauthenticated/login/index'
import { Route as SnapUnauthenticatedLoginPhoneImport } from './routes/snap/_unauthenticated/login/phone'
import { Route as SnapUnauthenticatedLoginEmailImport } from './routes/snap/_unauthenticated/login/email'
import { Route as SnapUnauthenticatedLoginCodeImport } from './routes/snap/_unauthenticated/login/code'
import { Route as SnapAuthenticatedTaskboardNewTaskImport } from './routes/snap/_authenticated/taskboard.new-task'
import { Route as SnapAuthenticatedTaskboardKeyImport } from './routes/snap/_authenticated/taskboard.$key'
import { Route as SnapAuthenticatedSettingsUsersImport } from './routes/snap/_authenticated/settings.users'
import { Route as SnapAuthenticatedSettingsUnitsImport } from './routes/snap/_authenticated/settings.units'
import { Route as SnapAuthenticatedSettingsRulesImport } from './routes/snap/_authenticated/settings.rules'
import { Route as SnapAuthenticatedSettingsCategoriesImport } from './routes/snap/_authenticated/settings.categories'
import { Route as SnapAuthenticatedSettingsAccountImport } from './routes/snap/_authenticated/settings.account'
import { Route as SnapAuthenticatedReportsWorkloadImport } from './routes/snap/_authenticated/reports.workload'
import { Route as SnapAuthenticatedReportsOptionalImport } from './routes/snap/_authenticated/reports.optional'
import { Route as SnapAuthenticatedReportsIssuesImport } from './routes/snap/_authenticated/reports.issues'
import { Route as SnapAuthenticatedReportsHousekeepingImport } from './routes/snap/_authenticated/reports.housekeeping'
import { Route as SnapAuthenticatedIssuesNewImport } from './routes/snap/_authenticated/issues.new'
import { Route as SnapAuthenticatedIssuesKeyImport } from './routes/snap/_authenticated/issues.$key'
import { Route as SnapAuthenticatedHousekeepingCommentsAndNotesImport } from './routes/snap/_authenticated/housekeeping.comments-and-notes'
import { Route as SnapAuthenticatedHousekeepingAreaKeyImport } from './routes/snap/_authenticated/housekeeping.$areaKey'
import { Route as SnapAuthenticatedSettingsUsersNewImport } from './routes/snap/_authenticated/settings.users.new'
import { Route as SnapAuthenticatedSettingsUsersKeyImport } from './routes/snap/_authenticated/settings.users.$key'
import { Route as SnapAuthenticatedSettingsUnitsNewImport } from './routes/snap/_authenticated/settings.units.new'
import { Route as SnapAuthenticatedSettingsUnitsKeyImport } from './routes/snap/_authenticated/settings.units.$key'
import { Route as SnapAuthenticatedSettingsRulesNewImport } from './routes/snap/_authenticated/settings.rules_.new'
import { Route as SnapAuthenticatedSettingsRulesKeyImport } from './routes/snap/_authenticated/settings.rules_.$key'
import { Route as SnapAuthenticatedSettingsCategoriesNewImport } from './routes/snap/_authenticated/settings.categories.new'
import { Route as SnapAuthenticatedSettingsCategoriesKeyImport } from './routes/snap/_authenticated/settings.categories.$key'
import { Route as SnapAuthenticatedSettingsAccountSubscriptionImport } from './routes/snap/_authenticated/settings.account.subscription'
import { Route as SnapAuthenticatedSettingsAccountInformationImport } from './routes/snap/_authenticated/settings.account.information'
import { Route as SnapAuthenticatedIntegrationsApaleoTaskboardImport } from './routes/snap/_authenticated/integrations.apaleo.taskboard'

// Create Virtual Routes

const SnapImport = createFileRoute('/snap')()

// Create/Update Routes

const SnapRoute = SnapImport.update({
  id: '/snap',
  path: '/snap',
  getParentRoute: () => rootRoute,
} as any)

const TaskboardRoute = TaskboardImport.update({
  id: '/taskboard',
  path: '/taskboard',
  getParentRoute: () => rootRoute,
} as any)

const IssuesRoute = IssuesImport.update({
  id: '/issues',
  path: '/issues',
  getParentRoute: () => rootRoute,
} as any)

const HousekeepingRoute = HousekeepingImport.update({
  id: '/housekeeping',
  path: '/housekeeping',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SnapIndexRoute = SnapIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => SnapRoute,
} as any)

const SnapUnauthenticatedRoute = SnapUnauthenticatedImport.update({
  id: '/_unauthenticated',
  getParentRoute: () => SnapRoute,
} as any)

const SnapAuthenticatedRoute = SnapAuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => SnapRoute,
} as any)

const SnapAuthenticatedTaskboardRoute = SnapAuthenticatedTaskboardImport.update(
  {
    id: '/taskboard',
    path: '/taskboard',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any,
)

const SnapAuthenticatedIssuesRoute = SnapAuthenticatedIssuesImport.update({
  id: '/issues',
  path: '/issues',
  getParentRoute: () => SnapAuthenticatedRoute,
} as any)

const SnapAuthenticatedHousekeepingRoute =
  SnapAuthenticatedHousekeepingImport.update({
    id: '/housekeeping',
    path: '/housekeeping',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const IntegrationsApaleoTaskboardRoute =
  IntegrationsApaleoTaskboardImport.update({
    id: '/integrations/apaleo/taskboard',
    path: '/integrations/apaleo/taskboard',
    getParentRoute: () => rootRoute,
  } as any)

const SnapUnauthenticatedLoginIndexRoute =
  SnapUnauthenticatedLoginIndexImport.update({
    id: '/login/',
    path: '/login/',
    getParentRoute: () => SnapUnauthenticatedRoute,
  } as any)

const SnapUnauthenticatedLoginPhoneRoute =
  SnapUnauthenticatedLoginPhoneImport.update({
    id: '/login/phone',
    path: '/login/phone',
    getParentRoute: () => SnapUnauthenticatedRoute,
  } as any)

const SnapUnauthenticatedLoginEmailRoute =
  SnapUnauthenticatedLoginEmailImport.update({
    id: '/login/email',
    path: '/login/email',
    getParentRoute: () => SnapUnauthenticatedRoute,
  } as any)

const SnapUnauthenticatedLoginCodeRoute =
  SnapUnauthenticatedLoginCodeImport.update({
    id: '/login/code',
    path: '/login/code',
    getParentRoute: () => SnapUnauthenticatedRoute,
  } as any)

const SnapAuthenticatedTaskboardNewTaskRoute =
  SnapAuthenticatedTaskboardNewTaskImport.update({
    id: '/new-task',
    path: '/new-task',
    getParentRoute: () => SnapAuthenticatedTaskboardRoute,
  } as any)

const SnapAuthenticatedTaskboardKeyRoute =
  SnapAuthenticatedTaskboardKeyImport.update({
    id: '/$key',
    path: '/$key',
    getParentRoute: () => SnapAuthenticatedTaskboardRoute,
  } as any)

const SnapAuthenticatedSettingsUsersRoute =
  SnapAuthenticatedSettingsUsersImport.update({
    id: '/settings/users',
    path: '/settings/users',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedSettingsUnitsRoute =
  SnapAuthenticatedSettingsUnitsImport.update({
    id: '/settings/units',
    path: '/settings/units',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedSettingsRulesRoute =
  SnapAuthenticatedSettingsRulesImport.update({
    id: '/settings/rules',
    path: '/settings/rules',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedSettingsCategoriesRoute =
  SnapAuthenticatedSettingsCategoriesImport.update({
    id: '/settings/categories',
    path: '/settings/categories',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedSettingsAccountRoute =
  SnapAuthenticatedSettingsAccountImport.update({
    id: '/settings/account',
    path: '/settings/account',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedReportsWorkloadRoute =
  SnapAuthenticatedReportsWorkloadImport.update({
    id: '/reports/workload',
    path: '/reports/workload',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedReportsOptionalRoute =
  SnapAuthenticatedReportsOptionalImport.update({
    id: '/reports/optional',
    path: '/reports/optional',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedReportsIssuesRoute =
  SnapAuthenticatedReportsIssuesImport.update({
    id: '/reports/issues',
    path: '/reports/issues',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedReportsHousekeepingRoute =
  SnapAuthenticatedReportsHousekeepingImport.update({
    id: '/reports/housekeeping',
    path: '/reports/housekeeping',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedIssuesNewRoute = SnapAuthenticatedIssuesNewImport.update(
  {
    id: '/new',
    path: '/new',
    getParentRoute: () => SnapAuthenticatedIssuesRoute,
  } as any,
)

const SnapAuthenticatedIssuesKeyRoute = SnapAuthenticatedIssuesKeyImport.update(
  {
    id: '/$key',
    path: '/$key',
    getParentRoute: () => SnapAuthenticatedIssuesRoute,
  } as any,
)

const SnapAuthenticatedHousekeepingCommentsAndNotesRoute =
  SnapAuthenticatedHousekeepingCommentsAndNotesImport.update({
    id: '/comments-and-notes',
    path: '/comments-and-notes',
    getParentRoute: () => SnapAuthenticatedHousekeepingRoute,
  } as any)

const SnapAuthenticatedHousekeepingAreaKeyRoute =
  SnapAuthenticatedHousekeepingAreaKeyImport.update({
    id: '/$areaKey',
    path: '/$areaKey',
    getParentRoute: () => SnapAuthenticatedHousekeepingRoute,
  } as any)

const SnapAuthenticatedSettingsUsersNewRoute =
  SnapAuthenticatedSettingsUsersNewImport.update({
    id: '/new',
    path: '/new',
    getParentRoute: () => SnapAuthenticatedSettingsUsersRoute,
  } as any)

const SnapAuthenticatedSettingsUsersKeyRoute =
  SnapAuthenticatedSettingsUsersKeyImport.update({
    id: '/$key',
    path: '/$key',
    getParentRoute: () => SnapAuthenticatedSettingsUsersRoute,
  } as any)

const SnapAuthenticatedSettingsUnitsNewRoute =
  SnapAuthenticatedSettingsUnitsNewImport.update({
    id: '/new',
    path: '/new',
    getParentRoute: () => SnapAuthenticatedSettingsUnitsRoute,
  } as any)

const SnapAuthenticatedSettingsUnitsKeyRoute =
  SnapAuthenticatedSettingsUnitsKeyImport.update({
    id: '/$key',
    path: '/$key',
    getParentRoute: () => SnapAuthenticatedSettingsUnitsRoute,
  } as any)

const SnapAuthenticatedSettingsRulesNewRoute =
  SnapAuthenticatedSettingsRulesNewImport.update({
    id: '/settings/rules_/new',
    path: '/settings/rules/new',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedSettingsRulesKeyRoute =
  SnapAuthenticatedSettingsRulesKeyImport.update({
    id: '/settings/rules_/$key',
    path: '/settings/rules/$key',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

const SnapAuthenticatedSettingsCategoriesNewRoute =
  SnapAuthenticatedSettingsCategoriesNewImport.update({
    id: '/new',
    path: '/new',
    getParentRoute: () => SnapAuthenticatedSettingsCategoriesRoute,
  } as any)

const SnapAuthenticatedSettingsCategoriesKeyRoute =
  SnapAuthenticatedSettingsCategoriesKeyImport.update({
    id: '/$key',
    path: '/$key',
    getParentRoute: () => SnapAuthenticatedSettingsCategoriesRoute,
  } as any)

const SnapAuthenticatedSettingsAccountSubscriptionRoute =
  SnapAuthenticatedSettingsAccountSubscriptionImport.update({
    id: '/subscription',
    path: '/subscription',
    getParentRoute: () => SnapAuthenticatedSettingsAccountRoute,
  } as any)

const SnapAuthenticatedSettingsAccountInformationRoute =
  SnapAuthenticatedSettingsAccountInformationImport.update({
    id: '/information',
    path: '/information',
    getParentRoute: () => SnapAuthenticatedSettingsAccountRoute,
  } as any)

const SnapAuthenticatedIntegrationsApaleoTaskboardRoute =
  SnapAuthenticatedIntegrationsApaleoTaskboardImport.update({
    id: '/integrations/apaleo/taskboard',
    path: '/integrations/apaleo/taskboard',
    getParentRoute: () => SnapAuthenticatedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/housekeeping': {
      id: '/housekeeping'
      path: '/housekeeping'
      fullPath: '/housekeeping'
      preLoaderRoute: typeof HousekeepingImport
      parentRoute: typeof rootRoute
    }
    '/issues': {
      id: '/issues'
      path: '/issues'
      fullPath: '/issues'
      preLoaderRoute: typeof IssuesImport
      parentRoute: typeof rootRoute
    }
    '/taskboard': {
      id: '/taskboard'
      path: '/taskboard'
      fullPath: '/taskboard'
      preLoaderRoute: typeof TaskboardImport
      parentRoute: typeof rootRoute
    }
    '/snap': {
      id: '/snap'
      path: '/snap'
      fullPath: '/snap'
      preLoaderRoute: typeof SnapImport
      parentRoute: typeof rootRoute
    }
    '/snap/_authenticated': {
      id: '/snap/_authenticated'
      path: '/snap'
      fullPath: '/snap'
      preLoaderRoute: typeof SnapAuthenticatedImport
      parentRoute: typeof SnapRoute
    }
    '/snap/_unauthenticated': {
      id: '/snap/_unauthenticated'
      path: ''
      fullPath: '/snap'
      preLoaderRoute: typeof SnapUnauthenticatedImport
      parentRoute: typeof SnapImport
    }
    '/snap/': {
      id: '/snap/'
      path: '/'
      fullPath: '/snap/'
      preLoaderRoute: typeof SnapIndexImport
      parentRoute: typeof SnapImport
    }
    '/integrations/apaleo/taskboard': {
      id: '/integrations/apaleo/taskboard'
      path: '/integrations/apaleo/taskboard'
      fullPath: '/integrations/apaleo/taskboard'
      preLoaderRoute: typeof IntegrationsApaleoTaskboardImport
      parentRoute: typeof rootRoute
    }
    '/snap/_authenticated/housekeeping': {
      id: '/snap/_authenticated/housekeeping'
      path: '/housekeeping'
      fullPath: '/snap/housekeeping'
      preLoaderRoute: typeof SnapAuthenticatedHousekeepingImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/issues': {
      id: '/snap/_authenticated/issues'
      path: '/issues'
      fullPath: '/snap/issues'
      preLoaderRoute: typeof SnapAuthenticatedIssuesImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/taskboard': {
      id: '/snap/_authenticated/taskboard'
      path: '/taskboard'
      fullPath: '/snap/taskboard'
      preLoaderRoute: typeof SnapAuthenticatedTaskboardImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/housekeeping/$areaKey': {
      id: '/snap/_authenticated/housekeeping/$areaKey'
      path: '/$areaKey'
      fullPath: '/snap/housekeeping/$areaKey'
      preLoaderRoute: typeof SnapAuthenticatedHousekeepingAreaKeyImport
      parentRoute: typeof SnapAuthenticatedHousekeepingImport
    }
    '/snap/_authenticated/housekeeping/comments-and-notes': {
      id: '/snap/_authenticated/housekeeping/comments-and-notes'
      path: '/comments-and-notes'
      fullPath: '/snap/housekeeping/comments-and-notes'
      preLoaderRoute: typeof SnapAuthenticatedHousekeepingCommentsAndNotesImport
      parentRoute: typeof SnapAuthenticatedHousekeepingImport
    }
    '/snap/_authenticated/issues/$key': {
      id: '/snap/_authenticated/issues/$key'
      path: '/$key'
      fullPath: '/snap/issues/$key'
      preLoaderRoute: typeof SnapAuthenticatedIssuesKeyImport
      parentRoute: typeof SnapAuthenticatedIssuesImport
    }
    '/snap/_authenticated/issues/new': {
      id: '/snap/_authenticated/issues/new'
      path: '/new'
      fullPath: '/snap/issues/new'
      preLoaderRoute: typeof SnapAuthenticatedIssuesNewImport
      parentRoute: typeof SnapAuthenticatedIssuesImport
    }
    '/snap/_authenticated/reports/housekeeping': {
      id: '/snap/_authenticated/reports/housekeeping'
      path: '/reports/housekeeping'
      fullPath: '/snap/reports/housekeeping'
      preLoaderRoute: typeof SnapAuthenticatedReportsHousekeepingImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/reports/issues': {
      id: '/snap/_authenticated/reports/issues'
      path: '/reports/issues'
      fullPath: '/snap/reports/issues'
      preLoaderRoute: typeof SnapAuthenticatedReportsIssuesImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/reports/optional': {
      id: '/snap/_authenticated/reports/optional'
      path: '/reports/optional'
      fullPath: '/snap/reports/optional'
      preLoaderRoute: typeof SnapAuthenticatedReportsOptionalImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/reports/workload': {
      id: '/snap/_authenticated/reports/workload'
      path: '/reports/workload'
      fullPath: '/snap/reports/workload'
      preLoaderRoute: typeof SnapAuthenticatedReportsWorkloadImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/settings/account': {
      id: '/snap/_authenticated/settings/account'
      path: '/settings/account'
      fullPath: '/snap/settings/account'
      preLoaderRoute: typeof SnapAuthenticatedSettingsAccountImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/settings/categories': {
      id: '/snap/_authenticated/settings/categories'
      path: '/settings/categories'
      fullPath: '/snap/settings/categories'
      preLoaderRoute: typeof SnapAuthenticatedSettingsCategoriesImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/settings/rules': {
      id: '/snap/_authenticated/settings/rules'
      path: '/settings/rules'
      fullPath: '/snap/settings/rules'
      preLoaderRoute: typeof SnapAuthenticatedSettingsRulesImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/settings/units': {
      id: '/snap/_authenticated/settings/units'
      path: '/settings/units'
      fullPath: '/snap/settings/units'
      preLoaderRoute: typeof SnapAuthenticatedSettingsUnitsImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/settings/users': {
      id: '/snap/_authenticated/settings/users'
      path: '/settings/users'
      fullPath: '/snap/settings/users'
      preLoaderRoute: typeof SnapAuthenticatedSettingsUsersImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/taskboard/$key': {
      id: '/snap/_authenticated/taskboard/$key'
      path: '/$key'
      fullPath: '/snap/taskboard/$key'
      preLoaderRoute: typeof SnapAuthenticatedTaskboardKeyImport
      parentRoute: typeof SnapAuthenticatedTaskboardImport
    }
    '/snap/_authenticated/taskboard/new-task': {
      id: '/snap/_authenticated/taskboard/new-task'
      path: '/new-task'
      fullPath: '/snap/taskboard/new-task'
      preLoaderRoute: typeof SnapAuthenticatedTaskboardNewTaskImport
      parentRoute: typeof SnapAuthenticatedTaskboardImport
    }
    '/snap/_unauthenticated/login/code': {
      id: '/snap/_unauthenticated/login/code'
      path: '/login/code'
      fullPath: '/snap/login/code'
      preLoaderRoute: typeof SnapUnauthenticatedLoginCodeImport
      parentRoute: typeof SnapUnauthenticatedImport
    }
    '/snap/_unauthenticated/login/email': {
      id: '/snap/_unauthenticated/login/email'
      path: '/login/email'
      fullPath: '/snap/login/email'
      preLoaderRoute: typeof SnapUnauthenticatedLoginEmailImport
      parentRoute: typeof SnapUnauthenticatedImport
    }
    '/snap/_unauthenticated/login/phone': {
      id: '/snap/_unauthenticated/login/phone'
      path: '/login/phone'
      fullPath: '/snap/login/phone'
      preLoaderRoute: typeof SnapUnauthenticatedLoginPhoneImport
      parentRoute: typeof SnapUnauthenticatedImport
    }
    '/snap/_unauthenticated/login/': {
      id: '/snap/_unauthenticated/login/'
      path: '/login'
      fullPath: '/snap/login'
      preLoaderRoute: typeof SnapUnauthenticatedLoginIndexImport
      parentRoute: typeof SnapUnauthenticatedImport
    }
    '/snap/_authenticated/integrations/apaleo/taskboard': {
      id: '/snap/_authenticated/integrations/apaleo/taskboard'
      path: '/integrations/apaleo/taskboard'
      fullPath: '/snap/integrations/apaleo/taskboard'
      preLoaderRoute: typeof SnapAuthenticatedIntegrationsApaleoTaskboardImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/settings/account/information': {
      id: '/snap/_authenticated/settings/account/information'
      path: '/information'
      fullPath: '/snap/settings/account/information'
      preLoaderRoute: typeof SnapAuthenticatedSettingsAccountInformationImport
      parentRoute: typeof SnapAuthenticatedSettingsAccountImport
    }
    '/snap/_authenticated/settings/account/subscription': {
      id: '/snap/_authenticated/settings/account/subscription'
      path: '/subscription'
      fullPath: '/snap/settings/account/subscription'
      preLoaderRoute: typeof SnapAuthenticatedSettingsAccountSubscriptionImport
      parentRoute: typeof SnapAuthenticatedSettingsAccountImport
    }
    '/snap/_authenticated/settings/categories/$key': {
      id: '/snap/_authenticated/settings/categories/$key'
      path: '/$key'
      fullPath: '/snap/settings/categories/$key'
      preLoaderRoute: typeof SnapAuthenticatedSettingsCategoriesKeyImport
      parentRoute: typeof SnapAuthenticatedSettingsCategoriesImport
    }
    '/snap/_authenticated/settings/categories/new': {
      id: '/snap/_authenticated/settings/categories/new'
      path: '/new'
      fullPath: '/snap/settings/categories/new'
      preLoaderRoute: typeof SnapAuthenticatedSettingsCategoriesNewImport
      parentRoute: typeof SnapAuthenticatedSettingsCategoriesImport
    }
    '/snap/_authenticated/settings/rules_/$key': {
      id: '/snap/_authenticated/settings/rules_/$key'
      path: '/settings/rules/$key'
      fullPath: '/snap/settings/rules/$key'
      preLoaderRoute: typeof SnapAuthenticatedSettingsRulesKeyImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/settings/rules_/new': {
      id: '/snap/_authenticated/settings/rules_/new'
      path: '/settings/rules/new'
      fullPath: '/snap/settings/rules/new'
      preLoaderRoute: typeof SnapAuthenticatedSettingsRulesNewImport
      parentRoute: typeof SnapAuthenticatedImport
    }
    '/snap/_authenticated/settings/units/$key': {
      id: '/snap/_authenticated/settings/units/$key'
      path: '/$key'
      fullPath: '/snap/settings/units/$key'
      preLoaderRoute: typeof SnapAuthenticatedSettingsUnitsKeyImport
      parentRoute: typeof SnapAuthenticatedSettingsUnitsImport
    }
    '/snap/_authenticated/settings/units/new': {
      id: '/snap/_authenticated/settings/units/new'
      path: '/new'
      fullPath: '/snap/settings/units/new'
      preLoaderRoute: typeof SnapAuthenticatedSettingsUnitsNewImport
      parentRoute: typeof SnapAuthenticatedSettingsUnitsImport
    }
    '/snap/_authenticated/settings/users/$key': {
      id: '/snap/_authenticated/settings/users/$key'
      path: '/$key'
      fullPath: '/snap/settings/users/$key'
      preLoaderRoute: typeof SnapAuthenticatedSettingsUsersKeyImport
      parentRoute: typeof SnapAuthenticatedSettingsUsersImport
    }
    '/snap/_authenticated/settings/users/new': {
      id: '/snap/_authenticated/settings/users/new'
      path: '/new'
      fullPath: '/snap/settings/users/new'
      preLoaderRoute: typeof SnapAuthenticatedSettingsUsersNewImport
      parentRoute: typeof SnapAuthenticatedSettingsUsersImport
    }
  }
}

// Create and export the route tree

interface SnapAuthenticatedHousekeepingRouteChildren {
  SnapAuthenticatedHousekeepingAreaKeyRoute: typeof SnapAuthenticatedHousekeepingAreaKeyRoute
  SnapAuthenticatedHousekeepingCommentsAndNotesRoute: typeof SnapAuthenticatedHousekeepingCommentsAndNotesRoute
}

const SnapAuthenticatedHousekeepingRouteChildren: SnapAuthenticatedHousekeepingRouteChildren =
  {
    SnapAuthenticatedHousekeepingAreaKeyRoute:
      SnapAuthenticatedHousekeepingAreaKeyRoute,
    SnapAuthenticatedHousekeepingCommentsAndNotesRoute:
      SnapAuthenticatedHousekeepingCommentsAndNotesRoute,
  }

const SnapAuthenticatedHousekeepingRouteWithChildren =
  SnapAuthenticatedHousekeepingRoute._addFileChildren(
    SnapAuthenticatedHousekeepingRouteChildren,
  )

interface SnapAuthenticatedIssuesRouteChildren {
  SnapAuthenticatedIssuesKeyRoute: typeof SnapAuthenticatedIssuesKeyRoute
  SnapAuthenticatedIssuesNewRoute: typeof SnapAuthenticatedIssuesNewRoute
}

const SnapAuthenticatedIssuesRouteChildren: SnapAuthenticatedIssuesRouteChildren =
  {
    SnapAuthenticatedIssuesKeyRoute: SnapAuthenticatedIssuesKeyRoute,
    SnapAuthenticatedIssuesNewRoute: SnapAuthenticatedIssuesNewRoute,
  }

const SnapAuthenticatedIssuesRouteWithChildren =
  SnapAuthenticatedIssuesRoute._addFileChildren(
    SnapAuthenticatedIssuesRouteChildren,
  )

interface SnapAuthenticatedTaskboardRouteChildren {
  SnapAuthenticatedTaskboardKeyRoute: typeof SnapAuthenticatedTaskboardKeyRoute
  SnapAuthenticatedTaskboardNewTaskRoute: typeof SnapAuthenticatedTaskboardNewTaskRoute
}

const SnapAuthenticatedTaskboardRouteChildren: SnapAuthenticatedTaskboardRouteChildren =
  {
    SnapAuthenticatedTaskboardKeyRoute: SnapAuthenticatedTaskboardKeyRoute,
    SnapAuthenticatedTaskboardNewTaskRoute:
      SnapAuthenticatedTaskboardNewTaskRoute,
  }

const SnapAuthenticatedTaskboardRouteWithChildren =
  SnapAuthenticatedTaskboardRoute._addFileChildren(
    SnapAuthenticatedTaskboardRouteChildren,
  )

interface SnapAuthenticatedSettingsAccountRouteChildren {
  SnapAuthenticatedSettingsAccountInformationRoute: typeof SnapAuthenticatedSettingsAccountInformationRoute
  SnapAuthenticatedSettingsAccountSubscriptionRoute: typeof SnapAuthenticatedSettingsAccountSubscriptionRoute
}

const SnapAuthenticatedSettingsAccountRouteChildren: SnapAuthenticatedSettingsAccountRouteChildren =
  {
    SnapAuthenticatedSettingsAccountInformationRoute:
      SnapAuthenticatedSettingsAccountInformationRoute,
    SnapAuthenticatedSettingsAccountSubscriptionRoute:
      SnapAuthenticatedSettingsAccountSubscriptionRoute,
  }

const SnapAuthenticatedSettingsAccountRouteWithChildren =
  SnapAuthenticatedSettingsAccountRoute._addFileChildren(
    SnapAuthenticatedSettingsAccountRouteChildren,
  )

interface SnapAuthenticatedSettingsCategoriesRouteChildren {
  SnapAuthenticatedSettingsCategoriesKeyRoute: typeof SnapAuthenticatedSettingsCategoriesKeyRoute
  SnapAuthenticatedSettingsCategoriesNewRoute: typeof SnapAuthenticatedSettingsCategoriesNewRoute
}

const SnapAuthenticatedSettingsCategoriesRouteChildren: SnapAuthenticatedSettingsCategoriesRouteChildren =
  {
    SnapAuthenticatedSettingsCategoriesKeyRoute:
      SnapAuthenticatedSettingsCategoriesKeyRoute,
    SnapAuthenticatedSettingsCategoriesNewRoute:
      SnapAuthenticatedSettingsCategoriesNewRoute,
  }

const SnapAuthenticatedSettingsCategoriesRouteWithChildren =
  SnapAuthenticatedSettingsCategoriesRoute._addFileChildren(
    SnapAuthenticatedSettingsCategoriesRouteChildren,
  )

interface SnapAuthenticatedSettingsUnitsRouteChildren {
  SnapAuthenticatedSettingsUnitsKeyRoute: typeof SnapAuthenticatedSettingsUnitsKeyRoute
  SnapAuthenticatedSettingsUnitsNewRoute: typeof SnapAuthenticatedSettingsUnitsNewRoute
}

const SnapAuthenticatedSettingsUnitsRouteChildren: SnapAuthenticatedSettingsUnitsRouteChildren =
  {
    SnapAuthenticatedSettingsUnitsKeyRoute:
      SnapAuthenticatedSettingsUnitsKeyRoute,
    SnapAuthenticatedSettingsUnitsNewRoute:
      SnapAuthenticatedSettingsUnitsNewRoute,
  }

const SnapAuthenticatedSettingsUnitsRouteWithChildren =
  SnapAuthenticatedSettingsUnitsRoute._addFileChildren(
    SnapAuthenticatedSettingsUnitsRouteChildren,
  )

interface SnapAuthenticatedSettingsUsersRouteChildren {
  SnapAuthenticatedSettingsUsersKeyRoute: typeof SnapAuthenticatedSettingsUsersKeyRoute
  SnapAuthenticatedSettingsUsersNewRoute: typeof SnapAuthenticatedSettingsUsersNewRoute
}

const SnapAuthenticatedSettingsUsersRouteChildren: SnapAuthenticatedSettingsUsersRouteChildren =
  {
    SnapAuthenticatedSettingsUsersKeyRoute:
      SnapAuthenticatedSettingsUsersKeyRoute,
    SnapAuthenticatedSettingsUsersNewRoute:
      SnapAuthenticatedSettingsUsersNewRoute,
  }

const SnapAuthenticatedSettingsUsersRouteWithChildren =
  SnapAuthenticatedSettingsUsersRoute._addFileChildren(
    SnapAuthenticatedSettingsUsersRouteChildren,
  )

interface SnapAuthenticatedRouteChildren {
  SnapAuthenticatedHousekeepingRoute: typeof SnapAuthenticatedHousekeepingRouteWithChildren
  SnapAuthenticatedIssuesRoute: typeof SnapAuthenticatedIssuesRouteWithChildren
  SnapAuthenticatedTaskboardRoute: typeof SnapAuthenticatedTaskboardRouteWithChildren
  SnapAuthenticatedReportsHousekeepingRoute: typeof SnapAuthenticatedReportsHousekeepingRoute
  SnapAuthenticatedReportsIssuesRoute: typeof SnapAuthenticatedReportsIssuesRoute
  SnapAuthenticatedReportsOptionalRoute: typeof SnapAuthenticatedReportsOptionalRoute
  SnapAuthenticatedReportsWorkloadRoute: typeof SnapAuthenticatedReportsWorkloadRoute
  SnapAuthenticatedSettingsAccountRoute: typeof SnapAuthenticatedSettingsAccountRouteWithChildren
  SnapAuthenticatedSettingsCategoriesRoute: typeof SnapAuthenticatedSettingsCategoriesRouteWithChildren
  SnapAuthenticatedSettingsRulesRoute: typeof SnapAuthenticatedSettingsRulesRoute
  SnapAuthenticatedSettingsUnitsRoute: typeof SnapAuthenticatedSettingsUnitsRouteWithChildren
  SnapAuthenticatedSettingsUsersRoute: typeof SnapAuthenticatedSettingsUsersRouteWithChildren
  SnapAuthenticatedIntegrationsApaleoTaskboardRoute: typeof SnapAuthenticatedIntegrationsApaleoTaskboardRoute
  SnapAuthenticatedSettingsRulesKeyRoute: typeof SnapAuthenticatedSettingsRulesKeyRoute
  SnapAuthenticatedSettingsRulesNewRoute: typeof SnapAuthenticatedSettingsRulesNewRoute
}

const SnapAuthenticatedRouteChildren: SnapAuthenticatedRouteChildren = {
  SnapAuthenticatedHousekeepingRoute:
    SnapAuthenticatedHousekeepingRouteWithChildren,
  SnapAuthenticatedIssuesRoute: SnapAuthenticatedIssuesRouteWithChildren,
  SnapAuthenticatedTaskboardRoute: SnapAuthenticatedTaskboardRouteWithChildren,
  SnapAuthenticatedReportsHousekeepingRoute:
    SnapAuthenticatedReportsHousekeepingRoute,
  SnapAuthenticatedReportsIssuesRoute: SnapAuthenticatedReportsIssuesRoute,
  SnapAuthenticatedReportsOptionalRoute: SnapAuthenticatedReportsOptionalRoute,
  SnapAuthenticatedReportsWorkloadRoute: SnapAuthenticatedReportsWorkloadRoute,
  SnapAuthenticatedSettingsAccountRoute:
    SnapAuthenticatedSettingsAccountRouteWithChildren,
  SnapAuthenticatedSettingsCategoriesRoute:
    SnapAuthenticatedSettingsCategoriesRouteWithChildren,
  SnapAuthenticatedSettingsRulesRoute: SnapAuthenticatedSettingsRulesRoute,
  SnapAuthenticatedSettingsUnitsRoute:
    SnapAuthenticatedSettingsUnitsRouteWithChildren,
  SnapAuthenticatedSettingsUsersRoute:
    SnapAuthenticatedSettingsUsersRouteWithChildren,
  SnapAuthenticatedIntegrationsApaleoTaskboardRoute:
    SnapAuthenticatedIntegrationsApaleoTaskboardRoute,
  SnapAuthenticatedSettingsRulesKeyRoute:
    SnapAuthenticatedSettingsRulesKeyRoute,
  SnapAuthenticatedSettingsRulesNewRoute:
    SnapAuthenticatedSettingsRulesNewRoute,
}

const SnapAuthenticatedRouteWithChildren =
  SnapAuthenticatedRoute._addFileChildren(SnapAuthenticatedRouteChildren)

interface SnapUnauthenticatedRouteChildren {
  SnapUnauthenticatedLoginCodeRoute: typeof SnapUnauthenticatedLoginCodeRoute
  SnapUnauthenticatedLoginEmailRoute: typeof SnapUnauthenticatedLoginEmailRoute
  SnapUnauthenticatedLoginPhoneRoute: typeof SnapUnauthenticatedLoginPhoneRoute
  SnapUnauthenticatedLoginIndexRoute: typeof SnapUnauthenticatedLoginIndexRoute
}

const SnapUnauthenticatedRouteChildren: SnapUnauthenticatedRouteChildren = {
  SnapUnauthenticatedLoginCodeRoute: SnapUnauthenticatedLoginCodeRoute,
  SnapUnauthenticatedLoginEmailRoute: SnapUnauthenticatedLoginEmailRoute,
  SnapUnauthenticatedLoginPhoneRoute: SnapUnauthenticatedLoginPhoneRoute,
  SnapUnauthenticatedLoginIndexRoute: SnapUnauthenticatedLoginIndexRoute,
}

const SnapUnauthenticatedRouteWithChildren =
  SnapUnauthenticatedRoute._addFileChildren(SnapUnauthenticatedRouteChildren)

interface SnapRouteChildren {
  SnapAuthenticatedRoute: typeof SnapAuthenticatedRouteWithChildren
  SnapUnauthenticatedRoute: typeof SnapUnauthenticatedRouteWithChildren
  SnapIndexRoute: typeof SnapIndexRoute
}

const SnapRouteChildren: SnapRouteChildren = {
  SnapAuthenticatedRoute: SnapAuthenticatedRouteWithChildren,
  SnapUnauthenticatedRoute: SnapUnauthenticatedRouteWithChildren,
  SnapIndexRoute: SnapIndexRoute,
}

const SnapRouteWithChildren = SnapRoute._addFileChildren(SnapRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/housekeeping': typeof HousekeepingRoute
  '/issues': typeof IssuesRoute
  '/taskboard': typeof TaskboardRoute
  '/snap': typeof SnapUnauthenticatedRouteWithChildren
  '/snap/': typeof SnapIndexRoute
  '/integrations/apaleo/taskboard': typeof IntegrationsApaleoTaskboardRoute
  '/snap/housekeeping': typeof SnapAuthenticatedHousekeepingRouteWithChildren
  '/snap/issues': typeof SnapAuthenticatedIssuesRouteWithChildren
  '/snap/taskboard': typeof SnapAuthenticatedTaskboardRouteWithChildren
  '/snap/housekeeping/$areaKey': typeof SnapAuthenticatedHousekeepingAreaKeyRoute
  '/snap/housekeeping/comments-and-notes': typeof SnapAuthenticatedHousekeepingCommentsAndNotesRoute
  '/snap/issues/$key': typeof SnapAuthenticatedIssuesKeyRoute
  '/snap/issues/new': typeof SnapAuthenticatedIssuesNewRoute
  '/snap/reports/housekeeping': typeof SnapAuthenticatedReportsHousekeepingRoute
  '/snap/reports/issues': typeof SnapAuthenticatedReportsIssuesRoute
  '/snap/reports/optional': typeof SnapAuthenticatedReportsOptionalRoute
  '/snap/reports/workload': typeof SnapAuthenticatedReportsWorkloadRoute
  '/snap/settings/account': typeof SnapAuthenticatedSettingsAccountRouteWithChildren
  '/snap/settings/categories': typeof SnapAuthenticatedSettingsCategoriesRouteWithChildren
  '/snap/settings/rules': typeof SnapAuthenticatedSettingsRulesRoute
  '/snap/settings/units': typeof SnapAuthenticatedSettingsUnitsRouteWithChildren
  '/snap/settings/users': typeof SnapAuthenticatedSettingsUsersRouteWithChildren
  '/snap/taskboard/$key': typeof SnapAuthenticatedTaskboardKeyRoute
  '/snap/taskboard/new-task': typeof SnapAuthenticatedTaskboardNewTaskRoute
  '/snap/login/code': typeof SnapUnauthenticatedLoginCodeRoute
  '/snap/login/email': typeof SnapUnauthenticatedLoginEmailRoute
  '/snap/login/phone': typeof SnapUnauthenticatedLoginPhoneRoute
  '/snap/login': typeof SnapUnauthenticatedLoginIndexRoute
  '/snap/integrations/apaleo/taskboard': typeof SnapAuthenticatedIntegrationsApaleoTaskboardRoute
  '/snap/settings/account/information': typeof SnapAuthenticatedSettingsAccountInformationRoute
  '/snap/settings/account/subscription': typeof SnapAuthenticatedSettingsAccountSubscriptionRoute
  '/snap/settings/categories/$key': typeof SnapAuthenticatedSettingsCategoriesKeyRoute
  '/snap/settings/categories/new': typeof SnapAuthenticatedSettingsCategoriesNewRoute
  '/snap/settings/rules/$key': typeof SnapAuthenticatedSettingsRulesKeyRoute
  '/snap/settings/rules/new': typeof SnapAuthenticatedSettingsRulesNewRoute
  '/snap/settings/units/$key': typeof SnapAuthenticatedSettingsUnitsKeyRoute
  '/snap/settings/units/new': typeof SnapAuthenticatedSettingsUnitsNewRoute
  '/snap/settings/users/$key': typeof SnapAuthenticatedSettingsUsersKeyRoute
  '/snap/settings/users/new': typeof SnapAuthenticatedSettingsUsersNewRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/housekeeping': typeof HousekeepingRoute
  '/issues': typeof IssuesRoute
  '/taskboard': typeof TaskboardRoute
  '/snap': typeof SnapIndexRoute
  '/integrations/apaleo/taskboard': typeof IntegrationsApaleoTaskboardRoute
  '/snap/housekeeping': typeof SnapAuthenticatedHousekeepingRouteWithChildren
  '/snap/issues': typeof SnapAuthenticatedIssuesRouteWithChildren
  '/snap/taskboard': typeof SnapAuthenticatedTaskboardRouteWithChildren
  '/snap/housekeeping/$areaKey': typeof SnapAuthenticatedHousekeepingAreaKeyRoute
  '/snap/housekeeping/comments-and-notes': typeof SnapAuthenticatedHousekeepingCommentsAndNotesRoute
  '/snap/issues/$key': typeof SnapAuthenticatedIssuesKeyRoute
  '/snap/issues/new': typeof SnapAuthenticatedIssuesNewRoute
  '/snap/reports/housekeeping': typeof SnapAuthenticatedReportsHousekeepingRoute
  '/snap/reports/issues': typeof SnapAuthenticatedReportsIssuesRoute
  '/snap/reports/optional': typeof SnapAuthenticatedReportsOptionalRoute
  '/snap/reports/workload': typeof SnapAuthenticatedReportsWorkloadRoute
  '/snap/settings/account': typeof SnapAuthenticatedSettingsAccountRouteWithChildren
  '/snap/settings/categories': typeof SnapAuthenticatedSettingsCategoriesRouteWithChildren
  '/snap/settings/rules': typeof SnapAuthenticatedSettingsRulesRoute
  '/snap/settings/units': typeof SnapAuthenticatedSettingsUnitsRouteWithChildren
  '/snap/settings/users': typeof SnapAuthenticatedSettingsUsersRouteWithChildren
  '/snap/taskboard/$key': typeof SnapAuthenticatedTaskboardKeyRoute
  '/snap/taskboard/new-task': typeof SnapAuthenticatedTaskboardNewTaskRoute
  '/snap/login/code': typeof SnapUnauthenticatedLoginCodeRoute
  '/snap/login/email': typeof SnapUnauthenticatedLoginEmailRoute
  '/snap/login/phone': typeof SnapUnauthenticatedLoginPhoneRoute
  '/snap/login': typeof SnapUnauthenticatedLoginIndexRoute
  '/snap/integrations/apaleo/taskboard': typeof SnapAuthenticatedIntegrationsApaleoTaskboardRoute
  '/snap/settings/account/information': typeof SnapAuthenticatedSettingsAccountInformationRoute
  '/snap/settings/account/subscription': typeof SnapAuthenticatedSettingsAccountSubscriptionRoute
  '/snap/settings/categories/$key': typeof SnapAuthenticatedSettingsCategoriesKeyRoute
  '/snap/settings/categories/new': typeof SnapAuthenticatedSettingsCategoriesNewRoute
  '/snap/settings/rules/$key': typeof SnapAuthenticatedSettingsRulesKeyRoute
  '/snap/settings/rules/new': typeof SnapAuthenticatedSettingsRulesNewRoute
  '/snap/settings/units/$key': typeof SnapAuthenticatedSettingsUnitsKeyRoute
  '/snap/settings/units/new': typeof SnapAuthenticatedSettingsUnitsNewRoute
  '/snap/settings/users/$key': typeof SnapAuthenticatedSettingsUsersKeyRoute
  '/snap/settings/users/new': typeof SnapAuthenticatedSettingsUsersNewRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/housekeeping': typeof HousekeepingRoute
  '/issues': typeof IssuesRoute
  '/taskboard': typeof TaskboardRoute
  '/snap': typeof SnapRouteWithChildren
  '/snap/_authenticated': typeof SnapAuthenticatedRouteWithChildren
  '/snap/_unauthenticated': typeof SnapUnauthenticatedRouteWithChildren
  '/snap/': typeof SnapIndexRoute
  '/integrations/apaleo/taskboard': typeof IntegrationsApaleoTaskboardRoute
  '/snap/_authenticated/housekeeping': typeof SnapAuthenticatedHousekeepingRouteWithChildren
  '/snap/_authenticated/issues': typeof SnapAuthenticatedIssuesRouteWithChildren
  '/snap/_authenticated/taskboard': typeof SnapAuthenticatedTaskboardRouteWithChildren
  '/snap/_authenticated/housekeeping/$areaKey': typeof SnapAuthenticatedHousekeepingAreaKeyRoute
  '/snap/_authenticated/housekeeping/comments-and-notes': typeof SnapAuthenticatedHousekeepingCommentsAndNotesRoute
  '/snap/_authenticated/issues/$key': typeof SnapAuthenticatedIssuesKeyRoute
  '/snap/_authenticated/issues/new': typeof SnapAuthenticatedIssuesNewRoute
  '/snap/_authenticated/reports/housekeeping': typeof SnapAuthenticatedReportsHousekeepingRoute
  '/snap/_authenticated/reports/issues': typeof SnapAuthenticatedReportsIssuesRoute
  '/snap/_authenticated/reports/optional': typeof SnapAuthenticatedReportsOptionalRoute
  '/snap/_authenticated/reports/workload': typeof SnapAuthenticatedReportsWorkloadRoute
  '/snap/_authenticated/settings/account': typeof SnapAuthenticatedSettingsAccountRouteWithChildren
  '/snap/_authenticated/settings/categories': typeof SnapAuthenticatedSettingsCategoriesRouteWithChildren
  '/snap/_authenticated/settings/rules': typeof SnapAuthenticatedSettingsRulesRoute
  '/snap/_authenticated/settings/units': typeof SnapAuthenticatedSettingsUnitsRouteWithChildren
  '/snap/_authenticated/settings/users': typeof SnapAuthenticatedSettingsUsersRouteWithChildren
  '/snap/_authenticated/taskboard/$key': typeof SnapAuthenticatedTaskboardKeyRoute
  '/snap/_authenticated/taskboard/new-task': typeof SnapAuthenticatedTaskboardNewTaskRoute
  '/snap/_unauthenticated/login/code': typeof SnapUnauthenticatedLoginCodeRoute
  '/snap/_unauthenticated/login/email': typeof SnapUnauthenticatedLoginEmailRoute
  '/snap/_unauthenticated/login/phone': typeof SnapUnauthenticatedLoginPhoneRoute
  '/snap/_unauthenticated/login/': typeof SnapUnauthenticatedLoginIndexRoute
  '/snap/_authenticated/integrations/apaleo/taskboard': typeof SnapAuthenticatedIntegrationsApaleoTaskboardRoute
  '/snap/_authenticated/settings/account/information': typeof SnapAuthenticatedSettingsAccountInformationRoute
  '/snap/_authenticated/settings/account/subscription': typeof SnapAuthenticatedSettingsAccountSubscriptionRoute
  '/snap/_authenticated/settings/categories/$key': typeof SnapAuthenticatedSettingsCategoriesKeyRoute
  '/snap/_authenticated/settings/categories/new': typeof SnapAuthenticatedSettingsCategoriesNewRoute
  '/snap/_authenticated/settings/rules_/$key': typeof SnapAuthenticatedSettingsRulesKeyRoute
  '/snap/_authenticated/settings/rules_/new': typeof SnapAuthenticatedSettingsRulesNewRoute
  '/snap/_authenticated/settings/units/$key': typeof SnapAuthenticatedSettingsUnitsKeyRoute
  '/snap/_authenticated/settings/units/new': typeof SnapAuthenticatedSettingsUnitsNewRoute
  '/snap/_authenticated/settings/users/$key': typeof SnapAuthenticatedSettingsUsersKeyRoute
  '/snap/_authenticated/settings/users/new': typeof SnapAuthenticatedSettingsUsersNewRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/housekeeping'
    | '/issues'
    | '/taskboard'
    | '/snap'
    | '/snap/'
    | '/integrations/apaleo/taskboard'
    | '/snap/housekeeping'
    | '/snap/issues'
    | '/snap/taskboard'
    | '/snap/housekeeping/$areaKey'
    | '/snap/housekeeping/comments-and-notes'
    | '/snap/issues/$key'
    | '/snap/issues/new'
    | '/snap/reports/housekeeping'
    | '/snap/reports/issues'
    | '/snap/reports/optional'
    | '/snap/reports/workload'
    | '/snap/settings/account'
    | '/snap/settings/categories'
    | '/snap/settings/rules'
    | '/snap/settings/units'
    | '/snap/settings/users'
    | '/snap/taskboard/$key'
    | '/snap/taskboard/new-task'
    | '/snap/login/code'
    | '/snap/login/email'
    | '/snap/login/phone'
    | '/snap/login'
    | '/snap/integrations/apaleo/taskboard'
    | '/snap/settings/account/information'
    | '/snap/settings/account/subscription'
    | '/snap/settings/categories/$key'
    | '/snap/settings/categories/new'
    | '/snap/settings/rules/$key'
    | '/snap/settings/rules/new'
    | '/snap/settings/units/$key'
    | '/snap/settings/units/new'
    | '/snap/settings/users/$key'
    | '/snap/settings/users/new'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/housekeeping'
    | '/issues'
    | '/taskboard'
    | '/snap'
    | '/integrations/apaleo/taskboard'
    | '/snap/housekeeping'
    | '/snap/issues'
    | '/snap/taskboard'
    | '/snap/housekeeping/$areaKey'
    | '/snap/housekeeping/comments-and-notes'
    | '/snap/issues/$key'
    | '/snap/issues/new'
    | '/snap/reports/housekeeping'
    | '/snap/reports/issues'
    | '/snap/reports/optional'
    | '/snap/reports/workload'
    | '/snap/settings/account'
    | '/snap/settings/categories'
    | '/snap/settings/rules'
    | '/snap/settings/units'
    | '/snap/settings/users'
    | '/snap/taskboard/$key'
    | '/snap/taskboard/new-task'
    | '/snap/login/code'
    | '/snap/login/email'
    | '/snap/login/phone'
    | '/snap/login'
    | '/snap/integrations/apaleo/taskboard'
    | '/snap/settings/account/information'
    | '/snap/settings/account/subscription'
    | '/snap/settings/categories/$key'
    | '/snap/settings/categories/new'
    | '/snap/settings/rules/$key'
    | '/snap/settings/rules/new'
    | '/snap/settings/units/$key'
    | '/snap/settings/units/new'
    | '/snap/settings/users/$key'
    | '/snap/settings/users/new'
  id:
    | '__root__'
    | '/'
    | '/housekeeping'
    | '/issues'
    | '/taskboard'
    | '/snap'
    | '/snap/_authenticated'
    | '/snap/_unauthenticated'
    | '/snap/'
    | '/integrations/apaleo/taskboard'
    | '/snap/_authenticated/housekeeping'
    | '/snap/_authenticated/issues'
    | '/snap/_authenticated/taskboard'
    | '/snap/_authenticated/housekeeping/$areaKey'
    | '/snap/_authenticated/housekeeping/comments-and-notes'
    | '/snap/_authenticated/issues/$key'
    | '/snap/_authenticated/issues/new'
    | '/snap/_authenticated/reports/housekeeping'
    | '/snap/_authenticated/reports/issues'
    | '/snap/_authenticated/reports/optional'
    | '/snap/_authenticated/reports/workload'
    | '/snap/_authenticated/settings/account'
    | '/snap/_authenticated/settings/categories'
    | '/snap/_authenticated/settings/rules'
    | '/snap/_authenticated/settings/units'
    | '/snap/_authenticated/settings/users'
    | '/snap/_authenticated/taskboard/$key'
    | '/snap/_authenticated/taskboard/new-task'
    | '/snap/_unauthenticated/login/code'
    | '/snap/_unauthenticated/login/email'
    | '/snap/_unauthenticated/login/phone'
    | '/snap/_unauthenticated/login/'
    | '/snap/_authenticated/integrations/apaleo/taskboard'
    | '/snap/_authenticated/settings/account/information'
    | '/snap/_authenticated/settings/account/subscription'
    | '/snap/_authenticated/settings/categories/$key'
    | '/snap/_authenticated/settings/categories/new'
    | '/snap/_authenticated/settings/rules_/$key'
    | '/snap/_authenticated/settings/rules_/new'
    | '/snap/_authenticated/settings/units/$key'
    | '/snap/_authenticated/settings/units/new'
    | '/snap/_authenticated/settings/users/$key'
    | '/snap/_authenticated/settings/users/new'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  HousekeepingRoute: typeof HousekeepingRoute
  IssuesRoute: typeof IssuesRoute
  TaskboardRoute: typeof TaskboardRoute
  SnapRoute: typeof SnapRouteWithChildren
  IntegrationsApaleoTaskboardRoute: typeof IntegrationsApaleoTaskboardRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  HousekeepingRoute: HousekeepingRoute,
  IssuesRoute: IssuesRoute,
  TaskboardRoute: TaskboardRoute,
  SnapRoute: SnapRouteWithChildren,
  IntegrationsApaleoTaskboardRoute: IntegrationsApaleoTaskboardRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/housekeeping",
        "/issues",
        "/taskboard",
        "/snap",
        "/integrations/apaleo/taskboard"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/housekeeping": {
      "filePath": "housekeeping.tsx"
    },
    "/issues": {
      "filePath": "issues.tsx"
    },
    "/taskboard": {
      "filePath": "taskboard.tsx"
    },
    "/snap": {
      "filePath": "snap",
      "children": [
        "/snap/_authenticated",
        "/snap/_unauthenticated",
        "/snap/"
      ]
    },
    "/snap/_authenticated": {
      "filePath": "snap/_authenticated.tsx",
      "parent": "/snap",
      "children": [
        "/snap/_authenticated/housekeeping",
        "/snap/_authenticated/issues",
        "/snap/_authenticated/taskboard",
        "/snap/_authenticated/reports/housekeeping",
        "/snap/_authenticated/reports/issues",
        "/snap/_authenticated/reports/optional",
        "/snap/_authenticated/reports/workload",
        "/snap/_authenticated/settings/account",
        "/snap/_authenticated/settings/categories",
        "/snap/_authenticated/settings/rules",
        "/snap/_authenticated/settings/units",
        "/snap/_authenticated/settings/users",
        "/snap/_authenticated/integrations/apaleo/taskboard",
        "/snap/_authenticated/settings/rules_/$key",
        "/snap/_authenticated/settings/rules_/new"
      ]
    },
    "/snap/_unauthenticated": {
      "filePath": "snap/_unauthenticated.tsx",
      "parent": "/snap",
      "children": [
        "/snap/_unauthenticated/login/code",
        "/snap/_unauthenticated/login/email",
        "/snap/_unauthenticated/login/phone",
        "/snap/_unauthenticated/login/"
      ]
    },
    "/snap/": {
      "filePath": "snap/index.tsx",
      "parent": "/snap"
    },
    "/integrations/apaleo/taskboard": {
      "filePath": "integrations.apaleo.taskboard.tsx"
    },
    "/snap/_authenticated/housekeeping": {
      "filePath": "snap/_authenticated/housekeeping.tsx",
      "parent": "/snap/_authenticated",
      "children": [
        "/snap/_authenticated/housekeeping/$areaKey",
        "/snap/_authenticated/housekeeping/comments-and-notes"
      ]
    },
    "/snap/_authenticated/issues": {
      "filePath": "snap/_authenticated/issues.tsx",
      "parent": "/snap/_authenticated",
      "children": [
        "/snap/_authenticated/issues/$key",
        "/snap/_authenticated/issues/new"
      ]
    },
    "/snap/_authenticated/taskboard": {
      "filePath": "snap/_authenticated/taskboard.tsx",
      "parent": "/snap/_authenticated",
      "children": [
        "/snap/_authenticated/taskboard/$key",
        "/snap/_authenticated/taskboard/new-task"
      ]
    },
    "/snap/_authenticated/housekeeping/$areaKey": {
      "filePath": "snap/_authenticated/housekeeping.$areaKey.tsx",
      "parent": "/snap/_authenticated/housekeeping"
    },
    "/snap/_authenticated/housekeeping/comments-and-notes": {
      "filePath": "snap/_authenticated/housekeeping.comments-and-notes.tsx",
      "parent": "/snap/_authenticated/housekeeping"
    },
    "/snap/_authenticated/issues/$key": {
      "filePath": "snap/_authenticated/issues.$key.tsx",
      "parent": "/snap/_authenticated/issues"
    },
    "/snap/_authenticated/issues/new": {
      "filePath": "snap/_authenticated/issues.new.tsx",
      "parent": "/snap/_authenticated/issues"
    },
    "/snap/_authenticated/reports/housekeeping": {
      "filePath": "snap/_authenticated/reports.housekeeping.tsx",
      "parent": "/snap/_authenticated"
    },
    "/snap/_authenticated/reports/issues": {
      "filePath": "snap/_authenticated/reports.issues.tsx",
      "parent": "/snap/_authenticated"
    },
    "/snap/_authenticated/reports/optional": {
      "filePath": "snap/_authenticated/reports.optional.tsx",
      "parent": "/snap/_authenticated"
    },
    "/snap/_authenticated/reports/workload": {
      "filePath": "snap/_authenticated/reports.workload.tsx",
      "parent": "/snap/_authenticated"
    },
    "/snap/_authenticated/settings/account": {
      "filePath": "snap/_authenticated/settings.account.tsx",
      "parent": "/snap/_authenticated",
      "children": [
        "/snap/_authenticated/settings/account/information",
        "/snap/_authenticated/settings/account/subscription"
      ]
    },
    "/snap/_authenticated/settings/categories": {
      "filePath": "snap/_authenticated/settings.categories.tsx",
      "parent": "/snap/_authenticated",
      "children": [
        "/snap/_authenticated/settings/categories/$key",
        "/snap/_authenticated/settings/categories/new"
      ]
    },
    "/snap/_authenticated/settings/rules": {
      "filePath": "snap/_authenticated/settings.rules.tsx",
      "parent": "/snap/_authenticated"
    },
    "/snap/_authenticated/settings/units": {
      "filePath": "snap/_authenticated/settings.units.tsx",
      "parent": "/snap/_authenticated",
      "children": [
        "/snap/_authenticated/settings/units/$key",
        "/snap/_authenticated/settings/units/new"
      ]
    },
    "/snap/_authenticated/settings/users": {
      "filePath": "snap/_authenticated/settings.users.tsx",
      "parent": "/snap/_authenticated",
      "children": [
        "/snap/_authenticated/settings/users/$key",
        "/snap/_authenticated/settings/users/new"
      ]
    },
    "/snap/_authenticated/taskboard/$key": {
      "filePath": "snap/_authenticated/taskboard.$key.tsx",
      "parent": "/snap/_authenticated/taskboard"
    },
    "/snap/_authenticated/taskboard/new-task": {
      "filePath": "snap/_authenticated/taskboard.new-task.tsx",
      "parent": "/snap/_authenticated/taskboard"
    },
    "/snap/_unauthenticated/login/code": {
      "filePath": "snap/_unauthenticated/login/code.tsx",
      "parent": "/snap/_unauthenticated"
    },
    "/snap/_unauthenticated/login/email": {
      "filePath": "snap/_unauthenticated/login/email.tsx",
      "parent": "/snap/_unauthenticated"
    },
    "/snap/_unauthenticated/login/phone": {
      "filePath": "snap/_unauthenticated/login/phone.tsx",
      "parent": "/snap/_unauthenticated"
    },
    "/snap/_unauthenticated/login/": {
      "filePath": "snap/_unauthenticated/login/index.tsx",
      "parent": "/snap/_unauthenticated"
    },
    "/snap/_authenticated/integrations/apaleo/taskboard": {
      "filePath": "snap/_authenticated/integrations.apaleo.taskboard.tsx",
      "parent": "/snap/_authenticated"
    },
    "/snap/_authenticated/settings/account/information": {
      "filePath": "snap/_authenticated/settings.account.information.tsx",
      "parent": "/snap/_authenticated/settings/account"
    },
    "/snap/_authenticated/settings/account/subscription": {
      "filePath": "snap/_authenticated/settings.account.subscription.tsx",
      "parent": "/snap/_authenticated/settings/account"
    },
    "/snap/_authenticated/settings/categories/$key": {
      "filePath": "snap/_authenticated/settings.categories.$key.tsx",
      "parent": "/snap/_authenticated/settings/categories"
    },
    "/snap/_authenticated/settings/categories/new": {
      "filePath": "snap/_authenticated/settings.categories.new.tsx",
      "parent": "/snap/_authenticated/settings/categories"
    },
    "/snap/_authenticated/settings/rules_/$key": {
      "filePath": "snap/_authenticated/settings.rules_.$key.tsx",
      "parent": "/snap/_authenticated"
    },
    "/snap/_authenticated/settings/rules_/new": {
      "filePath": "snap/_authenticated/settings.rules_.new.tsx",
      "parent": "/snap/_authenticated"
    },
    "/snap/_authenticated/settings/units/$key": {
      "filePath": "snap/_authenticated/settings.units.$key.tsx",
      "parent": "/snap/_authenticated/settings/units"
    },
    "/snap/_authenticated/settings/units/new": {
      "filePath": "snap/_authenticated/settings.units.new.tsx",
      "parent": "/snap/_authenticated/settings/units"
    },
    "/snap/_authenticated/settings/users/$key": {
      "filePath": "snap/_authenticated/settings.users.$key.tsx",
      "parent": "/snap/_authenticated/settings/users"
    },
    "/snap/_authenticated/settings/users/new": {
      "filePath": "snap/_authenticated/settings.users.new.tsx",
      "parent": "/snap/_authenticated/settings/users"
    }
  }
}
ROUTE_MANIFEST_END */
