import type { AreaStruct, IssueStruct, TaskStruct } from '@shared/firestore-structs'
import generalTaskIcon from '@shared/img/general-task.svg'
import notDownloaded from '@shared/img/not-downloaded.svg'
import { constructBreadcrumbs } from '@shared/task-helpers'
import type { TaskboardContext } from '@shared/traces-types'
import moment from 'moment'
import { useMemo } from 'react'
import { useI18nContext } from '../../../../i18n/i18n-react'
import { type Size, UI } from '../../../registry/ui-elements-registry'
import type { CleaningStatusColor, Icon } from '../../../types/visuals.types'
import { useCurrentOrg } from '../../auth/hooks/use-auth-state'
import { Name } from '../../issues/ui/issue'

interface AreaInfoProps {
    context: TaskboardContext
    item: TaskStruct
    bold?: boolean
}

export interface GeneralVisualsRenderer {
    priority: TaskStruct['priority']
    src: string
    className?: string
}

export interface HousekeepingVisualsRenderer {
    areaKey: string
    color?: CleaningStatusColor
    priority?: TaskStruct['priority']
    areaName?: AreaStruct['name']
    icons?: Icon[]

    className?: string
}

export interface IssueVisualsRenderer {
    priority: TaskStruct['priority']
    src: 'specta-text-issue' | string
    className?: string
}

interface VisualsProps {
    task: TaskStruct
    Render: {
        General: (props: GeneralVisualsRenderer) => JSX.Element | null
        Housekeeping: (props: HousekeepingVisualsRenderer) => JSX.Element | null
        Issue: (props: IssueVisualsRenderer) => JSX.Element | null
    }
    className?: string
}

interface HousekeepingVisualsProps {
    areaKey: string
    className?: string
}

interface IssueVisualsProps {
    lastItemKey: IssueStruct['lastItemKey']
    lastThumbUrl: IssueStruct['lastThumbUrl']
    priority: TaskStruct['priority']
    Issue: VisualsProps['Render']['Issue']
    className?: string
}

export interface DueDateRenderer {
    date: string
    icon: 'specta-duedate'
    overdue: boolean
}

export function AreaInfo({ item, context, bold = false }: AreaInfoProps) {
    const breadcrumbs = constructBreadcrumbs(item, context)

    return (
        <UI.Text size="sm" color="sweeply-gray" weight={bold ? 'semibold' : 'normal'}>
            {breadcrumbs.join(' > ')}
        </UI.Text>
    )
}

export function Visuals({ task, Render, className }: VisualsProps) {
    switch (task.type) {
        case 'general':
            return <Render.General priority={task.priority} src={generalTaskIcon} className={className} />
        case 'cleaning': {
            // Cleaning tasks always have an area
            return <HousekeepingVisuals areaKey={task.area!.key!} Housekeeping={Render.Housekeeping} className={className} />
        }
        case 'issue':
            return (
                <IssueVisuals
                    lastItemKey={task.lastItemKey}
                    lastThumbUrl={task.lastThumbUrl}
                    priority={task.priority}
                    Issue={Render.Issue}
                    className={className}
                />
            )
        default:
            return <UI.ErrorMessage>Task type is wrong</UI.ErrorMessage>
    }
}

export function HousekeepingVisuals({
    areaKey,
    Housekeeping,
    className
}: HousekeepingVisualsProps & { Housekeeping: VisualsProps['Render']['Housekeeping'] }) {
    return <Housekeeping areaKey={areaKey} className={className} />
}

export function IssueVisuals({ lastItemKey, lastThumbUrl, priority, Issue, className }: IssueVisualsProps) {
    if (lastItemKey === 'text-issue') {
        return <Issue priority={priority} src={'specta-text-issue'} className={className} />
    }

    if (lastThumbUrl && lastThumbUrl.length >= 5 && lastThumbUrl.slice(0, 4) === 'http') {
        return <Issue priority={priority} src={lastThumbUrl} className={className} />
    }

    return <Issue priority={priority} src={notDownloaded} className={className} />
}

export function IssueName({ name, size = 'md' }: { name: string; size?: Size }) {
    return (
        <Name
            name={name}
            Render={({ name, hashtags }) => (
                <div className="flex flex-row gap-x-2 flex-wrap">
                    <UI.Text size={size} weight="normal">
                        {name}
                    </UI.Text>
                    {hashtags.map(hashtag => (
                        <div key={hashtag} className="flex items-center gap-2">
                            <UI.Text weight="normal" color="snap-light-blue" size={size}>
                                {hashtag}
                            </UI.Text>
                        </div>
                    ))}
                </div>
            )}
        />
    )
}

export function TaskName({ task }: { task: TaskStruct }) {
    if (task.type === 'issue') {
        return <IssueName name={task.name!} size="sm" />
    } else {
        return <UI.Text size="sm">{task.name}</UI.Text>
    }
}

export function DueDate({
    startDate,
    Render,
    renderNoDueDate = true
}: {
    startDate: TaskStruct['startDate']
    Render: (props: DueDateRenderer) => JSX.Element
    renderNoDueDate?: boolean
}) {
    const currentOrg = useCurrentOrg()
    const { LL } = useI18nContext()
    const date = useMemo(() => startDate && moment.tz(startDate, currentOrg.timezone).format('MMM D'), [startDate])
    const overdue = useMemo(() => {
        if (!startDate) return false
        const taskDate = moment.tz(startDate, currentOrg.timezone)
        const now = moment().tz(currentOrg.timezone)
        return taskDate.isBefore(now, 'day')
    }, [startDate, currentOrg.timezone])

    return date ? (
        <Render date={date} icon="specta-duedate" overdue={overdue} />
    ) : (
        <>
            {renderNoDueDate && (
                <UI.Text size="md" color="snap-red">
                    {LL.shared.itemsMetadata.noDueDate()}
                </UI.Text>
            )}
        </>
    )
}

export function AssignedTo({ assignedTo }: { assignedTo: TaskStruct['assignedTo'] }) {
    if (!assignedTo) return null

    return (
        <UI.View className="flex gap-1">
            {assignedTo.slice(0, 3).map((user, index) => (
                <UI.InitialsCircle key={index} initials={user.initials} name={user.name} size="sm" />
            ))}
            {assignedTo.length > 3 && <UI.InitialsCircle size="sm" initials={`+${assignedTo.length - 3}`} />}
        </UI.View>
    )
}

export function ChangedInfo({ updated, Render }: { updated: TaskStruct['updated']; Render: (props: { text: string }) => JSX.Element }) {
    const currentOrg = useCurrentOrg()
    const lastUpdatedTime = useMemo(() => moment.tz(updated, currentOrg.timezone).format('HH:mm'), [updated])
    const lastUpdated = useMemo(() => moment.tz(updated, currentOrg.timezone).format('ddd, MMM D, YYYY'), [updated])
    const { LL } = useI18nContext()

    return <Render text={LL.shared.itemsMetadata.changedInfo({ lastUpdatedTime, lastUpdated })} />
}

export function CreatedInfo({ created, Render }: { created: TaskStruct['created']; Render: (props: { text: string }) => JSX.Element }) {
    const currentOrg = useCurrentOrg()
    const createdDate = useMemo(() => moment.tz(created, currentOrg.timezone).format('ddd, MMM D, YYYY'), [created])
    const { LL } = useI18nContext()

    return <Render text={LL.shared.itemsMetadata.createdInfo({ createdDate })} />
}
